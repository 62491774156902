import { put, call, takeEvery } from 'redux-saga/effects';
import { apiCall } from 'common/utils/api-helpers';
import { actions as authActions } from 'modules/auth/state/auth.slice';
import type { ForkEffect } from 'redux-saga/effects';
import { actions } from './membership.slice';

function* fetchSubscription() {
  try {
    yield put(actions.fetchSubscriptionStarted());
    const { data } = yield call(apiCall, 'subscription', 'get', null);
    yield put(actions.fetchSubscriptionSucceeded(data));
  } catch (e) {
    yield put(actions.fetchSubscriptionFailed());
  }
}

export function* fetchSubscriptionSaga(): Generator<
  ForkEffect<never>,
  void,
  unknown
  > {
  yield takeEvery(
    [
      authActions.signInUserSucceeded,
      authActions.googleAuthSucceeded,
      authActions.facebookAuthSucceeded,
      authActions.refreshAuthSucceeded,
      authActions.resendEmailVerificationSucceeded,
    ],
    fetchSubscription,
  );
}
