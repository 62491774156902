/* eslint-disable camelcase */
import { Mixpanel, eventNames } from 'common/utils/mixpanel';

type AddThoughtEventPropTypes = {
  privacy: 'public' | 'private';
  kind: 'note' | 'journal';
}
type AddAnnotationEventPropTypes = AddThoughtEventPropTypes & {
  book_title: string;
  book_author: string;
  chapter_number: number;
  verse_number?: number;
};

export const trackShowThoughtEditor = (): void =>
  Mixpanel.trackEvent(eventNames.view_thought_editor);

export const trackAddThought = ({ privacy, kind }: AddThoughtEventPropTypes): void =>
  Mixpanel.trackEvent(eventNames.add_thought, { privacy, kind });

export const trackUpdateThought = ({ privacy, kind }: AddThoughtEventPropTypes): void =>
  Mixpanel.trackEvent(eventNames.update_thought, { privacy, kind });

export const trackAddAnnotation = ({
  privacy,
  kind,
  book_title,
  book_author,
  chapter_number,
  verse_number,
}: AddAnnotationEventPropTypes): void => Mixpanel.trackEvent(eventNames.add_thought, {
  privacy,
  kind,
  book_title,
  book_author,
  chapter_number,
  verse_number,
});
