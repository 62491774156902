import type { ChangeEvent } from 'react';
import React from 'react';
import styled from 'styled-components';

export const SelectAccessType = styled.div`
  position: relative;
  padding: 10px 20px 10px 0;
  color: ${({ theme: { colors } }) => colors.colorText};
`;

export const Select = styled.select`
  width: 100%;
  height: 100%;
  line-height: 1.6em;
  outline: 0;
  font-size: 1.4rem;
  border: unset;
  margin-right: .5rem;
  background: ${({ theme: { colors } }) => colors.colorBackground};
  border-radius: 0.5em;
  color: ${({ theme: { colors } }) => colors.colorText};
`;

export const SelectOption = styled.option`
  border: unset;
  outline: 0 !important;
  text-align: center;
  background: ${({ theme: { colors } }) => colors.colorPrimary4} !important;
`;

type Props = {
  className?: string;
  accessType: string;
  activityType: 'Note' | 'Journal';
  setAccessType: (event: ChangeEvent<HTMLSelectElement>) => void;
};
// TODO: Rename file to Selector
const AccessTypeSelector = ({
  className,
  accessType,
  setAccessType,
  activityType,
}: Props) => (
  <SelectAccessType>
    <Select
      className={className}
      name="accessType"
      onChange={setAccessType}
      value={accessType}
    >
      <SelectOption value="private">{`Private ${activityType}`}</SelectOption>
      <SelectOption value="public">{`Public ${activityType}`}</SelectOption>
    </Select>
  </SelectAccessType>
);

export default AccessTypeSelector;
