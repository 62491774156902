import type { GenericPagination } from 'common/types';
import { apiCall } from 'common/utils/api-helpers';
import type {
  JournalCreationParamType,
  JournalUpdateParamType,
} from '../state/journals.actions';

export type ThoughtPagination = { thoughts: Journal[]; pagination: GenericPagination };

export type ThoughtAuthor = {
  id: number;
  name: string;
  username: string;
  email: string;
};

export interface VerseOffsets {
  startOffset: number;
  endOffset: number;
}

export type ThoughtAccessType = 'private' | 'public' | 'community' | 'friends';
export interface Thought {
  id: number;
  slug: string;
  quote?: string;
  content: string;
  kind: 'journal' | 'note';
  accessType: ThoughtAccessType;
  authorId: number;
  author: ThoughtAuthor;
  thinkableId: number | null;
  thinkableType: 'verse' | 'chapter' | null;
  verseOffsets: VerseOffsets | null;
  createdAt?: string;
  updatedAt?: string;
}

export type Note = Thought & {
  kind: 'note';
};

export type Journal = Thought & {
  title?: string;
  kind: 'journal';
};

export type JournalVerse = {
  verseId: number;
  journals?: Journal[];
}


export const saveJournal = async (
  journal: JournalCreationParamType,
): Promise<Journal> => {
  const { data } = await apiCall('thoughts', 'post', journal);
  return data;
};

export const updateJournal = async (
  journal: JournalUpdateParamType,
): Promise<Journal> => {
  const { data } = await apiCall(`thoughts/${journal.id}`, 'put', journal);
  return data;
};

export const fetchUserJournals = async (
  pageNumber: number,
  limit = 5,
): Promise<ThoughtPagination> => {
  const { data } = await apiCall(
    `thoughts?page=${pageNumber}&limit=${limit}&kind=journal`,
    'get',
    null,
  );
  return data;
};

export const loadMoreJournals = async (
  pageNumber: number,
  limit = 5,
): Promise<ThoughtPagination> => {
  const { data } = await apiCall(
    `thoughts?page=${pageNumber}&limit=${limit}&kind=journal`,
    'get',
    null,
  );
  return data;
};

export const deleteJournal = async (journalId: number): Promise<number> => {
  const { data } = await apiCall(`thoughts/${journalId}`, 'delete', null);
  return data;
};

export const exportJournal = async (journalId: number): Promise<Journal> => {
  const { data } = await apiCall(`export/thoughts/${journalId}`, 'get', null);
  return data;
};
