import { createSlice } from '@reduxjs/toolkit';
import { SETTINGS } from 'modules/settings/constants';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { ThemeType } from 'modules/settings/utils/settingsUtils';
import type { RootState } from 'app/state/store';

export interface State {
  isDarkMode: boolean;
  theme: ThemeType;
}

export const initialState: State = {
  isDarkMode: false,
  theme: 'light',
};

const settingsSlice = createSlice({
  name: SETTINGS,
  initialState,
  reducers: {
    toggleDarkMode: (state, action: PayloadAction<ThemeType>) => ({
      ...state,
      isDarkMode: !state.isDarkMode,
      theme: action.payload
    }),
    setTheme: (state, action: PayloadAction<ThemeType>) => ({
      ...state,
      theme: action.payload
    }),
  }
});

export const { actions } = settingsSlice;

export const settingsReducer = settingsSlice.reducer;

export const selectSettings = (state: RootState): State => state[SETTINGS];
