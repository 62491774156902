import styled, { css } from 'styled-components';
import { setFlex, setFont } from 'app/globalStyles/variables';
import {
  isDesktopOrLarger,
  isTabletOrLarger,
  isTabletOrSmaller,
} from 'app/globalStyles/screens';
import { SubmitButton as StyledSubmitBtn } from 'common/components/styled-components';
import { LoginButton } from 'modules/auth/components/AuthButtons';

export const StyledPage = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem 0;
  ${setFlex({ x: 'space-between', y: 'stretch' })};
  ${isTabletOrSmaller(css`
    width: 100%;
    margin-bottom: 100px;
    flex-direction: column-reverse;
    align-items: center;
  `)}
`;

export const FormSection = styled.div`
  width: 100%;
  ${setFlex({ x: 'start' })};
  flex-direction: column;
  ${isTabletOrLarger(css`
    width: 400px;
  `)}
`;

export const FormHeroSection = styled.div`
  margin-top: 30px;
  margin-bottom: 10px;
  width: 100%;
  margin-right: unset;
  ${isTabletOrLarger(css`
    width: initial;
    margin-right: initial;
  `)}
`;

export const PageHeading = styled.h2`
  font-weight: 500;
  margin: 6px 0 12px;
  font-size: 28px;
  line-height: 32px;
  max-width: 400px;
  width: 100%;
  ${isTabletOrLarger(css`
    width: initial;
  `)}
`;

export const SubHeading = styled.div`
  margin: 1.6rem 0;
  font-size: 1.8rem;
  line-height: 1.6;
    width: 100%;
  ${isTabletOrLarger(css`
    width: initial;
  `)}
  div {
    margin: 30px 0;
    font-size: 16px;
  }
  p {
    margin-top: 10px;
    font-size: 16px;
  }
`;

export const StyledForm = styled.form`
  width: 100%;
  ${isTabletOrLarger(css`
    width: 90%;
    margin: auto;
  `)}
`;

const formRowStyles = css`
  border-radius: 5px;
  padding: 12px 12px;
  width: 100%;
  display: block;
  background: inherit;
  font-size: 16px;
  font-family: inherit;
  margin-top: 10px;
  color: inherit;
  border: 1px solid ${({ theme: { colors } }) => colors.colorBorder};
`;

export const Label = styled.label`
  font-size: 16px;
  font-weight: 300;
  letter-spacing: 0.025em;
  display: block;
  margin-bottom: 30px;
`;

export const CardError = styled.div`
  font-size: 16px;
  color: ${({ theme: { colors } }) => colors.mainRed};
`;

export const StyledCardElement = styled.div`
  input,
  .StripeElement {
    ${formRowStyles};
    color: ${({ theme: { colors } }) => colors.colorText};
  }

  input::placeholder {
    color: #aab7c4;
  }

  .StripeElement.IdealBankElement,
  .StripeElement.FpxBankElement,
  .StripeElement.PaymentRequestButton {
    padding: 0;
  }

  .StripeElement.PaymentRequestButton {
    height: 40px;
  }
`;

export const SubmitButton = styled<any>(StyledSubmitBtn)`
  width: 100%;
  height: 4.5rem;
  transition: box-shadow 2s;
  font-family: ${setFont.main};
  margin-bottom: 20px;
  position: relative;
  ${setFlex()};
  :disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

export const Plan = styled.div`
  ${setFlex()};
  flex-direction: column;
  border-radius: 5px;
  border: 0.1px solid ${({ theme: { colors } }) => colors.colorBorder};
  padding: 40px 0 30px 0;
  width: 100%;
  ${isTabletOrLarger(css`
    width: 400px;
  `)}
`;

export const PlanHeading = styled.h3`
  margin: 0 auto 20px auto;
  font-size: 20px;
  font-weight: 500;
`;

export const PlanPrice = styled.p`
  font-family: ${setFont.headings};
  margin-bottom: 20px;
  font-size: 20px;
`;

export const PlanFeatures = styled.ul`
  list-style-type: none;
  font-size: 16px;
  max-width: 80%;
  li {
    margin-bottom: 10px;
    ul {
      padding-top: 10px;
      list-style-type: none;
      margin-left: 10%;
    }
  }
`;

export const TogglePlan = styled<any>(Label)`
  ${setFlex({ x: 'start' })};
`;

export const SelectPlanButton = styled<any>(SubmitButton)`
  margin-bottom: 30px;
  padding: 15px 40px;
  text-transform: unset;
  max-width: 70%;
  :hover {
    cursor: pointer;
  }
  ${isTabletOrLarger(css`
    max-width: 40%;
  `)}
`;

// @ts-ignore
export const StyledLoginButton = styled(LoginButton)`
  width: 50%;
  margin: 20px 0;
  border: 1px solid ${({ theme: { colors } }) => colors.colorBorder};
`;

export const paymentModalStyles = css`
  width: 90%
  ${isTabletOrLarger(css`
    width: 400px;
 `)};
`;

export const MembershipPlansSection = styled.div`
  ${setFlex({ x: 'flex-start', y: 'center' })}
  flex-direction: column;
  height: 100%;
  max-width: 569px;
  margin-top: 2rem;
  margin-bottom: 5rem;
  ${isTabletOrSmaller(css`
    margin-top: 5rem;
  `)}
  ${isTabletOrLarger(css`
    margin-top: initial;
    margin-bottom: initial;
  `)}
`;

export const MembershipPlansHero = styled.header`
  ${setFlex()};
  flex-direction: column;
  margin: 0 auto 5rem auto;
  margin-bottom: 3rem;
  width: 100vw;
  padding: 0 1rem;
  ${isTabletOrLarger(css`
    margin-bottom: 5rem;
    width: initial;
    padding: 0 1rem;
  `)}
`;

export const MembershipPlans = styled.div`
  ${setFlex()};
  ${isDesktopOrLarger(css`
    width: 100%;
    flex-direction: row;
  `)}
  width: 100%;
  flex-flow: row nowrap;
  margin-bottom: 3.5rem;
  padding: 0 1rem;
  ${isTabletOrLarger(css`
    width: 100%;
    flex-flow: initial;
    margin-bottom: 5rem;
    padding: initial;
  `)}
`;

export const StyledMembershipPlan = styled.div<{ selected: boolean }>`
  border: 1px solid ${(p) => p.theme.colors.colorBorder};
  background: ${(p) => p.theme.colors.colorPrimary4};

  border-radius: 5px;
  &:nth-of-type(2) {
    margin-left: 0;
  }

  ${(p) =>
    p.selected
    && css`
      border-color: ${(p) => p.theme.colors.colorPrimary2};
      transition: border-color 0.2s linear;
    `}
     margin: 0 1rem;
    width: 50%;
    &:nth-of-type(2) {
      margin-left: 0;
    }

  ${isTabletOrLarger(css`
    margin: initial;
    width: 250px;
    &:nth-of-type(2) {
      margin-left: 2rem;
    }
  `)}
`;

export const OptionContent = styled.div`
  position: relative;
  padding: 2rem;
  background: ${(p) => p.theme.colors.colorBackground};
  flex-direction: column;
  ${setFlex({ x: 'stretch', y: 'center' })};
  height: 200px;
  
`;

export const memberShipBenefitIconstyles = {
  iconStyles: css`
    width: 16px;
    color: ${({ theme: { colors } }) => colors.colorPrimary3};
    margin-right: 10px;
  `,
  svgIconStyle: css`
    width: 16px;
    height: 16px;
    color: ${({ theme: { colors } }) => colors.colorPrimary3};
    margin-right: 10px;
    // ensures dynamically changing svg color in dark mode.
    path {
      fill: ${({ theme: { colors } }) => colors.colorPrimary3};
    }
  `,
};
