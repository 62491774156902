/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import type { NextRouter } from 'next/router';
import { withRouter } from 'next/router';
import Layout from 'common/page-layout/Layout';
import { updateChapter } from 'modules/admin/state/admin.actions';
import {
  EditDashboardContent,
  EditBookDetails,
  BackToChapterDetails,
} from 'modules/admin/components/__styles__/DashboardContent.styles';
import type { Book } from 'modules/books/utils/booksApiUtils';
import type { Chapter, Verse } from 'modules/reading/utils/api/chapterApiUtils';
import { selectAdmin } from 'modules/admin';
import { useAppDispatch, useAppSelector } from 'common/hooks/state-hooks';
import toast from '../../../common/components/Toast';
import Sidebar from '../components/Sidebar';
import Verses from '../components/Verses';

interface OwnProps {
  currentBook: Book;
  currentChapter: Chapter | null;
  chapterVerses: Verse[];
  fetchSelectedChapterVerses: (chapterSlug: string) => void;
}

interface InputProps {
  router: NextRouter;
}

export type Props = InputProps & OwnProps;

const EditBookPage = (props: Props) => {
  const {
    currentBook,
    currentChapter,
    chapterVerses,
    fetchSelectedChapterVerses,
  } = props;

  const { chapterUpdated, chapterUpdateError } = useAppSelector(selectAdmin);
  const dispatch = useAppDispatch();

  const initialChapterState = { chapter: null, index: -1 };
  const [selectedChapter, setSelectedChapter] = useState<{
    chapter: Chapter | null;
    index: number;
  }>(initialChapterState);

  const [book, setBook] = useState<Book | null>(null);
  const [displayVerse, setDisplayVerse] = useState<boolean>(false);

  const handleChapterClick = (chapter: Chapter, index: number) => {
    fetchSelectedChapterVerses(chapter.slug);
    setSelectedChapter({ chapter, index });
  };

  useEffect(() => {
    if (currentBook) {
      setBook(currentBook);
      setSelectedChapter((prevChapter) => ({
        ...prevChapter,
        chapter: currentChapter,
      }));
    }
  }, [book]);

  useEffect(() => {
    if (currentChapter) {
      setSelectedChapter((prevChapter) => ({
        ...prevChapter,
        chapter: currentChapter,
      }));
    }
  }, [currentChapter]);

  const chapterFieldChangeHandler = (e: any, index: number) => {
    const { name, value } = e.target;

    setBook((prevBook: Book) => {
      const chapters = [...(prevBook?.chapters as Chapter[])];
      chapters[index] = { ...chapters[index], [name]: value };

      return { ...prevBook, chapters };
    });
  };

  const chapterFieldSaveHandler = async ({ id, bookId, name, value }) => {
    dispatch(updateChapter({ id, bookId, [name]: value }));

    if (chapterUpdateError) {
      toast({ message: chapterUpdateError, type: 'error' });
    }

    if (!chapterUpdateError && chapterUpdated) {
      toast({ message: 'Saved', type: 'success' });
    }
  };
  const { chapter, index } = selectedChapter;

  return (
    <Layout>
      <EditDashboardContent>
        <Sidebar
          book={book}
          selectedChapter={chapter}
          handleChapterClick={handleChapterClick}
        />
        <EditBookDetails>
          <h2>
            {chapter !== null && !displayVerse ? (
              'Chapter Details'
            ) : (
              <>
                <BackToChapterDetails
                  onClick={() => setDisplayVerse((prevDisplay) => !prevDisplay)}
                >
                  {' '}
                  Chapter Details
                </BackToChapterDetails>
                {' '}
                Verses
              </>
            )}
          </h2>
          <Verses
            book={book}
            index={index}
            chapter={chapter}
            chapterVerses={chapterVerses}
            chapterFieldSaveHandler={chapterFieldSaveHandler}
            chapterFieldChangeHandler={chapterFieldChangeHandler}
            setDisplayVerse={setDisplayVerse}
            displayVerse={displayVerse}
          />
        </EditBookDetails>
      </EditDashboardContent>
    </Layout>
  );
};

const WithRouter = withRouter(EditBookPage);

export default WithRouter;
