import { combineReducers } from '@reduxjs/toolkit';
import type { State as SettingsState } from 'modules/reading/state/slices/settings.slice';
import { settingsReducer, initialState as initialSettingsState } from 'modules/reading/state/slices/settings.slice';
import type { State as HighlightsState } from 'modules/reading/state/slices/highlights.slice';
import { highlightsReducer, initialHighlightsState } from 'modules/reading/state/slices/highlights.slice';
import type { State as BookmarksState } from 'modules/reading/state/slices/bookmarks.slice';
import { bookmarksReducer, initialBookmarksState } from 'modules/reading/state/slices/bookmarks.slice';
import type { State as ChapterState } from 'modules/reading/state/slices/chapter.slice';
import { chapterReducer, initialState as initialChapterState } from 'modules/reading/state/slices/chapter.slice';
import type { State as JournalsState } from 'modules/reading/state/slices/journals.slice';
import { journalsReducer, initialState as initialJournalsState } from 'modules/reading/state/slices/journals.slice';
import {
  CHAPTER,
  BOOKMARKS,
  HIGHLIGHTS,
  SETTINGS,
  JOURNALS,
} from 'modules/reading/constants';

export type State = {
  [CHAPTER]: ChapterState;
  [HIGHLIGHTS]: HighlightsState;
  [BOOKMARKS]: BookmarksState;
  [SETTINGS]: SettingsState;
  [JOURNALS]: JournalsState;
};

export const initialState = {
  [CHAPTER]: initialChapterState,
  [HIGHLIGHTS]: initialHighlightsState,
  [BOOKMARKS]: initialBookmarksState,
  [SETTINGS]: initialSettingsState,
  [JOURNALS]: initialJournalsState,
};

export const readingReducer = combineReducers<State>({
  [CHAPTER]: chapterReducer,
  [HIGHLIGHTS]: highlightsReducer,
  [BOOKMARKS]: bookmarksReducer,
  [SETTINGS]: settingsReducer,
  [JOURNALS]: journalsReducer,
});
