import React, { useState, useEffect } from 'react';
import {
  StyledDropDown,
  Button,
  MoreIcon,
  MenuContainer,
} from 'common/components/__styles__/DropDownMenu.styles';

export interface Props {
  buttonElement?: JSX.Element;
  buttonStyles?: any; // todo
  children: JSX.Element | Array<JSX.Element>;
}

const DropDownMenu = ({ buttonElement, buttonStyles, children }: Props) => {
  const popUpRef: React.RefObject<HTMLDivElement> = React.createRef();
  const moreBtnRef: React.RefObject<HTMLButtonElement> = React.createRef();

  const [popUpVisible, setPopUpVisible] = useState(false);

  const closePopUp = () => {
    setPopUpVisible(false);
  };

  const openPopUp = (e) => {
    e.stopPropagation();
    setPopUpVisible(true);
  };

  const handleClosePopUp = (e) => {
    // If popUp is visible & click event is neither on the more icon nor popUp, close the popUp
    if (popUpVisible) {
      if (
        !moreBtnRef?.current?.contains(e.target)
        && !popUpRef?.current?.contains(e.target)
      ) {
        closePopUp();
      }
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClosePopUp);
    return () => {
      document.removeEventListener('click', handleClosePopUp);
    };
  }, [setPopUpVisible, popUpVisible]);

  return (
    <StyledDropDown>
      {buttonElement || (
        <Button style={buttonStyles} type="button" ref={moreBtnRef} onClick={openPopUp}>
          <MoreIcon />
        </Button>
      )}

      {popUpVisible && (
        <MenuContainer ref={popUpRef}>{children}</MenuContainer>
      )}
    </StyledDropDown>
  );
};

export default DropDownMenu;
