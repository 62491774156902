import styled from 'styled-components';
import {
  setFlex,
  setPosition,
  setFont, setZindex,
} from 'app/globalStyles/variables';
import { isTabletOrLarger } from 'app/globalStyles/screens';
import { Button } from './SettingsV2.styles';

// Confirm Delete Modal
export const ModalOverlay = styled.div`
  position: ${setPosition.fixed};
  top: 0;
  left: 0;
  bottom: 0;
  height: 100%;
  right: 0;
  background: ${({ theme: { colors } }) => colors.colorOverlay};
  overflow-y: scroll;
  z-index: ${setZindex.high};
  ${setFlex()}
`;

export const ConfirmDeleteModal = styled.div`
  width: 90%;
  position: relative;
  background: ${({ theme: { colors } }) => colors.colorBackground};
  opacity: 1;
  ${setFlex({ x: 'start', y: 'center' })};
  flex-direction: column;
  border: 1px solid ${({ theme: { colors } }) => colors.colorBorder};
  z-index: 100;
  ${isTabletOrLarger`
      width: 37.5%;
  `}
`;
export const ModalHeading = styled.h1`
  font-size: 3rem;
  text-align: center;
  letter-spacing: -0.444444px;
  font-family: ${setFont.headings};
  margin: 5rem auto 3rem auto;
`;

export const CloseModal = styled.button`
  position: ${setPosition.absolute};
  top: 20px;
  color: inherit;
  right: 30px;
  font-size: 2rem;
  font-weight: 400;
  border: unset;
  cursor: pointer;
  background: transparent;
  outline: unset;
`;

export const ConfirmDeleteButton = styled<any>(Button)`
  color: ${({ theme: { colors } }) => colors.colorTextWhite};
  background: ${({ theme: { colors } }) => colors.mainRed};
  width: 75%;
  position: relative;
  margin-bottom: 5rem;
  ${setFlex()};
`;

export const WarningInstruction = styled.div`
  font-size: 1.6rem;
  line-height: 2.5rem;
  text-align: center;
  vertical-align: center;
  letter-spacing: -0.166667px;
  color: ${({ theme: { colors } }) => colors.colorPrimary2};
  max-width: 75%;
`;
