import styled, { css } from 'styled-components';
import { setFont, setFlex } from 'app/globalStyles/variables';
import {
  isTabletOrSmaller,
  isTabletOrLarger
} from 'app/globalStyles/screens';

export const DashboardContent = styled.div`
  background: ${({ theme: { colors } }) => colors.colorBackground};
  padding-top: 2.6rem;
  padding-bottom: 15rem;
  ${isTabletOrLarger(css`
  font-size: initial;
  border: none; 
  min-height: 100vh; 
  margin: 0 auto;
  width: 75%;
  `)}
  `;
export const DashboardHeaderText = styled.h2`
   font-style: italic;
   `;

export const DashboardContentBox = styled.div` 
   display: flex;
   max-height: 100vh;
   overflow: auto;
`;

export const BookSidebar = styled.div`
    display: flex;
    overflow: auto;
    flex-basis: 30%;
    min-height: 100%;
    border: 1px solid ${({ theme: { colors } }) => colors.colorBorder};
    display: flex;
    flex-direction: column; 
    box-sizing: border-box;
    margin: 0;
`;

export const BookDetails = styled.div`
  flex-basis: 70%; 
  min-height: 100%;
  overflow: auto;
  display: flex;
  flex-direction: column;
  ${setFlex({ x: 'flex-start' })};
  border: 1px solid ${({ theme: { colors } }) => colors.colorBorder}; 
  h2 {
    font-family: ${setFont.headings};
    font-weight: normal;
    width: 100%;
    height: 80px;
    position: sticky;
    top: 0;
    padding: 20px 0;
    z-index: 3;
    background: ${({ theme: { colors } }) => colors.colorBackground};
    font-size: 3.0rem;
    margin: 0 auto 1.2rem auto;
    text-align: center;
    ${isTabletOrSmaller`
      width: 75%;
    `};
  }
`;
export const ChaptersButton = styled.button`
  background: ${({ theme: { colors } }) => colors.colorBackground};
  border: 1px solid ${({ theme: { colors } }) => colors.colorBorder}; 
  padding: 10px 20px; 
  width: 94%; 
  margin-top: 10px;
  margin-left: 20px;
  font-size: 1.6rem;
  &:hover {
    background: ${({ theme: { colors } }) => colors.colorPrimary4}
  }
`;

export const EditDashboardContent = styled.div`
  background: ${({ theme: { colors } }) => colors.colorBackground};
  width: 90%;
  margin: 0 auto;
  min-height: 100vh; 
  font-size: 1.8rem;
  display: flex;
  border: 1px solid ${({ theme: { colors } }) => colors.colorBorder}; 
  ${isTabletOrLarger(css`
    font-size: initial;
    width: 75%;
    max-height: 80vh; 
    margin: 40px auto;
  `)}
  h2 {
    font-family: ${setFont.headings};
    font-weight: normal;
    width: 100%;
    height: 80px;
    position: sticky;
    top: 0;
    padding: 20px 0;
    z-index: 3;
    background: ${({ theme: { colors } }) => colors.colorBackground};
    font-size: 3.0rem;
    margin: 0 auto 1.2rem auto;
    text-align: center;
    ${isTabletOrSmaller`
      width: 75%;
    `};
  }
  .titleLine {
    width: 15%;
    text-align: left;
    border-bottom: 2px solid ${({ theme: { colors } }) => colors.colorPrimary1};
    margin: 5px auto;
  }
  .verses {
    width: 80%;
    margin: 0 auto;
    padding-top: 1rem;
    ${isTabletOrSmaller`
      width: 75%;
    `};
    ${isTabletOrLarger`
      width: 55%;
    `}
    .verse {
      cursor: pointer;
      color: ${({ theme: { colors } }) => colors.colorText};
      text-align: left;
      text-indent: .5rem;
      background: ${({ theme: { colors } }) => colors.colorBackground};
      margin: 0 auto;
      font-size: 2rem;
      letter-spacing: -0.25px;
      ${isTabletOrLarger`
        text-align: left;
      `}
      .highlight {
        background: yellow;
        color: ${({ theme: { colors, isDarkTheme } }) => (isDarkTheme ? colors.colorBackground : colors.colorText)};
        border-radius: 15px;
      }
    }
    .selected {
      text-decoration: underline
    }
  }
`;

export const EditBookDetails = styled.div`
  flex-basis: 70%;
  position: relative; 
  min-height: 100%;
  overflow: auto;
`;

// VERSES
export const BackToChapterDetails = styled.button`
    padding: 3px 5px;
    height: 25px;
    position: absolute;
    border-radius: 10px;
    font-size: 1.2rem;
    cursor: pointer; 
    top: 45%;
    left: 10%;
    z-index: 3;
    border: 1px solid ${({ theme: { colors } }) => colors.colorBorder};
    color: ${({ theme: { colors } }) => colors.colorText};
    transition: all .2s cubic-bezier(0.55, 0.055, 0.675, 0.19);
    transform: scale(1.1);
    background: ${({ theme: { colors } }) => colors.colorBackground};
    box-shadow: 0 0 2px ${({ theme: { colors } }) => colors.colorBorder};
    &:hover { 
      box-shadow: 0 0 1.5px ${({ theme: { colors } }) => colors.colorBorder};
      background: ${({ theme: { colors } }) => colors.colorBackground};
      opacity: .8;
    }
  }
`;

// CHAPTERS SIDEBAR
export const Main = styled.div`
    flex-basis: 30%;
    min-height: 100%;
    border-right: 1px solid ${({ theme: { colors } }) => colors.colorBorder};
    display: flex;
    flex-direction: column; 
    box-sizing: border-box;
    overflow: auto;
    margin: 0;
`;

export const Header = styled.header`
    flex-basis: 30%;
    padding: 20px;
    border-bottom: 1px solid ${({ theme: { colors } }) => colors.colorBorder};
    font-size: 18px;
    height: 80px;
    font-weight: bold;
    position: sticky; 
    top: 0px;  
    background: ${({ theme: { colors } }) => colors.colorBackground};
    display: flex;
    justify-content: space-between;
    align-items: center;
    button {
      padding: 3px 5px;
      height: 35px;
      border-radius: 10px;
      font-size: 1.4rem;
      cursor: pointer; 
      border: 1px solid ${({ theme: { colors } }) => colors.colorBorder};
      color: ${({ theme: { colors } }) => colors.colorText};
      transition: all .2s cubic-bezier(0.55, 0.055, 0.675, 0.19);
      transform: scale(1.1);
      background: ${({ theme: { colors } }) => colors.colorBackground};
      box-shadow: 0 0 2px ${({ theme: { colors } }) => colors.colorBorder};
      &:hover { 
        background: ${({ theme: { colors } }) => colors.colorBackground};
        box-shadow: 0 0 1.5px ${({ theme: { colors } }) => colors.colorBorder};
        opacity: .8;
      }
    }
`;
export const BookTitle = styled.div<{ activeTab: boolean | null }>`
    padding: 20px;
    cursor: default;  
    &:nth-of-type(even) {
      background-color: ${({ activeTab, theme: { colors } }) => (activeTab ? colors.colorPrimary2 : colors.colorPrimary4)};
      color: ${({ activeTab, theme: { colors } }) => activeTab && colors.colorTextWhite}
    };
    :hover {
      background-color: ${({ theme: { colors } }) => colors.colorPrimary2};
      color: ${({ theme: { colors } }) => colors.colorTextWhite};
    };
    background-color: ${({ activeTab, theme: { colors } }) => activeTab && colors.colorPrimary2};
    color: ${({ activeTab, theme: { colors } }) => activeTab && colors.colorTextWhite}
`;
// END SIDEBAR ///
