import type { GenericPagination } from 'common/types';
import { apiCall } from 'common/utils/api-helpers';
import type { Bookmark } from 'modules/reading/utils/api/bookmarksApiUtils';

export type BookmarksPaginated = {
  bookmarks: Bookmark[];
  pagination: GenericPagination;
};

export const fetchBookmarks = async (
  pageNumber: number,
  limit = 5,
): Promise<BookmarksPaginated> => {
  const { data } = await apiCall(
    `bookmarks?page=${pageNumber}&limit=${limit}`,
    'get',
    null,
  );
  return data;
};

export const loadMoreBookmarks = async (
  pageNumber: number,
  limit = 5,
): Promise<BookmarksPaginated> => {
  const { data } = await apiCall(
    `bookmarks?page=${pageNumber}&limit=${limit}`,
    'get',
    null,
  );
  return data;
};

export const deleteBookmark = async (bookmarkId: number): Promise<number> => {
  const { data } = await apiCall(`bookmarks/${bookmarkId}`, 'delete', null);
  return data;
};
