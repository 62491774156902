import React from 'react';
import Moment from 'react-moment';
import { css } from 'styled-components';
import * as S from 'modules/profile/components/styledComponents';
import { isTabletOrLarger } from 'app/globalStyles/screens';
import ThoughtQuote from 'modules/thoughts/common/ThoughtQuote';
import DropDownMenu from 'common/components/DropDownMenu';
import type { Journal } from '../utils/journalsApiUtils';

const styles = css`
  display: flex;
  padding: 10px 20px;
  border: 1px solid ${({ theme: { colors } }) => colors.colorBorder};
  flex-direction: column;
  box-sizing: border-box;
  border-radius: 5px;
  margin: 0 10px 10px 10px;
  h2 {
    font-size: 1.4rem;
  }
  button {
    color: ${({ theme: { colors } }) => colors.colorText};
    font-weight: normal;
    letter-spacing: 0.6px;
    font-size: 1.1rem;
  }
  p {
    color: ${({ theme: { colors } }) => colors.colorText};
  }
`;

const CardTimeStamp = css`
  opacity: 0.6;
  font-size: 1rem;
  ${isTabletOrLarger`
    font-size: 1.1rem;
  `}
`;

export interface Props {
  journal: Journal;
  isSelectedJournal?: boolean;
  handleSecondClickOnExpandedCard: (journal: Journal) => void;
  handleFirstClickOnCard: (
    journal: Journal,
    plainJournalTextLength: number,
  ) => void;
  takeToEditor: (e: any, journal: Journal) => void;
  openDeleteModal: (e: any, journal: Journal) => void;
  goToJournal: (journal: Journal) => void;
  children?: any;
  isRecentJournal: boolean;
}

const JournalCard = ({
  journal,
  handleFirstClickOnCard,
  handleSecondClickOnExpandedCard,
  isSelectedJournal = false,
  isRecentJournal,
  takeToEditor,
  openDeleteModal,
}: Props) => {
  const initialTextToDisplay = journal.content.substr(0, 280);
  const fullText = journal.content;
  const isJournalExpanded = isSelectedJournal;
  const plainJournalTextLength = journal.content.length;

  const onEdit = (e) => {
    e.stopPropagation();
    takeToEditor(e, journal);
  };
  const onDelete = (e) => {
    e.stopPropagation();
    openDeleteModal(e, journal);
  };

  return (
    <S.Card
      key={journal.id}
      role="button"
      css={isRecentJournal && styles}
      tabIndex={0}
      onClick={() => handleFirstClickOnCard(journal, plainJournalTextLength)}
    >
      <S.CardHeader>
        {journal.title && <S.CardHeading>{journal.title}</S.CardHeading>}

        <S.CardTimeStamp css={isRecentJournal && CardTimeStamp}>
          <Moment fromNow date={journal.updatedAt} />
        </S.CardTimeStamp>
      </S.CardHeader>
      {journal.quote && <ThoughtQuote quote={journal.quote} />}
      <S.JournalCardBody
        role="button"
        tabIndex={0}
        onClick={() => handleSecondClickOnExpandedCard(journal)}
        css={
          isRecentJournal
          && css`
            font-size: 1.2rem;
            margin-bottom: 10px;
          `
        }
      >
        {isJournalExpanded ? fullText : initialTextToDisplay}

        {!isJournalExpanded && plainJournalTextLength > 280 && (
          <small className="click-to-expand">...click to expand</small>
        )}
      </S.JournalCardBody>
      <S.CardFooter>
        <div />
        <DropDownMenu buttonStyles={{ height: 'unset' }}>
          <S.ActionOptions>
            <S.Option onClick={onEdit}>
              <S.EditIcon />
              <S.OptionText>Edit</S.OptionText>
            </S.Option>
            <S.HR />
            <S.DeleteOption onClick={onDelete}>
              <S.DeleteIcon />
              <S.OptionText>Delete</S.OptionText>
            </S.DeleteOption>
          </S.ActionOptions>
        </DropDownMenu>
      </S.CardFooter>
    </S.Card>
  );
};

export default JournalCard;
