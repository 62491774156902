import React, { useEffect, useRef, useState } from 'react';
import Loader from 'common/components/Loader';
import { eventNames as AN, Mixpanel } from 'common/utils/mixpanel';
import {
  ConfirmDeleteModal,
  CloseModal,
  ConfirmDeleteButton,
  ModalOverlay,
  ModalHeading,
  WarningInstruction,
} from './__styles__/ConfirmDelete.styles';
import {
  Form,
  FormGroup,
  InputField,
  Label,
  ValidationFailMsg,
} from './__styles__/SettingsV2.styles';
import type { User } from '../utils/profileApiUtils';

export interface Props {
  user: User | null;
  closeModal: () => void;
  handleSubmit: (event: any) => void;
  isDeletingAccount: boolean;
  warningInstruction: string;
  submitButtonText: string;
  title: string;
}

const ConfirmDelete = ({
  closeModal,
  handleSubmit,
  user,
  isDeletingAccount, title,
  warningInstruction, submitButtonText,
}: Props) => {
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState('');
  const modalRef: React.RefObject<HTMLDivElement> = useRef(null);

  useEffect(() => {
    const handleModalClose = (e) => {
      if (!modalRef.current?.contains(e.target)) {
        closeModal();
      }
    };
    document.addEventListener('click', handleModalClose);
    document.body.classList.add('Modal-Open');
    return () => {
      document.removeEventListener('click', handleModalClose);
      document.body.classList.remove('Modal-Open');
    };
  }, []);


  const handleConfirmEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
    setEmailError('');
  };

  const handleDelete = async (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    if (email !== user?.email) {
      setEmailError('Email does not match current user email');
    } else {
      await handleSubmit(event);
      Mixpanel.trackEvent(AN.delete_account);
      closeModal();
    }
  };

  return (
    <ModalOverlay>
      <ConfirmDeleteModal ref={modalRef}>
        <CloseModal type="button" onClick={closeModal}>
          &times;
        </CloseModal>
        <ModalHeading>{title}</ModalHeading>
        <WarningInstruction>
          {warningInstruction}
        </WarningInstruction>
        <Form>
          <FormGroup>
            <Label>Confirm your email address</Label>
            <InputField onChange={handleConfirmEmail} value={email} />
            {emailError && <ValidationFailMsg>{emailError}</ValidationFailMsg>}
          </FormGroup>
          <ConfirmDeleteButton type="submit" onClick={handleDelete}>
            <span>{submitButtonText}</span>
            {isDeletingAccount && (
              <Loader
                size="2.5rem"
                css={`
                  border-top: 0.2rem solid ${({ theme: { colors } }) => colors.colorTextWhite};
                  position: absolute;
                  right: 1rem;
                `}
              />
            )}
          </ConfirmDeleteButton>
        </Form>
      </ConfirmDeleteModal>
    </ModalOverlay>
  );
};

export default ConfirmDelete;
