import styled from 'styled-components';


export const Popover = styled.div<{ isHovered: any }>`
  padding: 9px;
  background: inherit;
  border-radius: 4px;
  position: absolute;
  height: 70px;
  left: 22px;
  bottom: -30px;
  z-index: 50;
  display: flex; 
  color: #fff;
  visibility: hidden;
  transition: .25s all ease;
  transition-delay: .25s;
  opacity: 0;
  align-items: center;
  border: none;
  text-align: center;
  transition: all 9ms linear;
  background: #222;
  ${({ isHovered }) => (isHovered ? 'visibility: visible;opacity: 1; transition: .25s all ease 0s;' : 'transition: all .3s ease-out; visibility: hidden; opacity: 0; bottom: -30px;')};
  &:after {
    content: '';
    position: absolute;
    left: 50%;
    top: -8px;
    transform: translateX(-50%);
    width: 0;
    height: 0;
    background: transparent;
    border-right: 8px solid transparent;
    border-left: 8px solid transparent;
    border-bottom: 8px solid #222;
  }

`;
