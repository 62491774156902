export const formValidate = (formErrors: any): boolean => {
  let valid = true;
  Object.values(formErrors).forEach((value: string): void => {
    if (value.length > 0) {
      valid = false;
    }
  });
  return valid;
};

export const emailRegex: any = RegExp(
  // eslint-disable-next-line
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
);

interface SignInParams {
  email: string;
  password: string;
}

type SignUpParams = SignInParams & {
  name: string;
}

export const validateSignUp = ({ name, email, password }: SignUpParams) => {
  const errors: SignUpParams = {} as SignUpParams;

  if (!name || name.trim() === '') {
    errors.name = 'Name cannot be blank';
  }

  if (!email || email.trim() === '' || !emailRegex.test(email)) {
    errors.email = 'Invalid Email Address';
  }

  if (!password || password.trim() === '' || password.length < 8) {
    errors.password = 'Password should be at least 8 characters long';
  }

  return {
    errors: {
      name: errors.name || '',
      email: errors.email || '',
      password: errors.password || '',
    },
    isValid: !Object.keys(errors).length,
  };
};

export const validateSignIn = ({ email, password }: SignInParams) => {
  const errors: SignUpParams = {} as SignUpParams;

  if (!email || email.trim() === '' || !emailRegex.test(email)) {
    errors.email = 'Invalid Email Address';
  }

  if (!password || password.trim() === '' || password.length < 8) {
    errors.password = 'Password should be at least 8 characters long';
  }

  return {
    errors: {
      name: '',
      email: errors.email || '',
      password: errors.password || '',
    },
    isValid: !Object.keys(errors).length,
  };
};

export const validateForgotPasswd = (
  email: string,
): { isValid: boolean; error: string } => {
  let isValid = true;
  let error;
  if (!email || email.trim() === '' || !emailRegex.test(email)) {
    isValid = false;
    error = 'Input field cannot be empty';
  }
  return {
    isValid,
    error,
  };
};

export const validatePass = (password: string, confPassword: string) => {
  const errors: { password: string } = { password: '' };
  if (!password || password.trim() === '' || password.length < 8) {
    errors.password = 'Password should be at least 8 characters long';
  }
  if (password && password !== confPassword) {
    errors.password = 'Both passwords MUST match!';
  }

  return {
    errors: {
      password: errors.password || '',
    },
  };
};
