import { combineReducers } from '@reduxjs/toolkit';
import type { State as AuthState } from 'modules/auth';
import {
  AUTH,
  authReducer,
  initialState as initAuth,
} from 'modules/auth';
import type { State as BooksState } from 'modules/books';
import {
  BOOKS,
  booksReducer,
  booksInitialState as initBooks,
} from 'modules/books';
import type { State as ReadingState } from 'modules/reading';
import {
  READING,
  readingReducer,
  initialState as initialReadingState,
} from 'modules/reading';
import type { State as JournalsState } from 'modules/thoughts/journals';
import {
  JOURNALS,
  journalsReducer,
  initialState as initJournals,
} from 'modules/thoughts/journals';
import type { State as EditorState } from 'modules/thoughts/editor';
import {
  EDITOR,
  editorReducer,
  initialState as initEditor,
} from 'modules/thoughts/editor';
import type { State as HighlightsState } from 'modules/highlights';
import {
  HIGHLIGHTS,
  highlightsReducer,
  initialState as initHighlights,
} from 'modules/highlights';
import type { State as BookmarkState } from 'modules/bookmarks';
import {
  BOOKMARKS,
  bookmarksReducer,
  initialState as initBookmarks,
} from 'modules/bookmarks';
import type { State as VerseState } from 'modules/search';
import {
  SEARCH,
  searchReducer,
  initialState as initSearchResults,
} from 'modules/search';
import type { State as ProfileState } from 'modules/profile';
import {
  PROFILE,
  profileReducer,
  initialState as initProfile,
} from 'modules/profile';
import type { State as PatronageState } from 'modules/membership';
import {
  MEMBERSHIP,
  membershipReducer,
  initialState as initPatronage,
} from 'modules/membership';
import type { State as DonationsState } from 'modules/donations';
import {
  DONATIONS,
  donationsReducer,
  initialState as initDonations,
} from 'modules/donations';
import type { State as FeedbackState } from 'modules/feedback';
import {
  FEEDBACK,
  feedbackReducer,
  initialState as initFeedback,
} from 'modules/feedback';
import type { State as StreakState } from 'modules/streaks';
import {
  STREAKS,
  streaksReducer,
  initialState as initStreak,
} from 'modules/streaks';
import type { State as AdminState } from 'modules/admin';
import { ADMIN, adminReducer, initialState as initAdmin } from 'modules/admin';
import {
  SETTINGS,
  settingsReducer,
  initialState as initSettings
} from 'modules/settings';
import type { State as SettingsState } from 'modules/settings';

export interface InputStoreState {
  [AUTH]: AuthState;
  [BOOKMARKS]: BookmarkState;
  [BOOKS]: BooksState;
  [READING]: ReadingState;
  [JOURNALS]: JournalsState;
  [HIGHLIGHTS]: HighlightsState;
  [EDITOR]: EditorState;
  [SEARCH]: VerseState;
  [PROFILE]: ProfileState;
  [MEMBERSHIP]: PatronageState;
  [DONATIONS]: DonationsState;
  [FEEDBACK]: FeedbackState;
  [STREAKS]: StreakState;
  [ADMIN]: AdminState;
  [SETTINGS]: SettingsState;
}

export const initialStoreState: InputStoreState = {
  [AUTH]: initAuth,
  [BOOKMARKS]: initBookmarks,
  [BOOKS]: initBooks,
  [READING]: initialReadingState,
  [JOURNALS]: initJournals,
  [HIGHLIGHTS]: initHighlights,
  [EDITOR]: initEditor,
  [SEARCH]: initSearchResults,
  [PROFILE]: initProfile,
  [MEMBERSHIP]: initPatronage,
  [DONATIONS]: initDonations,
  [FEEDBACK]: initFeedback,
  [STREAKS]: initStreak,
  [ADMIN]: initAdmin,
  [SETTINGS]: initSettings,
};

const rootReducer = combineReducers<InputStoreState>({
  [AUTH]: authReducer,
  [BOOKMARKS]: bookmarksReducer,
  [BOOKS]: booksReducer,
  [READING]: readingReducer,
  [JOURNALS]: journalsReducer,
  [HIGHLIGHTS]: highlightsReducer,
  [EDITOR]: editorReducer,
  [SEARCH]: searchReducer,
  [PROFILE]: profileReducer,
  [MEMBERSHIP]: membershipReducer,
  [DONATIONS]: donationsReducer,
  [FEEDBACK]: feedbackReducer,
  [STREAKS]: streaksReducer,
  [ADMIN]: adminReducer,
  [SETTINGS]: settingsReducer,
});


export default rootReducer;
