import styled, { css } from 'styled-components';
import { setFlex, setFont } from 'app/globalStyles/variables';
import {
  isTabletOrSmaller,
  isTabletOrLarger,
  isDesktopOrLarger,
} from 'app/globalStyles/screens';
import Link from 'next/link';

export const Header = styled.p`
  font-family: ${setFont.headings};
  font-style: normal;
  font-weight: normal;
  font-size: 21px;
  line-height: 28px;
  letter-spacing: -0.291667px;
  color: ${({ theme: { colors } }) => colors.colorText};

  ${isTabletOrLarger`
  font-size: 42px;
  line-height: 50px;
    letter-spacing: initial;
  `}
  ${isTabletOrSmaller`
    font-size: 32px;
`}
`;

export const SubHeader = styled.p`
  padding: 5px 0;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: -0.25px;
  color: ${({ theme: { colors } }) => colors.colorText};
   ${isTabletOrLarger`
     font-size: 18px;
     line-height: 20px;
  `}
  ${isTabletOrSmaller`
    font-size: 14px;
`}
`;

export const Content = styled.div`
  width: 100%;
  border: none;
  padding: 0px;
  font-size: 1.4rem;
  line-height: 175%;
  background: ${({ theme: { colors } }) => colors.colorBackground};
  color: ${({ theme: { colors } }) => colors.colorText};
   margin: 10px 0;
  ${isTabletOrLarger`
    margin: initial;
  `}
`;
export const BooksRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 99%;
  padding: 20px 0px;
  box-sizing: border-box;
  .table_footer {
    width: 100%;
    font-size: 16px;
    line-height: 249.52%;
    letter-spacing: -0.25px;
    box-sizing: border-box;
    ul {
      list-style: none;
      width: 100%;
      box-sizing: border-box;
      li {
        display: flex;
        flex-direction: column;
        overflow-wrap: break-word;
        word-break: break-word;
        a {
          display: block;
          width: 100%;
          font-size: 1.4rem;
          color: ${({ theme: { colors } }) => colors.colorPrimary2};
          cursor: pointer;
          text-decoration: none;
          transition: text-decoration 0.5s ease-in-out;
          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
    p {
      width: 100%;
      padding: 10px 0;
    }
  }
`;

export const BookCards = styled(Link)`
  width: 300px;
  text-decoration: none;
  border-radius: 5px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  cursor: default;
  img {
    width: 100%;
    min-height: 241px;
    border-radius: 5px;
    object-fit: cover;
    background: lightgrey;
    margin: 0;
  }

`;

export const ImageSection = styled<any>(BookCards)`
  width: 100%;
  img {
    margin-bottom: 10px;
    min-height: unset;
    height: 190px;
  };

  ${isTabletOrLarger`
    width: 300px;
    img {
      height: initial;
      min-height: unset;
    }
  `}

  .headings {
   width: 100%;
    margin: 10px 0;
  };

  .CTA {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
     margin: 10px 0;
    ${isTabletOrLarger`
           margin: initial;
        `}
    .shareBtn {
      width: 100%;
      border: 1px solid #BFD3F3;
      border-radius: 5px;

    }
  };


`;

export const SimilarBookCard = styled(Link)`
  padding: 0px;
  margin: 15px;
  display: flex;
  place-content: flex-start;
  width: 170px;
  border: none;
  flex-direction: column;
  cursor: pointer;
  text-decoration: none;
  border-radius: 3px;
  color: ${({ theme: { colors } }) => colors.colorText};
  img {
    width: 100%;
    border-radius: 5px;
    margin: unset;
    height: 151.78px;
    background: lightgrey;
  }
  .card_footer {
    padding: 16px 13px;
    h3 {
      width: 183.03px;
      font-size: 16px;
      line-height: 19px;
      letter-spacing: -0.159444px;
      margin-bottom: 8px;
    }
    span {
      font-size: 12px;
      line-height: 15px;
      letter-spacing: -0.113889px;
    }
  }
`;

export const TableOfContentItem = styled(Link)`
  font-size: 1.4rem;
  display: flex;
  padding: 10px;
  letter-spacing: -0.3px;
  color: ${({ theme: { colors } }) => colors.colorPrimary2};
  cursor: pointer;
  text-decoration: none;
  word-break: break-word;
  transition: text-decoration 0.5s ease-in-out;
  &:hover {
    text-decoration: underline;
  };
  ${isDesktopOrLarger`
    font-size: 1.6rem;
  `};
`;

export const TableOfContent = styled.div`
  position: relative;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  padding: 0;
  ${isTabletOrLarger` padding: 0 30px; `}
  ${isTabletOrSmaller`
    padding: 0;
    h2 {
      font-size: 1.8rem;
    }
`}
`;

export const BuyButton = styled(Link)`
  border: unset;
  letter-spacing: -0.3px;
  font-family: ${setFont.main};
  font-size: 14px;
  width: 100%;
  margin-bottom: 15px;
  height: 50px;
  border-radius: 3px;
  padding: 15px 0;
  min-width: 100%;
  color: ${({ theme: { colors } }) => colors.colorTextWhite};
  background: ${({ theme: { colors } }) => colors.colorPrimary1};
  text-align: center;
  text-decoration: none;
  ${isTabletOrSmaller`
     font-size: 14px;
     padding: 15px 20px;
  `};

  &:hover {
    cursor: pointer;
  }
  span {
    &:hover {
      opacity: 0.7;
    }
  }
`;

export const StyledBookDetails = styled.div`
  display: flex;
  flex-direction: column;
  ${isDesktopOrLarger`
     width: 60%;
     flex-direction: row;
     align-items: flex-start;
  `}
`;

export const styles = css`
  border: unset;
  border-radius: 3px;
  font-size: 1.6rem;
 font-family: ${setFont.main};
  line-height: 1.9rem;
  text-align: center;
  padding: 1.2rem 2rem;
  margin-right: 2rem;
  outline: unset;
  ${setFlex({ x: 'unset', y: 'center' })};
  width: 100%;
  span {
    width: 100%;
  }
  color: ${({ theme: { colors } }) => colors.colorText};
   background: unset;
  ${({ theme: { colors } }) => isTabletOrLarger`
    background: ${colors.colorPrimary4};
  `}

`;

export const BooksContainerRight = styled.div`
  width: 35%;
  position: relative;
  height: 90vh;
  overflow: scroll;
  background: ${({ theme: { colors } }) => colors.colorBackground};
  display: none;
  ${isTabletOrLarger`
    display: initial;
  `};
  h2 {
    width: 100%;
    margin-bottom: 20px;
    padding-left: 12px;
    ${isTabletOrSmaller`
      font-size: 1.8rem;
    `};
  };

  div {
    display: flex;
    flex-wrap: wrap;
  };

  .page {
    ${isTabletOrSmaller`
        justify-content: center;
        overflow-x: unset;
    `};
  };

  ${isDesktopOrLarger`
     width: 30%;
  `};

  ${isTabletOrSmaller`
    flex-direction: column;
  `};
`;

export const StyledBookPage = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 20px auto;
  padding: 0;
  width: 90%;
  ${isTabletOrLarger`
    padding: initial;
    width: 76%;
  `}
`;
