import React from 'react';
import styled from 'styled-components';
import {
  CheckBox as StyledCheckBox,
  CheckBoxLabel,
  CheckBoxWrapper,
  Div,
  InputLabel,
} from 'common/components/styled-components';

export const InputContainer = styled<any>(Div)` 
    position: relative;
    margin: 2rem;
`;

interface Props {
  checked: boolean;
  name: string;
  label?: string;
  onChange: any;
}

const Checkbox = ({
  name, label, checked, onChange,
}: Props) => (
  <InputContainer>
    <InputLabel>{label}</InputLabel>
    <CheckBoxWrapper>
      <StyledCheckBox
        id="checkbox"
        onChange={(e) => onChange(e)}
        name={name}
        checked={checked}
        type="checkbox"
      />
      <CheckBoxLabel htmlFor="checkbox" />
    </CheckBoxWrapper>
  </InputContainer>

);

export default Checkbox;
