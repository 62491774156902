import styled, { css } from 'styled-components';
import { setFlex, setFont } from 'app/globalStyles/variables';
import { isTabletOrLarger } from 'app/globalStyles/screens';

interface Props {
  isVerseActionsOpen: boolean;
}

export const StyledVerseAction = styled.div`
  position: fixed;
  left: 4vh;
  right: 4vh;
  width: 92%;
  z-index: 9;
  max-height: 140px;
  bottom: 0;
  background: ${({ theme: { colors } }) => colors.colorBackground};
   box-shadow: 0 -5px 5px -5px ${({ theme: { colors } }) => colors.colorInputBg};
  border-top: 1px solid ${({ theme: { colors } }) => colors.colorBorder};
  display: block;
  transform: ${({ isVerseActionsOpen }: Props) =>
    (isVerseActionsOpen ? 'translateY(0%)' : 'translateY(100%)')};
  transition: transform 0.5s linear;
  ${isTabletOrLarger(css`
     min-height: 165px;
  `)};
`;

export const Heading = styled.h2`
  margin-top: 18px;
  margin-bottom: 10px;
  color: ${({ theme: { colors } }) => colors.colorTitle};
  text-align: center;
  font-weight: 300;
  font-size: 1.2rem;
  ${isTabletOrLarger`
     font-size: initial;
  `}
`;

export const actionButtonStyles = css<{ disabled?: boolean }>`
  ${setFlex()};
  flex-flow: column nowrap;
  font-size: 1.4rem;
  background: inherit;
  font-family: ${setFont.main};
  color: ${({ theme: { colors }, disabled }) =>
    (disabled ? colors.colorTextDisabled : colors.colorText)};
  border: 0;
   padding: 10px 15px;
  outline: unset;
  position: relative;
  &:after {
    content: '';
    background: ${({ theme: { colors } }) => colors.colorBorder};
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    height: 60%;
    width: 1px;
  }
  ${isTabletOrLarger`
      padding: 10px 12px;
  `};
`;

export const Action = styled.button`
  ${actionButtonStyles};
`;
