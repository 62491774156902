import React, { useState } from 'react';
import { useRouter } from 'next/router';
import { sendPasswordResetLinkAction } from 'modules/auth/state/auth.actions';
import { routeTo } from 'common/utils/routes';
import Loader from 'common/components/Loader';
import { useAppDispatch, useAppSelector } from 'common/hooks/state-hooks';
import { selectAuth } from 'modules/auth/state/auth.slice';
import * as S from '../components/__styles__/AuthComponent.styles';
import { validateForgotPasswd } from '../utils/validateAuth';

const ForgotPasswordPage = () => {
  const router = useRouter();
  const [email, setEmail] = useState('');
  const [validationError, setError] = useState('');
  const [successMessage, setSuccessMessage] = useState('');

  const dispatch = useAppDispatch();
  const { sendingResetLink } = useAppSelector(selectAuth);

  const handleFocus = (): void => {
    setError('');
    setSuccessMessage('');
  };
  const handleChange = (e): void => {
    setEmail(e.target.value);
  };

  const goToSignIn = () => router.push(routeTo.login);

  const handleSubmit = async (e): Promise<void> => {
    e.preventDefault();
    const { error } = validateForgotPasswd(email);
    if (validateForgotPasswd(email).isValid) {
      dispatch(sendPasswordResetLinkAction(email, setSuccessMessage));
    }
    setError(error);
  };

  return (
    <S.AuthComponent>
      <S.Heading>Reset your password</S.Heading>
      <S.FormInfo>
        Don't remember your password? Let’s get you a new one.
      </S.FormInfo>
      {successMessage && <S.SuccessMsg>{successMessage}</S.SuccessMsg>}
      {validationError && <S.ErrorMsg>{validationError}</S.ErrorMsg>}
      <S.Form onSubmit={handleSubmit}>
        <S.FormGroup className="form-group">
          <S.InputLabel htmlFor="email">Your Email</S.InputLabel>
          <S.InputField
            type="email"
            onChange={handleChange}
            onFocus={handleFocus}
            name="email"
            placeholder="Enter your email address"
          />
        </S.FormGroup>
        <S.SubmitButton
          type="submit"
          id="submit"
          isAuthenticating={sendingResetLink}
          disabled={sendingResetLink}
        >
          {sendingResetLink ? (
            'Sending...'
          ) : (
            <S.SubmitButtonText>Get Reset Link</S.SubmitButtonText>
          )}

          {sendingResetLink && (
            <Loader
              size="2.5rem"
              style={{
                borderTop: '0.2rem solid #fff',
                position: 'absolute',
                right: '1rem',
              }}
            />
          )}
        </S.SubmitButton>
      </S.Form>
      <S.AlreadyHave>
        <S.AlreadyHaveText>Remember your password?</S.AlreadyHaveText>
        <S.AlreadyHaveLink type="button" onClick={goToSignIn}>
          Login
        </S.AlreadyHaveLink>
      </S.AlreadyHave>
    </S.AuthComponent>
  );
};

export default ForgotPasswordPage;
