import styled from 'styled-components';
import { Button } from 'common/components/styled-components';
import { setFlex, setFont } from 'app/globalStyles/variables';
import { isTabletOrLarger, isTablet } from 'app/globalStyles/screens';
import Link from 'next/link';

export const Header = styled.h3`
  font-weight: 500;
  font-size: 26px;
  font-family: ${setFont.headings};
  text-align: left;
  padding: 10px 0;
  color: ${({ theme: { colors } }) => colors.colorText};
`;

export const BooksContainer = styled.div`
  width: 90%;
  display: flex;
  margin: 0px auto 50px;
  flex-direction: column;
  align-items: flex-start;
  padding: 30px 0;
  background: ${({ theme: { colors } }) => colors.colorBackground};
  ${isTabletOrLarger`
    width: 65%;
    margin: initial;
    margin-left: 12%;
    `};
  ${isTablet`
    width: 80%;
    margin: 50px auto;
  `};
`;

export const BookList = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: space-between;
`;

export const BookCard = styled(Link)`
  height: auto;
  width: 100%;
  padding: 20px;
  margin: 20px 0;
  text-decoration: none;
  border: 1px solid ${({ theme: { colors } }) => colors.colorBorder};
  color: ${({ theme: { colors } }) => colors.colorText};
  border-radius: 3px;
  ${setFlex()};
  flex-direction: column;
  cursor: default;
  ${isTablet`
    margin: 30px auto;
    flex-direction: column;
  `};

  ${isTabletOrLarger`
    width: 270px;
    padding: 20px 5px;
    margin: 10px 0;
  `};
`;

export const BookCardImage = styled.img`
  height: 170px;
  border-radius: 3px;
  object-fit: cover;
  width: 90%;
  margin: 0 26px;
  background: lightgrey;
  ${isTabletOrLarger`
    height: 150px;
  `};
`;

export const CardContent = styled.div`
  display: flex;
  justify-content: space-between;
  width: 85%;
  padding: 5px 0;
  h3 {
    line-height: 200%;
  }
`;
export const BuyButton = styled<any>(Button)`
  font-size: 14px;
  padding: 10px;
  margin: 20px 3px 10px;
  width: 85%;
  min-width: 35%;
  background: ${({ theme: { colors } }) => colors.colorPrimary4};
  color: inherit;
`;
