import styled, { css } from 'styled-components';
import { isTabletOrLarger } from 'app/globalStyles/screens';
import Link from 'next/link';

export const StyledVerifyEmailNotice = styled.div<{
  isLandingPage: boolean;
  hideVerifyEmailMsg: boolean;
}>`
  background: ${({ theme: { colors } }) => colors.colorBackgroundDark};
  border-radius: 5px;
  border: 0.1px solid ${({ theme: { colors } }) => colors.colorBorder};
  padding: 10px 15px;
  padding-right: 35px;
  position: relative;
  width: 90%;
  margin: 5px auto;
  font-size: 14px;
  text-align: center;
  transition: all 300ms ease-in;
  display: ${({ hideVerifyEmailMsg }) => hideVerifyEmailMsg && 'none'};
  ${isTabletOrLarger(css`
    width: 650px;
    margin: 0 auto;
    margin-bottom: 1px;
  `)};
  ${({ isLandingPage }) =>
    isLandingPage
    && isTabletOrLarger(css`
      width: 25%;
      position: fixed;
      bottom: 15px;
      right: 15px;
      z-index: 1001;
      margin: 0px auto 1px;
      font-size: 14px;
      text-align: center;
      padding-right: 20px;
    `)};
`;

export const ClosedButton = styled.button`
  width: 25px;
  height: 25px;
  font-size: 18px;
  font-weight: 700;
  border: 1px solid ${({ theme: { colors } }) => colors.colorBorder};
  border-radius: 50%;
  line-height: 1.2rem;
  background: transparent;
  color: ${({ theme: { colors } }) => colors.colorText};
  position: absolute;
  transition: all 300ms ease-in;
  top: 5px;
  right: 5px;
  z-index: 2;
  filter: blur(0.3px);
  &:hover {
    transform: scale(1.04);
  }
  &:not(:hover) {
    transform: scale(1);
  }
`;

export const ResendLink = styled(Link)`
  color: inherit;
  margin-left: 5px;
`;
