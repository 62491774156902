import { batch } from 'react-redux';
import type { AxiosResponse } from 'axios';
import { actions as journalsActions } from 'modules/thoughts/journals/state/journals.slice';
import { actions as readingHighlightsActions } from 'modules/reading/state/slices/highlights.slice';
import { actions as highlightsActions } from 'modules/highlights/state/highlights.slice';
import { apiCall } from 'common/utils/api-helpers';
import * as api from 'modules/thoughts/journals/utils/journalsApiUtils';

import type { AppThunk } from 'app/state/store';
import { getHighlightId } from 'modules/highlights/utils';
import type { ThoughtAccessType, VerseOffsets } from 'modules/thoughts/journals/utils/journalsApiUtils';

export type JournalUpdateParamType = {
  id?: number;
  title?: string;
  content: string;
  accessType: ThoughtAccessType;
};

export type ThinkableType = 'chapter' | 'highlight' | undefined;
export type ThoughtKind = 'note' | 'journal';

export type JournalCreationParamType = JournalUpdateParamType & {
  quote?: string;
  kind: ThoughtKind;
  chapterId?: number;
  verseId?: number;
  thinkableType?: ThinkableType;
  verseOffsets?: VerseOffsets;
  highlightId?: number;
};

// TODO: Consider simplifying this function by breaking it down
//  into separate functions for each edge case
export const saveJournal = (
  journal: JournalCreationParamType,
): AppThunk => async (dispatch): Promise<any> => {
  try {
    if (
      journal.thinkableType
      && journal.thinkableType === 'highlight'
      && journal.verseOffsets
      && journal.verseId
    ) {
      const { startOffset, endOffset } = journal.verseOffsets;
      const { verseId } = journal;
      batch(() => {
        dispatch(readingHighlightsActions.addHighlightStarted({
          color: 'yellow',
          startOffset,
          endOffset,
          verseId,
          id: getHighlightId(startOffset, endOffset)
        }));
        dispatch(journalsActions.saveJournalStarted());
      });
    } else {
      dispatch(journalsActions.saveJournalStarted());
    }
    const { data } = await apiCall('thoughts', 'post', journal);
    if (
      journal.thinkableType
      && journal.thinkableType === 'highlight'
      && data.highlight
    ) {
      // batch highlight and journal actions
      batch(() => {
        if (!journal.highlightId) {
          // if there was already ahlight on the clie do not update.
          dispatch(readingHighlightsActions.addHighlightSucceeded(data.highlight));
          dispatch(highlightsActions.addUnfetchedHighlight(data.highlight.id));
        }
        dispatch(journalsActions.saveJournalSucceeded(data));
      });
    } else {
      dispatch(journalsActions.saveJournalSucceeded(data));
    }
    return data;
  } catch (err) {
    if (err && err.response) {
      dispatch(journalsActions.saveJournalFailed(err.response));
    }
    throw err;
  }
};

export const updateJournal = (journal: JournalUpdateParamType): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(journalsActions.updateJournalStarted());
      const data = await api.updateJournal(journal);
      dispatch(journalsActions.updateJournalSucceeded(data));
      return data;
    } catch (error) {
      if (error && error.response.data) {
        dispatch(journalsActions.updateJournalFailed());
      }
      throw error;
    }
  };

export const fetchUserJournals = (pageNumber: number, limit = 5): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(journalsActions.fetchUserJournalsStarted());
      const data = await api.fetchUserJournals(pageNumber, limit);
      dispatch(journalsActions.fetchUserJournalsSucceeded(data));
      console.log(data);
      return data;
    } catch (error) {
      if (error && error.response.data) {
        dispatch(journalsActions.fetchUserJournalsFailed());
      }
      throw error;
    }
  };

export const fetchJournal = (slug: string, token: string | undefined): AppThunk => (
  dispatch,
): Promise<any> => {
  dispatch(journalsActions.fetchJournalStarted());
  return apiCall(`thoughts/${slug}`, 'get', null, token)
    .then((res) => {
      dispatch(journalsActions.fetchJournalSucceeded(res.data));
      return res.data;
    })
    .catch((err) => {
      if (err && err.response) {
        dispatch(journalsActions.fetchJournalFailed());
      }
      throw err;
    });
};

export const loadMoreJournals = (pageNumber: number, limit = 5): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(journalsActions.loadMoreJournalsStarted());
      const data = await api.loadMoreJournals(pageNumber, limit);
      dispatch(journalsActions.loadMoreJournalsSucceeded(data));
      return data;
    } catch (error) {
      if (error && error.response.data) {
        dispatch(journalsActions.loadMoreJournalsFailed());
      }
      throw error;
    }
  };

export const deleteJournal = (journalId: number): AppThunk<any> =>
  async (dispatch): Promise<void | AxiosResponse<any>> => {
    try {
      dispatch(journalsActions.deleteJournalStarted());
      const data = await api.deleteJournal(journalId);
      dispatch(journalsActions.deleteJournalSucceeded(data));
    } catch (error) {
      if (error && error.response.data) {
        dispatch(journalsActions.loadMoreJournalsFailed());
      }
      throw error;
    }
  };

export const exportJournal = (journalId: number): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(journalsActions.exportJournalStarted());
      const data = await api.exportJournal(journalId);
      dispatch(journalsActions.exportJournalSucceeded(data));
      return data;
    } catch (error) {
      if (error && error.response.data) {
        dispatch(journalsActions.exportJournalFailed());
      }
      throw error;
    }
  };
