import type { AppThunk } from 'app/state/store';
import type { AxiosResponse } from 'axios';
import { actions } from 'modules/bookmarks/state/bookmarks.slice';
import * as api from 'modules/bookmarks/utils/bookmarksApiUtils';


export const fetchBookmarksAction = (pageNumber: number, limit = 5): AppThunk =>
  async (dispatch): Promise<void | AxiosResponse<any>> => {
    try {
      dispatch(actions.fetchBookmarksStarted());
      const data = await api.fetchBookmarks(pageNumber, limit);
      dispatch(actions.fetchBookmarksSucceeded(data));
    } catch (err) {
      if (err && err.response) {
        dispatch(actions.fetchBookmarksFailed());
      }
    }
  };

export const loadMoreBookmarks = (pageNumber: number, limit = 5): AppThunk =>
  async (dispatch): Promise<void | AxiosResponse<any>> => {
    try {
      dispatch(actions.loadMoreBookmarksStarted());
      const data = await api.loadMoreBookmarks(pageNumber, limit);
      dispatch(actions.loadMoreBookmarksSucceeded(data));
    } catch (err) {
      if (err && err.response) {
        dispatch(actions.loadMoreBookmarksFailed());
      }
    }
  };
  
export const deleteBookmarkAction = (bookmarkId: number): AppThunk => async (
  dispatch,
) => {
  try {
    dispatch(actions.deleteBookmarkStarted());
    const data = await api.deleteBookmark(bookmarkId);
    dispatch(actions.deleteBookmarkSucceeded(data));
  } catch (err) {
    if (err && err.response) {
      dispatch(actions.deleteBookmarkFailed());
    }
  }
};
