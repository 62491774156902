import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { CHAPTER, READING } from 'modules/reading/constants';
import { actions as authActions } from 'modules/auth/state/auth.slice';
import type { ChapterLockReasons } from 'modules/reading/utils/readingUtils';
import type { Chapter, FetchAndReloadChapterResponse, Verse } from 'modules/reading/utils/api/chapterApiUtils';
import type { Book } from 'modules/books/utils/booksApiUtils';
import type { RootState } from 'app/state/store';

export interface State {
  currentBook: Book | null;
  currentChapter: Chapter | null;
  currentChapterSlug: string;
  currentChapterVerses: Verse[];
  currentChapterReadCount: number;
  nextChapter: Chapter | null;
  prevChapter: Chapter | null;
  chapterLock: { reason: ChapterLockReasons } | null;
  isLoadingChapter: boolean;
}

export const initialState: State = {
  currentBook: null,
  currentChapter: null,
  currentChapterSlug: 'letters-from-a-stoic-1-on-saving-time',
  currentChapterVerses: [],
  currentChapterReadCount: 0,
  nextChapter: null,
  prevChapter: null,
  chapterLock: null,
  isLoadingChapter: false,
};


const setCurrentChapter = (state, action: PayloadAction<FetchAndReloadChapterResponse>) => {
  const {
    chapter: { book, verses, ...rest },
    nextChapter,
    prevChapter,
    chapterLock,
  } = action.payload;

  return {
    ...state,
    currentBook: book,
    currentChapter: rest,
    currentChapterSlug: rest.slug,
    currentChapterVerses: verses,
    nextChapter,
    prevChapter,
    chapterLock: chapterLock || null,
    isLoadingChapter: false,
  };
};

const chapterSlice = createSlice({
  name: CHAPTER,
  initialState,
  reducers: {
    reloadCurrentChapterStarted: (state) => {
      state.isLoadingChapter = true;
    },

    reloadCurrentChapterSucceeded: setCurrentChapter,

    reloadCurrentChapterFailed: (state) => state,

    fetchBookChapterStarted: (state) => {
      // TODO: isLoadingChapter should not be set to true since this is server side action.
      //  We don't want loading icon server-side as this will not helped SEO indexed page.
      //  Consider using only the reloadChapter action for setting this value
      state.isLoadingChapter = true;
    },

    fetchBookChapterSucceeded: setCurrentChapter,

    fetchBookChapterFailed: (state) => state,

    fetchChapterReadCountStarted: (state) => {
      state.currentChapterReadCount = 0;
    },

    fetchChapterReadCountSucceeded: (state, action: PayloadAction<{count: number}>) => {
      const { count } = action.payload;
      return {
        ...state,
        currentChapterReadCount: count,
      };
    },
    fetchChapterReadCountFailed: (state) => state,

  },
  // we don't clear the slug for the current chapter.
  extraReducers: (builder) =>
    builder
      .addCase(authActions.logoutUser, (state) => ({
        ...initialState, currentChapterSlug: state.currentChapterSlug
      })),
});

export const { actions } = chapterSlice;

const blacklist: Partial<State> = { ...initialState };
delete blacklist.currentChapterSlug;

const booksPersistConfig = {
  key: CHAPTER,
  storage,
  blacklist: Object.keys(blacklist),
};

export const selectCurrentChapter = (state: RootState): State =>
  state[READING][CHAPTER];

export const chapterReducer = persistReducer(booksPersistConfig, chapterSlice.reducer);
