import React, { useRef, useEffect, useState } from 'react';
import Toggle from 'react-toggle';
import {
  DarkMode,
  FontSizeTab,
  FontSizeTabs,
  FontTab,
  FontTabs,
  OtherActions,
  StyledSettings,
  TooltipTarget,
  CheckedIcon,
  UncheckedIcon,
} from 'modules/reading/components/__styles__/ChapterToolbar.styles';
import TooltipComponent from 'common/components/TooltipComponent';
import type { ThemeType } from 'modules/settings/utils/settingsUtils';
import { trackOpenReaderSettings } from 'modules/reading/utils/analytics';

const fontSizes = [
  ['Aa', 1.8],
  ['Aa', 2],
  ['Aa', 2.2],
];
const fontFamilies = [
  ['Avenir', 'Avenir'],
  ['Inter', 'Inter'],
  ['Georgia', 'Georgia'],
  ['Lora', 'Lora'],
];

export interface Props {
  toggleNightMode: (event: any) => void;
  fontFamily: string;
  fontSize: number;
  shouldShowVerseNumbers: boolean;
  isMemberOrFreeTrialUser: boolean;
  theme: ThemeType;
  selectFont: (event: any, font: string) => void;
  selectFontSize: (event: any, size: number) => void;
  onShowVerseNumbers: (event: any) => void;
  toggleModal: () => void;
  isAuthenticated: boolean;
}

const ReaderSettings = ({
  fontFamily,
  fontSize,
  isMemberOrFreeTrialUser,
  theme,
  isAuthenticated,
  toggleNightMode,
  selectFontSize,
  selectFont,
  onShowVerseNumbers,
  shouldShowVerseNumbers,
  toggleModal,
}: Props) => {
  const settingsRef = useRef<HTMLDivElement>(null);
  const darkModeHoverRef = useRef<HTMLDivElement>(null);

  const [hover, setHover] = useState(false);
  const handleCloseModal = (e: any) => {
    // @ts-ignore
    if (settingsRef && !settingsRef?.current?.contains(e.target)) {
      toggleModal();
    }
  };
  const handleSetHover = (e: any) => {
    if (darkModeHoverRef && darkModeHoverRef?.current?.contains(e.target)) {
      return setHover(true);
    }
    return setHover(false);
  };

  useEffect(() => {
    trackOpenReaderSettings();
  }, []);

  useEffect(() => {
    // TODO: re-enable closing modal on click outside
    // document.addEventListener('click', handleCloseModal);
    document.addEventListener('mouseover', handleSetHover);

    return () => {
      document.removeEventListener('click', handleCloseModal);
      document.removeEventListener('mouseout', handleSetHover);
    };
  }, []);

  const tooltipMessage = !isAuthenticated
    ? 'Login To Enable Dark Mode'
    : 'Get Membership To Unlock Dark Mode';

  return (
    <StyledSettings ref={settingsRef}>
      <FontSizeTabs>
        {fontSizes.map(([name, size]: [string, number]) => (
          <FontSizeTab
            key={size}
            onClick={(event) => selectFontSize(event, size)}
            selected={size === fontSize}
          >
            {name}
          </FontSizeTab>
        ))}
      </FontSizeTabs>

      <FontTabs>
        {fontFamilies.map(([family, value]) => (
          <FontTab
            key={value}
            onClick={(event) => selectFont(event, value)}
            selected={fontFamily === value}
          >
            {family}
          </FontTab>
        ))}
      </FontTabs>
      <OtherActions>
        <DarkMode>
          <span>Dark Mode</span>
          {(!isMemberOrFreeTrialUser || !isAuthenticated) && (
            <TooltipComponent isHovered={hover} message={tooltipMessage} />
          )}

          <TooltipTarget ref={darkModeHoverRef}>
            <Toggle
              icons={false}
              checked={theme !== 'light'}
              value={theme}
              onChange={toggleNightMode}
              disabled={!isMemberOrFreeTrialUser}
            />
          </TooltipTarget>
        </DarkMode>
        <label
          style={{ fontSize: 14, display: 'flex', alignItems: 'center' }}
          htmlFor="verseNumber"
        >
          Show verse numbers
          <input
            type="checkbox"
            name="verseNumber"
            id="verseNumber"
            checked={shouldShowVerseNumbers}
            onChange={onShowVerseNumbers}
            style={{ marginLeft: '10px', display: 'none' }}
          />
          {shouldShowVerseNumbers ? <CheckedIcon /> : <UncheckedIcon />}
        </label>
      </OtherActions>
    </StyledSettings>
  );
};

export default ReaderSettings;
