import { apiCall } from 'common/utils/api-helpers';
import type { Subscription } from 'modules/membership/utils/membershipApiUtils';

export interface User {
  id?: number;
  username: string;
  name?: string;
  email: string;
  emailVerified?: boolean;
  isAdmin: boolean;
  timezone: string;
  provider: string;
  stripeCustomerId?: string;
  freeTrialEndAt: Date;
  subscription?: Subscription;
  createdAt: string;
}

export type UpdateEmailPayload = {
  user: User;
  token: string;
  message: string;
};

export const updatePassword = async (
  newPassword: string,
  currentPassword: string,
): Promise<{ user: User; message: string }> => {
  const { data } = await apiCall('user/update_password', 'put', {
    newPassword,
    currentPassword,
  });
  return data;
};

export const updateEmail = async (
  email: string,
): Promise<UpdateEmailPayload> => {
  const { data } = await apiCall('user/update_email', 'put', { email });
  return data;
};

export const checkUsernameAvailable = async (
  username: string,
): Promise<{ isUsernameAvailable: boolean }> => {
  const { data } = await apiCall('user/available_usernames', 'post', {
    username,
  });
  return data;
};

export const updateUsername = async (username: string): Promise<User> => {
  const { data } = await apiCall('user/update_username', 'put', { username });
  return data;
};

export const updateName = async (name: string): Promise<User> => {
  const { data } = await apiCall('user/update_name', 'put', { name });
  return data;
};

export const deleteAccount = async (userId: number): Promise<void> => {
  await apiCall(`user/${userId}`, 'delete', null);
};
