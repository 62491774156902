import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { STREAKS } from 'modules/streaks/constants';
import type { RootState } from 'app/state/store';
import type { Streak } from '../utils/streaksApiUtils'; 

export interface State {
  readingStreak: number;
  isLoadingReadingStreak: boolean;
  journalingStreak: number;
  isLoadingJournalingStreak: boolean;
  fetchingReadingStreakError: string;
  fetchingJournalingStreakError: string;
}

export const initialState = {
  readingStreak: 0,
  isLoadingReadingStreak: false,
  journalingStreak: 0,
  isLoadingJournalingStreak: false,
  fetchingReadingStreakError: '',
  fetchingJournalingStreakError: '',
};

export const streaksSlice = createSlice({
  name: STREAKS,
  initialState, 
  reducers: {
    fetchReadingStreakStarted: (state) => {
      state.isLoadingReadingStreak = true;
    },

    fetchReadingStreakSucceeded: (state, action: PayloadAction<Streak>) => {
      const latestStreak = action.payload;
      return {
        ...state, readingStreak: latestStreak.length, isLoadingReadingStreak: false
      };
    },

    fetchReadingStreakFailed: (state, action: PayloadAction<{ error: string }>) => {
      const { error } = action.payload;
      return {
        ...state,
        isLoadingReadingStreak: false,
        fetchingReadingStreakError: error,
      };
    },

    fetchJournalingStreakStarted: (state) => {
      state.isLoadingJournalingStreak = true;
    },

    fetchJournalingStreakSucceeded: (state, action: PayloadAction<Streak>) => {
      const latestStreak = action.payload;
      return { ...state, journalingStreak: latestStreak.length, isLoadingJournalingStreak: false };
    },

    fetchJournalingStreakFailed: (state, action: PayloadAction<{ error: string }>) => {
      const { error } = action.payload;
      return {
        ...state,
        isLoadingJournalingStreak: false,
        fetchingJournalingStreakError: error,
      };
    },

    updateJournalingStreak: (state, action: PayloadAction<Streak>) => {
      const latestStreak = action.payload;
      return { ...state, journalingStreak: latestStreak.length };
    },

    updatingReadingStreak: (state, action: PayloadAction<Streak>) => {
      const latestStreak = action.payload;
      return { ...state, readingStreak: latestStreak.length };
    }
  },
});

export const { actions } = streaksSlice;

export const selectStreak = (state: RootState): State => state[STREAKS];

export const streaksReducer = streaksSlice.reducer;
