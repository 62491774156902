import React from 'react';
import styled from 'styled-components';
import { isTabletOrLarger } from 'app/globalStyles/screens';
import BecomeAMemberCTA from 'modules/membership/components/BecomeAMemberCTA';

const Centent = styled.div`
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
   width: 90%;
    & > div {
      width: 80%;
    }
  ${isTabletOrLarger`
    width: 55%;
    & > div {
      width: initial;
    }
  `}
`;

// TODO: This should be made reusable as something similar is rendered in Membership settings.
const TrialExpiredPageCTA = () => (
  <Centent>
    <div css="font-size: 18px; line-height: inherit; text-align: center; width: 60%; margin: 30px 0;">
      Your Free trial has expired. You can continue reading for free, but
      journaling would require active membership
    </div>
    <BecomeAMemberCTA title="Get Membership" />
  </Centent>
);

export default TrialExpiredPageCTA;
