import React, { useState, useEffect } from 'react';
import type { CSSProp } from 'styled-components';
import styled from 'styled-components';
import { setFlex } from 'app/globalStyles/variables';

const StyledSecondaryHeader = styled.div`
  ${setFlex({ x: 'start', y: 'center' })};
  height: 55px;
  width: 100%;
  background: ${({ theme: { colors } }) => colors.colorBackground};
  z-index: 1;
  border-bottom: 0.5px solid ${({ theme: { colors } }) => colors.colorInputBg};
  position: sticky;
  top: ${({ selectorVisible }: { selectorVisible: boolean }) =>
    (selectorVisible ? '0' : '60px')};
  transition: top 0.3s ease-in-out;
`;

export interface Props {
  children: JSX.Element | Array<JSX.Element> | null;
  style?: CSSProp;
}

export function SecondaryHeader({ children, style }: Props) {
  const [selectorVisible, setSelectorVisible] = useState(false);
  const [prevScrollposition, setPrevScrollPosition] = useState(
    window.pageYOffset,
  );

  const handleScroll = () => {
    const currentScrollPosition = window.pageYOffset;
    const visible = prevScrollposition < currentScrollPosition;

    setPrevScrollPosition(currentScrollPosition);
    setSelectorVisible(visible);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [prevScrollposition, selectorVisible]);

  return (
    <StyledSecondaryHeader selectorVisible={selectorVisible} css={style}>
      {children}
    </StyledSecondaryHeader>
  );
}

export default SecondaryHeader;
