import React from 'react';
import { css } from 'styled-components';
import {
  BookmarksFill,
  HighlightFill,
  BookmarksLine,
  HighlightLine,
  NoteFill,
  NoteLine,
  SettingsFill,
  SettingsLine,
} from 'static/assets';
import { routeAs } from 'common/utils/routes';
import * as S from './__styles__/ProfileTabs.styles';

const cardIconStyle = css`
  height: 15.3px;
  width: 15.3px;
  margin-right: 10px;
  margin-top: 7px;
  path {
    fill: ${({ theme: { colors } }) => colors.colorPrimary3};
  }
`;

function capitalizeFirstLetterOfPageName(pageName) {
  return pageName.charAt(0).toUpperCase() + pageName.slice(1);
}

interface Props {
  pageName: string;
  username: string;
  closeModal?: () => void;
}

const Tabs = ({ pageName, username, closeModal }: Props) => {
  const icons = {
    notes: () => <NoteFill css={cardIconStyle} />,
    journalsLine: () => <NoteLine css={cardIconStyle} />,
    settings: () => <SettingsFill css={cardIconStyle} />,
    settingsLine: () => <SettingsLine css={cardIconStyle} />,
    bookmarks: () => <BookmarksFill css={cardIconStyle} />,
    bookmarksLine: () => <BookmarksLine css={cardIconStyle} />,
    highlights: () => <HighlightFill css={cardIconStyle} />,
    highlightsLine: () => <HighlightLine css={cardIconStyle} />,
  };

  const tabsData = [
    { name: 'journals', icon: icons.journalsLine, iconActive: icons.notes },
    {
      name: 'highlights',
      icon: icons.highlightsLine,
      iconActive: icons.highlights,
    },
    {
      name: 'bookmarks',
      icon: icons.bookmarksLine,
      iconActive: icons.bookmarks,
    },
    { name: 'settings', icon: icons.settingsLine, iconActive: icons.settings },
  ];
  return (
    <S.Tabs>
      {tabsData.map((tab) => {
        const Icon = tab.icon;
        const IconActive = tab.iconActive;
        return (
          <S.TabItem
            key={tab.name}
            href={routeAs[tab.name](username)}
            title={tab.name}
            activeTab={pageName === tab.name}
            onClick={closeModal}
          >
            {pageName === tab.name ? (
              <span>
                <IconActive />
              </span>
            ) : (
              <span>
                <Icon />
              </span>
            )}
            <span>{capitalizeFirstLetterOfPageName(tab.name)}</span>
          </S.TabItem>
        );
      })}
    </S.Tabs>
  );
};

export default Tabs;
