import React from 'react';
import BecomeAMemberCTA from 'modules/membership/components/BecomeAMemberCTA';

const GetMembership = ({ instruction }: { instruction: string }): JSX.Element => (
  <div
    css="
        max-width: 80%;
        padding: 30px 0 0 0;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
      "
  >
    <div css="margin-bottom: 20px; text-align: center; font-size: 14px">
      {instruction}
    </div>
    <BecomeAMemberCTA title="Get Membership" />
  </div>
);

export default GetMembership;
