import styled, { css } from 'styled-components';
import { Book } from 'styled-icons/ionicons-outline';
import { Book as BookSolid } from 'styled-icons/ionicons-solid';
import { setFont } from 'app/globalStyles/variables';
import {
  isTabletOrLarger,
  isTabletOrSmaller,
  isTablet,
} from 'app/globalStyles/screens';
import { HomeFill, HomeLine, JournalFill, JournalLine } from 'static/assets';
import Link from 'next/link';

export const iconStyle = css`
  width: 20px;
  height: 20px;
  margin: 0;
`;

export const BookOpenIcon = styled<any>(Book)`
  ${iconStyle}
`;

export const BookOpenFill = styled<any>(BookSolid)`
  ${iconStyle}
`;

export const JournalIcon = styled<any>(JournalLine)`
  width: 20px;
  height: 18px;
  path {
    stroke: ${({ theme: { colors } }) => colors.colorText};
  }
`;

export const JournalFillIcon = styled<any>(JournalFill)`
  width: 20px;
  height: 18px;
  path {
    fill: ${({ theme: { colors } }) => colors.colorText};
  }
`;

export const HomeIcon = styled<any>(HomeLine)`
  width: 25px;
  height: 21px;
  path {
    fill: ${({ theme: { colors } }) => colors.colorText};
  }
`;

export const HomeFillIcon = styled<any>(HomeFill)`
  width: 25px;
  height: 21px;
  path {
    fill: ${({ theme: { colors } }) => colors.colorText};
  }
`;

export const IconStylebtn = css`
  ${iconStyle};
  position: absolute;
  margin: 15px auto;
  margin-left: 20px;
  line-height: 20px;
  pointer-events: none;
`;
export const IconStyle2 = css`
  ${IconStylebtn};
  height: 20px;
`;

export const StyledHeaderRight = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  align-self: stretch;
  height: 100%;
`;

export const navlinkStyle = css`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 5px;
  padding: 10px;
  border-radius: 50%;
  border: 1px solid ${({ theme: { colors } }) => colors.colorInputBg};
  color: ${({ theme: { colors } }) => colors.colorText};
  &:hover {
    cursor: pointer;
    background: ${({ theme: { colors } }) => colors.colorPrimary4};
    * {
      color: ${({ theme: { colors } }) => colors.colorText};
    }
  }
`;

export const NavLink = styled(Link)`
  ${navlinkStyle};
`;

export const AvatarNavLink = styled<any>(NavLink)`
  border: unset;
  border-radius: unset;
  padding-right: 5px;
  ${isTabletOrLarger`
      padding-right: unset;
  `}
  &:hover {
    background: unset;
    * {
      color: unset;
    }
  }
`;

// TODO: Change this to a link. Use anchor tags
export const Button = styled.button`
  border: unset;
  padding: 8px 25px;
  outline: unset;
  font-size: 1.4rem;
  line-height: 1.1458333333vw;
  letter-spacing: -0.3px;
  font-family: ${setFont.main};
  color: ${({ theme: { colors } }) => colors.colorText};
  ${isTabletOrSmaller`
     padding: 15px 20px;
  `};

  &:hover {
    cursor: pointer;
  }
  span {
    &:hover {
      opacity: 0.7;
    }
  }
`;

export const SignUpButton = styled<any>(Button)`
  background: ${({ theme: { colors } }) => colors.colorPrimary1};
  color: ${({ theme: { colors } }) => colors.colorTextWhite};
  border-radius: 5px;
  font-size: 1.4rem;
`;

export const SignInButton = styled<any>(Button)`
  background: transparent;
  padding: 0 4px;
  font-size: 1.4rem;
  ${isTablet`margin-right: 0;`};
  ${isTabletOrLarger`
      margin-right: 20px;
      width: unset;
      padding: 8px 25px;
    `}
`;

export const AvatarContainer = styled.div`
  cursor: pointer;
  margin-bottom: 0;
  margin-right: -5px;
  img.sb-avatar__image {
    max-width: 83.33%;
    max-height: 83.33%;
    height: 30px;
    width: 30px;
  }
  ${isTabletOrLarger(css`
    margin-bottom: 5px;
    margin-right: initial;
    img.sb-avatar__image {
      max-width: initial;
      max-height: initial;
      height: initial;
      width: initial;
    }
  `)}
  &:hover {
    .sb-avatar__text {
      box-shadow: 0 0 2px 2px burlywood inset;
    }
  }
`;
