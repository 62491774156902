import React from 'react';
import * as T from 'common/components/__styles__/Tooltip.styles';

export interface Props {
  message: string;
  isHovered?: boolean;
}
const TooltipComponent = ({ message, isHovered }: Props) => (
  <T.Popover isHovered={isHovered}>{message}</T.Popover>
);

export default TooltipComponent;
