import styled, { css } from 'styled-components';
import { setPosition } from 'app/globalStyles/variables';
import {
  isTabletOrLarger,
  isTabletOrSmaller,
} from 'app/globalStyles/screens';

export const bookmarkModalStyles = css`
    padding: 10px;
    width: 75%;
    background: ${({ theme: { colors } }) => colors.colorBackground};

    z-index: 10;
    position: relative;

  ${isTabletOrLarger`
      padding: 20px;
      width: 680px;
    `}

  ${isTabletOrSmaller`
      width: 75%;
    `}

  .closeBtn {
    font-size: 2rem;
    font-weight: 400;
    position: ${setPosition.absolute};
    right: 20px;
    top: 10px;
    border: unset;
    background: transparent;
    ${isTabletOrLarger`
      font-size: 2.6rem;
    `};
    &:focus {
      outline: unset;
    }
  }

  h2 {
    font-size: 1.2rem;
    display: block;
    border-bottom: .5px solid ${({ theme: { colors } }) => colors.colorBorder};
    padding-bottom: 1rem;
    text-align: center;
    color: ${({ theme: { colors } }) => colors.colorText};
    ${isTabletOrLarger`
      font-size: initial;
    `};
  }

  .content {
    margin-top: 20px;
    padding-bottom: 20px;
    font-size: 1rem;
    ${isTabletOrLarger`
      font-size: 2rem;
    `};
  };

  form {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;

    input[type="submit"] {
      color: ${({ theme: { colors } }) => colors.colorBackground};
      background: ${({ theme: { colors } }) => colors.colorPrimary1};
      width: 80px;
      border: unset;
      height: 30px;
      border-radius: 3px;
    }
  }
`;


export const SelectVerseNumber = styled.div`
  position: relative;
  padding: 5px;
  color: ${({ theme: { colors } }) => colors.colorText};
  border-bottom: 1px solid ${({ theme: { colors } }) => colors.colorBorder};
`;

export const Select = styled.select`
  width: 100%;
  height: 100%;
  line-height: 1.6em;
  outline: 0;
  font-size: 1.4rem;
  border: unset;
  margin-right: .5rem;
  background: ${({ theme: { colors } }) => colors.colorBackground};
  border-radius: 0.5em;
  color: ${({ theme: { colors } }) => colors.colorText};
`;

export const SelectOption = styled.option`
  border: unset;
  outline: 0 !important;
  text-align: center;
  background: ${({ theme: { colors } }) => colors.colorPrimary4} !important;
`;
