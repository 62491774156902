import styled, { css } from 'styled-components';
import {
  isDesktopOrLarger,
  isTabletOrLarger,
} from 'app/globalStyles/screens';
import { setFont } from 'app/globalStyles/variables';
import { SubmitButton as StyledSubmitBtn } from 'common/components/styled-components';

export const StyledEditorHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 90%;
  margin: 0 auto;
  ${isTabletOrLarger`width: 88%`};
  ${isDesktopOrLarger(css`
    width: 806px;
    padding-right: 0;
  `)};
`;

export const Heading = styled.h2`
  font-weight: normal;
  font-family: ${setFont.headings};
  font-size: 1.8rem; margin-right: 2px; text-align: left;
  display: none;
  ${isTabletOrLarger`font-size: initial; margin-right: intitial; text-align: center; display: initial;`};
`;

export const SubmitButton = styled<any>(StyledSubmitBtn)`
  font-size: 16px;
  padding: 5px 32px;
  border-radius: 5px;
`;

export const Form = styled.form`
  width: 90%;
  margin: 3rem auto;
  overflow-x: hidden;
  color: #fff;
  ${isTabletOrLarger`width: 88%`};
  ${isDesktopOrLarger`
    width: 806px;
 `};
`;

export const FormGroup = styled.div``;

export const TitleEditor = styled.input`
  width: 100%;
  display: block;
  color: ${({ theme: { colors } }) => colors.colorText};
  border: unset;
  outline: unset;
  font-size: 2.4rem;
  font-weight: normal;
  background: inherit;
  font-family: ${setFont.main};
  &::placeholder {
    color: ${({ theme: { colors } }) => colors.inputPlaceHolderColor};
  }
`;

export const RichEditorFormGroup = styled<any>(FormGroup)`
  max-width: 100%;
  letter-spacing: 0.01rem;
  font-size: 1.6rem;
  line-height: 30px;
  color: ${({ theme: { colors } }) => colors.colorText};
`;

export const HighlightedVerseText = styled.div`
  width: 90%;
  margin: 20px auto 0 auto;
  font-size: 16px;
  border-radius: 3px;
  border: 1px solid ${({ theme: { colors } }) => colors.colorBorder};
  padding: 20px;
  ${isTabletOrLarger(css`
    width: 88%;
  `)};
  ${isDesktopOrLarger(css`
    width: 45%;
  `)};
`;

export const StyledEditorFooter = styled.div`
  position: sticky;
  top: 100vh;
  bottom: 0;
  font-size: 14px;
  width: 90%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  ${isTabletOrLarger`width: 86%`};
  ${isDesktopOrLarger(css`
    width: 806px;
  `)};
`;
