import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import type { RootState } from 'app/state/store';
import { BOOKS } from 'modules/books/constants';
import type { Book } from '../utils/booksApiUtils';

type ErrorPayload = { error: string }

export interface State {
  books: Book[];
  isBooksLoading: boolean;
  isSingleBookLoading: boolean;
  currentBook: Book | null;
  isSavingUserPurchase: boolean;
  isBookPurchaseSaved: boolean;
  // TODO: this is a temporary key to hold all errors and avoid unused param lints,
  //  it is not meant to be used in UI yet.
  error: string;
}

export const initialState: State = {
  books: [],
  isBooksLoading: false,
  isSingleBookLoading: false,
  currentBook: null,
  isSavingUserPurchase: false,
  isBookPurchaseSaved: false,
  error: '',
};

const booksSlice = createSlice({
  name: BOOKS,
  initialState,
  reducers: {
    fetchBooksStarted: (state) => {
      state.isBooksLoading = true;
    },
    fetchBooksSucceeded: (state, action: PayloadAction<Book[]>) => {
      state.books = action.payload;
      state.isBooksLoading = false;
    },
    fetchBooksFailed: (state, action: PayloadAction<ErrorPayload>) => {
      state.isBooksLoading = false;
      state.error = action.payload.error;
    },
    fetchBookBySlugStarted: (state) => {
      state.isSingleBookLoading = true;
    },
    fetchBookBySlugSucceeded: (state, action: PayloadAction<Book>) => {
      state.currentBook = action.payload;
      state.isSingleBookLoading = false;
    },
    fetchBookBySlugFailed: (state, action: PayloadAction<ErrorPayload>) => {
      state.isSingleBookLoading = false;
      state.error = action.payload.error;
    },
    setCheckoutBook: (state, action: PayloadAction<Book>) => {
      state.currentBook = action.payload;
    },
    clearCheckoutBook: (state) => {
      state.currentBook = null;
    },
    saveUserPurchaseStarted: (state) => {
      state.isSavingUserPurchase = true;
      state.isBookPurchaseSaved = false;
    },
    saveUserPurchaseSucceeded: (state) => {
      state.isSavingUserPurchase = false;
      state.isBookPurchaseSaved = true;
    },
    saveUserPurchaseFailed: (state, action: PayloadAction<ErrorPayload>) => {
      state.isSavingUserPurchase = false;
      state.error = action.payload.error;
    },
  },
});

export const { actions } = booksSlice;

export const selectBooks = (state: RootState): State => state[BOOKS];

export const booksReducer = booksSlice.reducer;
