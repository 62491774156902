import React, { useState } from 'react';
import { useRouter } from 'next/router';
import Highlighter from 'react-highlight-words';
import { routeAs, routeTo } from 'common/utils/routes';
import InfiniteScrollList from 'common/components/InfiniteScrollList';
import { PageLoader } from 'common/components/Loader';
import type { Verse } from 'modules/reading/utils/api/chapterApiUtils';
import type { GenericPagination } from 'common/types';
import { SearchWrapper } from './__styles__/SearchResults.styles';

export interface Props {
  verses: Verse[];
  versesLoaded: boolean;
  loadingMore: boolean;
  loadMoreSearchResults: () => void;
  pagination: GenericPagination | null;
  searchString: string;
}

const SearchResults = ({
  verses,
  loadMoreSearchResults,
  pagination,
  versesLoaded,
  searchString,
}: Props) => {
  const router = useRouter();
  const [selectedVerse, setSelectedVerse] = useState<Verse | null>(null);
  const searchWords = searchString?.toLowerCase()
    .split(' ')
    .concat([searchString]);

  const goToVerse = (verse: Verse) => {
    if (selectedVerse?.id === verse.id) {
      router.push(
        routeTo.searchedVerse,
        routeAs.searchedVerse(verse.chapter?.slug as string, verse.number),
      );
    }
  };

  if (!versesLoaded) {
    return (
      <PageLoader />
    );
  }

  const getTextToHighlight = (
    searchString: string,
    fullText: string,
  ): string => {
    let indexOfFirstMatch = fullText
      .toLowerCase()
      .indexOf(searchString.toLowerCase());

    if (indexOfFirstMatch > 0) {
      if (indexOfFirstMatch >= 15) {
        return `...${fullText.substr(indexOfFirstMatch - 15, 280)}...`;
      }
      return `${fullText.substr(0, 280)}...`;
    }
    const fullTextWords = fullText.toLowerCase().split(' ');

    fullTextWords.some((text: string, i: number) => {
      const lowerCaseText = text.toLowerCase();
      if (searchWords.includes(lowerCaseText)) {
        indexOfFirstMatch = i;
        return true;
      }
      return false;
    });

    if (indexOfFirstMatch >= 2) {
      return `...${fullTextWords
        .splice(indexOfFirstMatch - 2, 50)
        .join(' ')}...`;
    }
    return `${fullTextWords.splice(0, 50).join(' ')}...`;
  };

  const handleVerseClick = (verse: Verse) => {
    setSelectedVerse(verse);
  };

  return (
    <SearchWrapper>
      <InfiniteScrollList
        dataLength={verses.length}
        pagination={pagination}
        loadMore={loadMoreSearchResults}
      >
        {!verses.length ? (
          <div css="width: 80%; margin: 0 auto;text-align: center; font-size: 15px;">
            NO RESULTS FOUND FOR THE SEARCH WORD
            <span css="background: yellow; color: #000; margin: 0 10px; padding: 0 2px">
              {`${searchString}`}
            </span>
          </div>
        ) : (
          verses.map((verse) => {
            const textToHighlight = getTextToHighlight(
              searchString,
              verse.text,
            );
            return (
              <div
                tabIndex={0}
                role="button"
                onClick={() => handleVerseClick(verse)}
                className="verse"
                key={verse.id}
              >
                <h4>{`${verse.book?.title} ${verse.chapter?.number} : ${verse.number}`}</h4>
                <div className="text" onClick={() => goToVerse(verse)}>
                  <Highlighter
                    searchWords={searchWords}
                    autoEscape
                    textToHighlight={
                      selectedVerse?.id === verse.id
                        ? selectedVerse.text
                        : textToHighlight
                    }
                    highlightStyle={{
                      backgroundColor: 'yellow',
                      borderRadius: '5px',
                    }}
                  />
                </div>
              </div>
            );
          })
        )}
      </InfiniteScrollList>
    </SearchWrapper>
  );
};

export default SearchResults;
