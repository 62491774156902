import WriteButton from 'modules/thoughts/common/WriteButton';
import React, { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'common/hooks/state-hooks';
import { PageLoader } from 'common/components/Loader';
import InfiniteScrollList from 'common/components/InfiniteScrollList';
import { useIsScrollable } from 'common/hooks/useIsScrollable';
import Layout from 'common/page-layout/Layout';
import { selectJournals } from 'modules/thoughts/journals';
import { selectUser } from 'modules/auth';
import JournalsEmptyMessage from '../components/JournalsEmptyMessage';
import * as journalActions from '../state/journals.actions';
import JournalsV2 from '../components/JournalsV2';
import {
  JournalPageContainer,
  JournalPageHeader,
  JournalPageHeading,
} from '../components/__styles__/JournalComponent.styles';

const JournalsPageV2 = () => {
  const dispatch = useAppDispatch();
  const { myJournals, pagination, journalsLoaded } = useAppSelector(
    selectJournals,
  );
  const user = useAppSelector(selectUser);
  const [isScrollable, ref] = useIsScrollable();
  const hasMore = !!pagination && pagination.currentPage < pagination.numberOfPages;

  useEffect(() => {
    (async () => {
      if (!journalsLoaded) {
        await dispatch(journalActions.fetchUserJournals(1, 9));
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      // If the content on the current page is not scrollable while there's
      // more data, load the next page
      if (!isScrollable && hasMore) {
        await dispatch(
          journalActions.loadMoreJournals(
            (pagination?.currentPage as number) + 1,
            6,
          ),
        );
      }
    })();
  }, [hasMore]);

  const loadMore = async () => {
    await dispatch(
      journalActions.loadMoreJournals(
        (pagination?.currentPage as number) + 1,
        6,
      ),
    );
  };

  const renderJournals = () => {
    if (!journalsLoaded) {
      return <PageLoader />;
    }

    return (
      <>
        {!myJournals.length ? (
          <JournalsEmptyMessage />
        ) : (
          <div ref={ref as (instance: HTMLDivElement | null) => void}>
            <InfiniteScrollList
              dataLength={myJournals.length}
              loadMore={loadMore}
              pagination={pagination}
            >
              <JournalPageContainer>
                <JournalPageHeader>
                  <JournalPageHeading>Your Journals</JournalPageHeading>
                  <WriteButton />
                </JournalPageHeader>
                <JournalsV2
                  user={user}
                  myJournals={myJournals}
                  deleteJournal={journalActions.deleteJournal}
                />
              </JournalPageContainer>
            </InfiniteScrollList>
          </div>
        )}
      </>
    );
  };

  return <Layout allowFooter={false}>{renderJournals()}</Layout>;
};

export default JournalsPageV2;
