import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { BOOKMARKS } from 'modules/bookmarks/constants';
import { actions as authActions } from 'modules/auth/state/auth.slice';
// eslint-disable-next-line import/no-cycle
import { actions as bookmarkActions } from 'modules/reading/state/slices/bookmarks.slice';
import type { Bookmark } from 'modules/reading/utils/api/bookmarksApiUtils';
import type { GenericPagination } from 'common/types';
import type { RootState } from 'app/state/store';

export type BookmarksPaginated = {
  bookmarks: Bookmark[],
  pagination: GenericPagination 
}

export interface State {
  bookmarks: Bookmark[];
  isBookmarking: boolean;
  bookmarksLoaded: boolean;
  pagination: GenericPagination | null;
  loadingMore: boolean;
  isDeletingBookmark: boolean;
}

export const initialState: State = {
  bookmarks: [],
  bookmarksLoaded: false,
  pagination: null,
  loadingMore: false,
  isDeletingBookmark: false,
  isBookmarking: false
};

const bookmarkSlice = createSlice({
  name: BOOKMARKS,
  initialState,
  reducers: {
    fetchBookmarksStarted: (state) => {
      state.bookmarksLoaded = false;
    },

    fetchBookmarksSucceeded: (state, action: PayloadAction<BookmarksPaginated>) => {
      const { bookmarks, pagination } = action.payload;
      return {
        ...state,
        bookmarks,
        pagination,
        bookmarksLoaded: true,
      };
    },

    fetchBookmarksFailed: (state) => {
      state.bookmarksLoaded = false;
    },

    loadMoreBookmarksStarted: (state) => {
      state.loadingMore = true;
    },

    loadMoreBookmarksSucceeded: (state, action: PayloadAction<BookmarksPaginated>) => {
      const { bookmarks, pagination } = action.payload;
      return {
        ...state,
        bookmarks: [...state.bookmarks, ...bookmarks],
        pagination,
        loadingMore: false,
      };
    },

    loadMoreBookmarksFailed: (state) => {
      state.loadingMore = false;
    },

    deleteBookmarkStarted: (state) => {
      state.isDeletingBookmark = true;
    },

    deleteBookmarkSucceeded: (state, action: PayloadAction<number>) => {
      const newBookmarks = [
        ...state.bookmarks.filter((bookmark) => bookmark.id !== action.payload),
      ];
      return {
        ...state,
        bookmarks: newBookmarks,
        isDeletingBookmark: false,
      };
    },

    deleteBookmarkFailed: (state) => {
      state.isDeletingBookmark = false;
    },
  },
  
  extraReducers: (builder) =>
    builder
      .addCase(bookmarkActions.saveBookmarkSucceeded, (state, action: PayloadAction<Bookmark>) => {
        const { bookmarks } = state;
        const newBookmarks = bookmarks.filter(
          ({ id }) => id !== action.payload.id,
        );
        return {
          ...state,
          bookmarks: [action.payload, ...newBookmarks],
        };
      })
      .addCase(authActions.logoutUser, () => initialState)
});

export const { actions } = bookmarkSlice;

export const selectBookmarks = (state: RootState): State => state[BOOKMARKS];
export const bookmarksReducer = bookmarkSlice.reducer;
