import type { AppThunk } from 'app/state/store';
import type { AxiosResponse } from 'axios';
import { fetchGetJSON, fetchPostJSON } from 'common/utils/api-helpers';
import { actions } from 'modules/membership/state/membership.slice';
import * as api from 'modules/membership/utils/membershipApiUtils';

const baseUrl = process.env.NEXT_PUBLIC_BASEURL;

export const createSubscription = (
  { customerEmail, priceId, cardElement, stripe }: any,
  successCallback: () => void,
): AppThunk<any> =>
  (dispatch): Promise<void | AxiosResponse<any>> => {
    dispatch(actions.purchaseSubscriptionStarted());

    return fetchPostJSON(`${baseUrl}/create-subscription`, {
      customerEmail,
      priceId,
    })
      .then(({ clientSecret }) => {
        stripe
          .confirmCardPayment(clientSecret, {
            payment_method: {
              card: cardElement,
            },
          })
          // eslint-disable-next-line consistent-return
          .then(async (result) => {
            if (result.error) {
              dispatch(actions.purchaseSubscriptionFailed(result.error.message));
            } else if (result.paymentIntent.status === 'succeeded') {
              const subscription = await fetchGetJSON(
                `${baseUrl}/subscription`,
              );
              dispatch(actions.purchaseSubscriptionSucceeded(subscription));
              successCallback();
              setTimeout(async () => {
                const subscription = await fetchGetJSON(
                  `${baseUrl}/subscription`,
                );
                dispatch(actions.purchaseSubscriptionSucceeded(subscription));
              }, 3000);
            }
          })
          .catch((error) => {
            dispatch(actions.purchaseSubscriptionFailed(error.message));
          });
      })
      .catch((error) => {
        dispatch(actions.purchaseSubscriptionFailed(error.message));
        throw error;
      });
  };

export const cancelSubscription = (subscriptionId: string, successCallback: () => void): AppThunk =>
  async (dispatch) => {
    dispatch(actions.cancelSubscriptionStarted());
    try {
      await api.cancelSubscription(subscriptionId);
      dispatch(actions.cancelSubscriptionSucceeded());
      successCallback();
    } catch (err) {
      if (err && err.response.data) {
        dispatch(actions.cancelSubscriptionFailed());
      }
    }
  };
