// import type { Highlight } from '';

import type { Highlight } from 'modules/reading/utils/api/highlightsApiUtils';

// https://stackoverflow.com/questions/4811822/get-a-ranges-start-and-end-offsets-relative-to-its-parent-container
export function getSelectionCharacterOffsetWithin(element) {
  let start = 0;
  let end = 0;
  const doc = element.ownerDocument || element.document;
  const win = doc.defaultView || doc.parentWindow;
  let sel;
  if (typeof win.getSelection !== 'undefined') {
    sel = win.getSelection();
    if (sel.rangeCount > 0) {
      const range = win.getSelection().getRangeAt(0);
      const preCaretRange = range.cloneRange();
      preCaretRange.selectNodeContents(element);
      preCaretRange.setEnd(range.startContainer, range.startOffset);
      start = preCaretRange.toString().length;
      preCaretRange.setEnd(range.endContainer, range.endOffset);
      end = preCaretRange.toString().length;
    }
    // eslint-disable-next-line no-cond-assign
  } else if ((sel = doc.selection) && sel.type !== 'Control') {
    const textRange = sel.createRange();
    const preCaretTextRange = doc.body.createTextRange();
    preCaretTextRange.moveToElementText(element);
    preCaretTextRange.setEndPoint('EndToStart', textRange);
    start = preCaretTextRange.text.length;
    preCaretTextRange.setEndPoint('EndToEnd', textRange);
    end = preCaretTextRange.text.length;
  }
  return { start, end };
}

const getNodeTextLength = (node) => {
  let textLength = 0;

  if (node.nodeName === 'BR') {
    textLength = 1;
  } else if (node.nodeName === '#text') {
    textLength = node.nodeValue.length;
  } else if (node.childNodes != null) {
    for (let i = 0; i < node.childNodes.length; i += 1) {
      textLength += getNodeTextLength(node.childNodes[i]);
    }
  }

  return textLength;
};


const getNodeOffset = (node) => (node === null ? -1 : 1 + getNodeOffset(node.previousSibling));

const getTextLength = (parent, node, offset) => {
  let textLength = 0;

  if (node?.nodeName === '#text') {
    textLength += offset;
  } else if (node) {
    for (let i = 0; i < offset; i += 1) {
      textLength += getNodeTextLength(node.childNodes[i]);
    }
  }

  if (node && node !== parent) {
    textLength += getTextLength(parent, node.parentNode, getNodeOffset(node));
  }

  return textLength;
};


export const getSelectionCoordinates = (selection: Selection, target: any) => {
  // eslint-disable-next-line no-param-reassign
  const range = selection.getRangeAt(0);

  const { x: viewportX, y: viewportY, width } = range.getBoundingClientRect();

  const boxRectangle = target.getBoundingClientRect();

  const localX = viewportX - boxRectangle.left;
  const localY = viewportY - boxRectangle.top;

  return {
    startOffset: getTextLength(target, range.startContainer, range.startOffset),
    endOffset: getTextLength(target, range.endContainer, range.endOffset),
    positionX: localX + (width / 2),
    positionY: localY,
  };
};

function getRelativeCoordinates(event, referenceElement) {
  const position = {
    x: event.pageX,
    y: event.pageY,
  };

  const offset = {
    left: referenceElement.offsetLeft,
    top: referenceElement.offsetTop,
  };

  let reference = referenceElement.offsetParent;

  while (reference) {
    offset.left += reference.offsetLeft;
    offset.top += reference.offsetTop;
    reference = reference.offsetParent;
  }

  return {
    x: position.x - offset.left,
    y: position.y - offset.top,
  };
}

export const getHighlightCoordinates = (parent, event: any, highlight: Highlight) => {
  const { startOffset, endOffset } = highlight;
  const { x, y } = getRelativeCoordinates(event, parent);
  return {
    startOffset,
    endOffset,
    positionX: x,
    positionY: y,
  };
};
