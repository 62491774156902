import toast from 'common/components/Toast';

export const notifyEmailVerify = () =>
  toast({
    type: 'error',
    message: 'Email not verified. Please check your inbox and confirm your email.',
  });

export const notifyUserMembershipOnHighlights = () => toast({
  type: 'error',
  message: 'Free trial expired. Membership is required to perform this action.'
});
