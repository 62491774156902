import styled from 'styled-components';
import Link from 'next/link';
import { setFlex, setFont } from 'app/globalStyles/variables';
import {
  isTabletOrLarger,
  isTablet,
} from 'app/globalStyles/screens';

export const StyledHeaderLeft = styled(Link)`
  line-height: 1.6;
  font-weight: 600;
  color: ${({ theme: { colors } }) => colors.colorTitle};
  text-decoration: none;
    height: 100%;
    padding: 20px 0;
    font-size: 1.4rem;
  ${setFlex({ x: 'start', y: 'center' })};
  ${isTablet`
    height: 30px;
    font-size: 1.3rem;
  `}
  ${isTabletOrLarger`
    height: initial;
    padding: initial;
    font-size: 18px;
  `}
  span {
    font-family: ${setFont.headings};
  }
`;

export const Logo = styled.img`
  width: 30px;
  height: 30px;
  margin-right: 5px;
`;
