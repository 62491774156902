import React from 'react';
import styled from 'styled-components';

export const QuoteCard = styled.div`
  width: 100%;
  margin-top: 10px;
  font-size: 16px;
  line-height: 1.5;
  border-radius: 3px;
  border: 1px solid ${({ theme: { colors } }) => colors.colorBorder};
  padding: 20px;
`;

export const Quote = styled.p`
  display: inline;
`;

export type Props = { quote: string; className?: string };

const ThoughtQuote = ({ quote, className }: Props) => (
  <QuoteCard className={className}>
    <Quote>{quote}</Quote>
  </QuoteCard>
);

export default ThoughtQuote;
