import React from 'react';
import type { NextRouter } from 'next/router';
import { withRouter } from 'next/router';
import type { CSSProp } from 'styled-components';
import styled from 'styled-components';
import { ArrowBack } from 'styled-icons/boxicons-regular';
import { setFlex } from 'app/globalStyles/variables';
import { isTabletOrLarger } from 'app/globalStyles/screens';

export const NavigateBackIcon = styled(ArrowBack)`
  color: ${({ theme: { colors } }) => colors.colorText};
  height: 2rem;
  width: 2rem;
  cursor: pointer;
  outline: 0;
  margin-right: 5px;
`;

export const StyledNavigateBack = styled.div`
  ${setFlex()};
  font-size: 1.4rem;
  outline: 0;
  margin-left: 0;
  span { display: none}
  margin-right: 3%;
  ${isTabletOrLarger`
    margin-left: 6%;
    span { display: initial}
    margin-right: initial;
  `};
`;

export const NavBackButton = styled.div`
  outline: 0;
  cursor: pointer;
  margin-right: 1rem;
  display: flex;
  align-items: center;
  font-size: 1.4rem;
  margin-left: -3px;
  padding: 0;
   ${isTabletOrLarger`
    margin-left: initial;
    padding: 1rem;
   `};
  &:hover {
    transform: translateY(-5%);
    transition: all 0.3s ease-in-out;
    background: ${({ theme: { colors } }) => colors.colorPrimary4};
    * {
      color: ${({ theme: { colors } }) => colors.colorText};
    }
    border-radius: 10%;
  }
`;

export type Props = {
  backTitle: string;
  router: NextRouter;
  style?: CSSProp;
  className?: string;
  goBack?: () => void;
};

const NavigateBack = ({
  backTitle,
  router,
  style,
  className,
  goBack,
}: Props) => {
  const handleBack = () => {
    if (goBack) {
      goBack();
    } else {
      router.back();
    }
  };

  return (
    <NavBackButton
      css={style}
      className={className}
      tabIndex={0}
      role="button"
      onClick={handleBack}
    >
      <NavigateBackIcon />
      <span>{backTitle}</span>
    </NavBackButton>
  );
};

export default withRouter(NavigateBack);
