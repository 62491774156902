import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { actions as authActions } from 'modules/auth/state/auth.slice';
import { JOURNALS } from 'modules/thoughts/journals/constants';
import type { GenericPagination } from 'common/types';
import type { RootState } from 'app/state/store';
import type { Journal, ThoughtPagination } from '../utils/journalsApiUtils';

export interface State {
  currentJournal: Journal | null;
  myJournals: Journal[];
  journalsLoaded: boolean;
  loadingMore: boolean;
  pagination: GenericPagination | null;
  isCreatingJournal: boolean;
  isUpdatingJournal: boolean;
  savingJournalErrorMsg: string;
}

export const initialState: State = {
  currentJournal: null,
  myJournals: [],
  pagination: null,
  journalsLoaded: false,
  loadingMore: false,
  isCreatingJournal: false,
  isUpdatingJournal: false,
  savingJournalErrorMsg: '',
};

const journalsSlice = createSlice({
  name: JOURNALS,
  initialState,
  reducers: {
    fetchUserJournalsStarted: (state) => {
      state.journalsLoaded = false;
    },

    fetchUserJournalsSucceeded: (state, action: PayloadAction<ThoughtPagination>) => {
      const { thoughts: journals, pagination } = action.payload;
      return {
        ...state,
        myJournals: journals,
        pagination,
        journalsLoaded: true,
        loadingMore: false,
      };
    },

    fetchUserJournalsFailed: (state) => {
      state.journalsLoaded = false;
    },

    loadMoreJournalsStarted: (state) => {
      state.loadingMore = true;
    },

    loadMoreJournalsSucceeded: (state, action: PayloadAction<ThoughtPagination>) => {
      const { thoughts: journals, pagination } = action.payload;
      return {
        ...state,
        myJournals: [...state.myJournals, ...journals],
        pagination,
        loadingMore: false,
      };
    },

    loadMoreJournalsFailed: (state) => {
      state.loadingMore = false;
    },

    fetchJournalStarted: (state) => {
      state.journalsLoaded = false; 
    },

    fetchJournalSucceeded: (state, action: PayloadAction<Journal>) => ({
      ...state, currentJournal: action.payload
    }),

    fetchJournalFailed: (state) => state,

    saveJournalStarted: (state) => {
      state.isCreatingJournal = true;
    },
    
    saveJournalSucceeded: (state, action: PayloadAction<Journal>) => ({
      ...state,
      isCreatingJournal: false,
      myJournals: [action.payload, ...state.myJournals],
    }),

    saveJournalFailed: (state, action: PayloadAction<{ error: string }>) => {
      const { error } = action.payload;
      return {
        ...state,
        isCreatingJournal: false,
        savingJournalErrorMsg: error,
      };
    },

    updateJournalStarted: (state) => {
      state.isUpdatingJournal = true;
    },

    updateJournalSucceeded: (state, action: PayloadAction<Journal>) => {
      const { myJournals } = state;
      const journals = myJournals.map((journal) => {
        if (journal?.id === action.payload.id) {
          return { ...journal, ...action.payload };
        }
        return journal;
      });
      return {
        ...state, isUpdatingJournal: false, myJournals: journals, currentJournal: action.payload
      };
    },

    updateJournalFailed: (state) => {
      state.isUpdatingJournal = false;
    },

    deleteJournalStarted: (state) => state,

    deleteJournalSucceeded: (state, action: PayloadAction<number>) => {
      const { myJournals } = state;
      const journals = myJournals.filter(
        (journal) => journal?.id !== action.payload,
      );
      return { ...state, myJournals: journals };
    },

    deleteJournalFailed: (state) => state,

    setJournal: (state, action: PayloadAction<Journal>) => ({
      ...state, currentJournal: action.payload
    }),

    exportJournalStarted: (state) => state,
    
    exportJournalSucceeded: (state, action: PayloadAction<Journal>) => ({
      ...state,
      myJournals: [action.payload, ...state.myJournals],
    }),

    exportJournalFailed: (state) => state,

  },

  extraReducers: (builder) => 
    builder
      .addCase(authActions.logoutUser, () => initialState)
});

export const { actions } = journalsSlice;

export const selectJournals = (state:RootState): State => state[JOURNALS];

export const journalsReducer = journalsSlice.reducer;
