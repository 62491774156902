import type { Action, Dispatch } from '@reduxjs/toolkit';
import type { AppThunk } from 'app/state/store';
import type { AxiosResponse } from 'axios';
import { completeAuth } from 'modules/auth/utils/auth';
import type * as OneSignal from 'common/utils/onesignal';
import * as api from 'modules/auth/utils/authApiUtils';
import { actions } from 'modules/auth/state/auth.slice';
import type { User } from 'modules/profile/utils/profileApiUtils';
import type { AuthServiceResponse } from 'modules/auth/utils/authApiUtils';

export const resetAuthErrorAction = (): Action => actions.resetAuthError();

export interface UserParams {
  name?: string;
  email: string;
  emailVerified?: boolean;
  password: string;
  timezone?: string;
}

export type FacebookAuthParams = {
  access_token: string;
  timezone: string;
};

export type GoogleAuthParams = FacebookAuthParams;

export type AuthResponse = {
  token: string;
  user: User;
  isNewUser?: boolean;
  onesignalAuthHashes?: OneSignal.OneSignalHashes;
};

export const signUpUserAction = (
  userParams: UserParams,
  successCallback: (payload: AuthServiceResponse) => void,
): AppThunk => async (dispatch) => {
  try {
    dispatch(actions.signUpUserStarted());
    const data = await api.signUpUser(userParams);
    dispatch(actions.signUpUserSucceeded(data));
    successCallback(data);
    completeAuth();
  } catch (err) {
    if (err && err.response) {
      dispatch(actions.signUpUserFailed(err.response));
    }
  }
};

export const signInUserAction = (
  userParams: UserParams,
  successCallback: (payload: AuthServiceResponse) => void,
): AppThunk => async (dispatch) => {
  try {
    dispatch(actions.signInUserStarted());
    const data = await api.signInUser(userParams);
    dispatch(actions.signInUserSucceeded(data));
    successCallback(data);
    completeAuth();
  } catch (err) {
    if (err && err.response) {
      dispatch(actions.signInUserFailed(err.response.data));
    }
  }
};

export const signInWithGoogleAction = (
  authParams: GoogleAuthParams,
  successCallback: (payload: AuthServiceResponse) => void,
): AppThunk => async (dispatch) => {
  try {
    dispatch(actions.googleAuthStarted());
    const data = await api.signInWithGoogle(authParams);
    dispatch(actions.googleAuthSucceeded(data));
    successCallback(data);
    completeAuth();
  } catch (err) {
    if (err && err.response) {
      dispatch(actions.googleAuthFailed(err.response.data));
    }
  }
};

export const signInWithFacebookAction = (
  authParams: FacebookAuthParams,
  successCallback: (payload: AuthServiceResponse) => void,
): AppThunk => async (dispatch) => {
  try {
    dispatch(actions.facebookAuthStarted());
    const data = await api.signInWithFacebook(authParams);
    dispatch(actions.facebookAuthSucceeded(data));
    successCallback(data);
    completeAuth();
  } catch (err) {
    if (err && err.response) {
      dispatch(actions.facebookAuthFailed(err.response.data));
    }
  }
};

export const sendPasswordResetLinkAction = (
  email: string,
  successCallback: (msg: string) => void,
): AppThunk => async (dispatch) => {
  try {
    dispatch(actions.sendPasswordResetLinkStarted());
    const data = await api.sendPasswordReset(email);
    dispatch(actions.sendPasswordResetLinkSucceeded());
    successCallback(data.message);
  } catch (err) {
    if (err && err.response) {
      dispatch(actions.sendPasswordResetLinkFailed(err.response.data));
    }
  }
};

export const resetPasswordAction = (
  token: string,
  password: string,
): AppThunk => async (dispatch): Promise<void | AxiosResponse<any>> => {
  try {
    dispatch(actions.resetPasswordStarted());
    await api.resetPassword(token, password);
    dispatch(actions.resetPasswordSucceeded());
    completeAuth();
  } catch (err) {
    if (err && err.response) {
      dispatch(actions.resetPasswordFailed());
    }
  }
};

export const handleLogoutAction = () => actions.logoutUser();

export const confirmEmailAddressAction = (token: string): AppThunk => async (
  dispatch,
): Promise<any> => {
  try {
    dispatch(actions.confirmEmailStarted());
    const data = await api.confirmEmailAddress(token);
    dispatch(actions.confirmEmailSucceeded(data));
  } catch (err) {
    if (err) {
      dispatch(actions.confirmEmailFailed(err.response.data));
    }
  }
};

export const resendEmailVerificationAction = (
  email: string,
  notifCallback: (message: string) => void,
): AppThunk => async (dispatch): Promise<void | AxiosResponse<any>> => {
  try {
    dispatch(actions.resendEmailVerificationStarted());
    const data = await api.resendEmailVerification(email);
    if (data.user) {
      dispatch(actions.resendEmailVerificationSucceeded(data));
      notifCallback(
        'Your email address has been verified already. Happy learning!',
      );
    } else {
      notifCallback(data.message);
    }
  } catch (err) {
    if (err && err.response) {
      dispatch(actions.resendEmailVerificationFailed(err.response.data));
    }
  }
};

export const refreshUserAuthAction = async (
  token: string,
  dispatch: Dispatch,
) => {
  const data = await api.refreshAuth(token);
  dispatch(actions.refreshAuthSucceeded(data));
  return data;
};
