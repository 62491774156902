import styled, { css } from 'styled-components';
import { NavigateNext } from 'styled-icons/material-sharp';
import { setPosition, setZindex } from 'app/globalStyles/variables';
import {
  isTabletOrLarger,
  isTabletOrSmaller,
  isDesktopOrLarger,
} from 'app/globalStyles/screens';

export const NextIcon = styled(NavigateNext)`
  width: 18px;
  margin: 0;
  display: inline-block;
  ${isTabletOrLarger(css`
    margin-left: 7px;
  `)};
`;

export const SelectChapterModalWrapper = styled.div`
  box-sizing: border-box;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: ${setZindex.high};
  background: ${({ theme: { colors } }) => colors.colorOverlay};
  animation-name: picker;
  animation-duration: 0.01s;
  animation-timing-function: ease-in-out;

  @keyframes picker {
    0% {
      right: 50%;
      left: 50%;
      top: 50%;
      bottom: 50%;
      height: 0;
    }
    100% {
      right: 0;
      left: 0;
      top: 0;
      bottom: 0;
    }
  }
`;

export const StyledSelectChapterModal = styled.div`
  top: 100px;
  width: 95%;
  grid-template-columns: 1.3fr 1.8fr;
  display: flex;
  margin: -50px auto;
  min-height: 90%;
  height: 500px;
  background: ${({ theme: { colors } }) => colors.colorBackground};
  border-radius: 5px;
  padding: 0;
  position: ${setPosition.fixed};
  left: 50%;
  transform: translateX(-50%);
  overflow: hidden;
  opacity: 1;
  z-index: ${setZindex.critical};

  ${isTabletOrSmaller(css`
    width: 80%;
    margin: 50px auto;
    top: 0;
    font-size: 1.4rem;
  `)};

  ${isTabletOrLarger(css`
    top: 111px;
    width: 65%;
    display: grid;
    grid-template-columns: 0.92fr 2fr;
    margin: 0 auto;
  `)}
`;

export const ChaptersContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: repeat(6, 1fr);

  ${isTabletOrLarger(css`
    grid-template-columns: 1fr 1fr;
    grid-template-rows: repeat(6, 1fr);
  `)};
`;
export const SectionMainHeader = styled.div`
  display: flex;
  flex-direction: column;
  position: sticky;
  z-index: 300;
  background: ${({ theme: { colors } }) => colors.colorBackground};
  top: 0;
`;
export const SectionFirstHeader = styled.div`
  display: flex;
  justify-content: center;
  z-index: 3;
  align-items: center;
  width: 100%;
  height: 100px;
  font-size: 1.6rem;
  font-weight: 500;
`;

export const SectionHeader = styled.header`
  position: sticky;
  top: 0;
  color: ${({ theme: { colors } }) => colors.colorText};
  background: ${({ theme: { colors } }) => colors.colorPrimary4};
  padding: 20px;
  width: 100%;
`;

export const MobileSectionHeader = styled<any>(SectionHeader)`
  display: flex;
  position: relative;
  top: unset;
  padding: 0;
  z-index: ${setZindex.high};
  background: unset;
  border-bottom: 1px solid ${({ theme: { colors } }) => colors.colorPrimary4};
`;

export const Tab = styled.div<{ activeTab?: any }>`
  margin: 0;
  padding: 0;
  text-align: center;
  font-size: 1.6rem;
  cursor: pointer;
  ${isTabletOrLarger(css`
    font-size: 1.8rem;
  `)};
  ${({ activeTab, theme: { colors } }) => css`
    width: 50%;
    height: 100%;
    padding: 20px;
    &:nth-child(1) {
      border-bottom: ${activeTab?.Books && `2px solid ${colors.colorTitle}`};
      opacity: ${!activeTab.Books && '.5'};
    }
    &:nth-child(2) {
      border-bottom: ${activeTab?.Chapters && `2px solid ${colors.colorTitle}`};
      opacity: ${!activeTab.Chapters && '.5'};
    }
  `}
`;

export const Section = styled.div`
  width: 90%;
  margin: 111px auto 0 auto;
  position: relative;
  height: 80vh;
  overflow-y: auto;
  background: ${({ theme: { colors } }) => colors.colorBackground};
  ${isDesktopOrLarger(css`
    width: 680px;
  `)}
`;

const isSelectedStyle = css<{ selected: boolean }>`
  background: ${({ selected, theme: { colors } }) =>
    selected && colors.colorPrimary4};
`;

const isHoveredStyle = css`
  background: ${({ theme: { colors } }) => colors.colorPrimary4};
  color: ${({ theme: { colors } }) => colors.colorText};
`;

export const CloseBtn = styled.button`
  position: absolute;
  top: 10px;
  right: 5px;
  border: unset;
  color: ${({ theme: { colors } }) => colors.colorText};
  background: transparent;
  font-size: 3rem;
  font-weight: 100;
  outline: unset;
  padding: 0 1rem;
  ${isTabletOrLarger`
    font-size: 2.8rem;
  `}
  &:hover {
    transform: translateY(-5%);
    transition: all 0.3s ease-in-out;
    ${isHoveredStyle};
    border-radius: 50px;
  }
`;
export const BooksSection = styled.div`
  position: relative;
  overflow-y: auto;
  border-right: 1px solid ${({ theme: { colors } }) => colors.colorBorder};
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
`;

export const Book = styled.div<{ selected: boolean }>`
  height: 9rem;
  border-bottom: 1px solid ${({ theme: { colors } }) => colors.colorPrimary4};
  padding-left: 20px;
  font-size: 1.8rem;
  display: flex;
  align-items: center;
  //font-weight: 500;
  ${isSelectedStyle};
  &:hover {
    background: unset;
    opacity: .8;
    cursor: pointer;
    ${isHoveredStyle};
    ${isTabletOrLarger`
      ${isSelectedStyle};
      opacity: 1;
  `}
  }
`;
export const BookItem = styled<any>(Book)`
  flex-direction: row;
  align-items: center;
  justify-content: start;
`;

export const BookItemDetail = styled.div<any>`
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
  padding-left: 10px;
  span {
    &:last-child {
      font-size: 1.4rem;
      opacity: 0.6;
    }
    i {
      margin-right: 5px;
    }
  }
`;

export const ChaptersSection = styled.div`
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  position: relative;
  padding-bottom: 10px;
  text-align: center;
  overflow-y: auto;
  list-style: none;
  border-bottom: 1px solid ${({ theme: { colors } }) => colors.colorPrimary4};
  box-sizing: border-box;
`;

export const ChapterItem = styled.div<{
  selected: boolean;
  isCentralized: boolean;
  shouldIncreaseHeight?: boolean;
}>`
  border-bottom: 1px solid ${({ theme: { colors } }) => colors.colorPrimary4};
  border-right: 1px solid ${({ theme: { colors } }) => colors.colorPrimary4};
  ${({ shouldIncreaseHeight }) =>
    (shouldIncreaseHeight ? 'min-height: 120px;' : 'min-height: 80px;')};

  border-collapse: collapse;

  ${isSelectedStyle};
  width: 100%;
  font-size: 1.8rem;
  display: flex;
  align-content: center;
  line-height: 20px;
  flex-wrap: wrap;
  cursor: pointer;
  word-wrap: normal;
  padding: 13px 13px;
  justify-content: flex-start;
  align-items: center;
  ${({ isCentralized }) =>
    isTabletOrLarger`
    padding: 13px 10px 13px 35px;
    ${isCentralized
    ? css`
              justify-content: center;
              text-align: center;
            `
    : css`
              justify-content: flex-start;
              text-align: left;
          `}

      `}

    &:hover {
      ${isHoveredStyle};
      opacity: .8;
    ${isTabletOrLarger`
          opacity: 1;
      `}
  }
`;
