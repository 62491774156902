import { setFlex } from 'app/globalStyles/variables';
import styled, { css } from 'styled-components';
import { isTabletOrLarger } from 'app/globalStyles/screens';

export const Content = styled.div`
  ${setFlex()};
  padding-top: 20px;
  padding-bottom: 40px;
  width: 90%;
  flex-direction: column;
  margin: 0 auto;
  text-align: center;
  ${isTabletOrLarger(css`
    padding: 40px 0;
    width: 80%;
  `)}
`;

export const Title = styled.h3`
  font-size: 2.2rem;
  font-weight: 500;
  line-height: 1.6;
  margin-bottom: 20px;
`;

export const SubTitle = styled.span`
   font-size: 1.4rem;
   margin-bottom: 30px;
  ${isTabletOrLarger(css`
   margin-bottom: 50px;
  `)}
`;
