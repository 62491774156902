import styled, { css } from 'styled-components';
import {
  Settings,
  CardMembership,
  VerifiedUser,
  Bookmarks,
} from 'styled-icons/material';
import { LogOut } from 'styled-icons/entypo';
import { Highlighter } from 'styled-icons/fa-solid';

const iconStyles = css`
  width: 18px;
  color: ${({ theme: { colors } }) => colors.colorText};
  margin-right: 10px;
`;

export const SettingsIcon = styled(Settings)`${iconStyles}`;
export const CardMembershipIcon = styled(CardMembership)`${iconStyles}`;
export const VerifiedUserIcon = styled(VerifiedUser)`${iconStyles}`;
export const BookmarksIcon = styled(Bookmarks)`${iconStyles}`;
export const LogOutIcon = styled(LogOut)`${iconStyles}`;
export const HighlighterIcon = styled(Highlighter)`${iconStyles}`;

export const StyledDropdown = styled.ul`
  position: absolute;
  top: 55px;
  right: 0;
  border: 1px solid ${({ theme: { colors } }) => colors.colorBorder};
  border-radius: 5px;
  width: 200px;
  box-shadow: 0 4px 18px rgba(0, 0, 0, 0.1);
  z-index: 3000;
  background: ${({ theme: { colors } }) => colors.colorBackground};

  animation-name: dropdown;
  animation-duration: 0.2s;
  animation-timing-function: linear;

  @keyframes dropdown {
    0% {
      opacity: 0;
    }
    25% {
      opacity: 0;
    }
    50% {
      opacity: 0;
    }
    75% {
      opacity: 0.8;
    }
    100% {
      opacity: 1;
    }
  }
`;

export const DropDownItem = styled.li`
    list-style: none;
    font-size: 1.4rem;
    animation-name: list-items;
    animation-duration: 0.3s;
    animation-timing-function: ease-out;

    &:last-child {
      padding: 15px 20px;
    }

    @keyframes list-items {
      0% {
        display: none;
        visibility: hidden;
      }
      100% {
        display: none;
        visibility: hidden;
      }
    }
    &:last-child {
      margin-bottom: 0.5rem;
    }
    &:hover {
      background: ${({ theme: { colors } }) => colors.colorPrimary4};
      * {
        color: ${({ theme: { colors } }) => colors.colorText};
      }
      cursor: pointer;
    }

    &:first-of-type  {
      font-weight: 500;opacity: .8;
      color: ${({ theme: { colors } }) => colors.colorText};
      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding: 15px 15px 15px 20px;
      text-decoration: none;
      a{
        padding: 0;
      }
    }

    a {
      color: ${({ theme: { colors } }) => colors.colorText};
      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding: 15px 15px 15px 20px;
      text-decoration: none;
    }
    span.user-name {
      margin-left: 5px;
    }
`;
