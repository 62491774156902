import React, { useEffect, useState } from 'react';
import SearchBar from 'modules/search/components/SearchBar';
import MediaQuery from 'react-responsive';
import HeaderRight from 'common/page-layout/Header/HeaderRight';
import HeaderLeft from 'common/page-layout/Header/HeaderLeft';
import { HeaderContainer } from 'common/page-layout/Header/__styles__/Header.styles';

export type Props = {
  className?: string;
  allowSearch?: boolean;
  headerLeft?: JSX.Element;
  headerRight?: JSX.Element;
};

export const Header = (props: Props) => {
  const { className, allowSearch = true, headerLeft, headerRight } = props;

  const [isHeaderVisible, setIsHeaderVisible] = useState(false);
  const [prevScrollPosition, setPrevScrollPosition] = useState(
    window.pageYOffset,
  );

  const handleScroll = () => {
    const currentScrollPosition = window.pageYOffset;
    const visible = prevScrollPosition > currentScrollPosition;

    setIsHeaderVisible(visible);
    setPrevScrollPosition(currentScrollPosition);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  });

  return (
    <HeaderContainer
      className={className}
      isHeaderVisible={isHeaderVisible}
    >
      {headerLeft || <HeaderLeft />}
      {allowSearch && (
        <MediaQuery minDeviceWidth={992}>
          <SearchBar placeholder="Search Stoic Bible" />
        </MediaQuery>
      )}
      {headerRight || <HeaderRight />}
    </HeaderContainer>
  );
};

export default Header;
