import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { FEEDBACK } from 'modules/feedback/constants';
import type { RootState } from 'app/state/store';

export interface State {
  submitFeedbackSuccessMessage: string;
  isSubmittingFeedback: boolean;
  submitFeedbackErrorMessage: string;
}

export const initialState: State = {
  isSubmittingFeedback: false,
  submitFeedbackErrorMessage: '',
  submitFeedbackSuccessMessage: '',
};

export const feedbackSlice = createSlice({
  name: FEEDBACK,
  initialState,
  reducers: {
    submitFeedbackStarted: (state) => {
      state.isSubmittingFeedback = true;
    },
    
    submitFeedbackSucceeded: (state, action: PayloadAction<{ message: string }>) => {
      const { message } = action.payload;
      return {
        ...state,
        submitFeedbackSuccessMessage: message,
        isSubmittingFeedback: false,
      };
    },
    
    submitFeedbackFailed: (state, action: PayloadAction<{error: string}>) => {
      const { error } = action.payload;
      return {
        ...state,
        isSubmittingFeedback: false,
        submitFeedbackErrorMessage: error,
      };
    },
  }
});

export const { actions } = feedbackSlice;

export const selectFeedback = (state: RootState): State => state[FEEDBACK];

export const feedbackReducer = feedbackSlice.reducer;
