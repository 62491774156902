import React, { useState } from 'react';
import { css } from 'styled-components';
import { SecuritySettings } from 'static/assets';
import Loader from 'common/components/Loader';
import type { updatePassword } from 'modules/profile/state/profile.actions';
import type { actions } from 'modules/profile/state/profile.slice';
import { useAppDispatch } from 'common/hooks/state-hooks';
import {
  SettingsCard,
  Heading,
  Body,
  ProfileBody,
  Instruction,
  Button,
  FormGroup,
  Label,
  InputField,
  Form,
  SubmitButton,
  ValidationSuccessMsg,
  ValidationFailMsg,
} from 'modules/profile/components/__styles__/SettingsV2.styles';

export interface Props {
  updatePassword: typeof updatePassword;
  isUpdatingPassword: boolean;
  updatePasswordSuccessMessage: string | null;
  updatePasswordErrMessage: string | null;
  resetPasswordErrorMessage: typeof actions.resetUpdatePasswordError;
}

const UpdatePassword = ({
  updatePassword,
  isUpdatingPassword,
  updatePasswordErrMessage,
  updatePasswordSuccessMessage,
  resetPasswordErrorMessage,
}: Props) => {
  const [showForm, toggleShowForm] = useState(false);
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [currentPasswordError, setCurrentPasswordError] = useState('');
  const [newPasswordError, setNewPasswordError] = useState('');
  const [confirmPasswordError, setConfirmPasswordError] = useState('');
  const dispatch = useAppDispatch();

  const handleCurrentPasswordChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setCurrentPassword(event.target.value);
  };

  const handleNewPasswordChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setNewPassword(event.target.value);
  };

  const handleConfirmPasswordChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setConfirmPassword(event.target.value);
  };

  const handleFocus = () => {
    setCurrentPasswordError('');
    setNewPasswordError('');
    setConfirmPasswordError('');
    dispatch(resetPasswordErrorMessage());
  };

  const handleSubmit = async (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    if (!currentPassword) {
      setCurrentPasswordError('Please provide current password');
    } else if (newPassword !== confirmPassword) {
      setConfirmPasswordError('Passwords do not match');
    } else if (
      !newPassword
      || newPassword.trim() === ''
      || newPassword.length < 8
    ) {
      setNewPasswordError('Password should be at least 8 characters long');
    } else {
      dispatch(updatePassword(newPassword, currentPassword));
    }
  };

  return (
    <SettingsCard>
      <Heading>Security Settings</Heading>
      <Body>
        {showForm ? (
          <Form>
            {updatePasswordErrMessage && (
              <ValidationFailMsg
                css={css`
                font-size: 1.4rem;
                text-align: center;
                width: 75%;
              `}
              >
                {updatePasswordErrMessage}
              </ValidationFailMsg>
            )}
            {updatePasswordSuccessMessage && (
              <ValidationSuccessMsg
                css={css`
                font-size: 1.4rem;
                text-align: center;
                width: 75%;
              `}
              >
                {updatePasswordSuccessMessage}
              </ValidationSuccessMsg>
            )}
            <FormGroup>
              <Label>Current Password</Label>
              <InputField
                onChange={handleCurrentPasswordChange}
                value={currentPassword}
                onFocus={handleFocus}
                type="password"
              />
              {currentPasswordError && (
                <ValidationFailMsg>{currentPasswordError}</ValidationFailMsg>
              )}
            </FormGroup>
            <FormGroup>
              <Label>New Password</Label>
              <InputField
                onChange={handleNewPasswordChange}
                value={newPassword}
                onFocus={handleFocus}
                type="password"
              />
              {newPasswordError && (
                <ValidationFailMsg>{newPasswordError}</ValidationFailMsg>
              )}
            </FormGroup>
            <FormGroup>
              <Label>Confirm New Password</Label>
              <InputField
                onChange={handleConfirmPasswordChange}
                value={confirmPassword}
                onFocus={handleFocus}
                type="password"
              />
              {confirmPasswordError && (
                <ValidationFailMsg>{confirmPasswordError}</ValidationFailMsg>
              )}
            </FormGroup>
            <SubmitButton type="submit" onClick={handleSubmit}>
              <span>Update Password</span>
              {isUpdatingPassword && (
                <Loader
                  size="2.5rem"
                  style={{
                    borderTop: `0.2rem solid ${({ theme: { colors } }) =>
                      colors.colorBackground}`,
                    position: 'absolute',
                    right: '1rem',
                  }}
                />
              )}
            </SubmitButton>
          </Form>
        ) : (
          <>
            <ProfileBody>
              <SecuritySettings />
              <Instruction>Change your current login password</Instruction>
            </ProfileBody>
            <Button type="button" onClick={() => toggleShowForm(true)}>
              Change Password
            </Button>
          </>

        )}
      </Body>
    </SettingsCard>
  );
};

export default UpdatePassword;
