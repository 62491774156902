import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { DONATIONS } from 'modules/donations/constants';
import type { Donation, Donor } from '../utils/donationsApiUtils';

type DonateSuccess = { donor: Donor; donation: Donation };

export interface State {
  donor: Donor | null;
  donation: Donation | null;
  isSavingDonation: boolean;
  errorSavingDonation: string;
}

export const initialState: State = {
  donor: null,
  donation: null,
  isSavingDonation: false,
  errorSavingDonation: '',
};


export const donationsSlice = createSlice({
  name: DONATIONS,
  initialState,
  reducers: {
    saveDonationStarted: (state) => {
      state.isSavingDonation = true;
    },

    saveDonationSucceeded: (state, action: PayloadAction<DonateSuccess>) => {
      const { donor, donation } = action.payload;
      return {
        ...state, donor, donation, isSavingDonation: false,
      };
    },
    
    saveDonationFailed: (state, action: PayloadAction<{ error: string }>) => {
      const { error } = action.payload;
      return {
        ...state,
        isSavingDonation: false,
        errorSavingDonation: error,
      };
    },
  },
});

export const { actions } = donationsSlice;

export const donationsReducer = donationsSlice.reducer;
