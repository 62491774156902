import { apiCall } from 'common/utils/api-helpers';

export interface Subscription {
  stripeId: string;
  priceId: string;
  status: string;
  currentPeriodStart: string;
  currentPeriodEnd: string;
  latestInvoiceId: string;
  latestInvoicePaymentIntentStatus: string;
  paymentMethodId: string;
  paymentMethodLast4: string;
  paymentMethodBrand: string;
  platform?: string;
  iapSku?: string;
}

export const cancelSubscription = async (
  subscriptionId: string,
): Promise<void> => {
  await apiCall('cancel-subscription', 'post', {
    subscriptionId,
  });
};
