import styled from 'styled-components';
import { setFont } from 'app/globalStyles/variables';
import { isDesktopOrLarger } from 'app/globalStyles/screens';

type HeaderProps = {
  isHeaderVisible: boolean;
  backgroundColor?: string;
};

export const HeaderContainer = styled.header<HeaderProps>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: ${({ theme }) => theme.colors.colorBackground};
  position: sticky;
  top: ${({ isHeaderVisible }) => (isHeaderVisible ? '0' : '-60px')};
  transition: top 0.3s ease-in-out;
  width: 90%;
  margin: 0 auto;
  height: 60px;
  font-family: ${setFont.main};
  z-index: 10;
  padding-top: env(safe-area-inset-top, 0);
  border-bottom: 0.1px solid
    ${({ backgroundColor, theme: { colors } }) =>
    backgroundColor || colors.colorInputBg};
  ${isDesktopOrLarger`
    width: 76%;
  `}
`;
