import type { GenericPagination } from 'common/types';
import { apiCall } from 'common/utils/api-helpers';
import type { Highlight } from 'modules/reading/utils/api/highlightsApiUtils';

type HighlightPagination = {
  highlights: Highlight[];
  pagination: GenericPagination | null;
};

export const fetchHighlights = async (
  pageNumber: number,
  limit = 5,
): Promise<HighlightPagination> => {
  const { data } = await apiCall(
    `highlights?page=${pageNumber}&limit=${limit}`,
    'get',
    null,
  );
  return data;
};

export const fetchUnfetchedHighlights = async (
  ids: Array<number>,
): Promise<Highlight[]> => {
  const { data } = await apiCall(
    `highlights/byIds?ids=${ids.join(',')}`,
    'get',
    null,
  );
  return data;
};

export const loadMoreHighlights = async (
  pageNumber: number,
  limit = 5,
): Promise<HighlightPagination> => {
  const { data } = await apiCall(
    `highlights?page=${pageNumber}&limit=${limit}`,
    'get',
    null,
  );
  return data;
};
