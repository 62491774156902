import type { GenericPagination } from 'common/types';
import { apiCall } from 'common/utils/api-helpers';
import type { Verse } from 'modules/reading/utils/api/chapterApiUtils';

export type VersesPagination = { verses: Verse[]; pagination: GenericPagination };

export const searchVerses = async (
  searchString: string,
  pageNumber = 1,
  limit = 100,
): Promise<VersesPagination> => {
  const { data } = await apiCall(
    `search/verses?page=${pageNumber}&limit=${limit}`,
    'post',
    {
      searchString,
    },
  );

  return data;
};

export const loadMoreVerses = async (
  searchString: string,
  pageNumber = 1,
  limit = 10,
): Promise<VersesPagination> => {
  const { data } = await apiCall(
    `search/verses?page=${pageNumber}&limit=${limit}`,
    'post',
    {
      searchString,
    },
  );
  return data;
};

export const fetchOneVerse = async (
  chapterSlug: string,
  verseNumber: number,
): Promise<Verse> => {
  const { data } = await apiCall(`verses/${chapterSlug}/${verseNumber}`, 'get');
  return data;
};
