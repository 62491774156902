import React, { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { css } from 'styled-components';
import { UserAvatar } from 'static/assets';
import { emailRegex } from 'modules/auth/utils/validateAuth';
import Loader from 'common/components/Loader';
import { routeTo, routeAs } from 'common/utils/routes';
import type { actions } from 'modules/auth';
import type { actions as profileActions } from 'modules/profile/state/profile.slice';
import { useAppDispatch } from 'common/hooks/state-hooks';

import type {
  checkUsernameAvailability,
  updateUsername,
  updateName,
  updateEmail,
} from 'modules/profile/state/profile.actions';
import { Name, Email } from 'modules/profile/components/__styles__/UpdateProfile.styles';
import {
  SettingsCard,
  Body,
  ProfileBody,
  Heading,
  Button,
  EditProfileFormGroup,
  Label,
  ValidationFailMsg,
  ValidationSuccessMsg,
  InputSubmitButton,
  Form,
  ProfileInputField,
  InputWithButton,
} from 'modules/profile/components/__styles__/SettingsV2.styles';
import type { User } from '../utils/profileApiUtils';

export interface Props {
  user: User;
  isUsernameAvailable: boolean | null;
  checkUsernameAvailability: typeof checkUsernameAvailability;
  updateUsername: typeof updateUsername;
  updateUsernameErrMessage?: string;
  isUpdatingUsername: boolean;
  updateName: typeof updateName;
  updateNameErrMessage?: string;
  isUpdatingName: boolean;
  updateEmail: typeof updateEmail;
  updateEmailErrMessage: string | null;
  updateEmailSuccessMessage: string | null;
  isUpdatingEmail: boolean;
  resetUpdateUsernameNotification: typeof profileActions.resetUpdateUsernameNotification;
  resetUpdateEmailNotification: typeof profileActions.resetUpdateEmailNotification;
  resetUpdateNameNotification: typeof profileActions.resetUpdateNameNotification;
  setLoginUser: typeof actions.setLoginUser;
}

export const UpdateProfile = (props: Props) :JSX.Element => {
  const {
    user,
    isUsernameAvailable,
    checkUsernameAvailability,
    resetUpdateUsernameNotification,
    resetUpdateEmailNotification,
    resetUpdateNameNotification,
    updateUsername,
    updateUsernameErrMessage,
    isUpdatingUsername,
    updateName,
    updateNameErrMessage,
    isUpdatingName,
    updateEmail,
    updateEmailErrMessage,
    updateEmailSuccessMessage,
    isUpdatingEmail,
  } = props;

  const dispatch = useAppDispatch();

  const [showForm, toggleShowForm] = useState(false);
  const [username, setUsername] = useState(user.username);
  const [name, setName] = useState(user.name || '');
  const [email, setEmail] = useState(user.email);
  const [emailError, setEmailError] = useState('');
  const [nameError, setNameError] = useState('');
  const [usernameError, setUsernameError] = useState('');

  const router = useRouter();
  useEffect(() => {
    router.push(routeTo.settings, routeAs.settings(user.username));
  }, [user.username]);

  const handleUserNameChange = async (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const { value } = event.target;
    setUsername(value);
    setUsernameError('');

    if (value.length < 3) {
      setUsernameError('Username must be at least 3 letters');
    } else if (!value || !/^[\w_.]+$/g.test(value)) {
      setUsernameError('Usernames may only use letters, numbers, “.”, and “_”');
    } else if (value && value !== user.username) {
      dispatch(checkUsernameAvailability(value));
    }
  };

  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const name = event.target.value;
    setName(name);
    if ((name.length > 0 && name.length < 3) || !/^[A-Za-z\s.]*$/g.test(name)) {
      setNameError(
        'Name may only use letters, space and and "." must have at least 3',
      );
    } else {
      setNameError('');
    }
  };

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const email = event.target.value;
    setEmail(email);
    setEmailError('');
    if (!email || email.trim() === '' || !emailRegex.test(email)) {
      setEmailError('Invalid Email');
    }
  };

  // Handling the profile updates
  const handleUsernameUpdate = async (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    if (!usernameError && (username !== user.username)) {
      dispatch(updateUsername(username));
    }
  };

  const handleNameUpdate = async (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    if (!nameError) {
      dispatch(updateName(name));
    }
  };

  const handleEmailUpdate = async (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    if (!emailError && (email !== user.email)) {
      dispatch(updateEmail(email));
    }
  };

  const handleFocus = () => {
    setEmailError('');
    setNameError('');
    setUsernameError('');
    dispatch(resetUpdateUsernameNotification());
    dispatch(resetUpdateEmailNotification());
    dispatch(resetUpdateNameNotification());
  };

  const usernameAvailabilityStatus = () => {
    if (user.username === username) {
      return null;
    }

    if (usernameError) {
      return (
        <ValidationFailMsg>
          {usernameError}
        </ValidationFailMsg>
      );
    }

    if (!isUsernameAvailable) {
      return (
        <ValidationFailMsg>
          Username already taken
        </ValidationFailMsg>
      );
    } if (isUsernameAvailable) {
      return (
        <ValidationSuccessMsg>Username Available</ValidationSuccessMsg>
      );
    } 
    return null;
  };

  return (
    <SettingsCard>
      <Heading>Profile</Heading>
      {showForm ? (
        <Body>
          <Form>
            {updateUsernameErrMessage && (
            <ValidationFailMsg
              css={css`
                font-size: 1.4rem;
                text-align: center;
                width: 75%;
                margin-bottom: 3rem;
              `}
            >
              {updateUsernameErrMessage}
            </ValidationFailMsg>
            )}
            
            <EditProfileFormGroup>
              <Label>Username</Label>
              <InputWithButton>
                <ProfileInputField
                  onChange={handleUserNameChange}
                  value={username}
                  onFocus={handleFocus}
                />
                <InputSubmitButton type="submit" onClick={handleUsernameUpdate}>
                  <span>Submit</span>
                  {isUpdatingUsername && (
                    <Loader
                      size="2.5rem"
                      css={`
                      border-top: 0.2rem solid
                        ${({ theme: { colors } }) => colors.colorBackground};
                      position: absolute;
                      right: 1rem;
                    `}
                    />
                  )}
                
                </InputSubmitButton>
              </InputWithButton>
            </EditProfileFormGroup>
            {usernameAvailabilityStatus()}
          </Form>

        
          <Form>
            {updateNameErrMessage && (
            <ValidationFailMsg
              css={css`
                font-size: 1.4rem;
                text-align: center;
                width: 75%;
                margin-bottom: 3rem;
              `}
            >
              {updateNameErrMessage}
            </ValidationFailMsg>
            )}
          
            <EditProfileFormGroup>
              <Label>Full Name</Label>
              <InputWithButton>
                <ProfileInputField
                  onChange={handleNameChange}
                  value={name}
                  onFocus={handleFocus}
                />
                <InputSubmitButton type="submit" onClick={handleNameUpdate}>
                  <span>Submit</span>
                  {isUpdatingName && (
                    <Loader
                      size="2.5rem"
                      css={`
                    border-top: 0.2rem solid
                      ${({ theme: { colors } }) => colors.colorBackground};
                    position: absolute;
                    right: 1rem;
                  `}
                    />
                  )}
                </InputSubmitButton>
              </InputWithButton>
            </EditProfileFormGroup>
            {nameError && <ValidationFailMsg>{nameError}</ValidationFailMsg>}
          </Form>

        
          <Form>
            {updateEmailErrMessage && (
            <ValidationFailMsg
              css={css`
                font-size: 1.4rem;
                text-align: center;
                width: 75%;
                margin-bottom: 3rem;
              `}
            >
              {updateEmailErrMessage}
            </ValidationFailMsg>
            )}
            {updateEmailSuccessMessage && (
            <ValidationSuccessMsg
              css={css`
                font-size: 1.4rem;
                text-align: center;
                width: 75%;
                margin-bottom: 3rem;
              `}
            >
              {updateEmailSuccessMessage}
            </ValidationSuccessMsg>
            )}
            
            <EditProfileFormGroup>
              <Label>Email Address</Label>
              <InputWithButton>
                <ProfileInputField
                  onChange={handleEmailChange}
                  value={email}
                  onFocus={handleFocus}
                />
              
                <InputSubmitButton type="submit" onClick={handleEmailUpdate}>
                  <span>Submit</span>
                  {isUpdatingEmail && (
                  <Loader
                    size="2.5rem"
                    css={`
                    border-top: 0.2rem solid
                      ${({ theme: { colors } }) => colors.colorBackground};
                    position: absolute;
                    right: 1rem;
                  `}
                  />
                  )}
              
                </InputSubmitButton>
              </InputWithButton>
            </EditProfileFormGroup>
            {emailError && <ValidationFailMsg>{emailError}</ValidationFailMsg>}
          </Form>
        </Body>
      ) : (
        <Body>
          <ProfileBody>
            <UserAvatar />
            <div>
              <Name>{user.name || user.username}</Name>
              <Email>{user.email}</Email>
            </div>
          </ProfileBody>
          <Button type="button" onClick={() => toggleShowForm(true)}>
            Edit Profile
          </Button>
        </Body>
      )}
    </SettingsCard>
  );
};

export default UpdateProfile;
