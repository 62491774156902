/* eslint-disable import/extensions */
import styled from 'styled-components';
import { setFont } from '../../../app/globalStyles/variables';
import { isTabletOrLarger } from '../../../app/globalStyles/screens';

export const FooterComp = styled.footer`
  background: ${({ theme: { colors } }) => colors.colorBackground};
  font-size: 1.4rem;
  margin-top: 2rem;
  text-align: center;
  border-top: 1px solid ${({ theme: { colors } }) => colors.colorBorder};
  h3 {
    font-size: 1.6rem;
    color: ${({ theme: { colors } }) => colors.colorText};
    margin-bottom: 1rem;
    font-family: ${setFont.headings};
  }

  .footer-container {
    display: flex;
    margin: 0 auto;
    justify-content: center;
    align-content: center;
    width: 100%;
    flex-wrap: wrap;
    -webkit-flex-wrap: wrap;
    div {
      text-align: left
    };

    ${isTabletOrLarger`
      width: 70%;
      flex-wrap: initial;
      -webkit-flex-wrap: initial;
    `};
  }


  .footerColumn {
    flex-basis: 20%;
    display: flex;
    flex-direction: column;
    align-items: baseline;
    padding: 15px;
     ul {
      list-style: none;
      li{
        margin: 5px 0; //Sets spacing between list items
      }
      a {
        text-decoration: none;
        color: ${({ theme: { colors } }) => colors.colorPrimary3};
      }
    }
    .mobile-badges {
      display: relative;
    }

      width: 50%;
      &:nth-child(1) {
        width: 100%;
        padding-left: 0;
      }
      &:last-of-type {
        width: 100%;
      }

    ${isTabletOrLarger`
      width: initial;
      &:nth-child(1) {
        width: initial;
      }
      &:last-of-type {
        width: initial;
      }
    `}

  }

  .copyright {
    text-align: center;
    padding: 5px;
  }
`;
