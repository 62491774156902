import Link from 'next/link';
import React from 'react';
import { routeTo } from 'common/utils/routes';
import styled, { css } from 'styled-components';
import { ArrowRight } from 'styled-icons/heroicons-outline';
import { isTabletOrLarger } from 'app/globalStyles/screens';

export const UnlockWithMembershipLink = styled(Link)`
  font-size: 16px;
  padding: 10px 20px;
  text-align: center;
  text-decoration: unset;
  color: ${({ theme: { colors } }) => colors.colorText};
  background: ${({ theme: { colors } }) => colors.colorPrimary4};
  border-radius: 5px;
`;

export const Arrow = styled(ArrowRight)`
  margin-left: 10px;
  right: 10px;
  width: 18px;
  height: 18px;
  color: ${({ theme: { colors } }) => colors.colorText};
  ${isTabletOrLarger(css`
    right: initial
    width: 14px;
    height: 14px;
  `)};
`;

type Props = {
  title: string;
  className?: string;
  showArrow?: boolean;
};

const BecomeAMemberCTA = ({
  className,
  title,
  showArrow = true,
}: Props): JSX.Element => (
  <UnlockWithMembershipLink className={className} href={routeTo.membership}>
    <span>{title}</span>
    {showArrow && <Arrow />}
  </UnlockWithMembershipLink>
);

export default BecomeAMemberCTA;
