import React, { useState } from 'react';
import { useRouter } from 'next/router';
import { resendEmailVerificationAction } from 'modules/auth/state/auth.actions';
import { useAppDispatch, useAppSelector } from 'common/hooks/state-hooks';
import toast from 'common/components/Toast';
import { routeTo } from 'common/utils/routes';
import {
  StyledVerifyEmailNotice,
  ClosedButton,
  ResendLink,
} from 'modules/auth/components/__styles__/VerifyEmailStyles';
import { selectUser } from 'modules/auth/state/auth.slice';

const VerifyEmailNotice = () => {
  const { asPath } = useRouter();
  const [hideVerifyEmailMsg, setHideVerifyEmailMsg] = useState(false);
  const isLandingPage = asPath === routeTo.landing;

  const { email } = useAppSelector(selectUser) || {};
  const dispatch = useAppDispatch();

  const notifyEmailVerify = (message: string) =>
    toast({
      type: 'success',
      message,
    });

  if (!email) {
    return null;
  }

  const handleSubmit = async () => {
    await dispatch(resendEmailVerificationAction(email, notifyEmailVerify));
  };
  const handleVerifyEmailNotication = () => setHideVerifyEmailMsg(true);

  return (
    <StyledVerifyEmailNotice
      hideVerifyEmailMsg={hideVerifyEmailMsg}
      isLandingPage={isLandingPage}
    >
      <ClosedButton onClick={handleVerifyEmailNotication}>
        {' '}
        &times;
        {' '}
      </ClosedButton>
      Please check your email (
      {email}
) to verify that you own this address.
      Need us to
      <ResendLink href="#" onClick={handleSubmit}>
        resend it?
      </ResendLink>
    </StyledVerifyEmailNotice>
  );
};

export default VerifyEmailNotice;
