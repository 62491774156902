import React from 'react';
import Layout from 'common/page-layout/Layout';
import { Elements } from '@stripe/react-stripe-js';
import { useMediaQuery } from 'react-responsive';
import ChapterContainer from 'modules/reading/components/ChapterContainer';
import { loadStripe } from '@stripe/stripe-js';

const stripePromise = loadStripe(
  process.env.NEXT_PUBLIC_STRIPE_PUBLISHABLE_KEY as string,
);

type Props = {
  verseNumber?: number;
};

const ChapterPage = ({ verseNumber }: Props): JSX.Element => {
  const isMobile = useMediaQuery({ maxWidth: 767 });
  return (
    <Layout allowFooter={!isMobile}>
      <Elements stripe={stripePromise}>
        <ChapterContainer verseNumber={Number(verseNumber) || undefined} />
      </Elements>
    </Layout>
  );
};

export default ChapterPage;
