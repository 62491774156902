import React from 'react';
import { routeAs } from 'common/utils/routes';
import {
  BookCard as StyledCard,
  BookCardImage,
  CardContent,
  BuyButton,
} from 'modules/books/components/__styles__/Books.styles';
import type { Book } from '../utils/booksApiUtils';


// this image wil replace any broken img on loading
// eslint-disable-next-line no-return-assign
const addDefaultSrc = (e): string =>
  ((e.target as HTMLInputElement).src = '/defaultBookImg.jpg');

export interface Props {
  book: Book;
}

export const BookCard = ({ book }: Props) => (
  <StyledCard href="#">
    <BookCardImage
      src={`/books/${book.imageUrl}`}
      alt="Default img"
      onError={addDefaultSrc}
    />

    <CardContent>
      <div>
        <h3>{book.displayTitle}</h3>
        <span>{book.author}</span>
      </div>
    </CardContent>
    <BuyButton href={routeAs.book(book.slug)}>View Details</BuyButton>
  </StyledCard>
);
