import { apiCall } from 'common/utils/api-helpers';
import type { Journal } from 'modules/thoughts/journals/utils/journalsApiUtils';


export const fetchPublicJournalsForVerse = async (
  verseId: number,
): Promise<Journal[]> => {
  const { data } = await apiCall(`journals/verse/${verseId}`, 'get');
  return data;
};
