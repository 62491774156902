import { MoreHoriz } from 'styled-icons/material';
import styled from 'styled-components';
import { isTabletOrLarger } from 'app/globalStyles/screens';

export const MoreIcon = styled(MoreHoriz)`
  color: ${({ theme: { colors } }) => colors.colorText};
  width: 20px;
`;

export const StyledDropDown = styled.div`
  background: ${({ theme: { colors } }) => colors.colorBackground};
  position: relative;
`;

export const Button = styled.button`
  height: 100%;
  width: 4.2rem;
  border: unset;
  background: transparent;
  border-radius: 4px;
  outline: unset;
  background: ${({ theme: { colors } }) => colors.colorPrimary4};
  &:hover {
    background: ${({ theme: { colors } }) => colors.colorPrimary4};
  }
`;

export const MenuContainer = styled.div`
  position: absolute;
  top: 90%;
  border: 1px solid ${({ theme: { colors } }) => colors.colorBorder};
  border-radius: 5px;
  z-index: 50;
  right: 0;
  ${isTabletOrLarger`
      right: initial;
    `}
`;
