
import React, { useState } from 'react';
import { useRouter } from 'next/router';
import Avatar from 'react-avatar';
import { routeTo, routeAs } from 'common/utils/routes';
import { handleLogoutAction } from 'modules/auth/state/auth.actions';
import { logout } from 'modules/auth/utils/auth';
import { connect } from 'react-redux';
import type { RootState } from 'app/state/store';
import ProfileDropDown from 'common/page-layout/Header/ProfileDropDown';
import {
  StyledHeaderRight,
  SignInButton,
  SignUpButton,
  NavLink,
  AvatarNavLink,
  BookOpenIcon,
  BookOpenFill,
  JournalIcon,
  JournalFillIcon,
  HomeIcon,
  HomeFillIcon,
  AvatarContainer,
} from 'common/page-layout/Header/__styles__/HeaderRight.styles';
import { trackLogout } from 'modules/auth/utils/analytics';
import { MEMBERSHIP } from 'modules/membership';
import { PROFILE } from 'modules/profile';
import AuthModal from 'modules/auth/components/AuthModal';
import type { User } from 'modules/profile/utils/profileApiUtils';

interface DispatchProps {
  handleLogout: typeof handleLogoutAction;
}

interface StateProps {
  user: User | null;
  isActiveMember: boolean;
}
export type Props = StateProps &
  DispatchProps & {
    className?: string;
  };

const HeaderRight = (props: Props) => {
  const router = useRouter();
  const { route } = router;
  const { user, isActiveMember, className } = props;
  const [visibleAuthModal, setVisibleAuthModal] = useState<
    'signup' | 'signin' | undefined
  >();
  const [isProfileDropdownVisible, setIsProfileDropdownVisible] = useState(false);

  const goToSignUp = () => {
    const { asPath } = router;
    if (asPath === routeTo.login) {
      router.push(routeTo.signup, routeAs.signup, { shallow: true });
    } else if (asPath !== routeTo.signup && asPath !== routeTo.login) {
      setVisibleAuthModal('signup');
    }
  };

  const goToSignIn = () => {
    const { asPath } = router;
    if (asPath === routeTo.signup) {
      router.push(routeTo.login, routeAs.login, { shallow: true });
    } else if (asPath !== routeTo.signup && asPath !== routeTo.login) {
      setVisibleAuthModal('signin');
    }
  };

  const showProfileDropdown = () => {
    setIsProfileDropdownVisible(true);
  };

  const closeProfileDropdown = () => {
    setIsProfileDropdownVisible(false);
  };

  const handleLogout = async () => {
    await logout(props.handleLogout);
    trackLogout();
    closeProfileDropdown();
  };

  return (
    <StyledHeaderRight className={className}>
      {user ? (
        <>
          <NavLink href={routeTo.home} shallow>
            {route === routeTo.home ? <HomeFillIcon /> : <HomeIcon />}
          </NavLink>
          <NavLink href={routeAs.read} shallow>
            {route === routeTo.read || route === routeTo.chapter ? (
              <BookOpenFill />
            ) : (
              <BookOpenIcon />
            )}
          </NavLink>
          <NavLink href={routeAs.journals(user.username)} shallow>
            {route === routeTo.journals ? (
              <JournalFillIcon />
            ) : (
              <JournalIcon />
            )}
          </NavLink>
          <AvatarNavLink
            href="#"
            onClick={showProfileDropdown}
            onMouseEnter={showProfileDropdown}
            onMouseLeave={closeProfileDropdown}
          >
            <AvatarContainer>
              <Avatar
                name={user.name || user.email}
                email={user.email}
                round
                size="30"
                className="avatar"
              />
            </AvatarContainer>
          </AvatarNavLink>
        </>
      ) : (
        <>
          <SignInButton onClick={goToSignIn} type="button">
            Sign In
          </SignInButton>
          <SignUpButton onClick={goToSignUp} type="button">
            Sign Up
          </SignUpButton>
        </>
      )}
      {Boolean(visibleAuthModal) && (
        <AuthModal
          closeModal={() => setVisibleAuthModal(undefined)}
          visibleAuthModal={visibleAuthModal}
        />
      )}
      {!!user && isProfileDropdownVisible && (
        <ProfileDropDown
          username={user.username}
          isAdminUser={user.isAdmin}
          isActiveMember={isActiveMember}
          handleLogout={handleLogout}
          closeProfileDropdown={closeProfileDropdown}
          showProfileDropdown={showProfileDropdown}
        />
      )}
    </StyledHeaderRight>
  );
};

// TODO: Header should not be connected directly to redux. Pass this data from pages that need them.
const mapStateToProps = ({
  [PROFILE]: { user },
  [MEMBERSHIP]: { subscription },
}: RootState): StateProps => ({
  user,
  isActiveMember: !!subscription && subscription.status === 'active',
});

export default connect(mapStateToProps, {
  handleLogout: handleLogoutAction,
})(HeaderRight);
