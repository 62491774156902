import React from 'react';
import NProgress from 'nprogress';
import Router from 'next/router';
import type { DefaultTheme } from 'styled-components';
import { withTheme } from 'styled-components';
import { setZindex } from 'app/globalStyles/variables';

export interface Props {
  showAfterMs?: number;
  spinner?: boolean;
  options?: any;
  theme: DefaultTheme;
}

class CustomNProgress extends React.Component<Props> {
  // eslint-disable-next-line react/sort-comp,react/static-property-placement
  static defaultProps = {
    showAfterMs: 300,
    spinner: true,
  };

  timer: any = null;

  componentDidMount() {
    const { options } = this.props;

    if (options) {
      NProgress.configure(options);
    }

    Router.events.on('routeChangeStart', this.routeChangeStart);
    Router.events.on('routeChangeComplete', this.routeChangeEnd);
    Router.events.on('routeChangeError', this.routeChangeEnd);
  }

  componentWillUnmount() {
    clearTimeout(this.timer);
    Router.events.off('routeChangeStart', this.routeChangeStart);
    Router.events.off('routeChangeComplete', this.routeChangeEnd);
    Router.events.off('routeChangeError', this.routeChangeEnd);
  }

  routeChangeStart = () => {
    const { showAfterMs } = this.props;
    clearTimeout(this.timer);
    this.timer = setTimeout(() => NProgress.start(), showAfterMs);
  };

  routeChangeEnd = () => {
    clearTimeout(this.timer);
    NProgress.done();
  };

  render() {
    const {
      spinner,
      theme: { colors },
    } = this.props;
    return (
      <style jsx global>
        {`
          #nprogress {
            pointer-events: none;
          }

          #nprogress .bar {
            background: ${colors.colorLoader};

            position: fixed;
            z-index: ${setZindex.critical};
            top: 0;
            left: 0;

            width: 100%;
            height: 3.5px;
          }

          #nprogress .peg {
            display: block;
            position: absolute;
            right: 0px;
            width: 100px;
            height: 100%;
            box-shadow: 0 0 10px ${colors.colorLoader},
              0 0 5px ${colors.colorLoader};
            opacity: 1;

            -webkit-transform: rotate(3deg) translate(0px, -4px);
            -ms-transform: rotate(3deg) translate(0px, -4px);
            transform: rotate(3deg) translate(0px, -4px);
          }

          #nprogress .spinner {
            display: ${spinner ? 'block' : 'none'};
            position: fixed;
            z-index: ${setZindex.critical};
            bottom: 15px;
            right: 15px;
          }

          #nprogress .spinner-icon {
            width: 18px;
            height: 18px;
            box-sizing: border-box;

            border: solid 2px transparent;
            border-top-color: ${colors.colorLoader};
            border-left-color: ${colors.colorLoader};
            border-radius: 50%;

            -webkit-animation: nprogresss-spinner 400ms linear infinite;
            animation: nprogress-spinner 400ms linear infinite;
          }

          .nprogress-custom-parent {
            overflow: hidden;
            position: relative;
          }

          .nprogress-custom-parent #nprogress .spinner,
          .nprogress-custom-parent #nprogress .bar {
            position: absolute;
          }

          @-webkit-keyframes nprogress-spinner {
            0% {
              -webkit-transform: rotate(0deg);
            }
            100% {
              -webkit-transform: rotate(360deg);
            }
          }
          @keyframes nprogress-spinner {
            0% {
              transform: rotate(0deg);
            }
            100% {
              transform: rotate(360deg);
            }
          }
        `}
      </style>
    );
  }
}

export default withTheme(CustomNProgress);
