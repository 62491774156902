import type { GenericPagination } from 'common/types';
import React from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Loader } from './Loader';

export interface Props {
  dataLength: number;
  pagination: GenericPagination | null;
  loadMore: () => void;
  children: JSX.Element[] | JSX.Element;
}

const InfiniteScrollList = ({
  dataLength, loadMore, pagination, children,
}: Props) => (
  <InfiniteScroll
    dataLength={dataLength}
    next={loadMore}
    hasMore={!!pagination && pagination.currentPage < pagination.numberOfPages}
    loader={<Loader size="3rem" />}
    style={{
      overflow: 'hidden',
      paddingBottom: '60px',
    }}
  >
    {children}
  </InfiniteScroll>
);

export default InfiniteScrollList;
