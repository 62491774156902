import type { DefaultTheme } from 'styled-components';

// TODO: color names could use some more refactoring.
export const colors = {
  colorPrimary1: '#1E2C46', // alternatives: rgb(41, 41, 41), #444

  colorPrimary2: '#4F6D9B',
  colorPrimary3: '#5d6b89', // '#677798' was the original from figma, it was used for text but not accessible enough. contrast low.,
  colorPrimary4: '#F2F5FD', // interactive color?

  colorOverlay: 'rgba(79, 109, 155, 0.8)',

  colorInputBg: 'inherit', // #FBFCFE todo: remove when save
  colorBorder: '#BFD3F3',
  inputPlaceHolderColor: '#A3ADC2',
  colorIcons: '#fffefc',

  colorBackground: '#ffffff', // #fffff
  colorBackgroundDark: '#FBFCFE',
  QuotesBackground: '#0c162b',

  colorSuccess: '#6ab750',
  mainRed: '#D94D3A',
  lightRed: '#FDF3F2',
  colorLoader: '#1E2C46',

  colorTitle: '#1E2C46', // same as colorPrimary1
  colorText: '#1E2C46', // same as colorPrimary1
  colorTextWhite: '#ffffff', // Doesn't change in dark mode
  colorTextPrimary: '#1E2C46', // Doesn't change in dark mode
  colorQuotesAuthor: '#f5deb3',

  colorTextDisabled: 'rgba(30, 44, 70, 0.4)',
};

// TODO: avoid using colorBackground for text color in night mode.
//   Use the original colorPrimary
export const darkModeColors = {
  ...colors,
  colorPrimary1: colors.colorPrimary2,
  colorPrimary3: '#95a0b7',
  colorPrimary4: '#232731',
  colorBorder: 'rgba(191, 211, 243, 0.3)', // colorPrimary2

  colorBackground: '#181B22', // #121212
  colorBackgroundDark: '#232731',

  colorLoader: '#ffffff',

  colorTitle: '#ffffff',
  colorText: '#F2F5FD',
};

export const dimModeColors = {
  ...darkModeColors,
  colorPrimary1: colors.colorPrimary2,
  colorPrimary4: '#2d426a',
  // colorInputBg: '#223653',
  colorBorder: 'rgba(191, 211, 243, 0.2)',
  colorBackground: colors.colorPrimary1,
  colorBackgroundDark: '#2d426a',

  colorText: '#c8d5e2',
};

export const dark2ModeColors = {
  ...darkModeColors,
  colorPrimary1: colors.colorPrimary2,
  colorPrimary4: '#353e47',

  colorBackground: '#2A3138',
  colorBackgroundDark: '#353e47',

  // colorInputBg: '#303840',
  colorBorder: 'rgba(191, 211, 243, 0.2)', // colorPrimary2
};

export const lightTheme: DefaultTheme = {
  colors,
};

export const darkTheme: DefaultTheme = {
  colors: darkModeColors,
};
export const dark2Theme: DefaultTheme = {
  colors: dark2ModeColors,
};

export const dimTheme: DefaultTheme = {
  colors: dimModeColors,
};

export const setPosition = {
  absolute: 'absolute',
  relative: 'relative',
  fixed: 'fixed',
};

export const setFont = {
  main: "'Inter', sans-serif",
  headings: "'Playfair Display', serif",
  journalFont: "'Lora', serif",
  readerDefaultFont: "'Avenir', sans-serif",
};

export const setFlex = ({ x = 'center', y = 'center' } = {}) =>
  `display: flex; align-items: ${y}; justify-content: ${x};`;

export const setZindex = {
  none: undefined,
  low: 1000,
  medium: 2000,
  high: 3000,
  critical: 10000,
};

export const setBackground = ({
  img = 'https://images.pexels.com/photos/1628086/pexels-photo-1628086.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500',
  color = 'rgba(0,0,0,0)',
} = {}) => `background: linear-gradient(${color}, ${color}),
    url(${img}) center/cover fixed no-repeat`;

export const setRem = (number = 16) => `${number / 16}rem`;

export const setLetterSpacing = (number = 2) => `letter-spacing: ${number}px`;

export const setShadow = {
  light: 'box-shadow: 3px 3px 5px 0px rgba(0,0,0,0.75)',
  dark: 'box-shadow: 6px 6px 5px 0px rgba(0,0,0,0.75);',
  darkest: 'box-shadow: 10px 10px 5px 0px rgba(0,0,0,0.75);',
};

export const setBorder = ({
  width = '1px',
  style = 'solid',
  color = 'black',
} = {}) => `border: ${width} ${style} ${color}`;

export const setTransition = ({
  property = 'all',
  time = '0.3s',
  timing = 'ease-in-out',
} = {}) => `transition:${property} ${time} ${timing}`;

export const setPageWidth = (width = '55%') => `width: ${width}`;
