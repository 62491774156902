import { apiCall } from 'common/utils/api-helpers';
import type { ChapterLockReasons } from 'modules/reading/utils/readingUtils';
import type { WithRequired } from 'common/types';
import type { Book } from 'modules/books/utils/booksApiUtils';
import type { Highlight } from './highlightsApiUtils';

export interface Verse {
  chapterId: number;
  id: number;
  number: number;
  text: string;
  chapter?: Chapter;
  book?: Book;
  highlighted?: boolean;
  highlights?: Array<Highlight>;
}

export type Chapter = {
  bookId: number;
  id: number;
  number: number;
  slug: string;
  title: string;
  author?: string;
  verses?: Verse[];
  book?: Book;
  accessType: any;
  // TODO: Figure out the proper type and set it
};

export type FetchAndReloadChapterResponse = {
  chapter: WithRequired<Chapter, 'verses'>;
  nextChapter: Chapter;
  prevChapter: Chapter;
  chapterLock: { reason: ChapterLockReasons } | null;
}

export const fetchBookChapter = async (
  slug: string,
  token?: string,
): Promise<FetchAndReloadChapterResponse> => {
  const { data } = await apiCall(`chapters/${slug}`, 'get', null, token);
  return data;
};

export const fetchChapterReadCount = async (chapterId: number): Promise<{count: number}> => {
  const { data } = await apiCall(`reads/${chapterId}`, 'get');
  return data;
};
