import type { AppThunk } from 'app/state/store';
import type { AxiosResponse } from 'axios';
import { actions } from 'modules/books/state/books.slice';
import * as api from 'modules/books/utils/booksApiUtils';


export const fetchBooks = (): AppThunk =>
  async (dispatch): Promise<void | AxiosResponse<any>> => {
    try {
      dispatch(actions.fetchBooksStarted());
      const books = await api.fetchBooks();
      dispatch(actions.fetchBooksSucceeded(books));
    } catch (err) {
      if (err && err.response) {
        dispatch(actions.fetchBooksFailed(err.response));
      }
    }
  };
  
export const fetchBookBySlug = (bookSlug: string): AppThunk =>
  async (dispatch): Promise<void | AxiosResponse<any>> => {
    try {
      dispatch(actions.fetchBookBySlugStarted());
      const book = await api.fetchBookBySlug(bookSlug);
      dispatch(actions.fetchBookBySlugSucceeded(book));
    } catch (err) {
      if (err && err.response) {
        dispatch(actions.fetchBookBySlugFailed(err.response));
      }
    }
  };

export const saveUserBook = (bookId: number): AppThunk =>
  async (dispatch): Promise<void | AxiosResponse<any>> => {
    try {
      dispatch(actions.saveUserPurchaseStarted());
      await api.saveUserBook(bookId);
      dispatch(actions.saveUserPurchaseSucceeded());
    } catch (err) {
      if (err && err.response) {
        dispatch(actions.saveUserPurchaseFailed(err.response));
      }
    }
  };
