import styled from 'styled-components';
import {
  isDesktopOrLarger,
  isTabletOrLarger,
  isTablet,
} from 'app/globalStyles/screens';
import { setFlex, setFont } from 'app/globalStyles/variables';

export const StyledJournal = styled.div`
  padding-bottom: 50px;
  width: 80%;
  margin: 2% 10% 50px 10%;
    h1 {
      font-size: 2rem;
    }
  ${isTabletOrLarger`
    width: 45%;
    margin: 20px auto;
    h1 {
      font-size: initial;
    }
    `}
`;

export const JournalToolBar = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 10px 0 10px 0;
  font-size: 14px;
`;

export const ToolBarLeft = styled.div`
  color: #555;

  span {
    padding: 0 10px 0 10px;
    position: relative;

    &::after {
      content: '';
      height: 10px;
      border-right: 1px solid #bfd3f3;
      position: absolute;
      right: 0;
      top: 4px;
    }

    &:first-child {
      padding-left: 0;
    }

    &:last-child {
      padding-right: 0;
      ::after {
        content: '';
        display: none;
      }
    }
  }
`;

export const ToolBarRight = styled.div`
  color: #4f6d9b;
  min-width: 340px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  div {
    flex: 1;
    text-align: center;
    padding-top: 3px;
    position: relative;

    button {
      border: unset;
      font-size: 14px;
      color: #4f6d9b;

      span {
        margin-left: 4px;
      }
    }

    &::after {
      content: '';
      height: 10px;
      border-right: 1px solid #bfd3f3;
      position: absolute;
      right: 0;
      top: 6px;
    }

    &:first-child {
      padding-left: 0;
    }
    &:last-child ::after {
      content: '';
      display: none;
    }
  }
`;

export const Heading = styled.h1`
  color: ${({ theme: { colors } }) => colors.colorText};
  margin-bottom: 14px;
  font-weight: 500;
`;
export const Body = styled.div`
  margin-top: 20px;
  font: 400 1.8rem ${setFont.main};
  line-height: 32px;
  white-space: pre-wrap;
`;
export const HeadingEditor = styled.input`
  width: 100%;
  font-size: 3rem;
  margin-bottom: 1.4rem;
  font-weight: bold;
  outline: unset;
  border: unset;
  line-height: 1.2;
`;

export const JournalPageContainer = styled.div`
  width: 90%;
  margin: 3rem auto;
  ${isTablet`
     width: 70%;
   `};
  ${isDesktopOrLarger`
     width: 70%;
   `};
`;

export const JournalPageHeader = styled.div`
  position: relative;
  margin-bottom: 1rem;
  padding: 0;
  ${setFlex({ x: 'space-between', y: 'start' })};
  ${isTabletOrLarger`
    margin-bottom: 2rem;
    padding: 0 0.8rem;
  `}
`;

export const JournalPageHeading = styled.div`
  font-size: 2.6rem;
  font-weight: 500;
  font-family: ${setFont.headings};
`;
