/* eslint-disable camelcase */
import { Mixpanel, eventNames } from 'common/utils/mixpanel';

type ReaderEventPropTypes = {
  book_title: string;
  book_author: string;
  chapter_number: number;
  chapter_title?: string;
};

type VerseEventPropTypes = ReaderEventPropTypes & {
  verse_number: number;
};

type HighlightEventPropTypes = VerseEventPropTypes & {
  color: string; // TODO: Specify exact color names as type.
};

type ShareEventPropTypes = VerseEventPropTypes & {
  network_name: string;
};

// TODO: Can rename to something more appropriate
export const trackChangeReaderBook = ({
  book_title,
  book_author,
  chapter_title,
  chapter_number,
}: ReaderEventPropTypes): void => {
  Mixpanel.trackEvent(eventNames.change_reader_book, {
    book_title,
    book_author,
    chapter_number,
    chapter_title,
  });
};

export const trackViewChapter = ({
  book_title,
  book_author,
  chapter_title,
  chapter_number,
}: ReaderEventPropTypes): void => {
  Mixpanel.trackEvent(eventNames.view_chapter, {
    book_title,
    book_author,
    chapter_number,
    chapter_title,
  });
};

export const trackOpenReaderSettings = (): void => {
  Mixpanel.trackEvent(eventNames.open_reader_settings);
};

export const trackChangeReaderFontFamily = (font_family: string): void => {
  Mixpanel.trackEvent(eventNames.change_reader_font_family, { font_family });
};

export const trackChangeReaderFontSize = (font_size: number): void => {
  Mixpanel.trackEvent(eventNames.change_reader_font_size, { font_size });
};

export const trackChangeReaderVerseNumbers = (enabled: boolean): void => {
  Mixpanel.trackEvent(eventNames.change_reader_verse_numbers, { enabled });
};

export const trackAddHighlight = ({
  book_title,
  book_author,
  chapter_number,
  verse_number,
  color,
}: HighlightEventPropTypes): void => {
  Mixpanel.trackEvent(eventNames.add_highlight, {
    book_title,
    book_author,
    chapter_number,
    verse_number,
    color,
  });
};

export const trackChangeHighlightColor = ({
  book_title,
  book_author,
  chapter_number,
  verse_number,
  color,
}: HighlightEventPropTypes): void => {
  Mixpanel.trackEvent(eventNames.change_highlight_color, {
    book_title,
    book_author,
    chapter_number,
    verse_number,
    color,
  });
};

export const trackShareTextOnSocial = ({
  book_title,
  book_author,
  chapter_number,
  verse_number,
  network_name,
}: ShareEventPropTypes): void => {
  Mixpanel.trackEvent(eventNames.share_text_on_social, {
    book_title,
    book_author,
    chapter_number,
    verse_number,
    network_name,
  });
};

export const trackSaveBookmark = ({
  book_title,
  book_author,
  chapter_number,
  verse_number,
}: VerseEventPropTypes): void => {
  Mixpanel.trackEvent(eventNames.save_bookmark, {
    book_title,
    book_author,
    chapter_number,
    verse_number,
  });
};
