import { notifyFreeTrialExpired } from 'modules/membership/utils/membership';
import React, { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { useAppDispatch, useAppSelector } from 'common/hooks/state-hooks';
import SecondaryHeader from 'common/page-layout/SecondaryHeader';
import { routeTo, routeAs } from 'common/utils/routes';
import { removeHighlight,
  fetchChapterHighlights,
  addHighlight,
  updateHighlight,
} from 'modules/reading/state/actions/highlights.actions';
import SelectChapterModal from 'modules/reading/components/SelectChapterModal';
import ChapterContents from 'modules/reading/components/ChapterContents';
import NextPrevIcons from 'modules/reading/components/NextPrevIcons';
import { fetchBooks, selectBooks } from 'modules/books';
import { selectProfile } from 'modules/profile';
import { selectSettings, 
  actions as settingsActions 
} from 'modules/settings';
import {
  fetchChapterBookmark,
  saveBookmark
} from 'modules/reading/state/actions/bookmarks.actions';
import { actions } from 'modules/reading/state/slices/settings.slice';

import ChapterToolbar from 'modules/reading/components/ChapterToolbar';
import SaveBookmarkModal from 'modules/reading/components/SaveBookmarkModal';
import AuthPrompt from 'modules/reading/components/AuthPrompt';
import toast from 'common/components/Toast';
import {
  trackChangeReaderBook,
  trackViewChapter,
} from 'modules/reading/utils/analytics';

import { deleteBookmarkAction } from 'modules/bookmarks';
import { usePrevious } from 'common/utils/hooks';
import { selectMembership } from 'modules/membership';
import type { Verse } from 'modules/reading/utils/api/chapterApiUtils';
import { 
  selectCurrentChapter, 
  selectReaderSettings,
  selectCurrentChapterHighlights,
  selectCurrentChapterBookmark 
} from 'modules/reading';


const notifyEmailVerify = () =>
  toast({
    type: 'error',
    message:
      'Email not verified. Please check your inbox and confirm your email.',
  });

interface Props {
  verseNumber?: number;
  isMobile?: boolean;
}

interface State {
  isChapterPickerVisible: boolean;
  isBookmarkModalOpen: boolean;
  isAuthPromptOpen: boolean;
  activeVerse: Verse | null;
}

const ChapterContainer = ({ verseNumber }: Props) => {
  const {
    currentBook,
    currentChapter,
    nextChapter,
    prevChapter,
    chapterLock,
    currentChapterVerses,
  } = useAppSelector(selectCurrentChapter);
  const { chapterHighlights } = useAppSelector(selectCurrentChapterHighlights);
  const { currentChapterBookmark, isBookmarking } = useAppSelector(selectCurrentChapterBookmark);
  const { fontFamily, fontSize, shouldShowVerseNumbers } = useAppSelector(selectReaderSettings);
  const { books, isBooksLoading } = useAppSelector(selectBooks);
  const { user } = useAppSelector(selectProfile);
  const { theme } = useAppSelector(selectSettings);
  const { isMemberOrFreeTrialUser } = useAppSelector(selectMembership);
  const isEmailNotVerified = user?.emailVerified === false;
  const isAuthenticated = !!user;
  const lockReason = chapterLock?.reason;
  const dispatch = useAppDispatch();
  
  const [state, setState] = useState<State>({
    isChapterPickerVisible: false,
    isBookmarkModalOpen: false,
    activeVerse: null,
    isAuthPromptOpen: false,
  });

  const {
    isChapterPickerVisible,
    activeVerse,
    isBookmarkModalOpen,
    isAuthPromptOpen,
  } = state;

  const router = useRouter();
  const prevBookId = usePrevious(currentBook?.id);
  const prevChapterId = usePrevious(currentChapter?.id);

  useEffect(() => {
    if (currentBook && currentChapter) {
      if (prevBookId && prevBookId !== currentBook.id) {
        // TODO: fix: this doesn't track on first load of the page.
        trackChangeReaderBook({
          book_title: currentBook.title,
          book_author: currentBook.author,
          chapter_number: currentChapter.number,
        });
      }

      if (prevChapterId && prevChapterId !== currentChapter.id) {
        trackViewChapter({
          book_title: currentBook.title,
          book_author: currentBook.author,
          chapter_number: currentChapter.number,
        });
      }
    }
  }, [currentBook, currentChapter]);

  useEffect(() => {
    if (currentBook && currentChapter && prevChapterId !== currentChapter.id) {
      // TODO: fix: this doesn't run on first load of the page.
      window.scrollTo(0, 0);
      if (isAuthenticated) {
        dispatch(fetchChapterBookmark(currentChapter.id));
        dispatch(fetchChapterHighlights(currentChapter.id));
      }
    }
  }, [currentChapter?.id]);

  useEffect(() => {
    if (state.isChapterPickerVisible) {
      document.body.classList.add('Modal-Open');
    } else {
      document.body.classList.remove('Modal-Open');
    }
  }, [state.isChapterPickerVisible]);

  const openChapterPicker = () => {
    setState((prevProps) => ({ ...prevProps, isChapterPickerVisible: true }));
  };

  const closeChapterPicker = () => {
    setState((prevProps) => ({
      ...prevProps,
      isChapterPickerVisible: false,
    }));
  };

  const closeBookmarkModal = () =>
    setState((prevProps) => ({ ...prevProps, isBookmarkModalOpen: false }));

  const openBookmarkModal = () =>
    setState((prevProps) => ({ ...prevProps, isBookmarkModalOpen: true }));

  const closeAuthPrompt = () =>
    setState((prevProps) => ({ ...prevProps, isAuthPromptOpen: false }));

  const openAuthPrompt = () =>
    setState((prevProps) => ({ ...prevProps, isAuthPromptOpen: true }));

  const handleBookmarkChapter = () => {
    if (!isAuthenticated) {
      openAuthPrompt();
    } else if (isEmailNotVerified) {
      notifyEmailVerify();
    } else if (!isMemberOrFreeTrialUser) {
      notifyFreeTrialExpired();
    } else {
      openBookmarkModal();
    }
  };

  const deleteBookmark = () => {
    if (currentChapterBookmark) {
      dispatch(deleteBookmarkAction(currentChapterBookmark.id));
    }
  };

  const handleNext = (event): void => {
    event.preventDefault();
    router.push(routeTo.chapter, routeAs.chapter(nextChapter?.slug as string));
  };

  const handlePrev = (event): void => {
    event.preventDefault();
    router.push(routeTo.chapter, routeAs.chapter(prevChapter?.slug as string));
  };

  const setActiveVerse = (verse: Verse | null) =>
    setState((prevProps) => ({ ...prevProps, activeVerse: verse }));

  return (
    <>
      <SecondaryHeader style={{ justifyContent: 'center' }}>
        {currentBook && currentChapter && (
          <ChapterToolbar
            bookTitle={currentBook.title}
            chapterNumber={currentChapter.number}
            openChapterPicker={openChapterPicker}
            theme={theme}
            toggleDarkMode={settingsActions.toggleDarkMode}
            fontFamily={fontFamily}
            fontSize={fontSize}
            shouldShowVerseNumbers={shouldShowVerseNumbers}
            setFontSize={actions.setFontSize}
            setFontFamily={actions.setFontFamily}
            showVerseNumbers={actions.showVerseNumbers}
            isMemberOrFreeTrialUser={isMemberOrFreeTrialUser}
            isAuthenticated={isAuthenticated}
            openBookmarkModal={handleBookmarkChapter}
            isChapterBookmarked={!!currentChapterBookmark}
            deleteBookmark={deleteBookmark}
          />
        )}
      </SecondaryHeader>

      {isChapterPickerVisible && (
        <SelectChapterModal
          closeChapterPicker={closeChapterPicker}
          currentBook={currentBook}
          currentChapter={currentChapter}
          books={books}
          isBooksLoading={isBooksLoading}
          fetchBooks={fetchBooks}
        />
      )}

      {isAuthPromptOpen && (
        <AuthPrompt type="bookmark" closeModal={closeAuthPrompt} />
      )}

      {isBookmarkModalOpen && currentBook && currentChapter && (
        <SaveBookmarkModal
          isBookmarking={isBookmarking}
          currentBook={currentBook}
          currentChapter={currentChapter}
          currentChapterVerses={currentChapterVerses}
          saveBookmark={saveBookmark}
          closeModal={closeBookmarkModal}
          activeVerse={activeVerse}
        />
      )}

      {currentChapter && currentBook && (
        <ChapterContents
          currentChapterVerses={currentChapterVerses}
          currentChapter={currentChapter}
          currentBook={currentBook}
          activeVerse={activeVerse}
          verseNumber={verseNumber}
          fontFamily={fontFamily}
          fontSize={fontSize}
          shouldShowVerseNumbers={shouldShowVerseNumbers}
          isAuthenticated={isAuthenticated}
          isMemberOrFreeTrialUser={isMemberOrFreeTrialUser}
          isEmailNotVerified={isEmailNotVerified}
          setActiveVerse={setActiveVerse}
          chapterHighlights={chapterHighlights}
          addHighlight={addHighlight}
          updateHighlight={updateHighlight}
          removeHighlight={removeHighlight}
          openBookmarkModal={openBookmarkModal}
          lockReason={lockReason}
        />
      )}
      <NextPrevIcons
        prevItem={prevChapter}
        nextItem={nextChapter}
        handlePrev={handlePrev}
        handleNext={handleNext}
      />
    </>
  );
};

export default ChapterContainer;
