import React, { useEffect, useState } from 'react';
import SubmitInput from 'modules/admin/components/ui/SubmitInput';
import CheckBox from 'modules/admin/components/ui/CheckBox';
import { fetchBooks, updateBook } from 'modules/admin/state/admin.actions';
import { useAppDispatch, useAppSelector } from 'common/hooks/state-hooks';
import { selectAdmin } from 'modules/admin';
import type { NextRouter } from 'next/router';
import { withRouter } from 'next/router';
import { PageLoader } from 'common/components/Loader';
import { Form } from 'common/components/styled-components';
import { routeAs, routeTo } from 'common/utils/routes';
import toast from 'common/components/Toast';
import Layout from 'common/page-layout/Layout';
import type { Book } from 'modules/books/utils/booksApiUtils';
import {
  DashboardContent,
  DashboardHeaderText,
  DashboardContentBox,
  BookSidebar,
  BookTitle,
  BookDetails,
  ChaptersButton
} from 'modules/admin/components/__styles__/DashboardContent.styles';

interface Props {
  router: NextRouter;
}

// export type Props = StateProps & DispatchProps & InputProps;

const DashboardPage = (props: Props) => {
  const {
    router,
  } = props;

  const { books, bookUpdated, bookUpdateError } = useAppSelector(selectAdmin);
  const dispatch = useAppDispatch();

  const [selectedBook, setSelectedBook] = useState<Book>(books[0]);

  useEffect(() => {
    if (!router.isReady) return;
    dispatch(fetchBooks());
  }, [router.isReady]);

  useEffect(() => {
    if (books.length) {
      setSelectedBook(books[0]);
    }
  }, [books]);

  const bookFieldChangeHandler = (e: any) => {
    const { name, value } = e.target;

    setSelectedBook(
      (prevBook) => ({
        ...prevBook,
        [name]: value,
      }),
    );
  };

  const bookCheckboxChangeHandler = async (e: any, id) => {
    const { name, checked } = e.target;

    dispatch(updateBook({ id, [name]: checked }));

    if (bookUpdated) {
      setSelectedBook(
        (prevBook) =>
          ({
            ...prevBook,
            [name]: checked,
          }),
      );
    }

    if (bookUpdateError) {
      toast({ message: bookUpdateError, type: 'error' });
    }

    if (!bookUpdateError && bookUpdated) {
      toast({ message: 'Saved', type: 'success' });
    }
  };

  const saveBookTitle = async ({ id, name, value }) => {
    dispatch(updateBook({ id, [name]: value }));

    if (bookUpdateError) {
      toast({ message: bookUpdateError, type: 'error' });
    }

    if (!bookUpdateError && bookUpdated) {
      toast({ message: 'Saved', type: 'success' });
    }
  };

  const saveBookAuthor = async ({ id, name, value }) => {
    dispatch(updateBook({ id, [name]: value }));

    if (bookUpdateError) {
      toast({ message: bookUpdateError, type: 'error' });
    }

    if (!bookUpdateError && bookUpdated) {
      toast({ message: 'Saved', type: 'success' });
    }
  };

  const saveBookNumber = async ({ id, name, value }) => {
    dispatch(updateBook({ id, [name]: value }));

    if (bookUpdateError) {
      toast({ message: bookUpdateError, type: 'error' });
    }

    if (!bookUpdateError && bookUpdated) {
      toast({ message: 'Saved', type: 'success' });
    }
  };

  const saveBookAccessType = async ({ id, name, value }) => {
    dispatch(updateBook({ id, [name]: value }));

    if (bookUpdateError) {
      toast({ message: bookUpdateError, type: 'error' });
    }

    if (!bookUpdateError && bookUpdated) {
      toast({ message: 'Saved', type: 'success' });
    }
  };

  const saveBookTag = async ({ id, name, value }) => {
    dispatch(updateBook({ id, [name]: value }));

    if (bookUpdateError) {
      toast({ message: bookUpdateError, type: 'error' });
    }

    if (!bookUpdateError && bookUpdated) {
      toast({ message: 'Saved', type: 'success' });
    }
  };

  const saveBookTranslator = async ({ id, name, value }) => {
    // TODO: Avoid this generic keys like this.
    //  Provide the current key for [name] in all places following this pattern
    dispatch(updateBook({ id, [name]: value }));

    if (bookUpdateError) {
      toast({ message: bookUpdateError, type: 'error' });
    }

    if (!bookUpdateError && bookUpdated) {
      toast({ message: 'Saved', type: 'success' });
    }
  };


  const handleBookClick = (book: Book) => {
    setSelectedBook(book);
  };

  return (
    <Layout>
      <DashboardContent>
        <DashboardHeaderText> Dashboard! </DashboardHeaderText>
        <DashboardContentBox>
          <BookSidebar>
            { books.map((book) => (
              <BookTitle
                key={book.id}
                activeTab={!!selectedBook && selectedBook.id === book.id}
                onClick={() => handleBookClick(book)}
              >
                { book.displayTitle }
              </BookTitle>
            ))}
          </BookSidebar>
          <BookDetails>
            { !books.length || !selectedBook ? <PageLoader /> : (
              <Form>
                <h2>Book Details</h2>
                <SubmitInput
                  required
                  name="title"
                  value={selectedBook.title}
                  label="Title"
                  onChange={bookFieldChangeHandler}
                  onSave={({ name, value }) => saveBookTitle({ id: selectedBook.id, name, value })}
                />

                <CheckBox
                  name="isPublished"
                  label="Is Published"
                  checked={selectedBook.isPublished}
                  onChange={(e) => bookCheckboxChangeHandler(e, selectedBook.id)}
                />

                <SubmitInput
                  name="author"
                  value={selectedBook.author}
                  label="Author"
                  onChange={bookFieldChangeHandler}
                  onSave={({ name, value }) =>
                    saveBookAuthor({ id: selectedBook.id, name, value })}
                />

                <SubmitInput
                  name="number"
                  value={selectedBook.number}
                  type="number"
                  label="Number"
                  onChange={bookFieldChangeHandler}
                  onSave={({ name, value }) =>
                    saveBookNumber({ id: selectedBook.id, name, value })}
                />

                <SubmitInput
                  name="accessType"
                  value={selectedBook.accessType}
                  label="Access Type"
                  onChange={bookFieldChangeHandler}
                  onSave={({ name, value }) =>
                    saveBookAccessType({ id: selectedBook.id, name, value })}
                />

                <SubmitInput
                  name="tag"
                  value={selectedBook.tag || 'No Available Tags'}
                  label="Tag"
                  onChange={bookFieldChangeHandler}
                  onSave={({ name, value }) =>
                    saveBookTag({ id: selectedBook.id, name, value })}
                />

                <SubmitInput
                  name="translator"
                  value={selectedBook.translator}
                  label="Translator"
                  onChange={bookFieldChangeHandler}
                  onSave={({ name, value }) =>
                    saveBookTranslator({ id: selectedBook.id, name, value })}
                />

                <ChaptersButton
                  type="button"
                  onClick={() =>
                    router.push(routeTo.editBook, routeAs.editBook(selectedBook.id))}
                >
                  {' '}
                  Books Chapters
                  {' '}
                </ChaptersButton>
              </Form>
            )}
          </BookDetails>
        </DashboardContentBox>
      </DashboardContent>
    </Layout>
  );
};

const WithRouter = withRouter(DashboardPage);

export default WithRouter;
