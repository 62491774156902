import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { ADMIN } from 'modules/admin/constants';
import type { Book } from 'modules/books/utils/booksApiUtils';
import type { Chapter, Verse } from 'modules/reading/utils/api/chapterApiUtils';
import type { RootState } from 'app/state/store';
import type { ChapterPayload } from '../utils/adminApiUtils';

const fetchFailed = (state, action: PayloadAction<{ error: string }>) => {
  state.error = action.payload.error;
};


export interface State {
  books: Book[];
  isBookLoading: boolean;
  isUpdatingBook: boolean;
  chapterUpdating: boolean;
  bookUpdated: boolean;
  bookUpdateError: string;
  chapterUpdateError: string;
  chapterUpdated: boolean;
  currentBook: Book | null;
  currentChapter: Chapter | null;
  currentChapterVerses: Verse[];
  currentVerse: Verse | null;
  openChapterPicker: boolean;
  nextChapter: Chapter | null;
  prevChapter: Chapter | null;
  // Temporary for error payload
  error: string;
}

export const initialState: State = {
  books: [],
  isBookLoading: false,
  bookUpdated: false,
  isUpdatingBook: false,
  chapterUpdating: false,
  bookUpdateError: '',
  chapterUpdateError: '',
  chapterUpdated: false,
  currentBook: null,
  currentChapter: null,
  currentVerse: null,
  currentChapterVerses: [],
  openChapterPicker: false,
  nextChapter: null,
  prevChapter: null,
  error: '',
};

const adminSlice = createSlice({
  name: ADMIN,
  initialState,
  reducers: {
    fetchBooksStarted: (state) => state,

    fetchBooksSucceeded: (state, action: PayloadAction<Book[]>) => ({
      ...state,
      books: action.payload,
    }),
    
    fetchBooksFailed: fetchFailed, 

    fetchBookStarted: (state) => {
      state.isBookLoading = true;
    },

    fetchBookSucceeded: (state, action: PayloadAction<Book>) => ({
      ...state,
      currentBook: action.payload,
      isBookLoading: false,
    }),
    
    fetchBookFailed: fetchFailed, 

    updateBookStarted: (state) => ({ ...state, isUpdatingBook: true, bookUpdated: false }),

    updateBookSucceeded: (state, action: PayloadAction<Book>) => ({
      ...state,
      currentBook: action.payload,
      bookUpdated: true,
    }),

    updateBookFailed: (state, action: PayloadAction<{ error: string }>) => {
      const { error } = action.payload;
      return {
        ...state,
        bookUpdateError: error,
        isUpdatingBook: false,
        bookUpdated: false,
      };
    },
    
    updateBookChapterStarted: (state) => ({
      ...state, chapterUpdating: true, chapterUpdated: false
    }),

    updateBookChapterSucceeded: (state, action: PayloadAction<ChapterPayload>) => {
      const {
        chapter: { book, verses, ...rest },
        nextChapter,
        prevChapter,
      } = action.payload;
      return {
        ...state,
        currentBook: book as Book,
        currentChapter: rest,
        currentChapterVerses: verses as Verse[],
        nextChapter,
        prevChapter,
        bookUpdated: true,
      };
    },
    
    updateBookChapterFailed: (state, action: PayloadAction<{ error: string }>) => {
      const { error } = action.payload;
      return {
        ...state,
        chapterUpdateError: error,
        chapterUpdating: false,
        chapterUpdated: false,
      };
    },

    fetchBookChapterStarted: (state) => state,
  
    fetchBookChapterSucceeded: (state, action: PayloadAction<ChapterPayload>) => {
      const {
        chapter: { verses, ...rest },
        nextChapter,
        prevChapter,
      } = action.payload;
      return {
        ...state,
        currentChapter: rest,
        currentChapterVerses: verses as Verse[],
        nextChapter,
        prevChapter,
      };
    },

    fetchBookChapterFailed: fetchFailed,

    clearReadingState: () => initialState,
  },
});

export const { actions } = adminSlice;

const booksPersistConfig = {
  key: ADMIN,
  storage,
  blacklist: [...Object.keys(initialState)],
  throttle: 500,
};

export const selectAdmin = (state: RootState): State => state[ADMIN];

export const adminReducer = persistReducer(booksPersistConfig, adminSlice.reducer);
