import React from 'react';
import {
  CheckedIcon,
  CustomButton,
  Flex,
  Text,
} from 'common/components/styled-components';
import {
  OptionContent,
  StyledMembershipPlan,
} from 'modules/membership/components/styled';
import type Stripe from 'stripe';
import { getDisplayedPrice, getPlanName } from 'modules/membership/utils/membership';

interface Props {
  togglePlan: (event: any, plan: Stripe.Price) => void;
  heading: string;
  plan: Stripe.Price;
  isPlanSelected: boolean;
}

const PlanCard = ({ togglePlan, plan, heading, isPlanSelected }: Props): JSX.Element => (
  <StyledMembershipPlan
    onClick={(event) => togglePlan(event, plan)}
    selected={isPlanSelected}
  >
    <Flex height="59px" justifyContent="center">
      <Text as="strong" fontSize="12px" fontWeight="400" color="colorTitle">
        {heading}
      </Text>
    </Flex>
    <OptionContent>
      <Text margin="0 0 1rem 0" color="colorPrimary3" fontWeight="400">
        {getPlanName(plan.recurring?.interval)}
      </Text>
      <Text
        color="colorPrimary1"
        fontSize="24px"
        margin="1rem 0 0 0"
        fontWeight="400"
      >
        {`${getDisplayedPrice(plan.unit_amount as number)}/${plan.recurring?.interval}`}
      </Text>
      <Text color="colorPrimary2" fontSize="1.2rem" as="small">
        {plan.recurring?.interval === 'year' && '( Save $10 )'}
      </Text>
      <CustomButton
        variant="contained"
        backgroundColor={isPlanSelected ? 'colorPrimary1' : 'colorPrimary4'}
        color={isPlanSelected ? 'colorBackground' : 'colorPrimary3'}
        fontSize="1.2rem"
        fontWeight="400"
        width="139px"
        height="40px"
        onClick={(event) => togglePlan(event, plan)}
        css="transition: background 0.2s linear; position:absolute; bottom: 3rem"
      >
        {isPlanSelected ? (
          <>
            <CheckedIcon />
            Selected
          </>
        ) : (
          'Select'
        )}
      </CustomButton>
    </OptionContent>
  </StyledMembershipPlan>
);

export default PlanCard;
