import React, { useState } from 'react';
import { useAppSelector } from 'common/hooks/state-hooks';
import { selectProfile } from 'modules/profile';
import { actions as profileActions } from 'modules/profile/state/profile.slice';
import { css } from 'styled-components';
import { handleLogoutAction } from 'modules/auth/state/auth.actions';
import { PageContainer } from 'modules/profile/components/styledComponents';
import { actions } from 'modules/auth';

import {
  updatePassword,
  checkUsernameAvailability,
  deleteAccount,
  updateUsername,
  updateName,
  updateEmail,
} from 'modules/profile/state/profile.actions';
import UpdateProfile from 'modules/profile/components/UpdateProfile';
import UpdatePassword from 'modules/profile/components/UpdatePassword';
import DeleteAccount from 'modules/profile/components/DeleteAccount';
import Membership from 'modules/profile/components/Membership';
import ChangeTheme from 'modules/profile/components/ChangeTheme';
import {
  PageHeading,
  SideBar,
  Tab,
  PageContents,
} from 'modules/profile/components/__styles__/SettingsV2.styles';
import type { User } from 'modules/profile/utils/profileApiUtils';
import { selectSettings } from 'modules/settings';


interface Props {
  user: User;
}

const ProfilePage = ({ user }: Props) => {
  const [activeTab, setActiveTab] = useState('edit-profile');
  const { 
    isUsernameAvailable,
    isUpdatingUsername,
    updateUsernameErrMessage,
    isUpdatingName,
    updateNameErrMessage,
    isUpdatingEmail,
    updateEmailSuccessMessage,
    updateEmailErrMessage,
    isUpdatingPassword,
    updatePasswordSuccessMessage,
    updatePasswordErrMessage,
    isDeletingAccount,
  } = useAppSelector(selectProfile);
  const { theme } = useAppSelector(selectSettings);

  const renderActiveTab = () => {
    const defaultTab = (
      <UpdateProfile
        user={user}
        isUsernameAvailable={isUsernameAvailable}
        checkUsernameAvailability={checkUsernameAvailability}
        updateUsername={updateUsername}
        updateName={updateName}
        updateEmail={updateEmail}
        isUpdatingUsername={isUpdatingUsername}
        isUpdatingName={isUpdatingName}
        isUpdatingEmail={isUpdatingEmail}
        updateEmailErrMessage={updateEmailErrMessage}
        updateEmailSuccessMessage={updateEmailSuccessMessage}
        updateNameErrMessage={updateNameErrMessage}
        updateUsernameErrMessage={updateUsernameErrMessage}
        resetUpdateUsernameNotification={profileActions.resetUpdateUsernameNotification}
        resetUpdateEmailNotification={profileActions.resetUpdateEmailNotification}
        resetUpdateNameNotification={profileActions.resetUpdateNameNotification}
        setLoginUser={actions.setLoginUser}
      />
    );
    switch (activeTab) {
      case 'edit-profile':
        return defaultTab;
      case 'change-password':
        return (
          <UpdatePassword
            updatePassword={updatePassword}
            isUpdatingPassword={isUpdatingPassword}
            updatePasswordSuccessMessage={updatePasswordSuccessMessage}
            updatePasswordErrMessage={updatePasswordErrMessage}
            resetPasswordErrorMessage={profileActions.resetUpdatePasswordError}
          />
        );
      case 'membership':
        return <Membership />;
      case 'change-theme':
        return <ChangeTheme />;
      case 'delete-account':
        return (
          <DeleteAccount
            user={user}
            deleteAccount={deleteAccount}
            isDeletingAccount={isDeletingAccount}
            handleLogout={handleLogoutAction}
          />
        );
      default:
        return defaultTab;
    }
  };

  return (
    <PageContainer>
      <PageHeading>Settings</PageHeading>
      <PageContents>
        <SideBar color={theme}>
          <Tab
            active={activeTab === 'edit-profile'}
            onClick={() => setActiveTab('edit-profile')}
          >
            Edit Profile
          </Tab>
          <Tab
            active={activeTab === 'change-password'}
            onClick={() => setActiveTab('change-password')}
          >
            Change Password
          </Tab>
          <Tab
            active={activeTab === 'membership'}
            onClick={() => setActiveTab('membership')}
          >
            Membership
          </Tab>
          <Tab
            active={activeTab === 'change-theme'}
            onClick={() => setActiveTab('change-theme')}
          >
            Change Theme
          </Tab>
          <Tab
            active={false}
            css={css`
              color: ${activeTab === 'delete-account' && '#D94D3A'};
            `}
            onClick={() => setActiveTab('delete-account')}
          >
            Delete Account
          </Tab>
        </SideBar>
        {renderActiveTab()}
      </PageContents>
    </PageContainer>
  );
};

export default ProfilePage;
