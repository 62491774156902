/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import ProfileTabBar from 'modules/profile/components/ProfileTabBar';
import WriteButton from 'modules/thoughts/common/WriteButton';
import * as S from 'modules/profile/components/styledComponents';
import React, { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'common/hooks/state-hooks';
import { PageLoader } from 'common/components/Loader';
import InfiniteScrollList from 'common/components/InfiniteScrollList';
import JournalsEmptyMessage from 'modules/thoughts/journals/components/JournalsEmptyMessage';
import * as journalActions from 'modules/thoughts/journals/state/journals.actions';
import Journals from 'modules/thoughts/journals/components/Journals';
import Layout from 'common/page-layout/Layout';
import { selectJournals } from 'modules/thoughts/journals';
import { selectUser } from 'modules/auth';

export const JournalsPage = () => {
  const { myJournals, pagination, journalsLoaded } = useAppSelector(
    selectJournals,
  );
  const user = useAppSelector(selectUser);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!journalsLoaded) {
      dispatch(journalActions.fetchUserJournals(1));
    }
  }, []);

  const loadMore = async () => {
    await dispatch(
      journalActions.loadMoreJournals((pagination?.currentPage as number) + 1),
    );
  };

  const renderJournals = () => {
    if (!journalsLoaded) {
      return <PageLoader />;
    }

    return !myJournals.length ? (
      <JournalsEmptyMessage />
    ) : (
      <InfiniteScrollList
        dataLength={myJournals.length}
        loadMore={loadMore}
        pagination={pagination}
      >
        <S.PageContainer>
          <S.PageHeader>
            <S.PageHeading>Your Journals</S.PageHeading>
            <WriteButton />
          </S.PageHeader>
          <Journals
            user={user}
            myJournals={myJournals}
            deleteJournal={journalActions.deleteJournal}
          />
        </S.PageContainer>
      </InfiniteScrollList>
    );
  };

  return (
    <Layout allowFooter={false}>
      {user && <ProfileTabBar pageName="journals" user={user} />}

      {renderJournals()}
    </Layout>
  );
};

export default JournalsPage;
