/* eslint-disable consistent-return */
import { useCallback, useLayoutEffect, useState } from 'react';

export const useIsScrollable = () => {
  const [node, setNode] = useState<HTMLDivElement>();

  const ref = useCallback((node: HTMLDivElement) => {
    setNode(node);
  }, []);

  const [isScrollable, setIsScrollable] = useState<boolean>(false);

  useLayoutEffect(() => {
    if (node) {
      setIsScrollable(node.scrollHeight > node.clientHeight);
    }
  }, [node]);

  return [isScrollable, ref, node];
};
