import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { SEARCH } from 'modules/search/constants';
import type { Verse } from 'modules/reading/utils/api/chapterApiUtils';
import type { GenericPagination } from 'common/types';
import type { VersesPagination } from 'modules/search/utils/searchApiUtils';
import type { RootState } from 'app/state/store';

export interface State {
  verses: Array<Verse>;
  verse: Verse | null;
  pagination: GenericPagination | null;
  searchString: string | null;
  versesLoaded: boolean;
  loadingMore: boolean;
  searchingVerseError: string;
  fetchingOneVerseError: string;
}

export const initialState: State = {
  verses: [],
  verse: null,
  pagination: null,
  searchString: null,
  versesLoaded: false,
  loadingMore: false,
  searchingVerseError: '',
  fetchingOneVerseError: '',
};


const searchSlice = createSlice({
  name: SEARCH,
  initialState,
  reducers: {
    searchForVersesStarted: (state) => {
      state.versesLoaded = false;
    },

    searchForVersesSucceeded: (state, action: PayloadAction<VersesPagination>) => {
      const { verses, pagination } = action.payload;
      return {
        ...state,
        verses,
        pagination,
        versesLoaded: true,
      };
    },

    searchForVersesFailed: (state, action: PayloadAction<{ error: string }>) => {
      const { error } = action.payload;
      return {
        ...state,
        versesLoaded: false,
        searchingVerseError: error,
      };
    },

    loadMoreVersesStarted: (state) => {
      state.loadingMore = true;
    },

    loadMoreVersesSucceeded: (state, action: PayloadAction<VersesPagination>) => {
      const { verses, pagination } = action.payload;
      return {
        ...state,
        verses: [...state.verses, ...verses],
        pagination,
        loadingMore: false,
      };
    },

    loadMoreVersesFailed: (state) => {
      state.loadingMore = false;
    },

    fetchOneVerseStarted: (state) => state,

    fetchOneVerseSucceeded: (state, action: PayloadAction<Verse>) => ({
      ...state, verse: action.payload
    }),

    fetchOneVerseFailed: (state, action: PayloadAction<{ error: string }>) => {
      const { error } = action.payload;
      return {
        ...state,
        fetchingOneVerseError: error,
      };
    },

    setSearchString: (state, action: PayloadAction<string>) => ({
      ...state, searchString: action.payload
    })
  }
});

export const { actions } = searchSlice;

const blacklist = { ...initialState };
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const { searchString, ...restOfBlacklist } = blacklist;

const booksPersistConfig = {
  key: SEARCH,
  storage,
  blacklist: Object.keys(restOfBlacklist),
};

export const selectSearch = (state: RootState): State => state[SEARCH];

export const searchReducer = persistReducer(booksPersistConfig, searchSlice.reducer);
