import type { AppThunk } from 'app/state/store';
import { actions } from 'modules/admin/state/admin.slice';
import * as api from 'modules/admin/utils/adminApiUtils';
import type { Book } from 'modules/books/utils/booksApiUtils';
import type { Chapter } from 'modules/reading/utils/api/chapterApiUtils';

export const fetchBooks = (): AppThunk => async (dispatch) => {
  dispatch(actions.fetchBooksStarted());
  try {
    const data = await api.fetchBooks();
    dispatch(actions.fetchBooksSucceeded(data));
  } catch (err) {
    if (err && err.response) {
      dispatch(actions.fetchBooksFailed(err.response));
    }
  }
};

export const fetchBook = (bookId: number): AppThunk => async (dispatch) => {
  dispatch(actions.fetchBookStarted());
  try {
    const data = await api.fetchBook(bookId);
    dispatch(actions.fetchBookSucceeded(data));
  } catch (err) {
    if (err && err.response) {
      dispatch(actions.fetchBookFailed(err.response));
    }
  }
};

export const updateBook = (book: Partial<Book>): AppThunk => async (dispatch) => {
  dispatch(actions.updateBookStarted());
  try {
    const data = await api.updateBook(book);
    dispatch(actions.updateBookSucceeded(data));
  } catch (err) {
    if (err && err.response) {
      dispatch(actions.updateBookFailed(err.response));
    }
  }
};

export const updateChapter = (chapter: Partial<Chapter>): AppThunk => async (dispatch) => {
  dispatch(actions.updateBookChapterStarted());
  try {
    const data = await api.updateChapter(chapter);
    dispatch(actions.updateBookChapterSucceeded(data));
  } catch (err) {
    if (err && err.response) {
      dispatch(actions.updateBookChapterFailed(err.response));
    }
  }
};

export const fetchBookChapterAction = (url: string, token?: string):
  AppThunk => async (dispatch) => {
  dispatch(actions.fetchBookChapterStarted());
  try {
    const data = await api.fetchBookChapter(url, token);
    dispatch(actions.fetchBookChapterSucceeded(data));
  } catch (err) {
    if (err && err.response) {
      dispatch(actions.fetchBookChapterFailed(err.response));
    }
  }
};
