import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { actions as authActions } from 'modules/auth/state/auth.slice';
import { JOURNALS } from 'modules/thoughts/journals/constants';
import type { VerseJournals } from 'modules/reading/utils/readingUtils';
import type { Journal, JournalVerse } from 'modules/thoughts/journals/utils/journalsApiUtils';
import type { RootState } from 'app/state/store';
import { READING } from 'modules/reading/constants';

export interface State {
  verseJournals: VerseJournals;
  isLoadingVerseJournals: boolean;
}

export const initialState: State = {
  verseJournals: {},
  isLoadingVerseJournals: false,
};


export const journalsSlice = createSlice({
  name: JOURNALS,
  initialState,
  reducers: {
    fetchJournalsForVerseStarted: (state) => ({
      ...state,
      isLoadingVerseJournals: true,
    }),
    fetchJournalsForVerseSucceeded: (state, action: PayloadAction<JournalVerse>) => {
      const { verseId, journals } = action.payload;
      return {
        ...state,
        isLoadingVerseJournals: false,
        verseJournals: {
          ...state.verseJournals,
          [verseId]: journals as Journal[],
        },
      };
    },
    fetchJournalsForVerseFailed: (state, action: PayloadAction<{error: string}>) => ({
      ...state,
      isLoadingVerseJournals: false,
      state: action.payload.error,
    }),
  },
  extraReducers: (builder) =>
    builder
      .addCase(authActions.logoutUser, () => initialState),
});

export const { actions } = journalsSlice;

export const journalsReducer = journalsSlice.reducer;

export const selectReadingJournals = (state: RootState): State =>
  state[READING][JOURNALS];
