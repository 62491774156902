import React from 'react';
import modalContentStyles from 'common/components/__styles__/ConfirmDeleteModal.styles';
import Modal from 'common/components/Modal';

interface Props {
  closeModal: () => void;
  onConfirm: () => void;
}

// TODO: Rename this to re-usable modal
const ConfirmDeleteModal = ({ closeModal, onConfirm }: Props) => (
  <Modal
    style={{ content: modalContentStyles }}
    closeModal={closeModal}
    allowClose={false}
  >
    <div className="modal">
      <button type="button" onClick={closeModal} className="close-btn">
          &times;
      </button>
      <h4>Delete Journal</h4>
        Are you sure you want to delete this journal? This cannot be undone.
      <div className="btns">
        <button type="button" onClick={() => closeModal()} className="cancel">
            Cancel
        </button>
        <button type="button" onClick={onConfirm} className="delete">
            Delete
        </button>
      </div>
    </div>
  </Modal>
);

export default ConfirmDeleteModal;
