import React, { useState, useEffect, useRef } from 'react';
import {
  FacebookIcon,
  FacebookShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  RedditIcon,
  RedditShareButton,
  TwitterIcon,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from 'react-share';
import type { CSSProp } from 'styled-components';
import { StyledShare, SharePopUp, Button } from 'common/components/__styles__/Share';

export interface Props {
  shareUrl: string;
  quote?: string;
  title?: string;
  children: JSX.Element | Array<JSX.Element>;
  buttonStyle?: CSSProp;
  handleShare?: (networkName: string) => void;
}

const Share = ({
  shareUrl,
  quote,
  title,
  children,
  buttonStyle,
  handleShare,
}: Props) => {
  const popUpRef: React.RefObject<HTMLDivElement> = useRef(null);
  const btnRef: React.RefObject<HTMLButtonElement> = useRef(null);

  const [popUpVisible, setPopUpVisible] = useState(false);

  const closePopUp = () => {
    setPopUpVisible(false);
  };

  const openPopUp = (e) => {
    e.stopPropagation();
    setPopUpVisible(!popUpVisible);
  };

  const onButtonClick = (event) => {
    if (handleShare) {
      handleShare(event.currentTarget.value);
    }
  };

  useEffect(() => {
    const handleClosePopUp = (e) => {
      if (popUpVisible) {
        if (
          !btnRef?.current?.contains(e.target)
          && !popUpRef?.current?.contains(e.target)
        ) {
          closePopUp();
        }
      }
    };

    document.addEventListener('click', handleClosePopUp);
    return () => {
      document.removeEventListener('click', handleClosePopUp);
    };
  }, [popUpVisible]);

  return (
    <StyledShare>
      {popUpVisible && (
        <SharePopUp ref={popUpRef}>
          <WhatsappShareButton
            value="whatsapp"
            title={title}
            url={shareUrl}
            onClick={onButtonClick}
          >
            <WhatsappIcon size={32} round path="" />
          </WhatsappShareButton>
          <RedditShareButton
            value="reddit"
            title={title}
            url={shareUrl}
            onClick={onButtonClick}
          >
            <RedditIcon size={32} round path="" />
          </RedditShareButton>
          <LinkedinShareButton
            value="linkedIn"
            title={title}
            url={shareUrl}
            onClick={onButtonClick}
          >
            <LinkedinIcon size={32} round path="" />
          </LinkedinShareButton>
          <FacebookShareButton
            value="facebook"
            url={shareUrl}
            quote={quote}
            onClick={onButtonClick}
          >
            <FacebookIcon size={32} round path="" />
          </FacebookShareButton>
          <TwitterShareButton
            value="twitter"
            title={title}
            url={shareUrl}
            onClick={onButtonClick}
          >
            <TwitterIcon size={32} round path="" />
          </TwitterShareButton>
        </SharePopUp>
      )}
      <Button css={buttonStyle} type="button" ref={btnRef} onClick={openPopUp}>
        {children}
      </Button>
    </StyledShare>
  );
};

export default Share;
