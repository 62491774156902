import AuthPage from 'modules/auth/pages/AuthPage';
import ForgotPasswordPage from 'modules/auth/pages/ForgotPasswordPage';
import ResetPasswordPage from 'modules/auth/pages/ResetPasswordPage';
import AuthModal from 'modules/auth/components/AuthModal';

export { AUTH } from 'modules/auth/constants';
export * from 'modules/auth/state/auth.slice';
// eslint-disable-next-line import/no-cycle
export {
  refreshUserAuthAction,
  confirmEmailAddressAction,
  handleLogoutAction,
} from 'modules/auth/state/auth.actions';

export { AuthPage, AuthModal, ForgotPasswordPage, ResetPasswordPage };
