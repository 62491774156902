import React from 'react';
import { useAppDispatch, useAppSelector } from 'common/hooks/state-hooks';
import { actions, selectSettings } from 'modules/settings';
import { selectMembership } from 'modules/membership';
import { Moon } from 'styled-icons/fa-solid';
import { LightDown } from 'styled-icons/entypo';
import { MoonStar, HalfLightDown } from 'static/assets';
import { ArrowDropDown } from 'styled-icons/material-outlined';
import {
  IconStylebtn,
  IconStyle2,
} from 'common/page-layout/Header/__styles__/HeaderRight.styles';
import GetMembership from 'modules/profile/components/GetSettingsMembershipCTA';

import {
  SettingsCard,
  Heading,
  SelectBtn,
  ThemeSelect,
  SelectorWrapper,
  SelectOption,
  SelectorIconStyles,
} from 'modules/profile/components/__styles__/SettingsV2.styles';

const getMembershipInstruction = 'This feature is for members only. Please become a member and enjoy exclusive benefits while supporting the Stoic Bible project.';

const ChangeTheme = () => {
  const { theme } = useAppSelector(selectSettings);
  const { isMemberOrFreeTrialUser } = useAppSelector(selectMembership);
  const dispatch = useAppDispatch();

  const selectTheme = (event) => {
    dispatch(actions.setTheme(event.target.value));
  };

  return (
    <SettingsCard>
      <Heading>Change Theme</Heading>
      <ThemeSelect>
        <SelectorWrapper>
          {theme === 'dark' && <Moon css={IconStylebtn} />}
          {theme === 'light' && <LightDown css={IconStylebtn} />}
          {theme === 'dim' && <HalfLightDown css={IconStyle2} />}
          {theme === 'dark2' && <MoonStar css={IconStyle2} />}
          <SelectBtn
            disabled={!isMemberOrFreeTrialUser}
            onChange={selectTheme}
            value={theme}
          >
            <SelectOption value="light">Light</SelectOption>
            <SelectOption value="dark">Dark</SelectOption>
            <SelectOption value="dark2">Dim</SelectOption>
            <SelectOption value="dim">Dim-blue</SelectOption>
          </SelectBtn>

          <ArrowDropDown width="30px" css={SelectorIconStyles} />
        </SelectorWrapper>
      </ThemeSelect>
      {!isMemberOrFreeTrialUser && (
        <GetMembership instruction={getMembershipInstruction} />
      )}
    </SettingsCard>
  );
};

export default ChangeTheme;
