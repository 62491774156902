import styled from 'styled-components';
import {
  setFlex,

  setZindex,
} from '../../../app/globalStyles/variables';

export const StyledShare = styled.div`
  position: relative;
`;

export const SharePopUp = styled.div`
  position: absolute;
  bottom: 110%;
  right: -20px;
  left: auto;
  width: 200px;
  height: 60px;
  ${setFlex({ x: 'center', y: 'center' })};
  border: 1px solid ${({ theme: { colors } }) => colors.colorBorder};
  border-radius: 5px;
  background: ${({ theme: { colors } }) => colors.colorBackground};
  z-index: ${setZindex.high};
  button {
    outline: 0;
    border: unset;
    padding: 0 2.5px !important;
    height: 32px;
  }
`;

export const Button = styled.button`
  background: transparent;
`;
