import type { AppThunk } from 'app/state/store';
import type { AxiosResponse } from 'axios';
import { actions } from 'modules/profile/state/profile.slice';
import * as api from 'modules/profile/utils/profileApiUtils';

export const updatePassword = (
  newPassword: string,
  currentPassword: string,
): AppThunk => async (dispatch): Promise<void | AxiosResponse<any>> => {
  try {
    dispatch(actions.updatePasswordStarted());
    const data = await api.updatePassword(newPassword, currentPassword);
    dispatch(actions.updatePasswordSucceeded(data));
  } catch (err) {
    if (err && err.response.data) {
      dispatch(actions.updatePasswordFailed(err.response.data));
    }
    throw err;
  }
};

export const checkUsernameAvailability = (username: string): AppThunk =>
  async (dispatch): Promise<void | AxiosResponse<any>> => {
    try {
      dispatch(actions.checkUsernameAvailabilityStarted());
      const data = await api.checkUsernameAvailable(username);
      dispatch(actions.checkUsernameAvailabilitySucceeded({
        isUsernameAvailable: !data.isUsernameAvailable
      }));
    } catch (err) {
      if (err && err.response.data) {
        dispatch(actions.checkUsernameAvailabilityFailed());
      }
      throw err;
    }
  };

export const updateUsername = (
  username: string
): AppThunk => async (dispatch): Promise<void | AxiosResponse<any>> => {
  try {
    dispatch(actions.updateUsernameStarted());
    const data = await api.updateUsername(username);
    dispatch(actions.updateUsernameSucceeded(data));
  } catch (err) {
    if (err && err.response) {
      dispatch(actions.updateUsernameFailed(err.response.data));
    }
    throw err;
  }
};

export const updateName = (
  name: string
): AppThunk => async (dispatch): Promise<void | AxiosResponse<any>> => {
  try {
    dispatch(actions.updateNameStarted());
    const data = await api.updateName(name);
    dispatch(actions.updateNameSucceeded(data));
  } catch (err) {
    if (err && err.response) {
      dispatch(actions.updateNameFailed(err.response.data));
    }
    throw err;
  }
};

export const updateEmail = (
  email: string
): AppThunk => async (dispatch): Promise<void | AxiosResponse<any>> => {
  try {
    dispatch(actions.updateEmailStarted());
    const data = await api.updateEmail(email);
    dispatch(actions.updateEmailSucceeded(data));
  } catch (err) {
    if (err && err.response) {
      dispatch(actions.updateEmailFailed(err.response.data));
    }
    throw err;
  }
};


export const deleteAccount = (userId: number): AppThunk =>
  async (dispatch): Promise<void | AxiosResponse<any>> => {
    try {
      dispatch(actions.deleteAccountStarted());
      await api.deleteAccount(userId);
      dispatch(actions.deleteAccountSucceeded());
    } catch (err) {
      if (err && err.response) {
        dispatch(actions.deleteAccountError());
      }
      throw err;
    }
  };
