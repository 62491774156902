import Link from 'next/link';
import React from 'react';
import { Facebook, Twitter } from 'styled-icons/boxicons-logos';
import { routeTo } from 'common/utils/routes';
import { FooterComp } from '../components/__styles__/Footer';

const presentDate: number = new Date().getFullYear();
const iconStyles = {
  width: '18px',
  color: ({ theme: { colors } }) => colors.colorPrimary1,
  margin: '0',
  marginRight: '10px',
};

export const Footer = (): JSX.Element => (
  <FooterComp>
    <div className="footer-container">
      <div className="footerColumn">
        <h2>Stoic Bible</h2>
        <p>Stoicism in one place</p>
      </div>
      <div className="footerColumn">
        <h3>Product</h3>
        <ul>
          <li>
            <Link href={routeTo.books}>
              All Books
            </Link>
          </li>
          <li>
            <Link href={routeTo.membership}>
              Membership
            </Link>
          </li>
        </ul>
      </div>
      <div className="footerColumn">
        <h3>Help</h3>
        <ul>
          <li>
            <Link href={routeTo.faqs}>
              FAQs
            </Link>
          </li>
          <li>
            <Link href={routeTo.feedback}>
              Contact Us
            </Link>
          </li>
        </ul>
      </div>
      <div className="footerColumn">
        <h3>Legal Stuff</h3>
        <ul>
          <li>
            <Link href={routeTo.terms}>
              Terms
            </Link>
          </li>
          <li>
            <Link href={routeTo.privacy}>
              Privacy
            </Link>
          </li>
        </ul>
      </div>
      <div className="footerColumn">
        <h3>Community</h3>
        <ul className="socialIcons">
          <li>
            <a
              href="https://twitter.com/stoicbible"
              rel="noopener noreferrer"
              target="_blank"
            >
              <Twitter style={{ ...iconStyles, color: '#00ACEE' }} />
              Twitter
            </a>
          </li>
          <li>
            <a
              href="https://facebook.com/stoicbible"
              rel="noopener noreferrer"
              target="_blank"
            >
              <Facebook style={{ ...iconStyles, color: '#3b5998' }} />
              Facebook
            </a>
          </li>
        </ul>
      </div>
      <div className="footerColumn">
        <h3>Mobile App</h3>
        <ul>
          <li>
            <Link href={routeTo.app}>
              Download Now
            </Link>
          </li>
        </ul>
      </div>
    </div>
    <div className="copyright">
      <p>
        Stoic Bible &copy;
        {` ${presentDate}. All Rights Reserved`}
      </p>
    </div>
  </FooterComp>
);

export default Footer;
