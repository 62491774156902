import React, { useEffect, useRef } from 'react';
import type { CSSProp } from 'styled-components';
import * as S from 'common/components/__styles__/Modal';
import { Close } from 'common/components/__styles__/Modal';

type ModalDefaultStyles = { overlay?: CSSProp; content?: CSSProp };

export interface Props {
  title?: string;
  allowClose?: boolean;
  style?: ModalDefaultStyles;
  children: JSX.Element | Array<JSX.Element>;
  shouldCloseOnOverlayClick?: boolean;
  closeModal: () => void;
}

const Modal = ({
  title,
  allowClose = true,
  children,
  shouldCloseOnOverlayClick = true,
  closeModal,
  style,
}: Props) => {
  const modalRef: React.RefObject<HTMLDivElement> = useRef(null);

  useEffect(() => {
    const handleModalClose = (e) => {
      if (shouldCloseOnOverlayClick && !modalRef.current?.contains(e.target)) {
        closeModal();
      }
    };
    // TODO: We use mousedown because of a bug on the auth modal.
    document.addEventListener('mousedown', handleModalClose);
    document.body.classList.add('Modal-Open');
    return () => {
      document.removeEventListener('mousedown', handleModalClose);
      document.body.classList.remove('Modal-Open');
    };
  }, []);

  return (
    <S.ModalOverlay
      css={style?.overlay}
    >
      <S.Modal
        ref={modalRef}
        css={style?.content}
      >
        {allowClose && <S.CloseModal type="button" onClick={closeModal}><Close /></S.CloseModal>}
        {title && <S.ModalHeading>{title}</S.ModalHeading>}
        {children}
      </S.Modal>
    </S.ModalOverlay>
  );
};

export default Modal;
