import styled, { css } from 'styled-components';
import { ArrowDropDown } from 'styled-icons/material';
import {
  isTabletOrSmaller,
  isTabletOrLarger,
} from 'app/globalStyles/screens';
import { setFlex, setFont, setPosition } from 'app/globalStyles/variables';
import { ModalOverlay } from 'common/components/__styles__/Modal';
import {
  BookmarkFill,
  BookmarkLine,
  RadioButtonUnchecked,
  CheckCircleLine,
} from 'static/assets';
import Link from 'next/link';

export const Container = styled.div`
  position: relative;
  ${setFlex()};
  max-width: 90%;
  ${isTabletOrLarger`
    max-width: 100%;
  `}
`;

export const NowReading = styled.span`
  margin-right: 10px;
  font-size: 12px;
  ${isTabletOrLarger`
      margin-right: 20px;
      font-size: 14px;
  `}
`;

export const TooltipTarget = styled.div``;

export const StyledArrowDropDown = styled<any>(ArrowDropDown)`
  position: ${setPosition.absolute};
  right: 15px;
  color: ${({ theme: { colors } }) => colors.colorText};
  ${isTabletOrSmaller`
    right: 5px;;
    margin-left: 5px;
    width: 30px;
  `}
`;

export const StyledChapterPickerButton = styled.button`
  position: relative;
  outline: unset;
  width: 300px;
  padding: 5px 30px;
  color: ${({ theme: { colors } }) => colors.colorText};
  font-size: 1.6rem;
  cursor: pointer;
  border: 1px solid ${({ theme: { colors } }) => colors.colorBorder};
  border-radius: 3px;
  background: unset;
  overflow: hidden;
  font-family: ${setFont.main};
  animation-name: book-title;
  animation-duration: 0.2s;
  animation-timing-function: ease-in-out;
  ${setFlex()};
  h1 {
    padding-right: 10px;
    font-size: inherit;
    font-weight: inherit;
  }

  @keyframes book-title {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 0.8;
    }
  }
  ${isTabletOrLarger`
      width: 320px;
  `}
`;

export const ToolButton = styled.div`
  margin-left: 2rem;
  height: 100%;
  outline: unset;
  border: unset;
  cursor: pointer;
`;

export const StyledSettings = styled.div`
  position: absolute;
  top: 42px;
  right: 10px;
  width: 250px;
  height: 280px;
  font-size: 12px;
  border: 1px solid ${({ theme: { colors } }) => colors.colorBorder};
  border-radius: 5px;
  box-shadow: 0 4px 18px rgba(0, 0, 0, 0.1);
  z-index: 3000;
  background: ${({ theme: { colors } }) => colors.colorBackground};
  padding: 30px 15px;
  display: inline-flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: stretch;
  animation: dropdown 0.2s linear;

  @keyframes dropdown {
    0% {
      opacity: 0;
    }
    25% {
      opacity: 0;
    }
    50% {
      opacity: 0;
    }
    75% {
      opacity: 0.8;
    }
    100% {
      opacity: 1;
    }
  }
  ${isTabletOrLarger`
     right: -30px;
  `}
`;

export const FontSizeTabs = styled.div`
  border: 1px solid ${({ theme: { colors } }) => colors.colorBorder};
  border-radius: 5px;
  display: inline-flex;
  flex-wrap: nowrap;
  align-items: stretch;
  line-height: 1;
  margin-bottom: 15px;
`;
export const FontSizeTab = styled.button<{ selected: boolean }>`
  cursor: pointer;
  border: unset;
  color: inherit;
  border-right: 1px solid ${({ theme: { colors } }) => colors.colorBorder};
  background: ${({ selected, theme: { colors } }) =>
    (selected ? colors.colorBorder : 'unset')};
  flex-grow: 1;
  display: flex;
  padding: 15px;
  outline: 0;
  ${setFlex()};
  &:first-child {
    border-radius: 5px 0 0 5px;
  }
  &:last-child {
    border: unset;
    border-radius: 0 5px 5px 0;
  }
`;

export const FontTabs = styled<any>(FontSizeTabs)`
  flex-wrap: wrap;
  flex-direction: row;
`;

export const FontTab = styled.button<{ selected: boolean }>`
  cursor: pointer;
  border: none;
  color: inherit;
  border-right: 1px solid ${({ theme: { colors } }) => colors.colorBorder};
  flex-grow: 1;
  display: flex;
  width: 50%;
  border-bottom: 1px solid ${({ theme: { colors } }) => colors.colorBorder};
  background: ${({ selected, theme: { colors } }) =>
    (selected ? colors.colorBorder : 'none')};
  padding: 15px;
  outline: 0;
  ${setFlex()};
  &:first-child {
    border-radius: 5px 0 0 0;
  }
  &:nth-child(2) {
    border-radius: 0 5px 0 0;
    border-right: none;
  }
  &:nth-child(odd):nth-last-child(-n + 2) {
    border-radius: 0 0 0 5px;
    border-bottom: none;
  }
  &:nth-child(2n) {
    border-right: none;
  }
  &:nth-child(4) {
    border-bottom: none;
    border-radius: 0 0 5px 0;
  }
`;

export const OtherActions = styled.div`
  font-size: 14px;
  padding-left: 10px;
`;

export const DarkMode = styled.label`
  ${setFlex({ x: 'start', y: 'center' })};
  margin: 0 0 10px 0;
  span {
    margin-right: 10px;
  }
`;

export const ReadInVerseMode = styled(Link)`
  cursor: pointer;
  text-decoration: unset;
  color: inherit;
`;

export const SettingsHiddenModal = styled<any>(ModalOverlay)`
  background: rgba(0, 0, 0, 0.4);
  z-index: 40000;
  width: 100%;
  overflow-y: unset;
`;

const bookmarkIconStyle = css`
  width: 25px;
  height: 25px;
  path {
    fill: ${({ theme: { colors } }) => colors.colorText};
    opacity: 0.8;
  }
`;

export const BookmarkFillIcon = styled<any>(BookmarkFill)`
  ${bookmarkIconStyle};
`;

export const BookmarkLineIcon = styled<any>(BookmarkLine)`
  ${bookmarkIconStyle};
`;

const checkerIconStyles = css`
  width: 22px;
  height: 22px;
  margin-left: 5px;
  path {
    fill: ${({ theme: { colors } }) => colors.colorText};
    opacity: 0.8;
  }
`;
export const UncheckedIcon = styled<any>(RadioButtonUnchecked)`
  ${checkerIconStyles};
`;

export const CheckedIcon = styled<any>(CheckCircleLine)`
  ${checkerIconStyles};
`;
