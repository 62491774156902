import * as api from 'modules/reading/utils/api/chapterApiUtils';
import type { AppThunk } from 'app/state/store';
import type { AxiosResponse } from 'axios';
import { actions } from 'modules/reading/state/slices/chapter.slice';
import { ChapterLockReasons } from 'modules/reading/utils/readingUtils';
import { refreshUserAuthAction } from 'modules/auth/state/auth.actions';
import { AUTH } from 'modules/auth';
import type { Chapter } from 'modules/reading/utils/api/chapterApiUtils';

export type FetchChapterPayload = {
  chapter: Chapter;
  prevChapter: Chapter;
  nextChapter: Chapter;
  chapterLock: { reason: ChapterLockReasons };
};

export const fetchBookChapterAction = (
  slug: string,
  token?: string,
): AppThunk => async (
  dispatch,
  getState,
): Promise<void | AxiosResponse<any>> => {
  try {
    dispatch(actions.fetchBookChapterStarted());
    let data = await api.fetchBookChapter(slug, token);
    const {
      [AUTH]: { token: userToken },
    } = getState();

    if (userToken || token) {
      const { chapterLock } = data || {};
      if (chapterLock && chapterLock.reason === ChapterLockReasons.AUTH) {
        const { token: newToken } = await refreshUserAuthAction(
          userToken || (token as string),
          dispatch,
        );
        data = await api.fetchBookChapter(slug, newToken);
      }
    }
    dispatch(actions.fetchBookChapterSucceeded(data));
  } catch (error) {
    if (error && error.response) {
      dispatch(actions.fetchBookChapterFailed());
    }
    throw error;
  }
};

export const fetchChapterReadCount = (chapterId: number): AppThunk => async (
  dispatch,
): Promise<void | AxiosResponse<any>> => {
  try {
    dispatch(actions.fetchChapterReadCountStarted());
    const data = await api.fetchChapterReadCount(chapterId);
    dispatch(actions.fetchChapterReadCountSucceeded(data));
  } catch (err) {
    if (err && err.response) {
      dispatch(actions.fetchChapterReadCountFailed());
    }
    throw err;
  }
};
