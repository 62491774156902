import React from 'react';
import styled from 'styled-components';
import BecomeAMemberCTA from 'modules/membership/components/BecomeAMemberCTA';
import { Content } from 'modules/reading/components/__styles__/ChapterLock.styles';

// import { SubTitle, Titmodules/books/api/booksApiUtilsthPrompt.styles'
import type { Book } from 'modules/books/utils/booksApiUtils';
import { SubTitle, Title } from './__styles__/AuthPrompt.styles';

const BecomeAMember = styled(BecomeAMemberCTA)`
  margin-bottom: 20px;
  background: ${({ theme: { colors } }) => colors.colorPrimary1};
  * {
    color: ${({ theme: { colors } }) => colors.colorTextWhite};
  }
`;

export type Props = {
  title?: string;
  subTitle?: string;
  currentBook: Book;
};

const defaultTitle = 'Get unlimited access with membership';
const defaultSubTitle = "This chapter isn't free for non-members. Become member for just $5/month to unlock access to all books and all features.";
// const buyBookMsg = (bookPrice) =>
//   `Or Consider unlocking read access to the book, for just $${bookPrice}. `;

const ChapterMembershipLock = ({
  title = defaultTitle,
  subTitle = defaultSubTitle,
}: // currentBook,
Props) => (
  <Content>
    <Title>{title}</Title>
    <SubTitle css="margin-bottom: 20px;">{subTitle}</SubTitle>
    <BecomeAMember title="Get Membership" showArrow={false} />

    {/* <div css="max-width: 80%"> */}
    {/*  <SubTitle css="margin-bottom: 20px; line-height: 1.9;"> */}
    {/*    {buyBookMsg(currentBook.price)} */}
    {/*  </SubTitle> */}
    {/*  <BuyBookCheckout currentBook={currentBook} /> */}
    {/* </div> */}
  </Content>
);

export default ChapterMembershipLock;
