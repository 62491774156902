import { actions } from 'modules/highlights/state/highlights.slice';
import type { AxiosResponse } from 'axios';
import type { AppThunk } from 'app/state/store';
import * as api from 'modules/highlights/utils/highlightsApiUtils';

export type HighlightCreationParams = {
  color?: string;
  verseId: number;
  startOffset: number;
  endOffset: number;
};

export type DraftHighlight = HighlightCreationParams & {
  id: string;
};

export const fetchHighlights = (pageNumber: number, limit = 5): AppThunk =>
  async (dispatch): Promise<void | AxiosResponse<any>> => {
    try {
      dispatch(actions.fetchHighlightsStarted());
      const data = await api.fetchHighlights(pageNumber, limit);
      dispatch(actions.fetchHighlightsSucceeded(data));
    } catch (err) {
      if (err && err.response) {
        dispatch(actions.fetchHighlightsFailed(err.response.data));
      }
      throw err;
    }
  };

export const fetchUnfetchedHighlights = (
  ids: Array<number>,
): AppThunk => async (dispatch): Promise<void | AxiosResponse<any>> => {
  try {
    dispatch(actions.fetchUnfetchedHighlightsStarted());
    const data = await api.fetchUnfetchedHighlights(ids);
    dispatch(actions.fetchUnfetchedHighlightsSucceeded(data));
  } catch (err) {
    if (err && err.response) {
      dispatch(actions.fetchUnfetchedHighlightsFailed(err.response.data));
    }
    throw err;
  }
};

export const loadMoreHighlights = (pageNumber: number, limit = 5): AppThunk =>
  async (dispatch): Promise<void | AxiosResponse<any>> => {
    try {
      dispatch(actions.loadMoreHighlightsStarted());
      const data = await api.loadMoreHighlights(pageNumber, limit);
      dispatch(actions.loadMoreHighlightsSucceeded(data));
    } catch (err) {
      if (err && err.response) {
        dispatch(actions.loadMoreHighlightsFailed(err.response.data));
      }
      throw err;
    }
  };
