import { highlightColors } from 'modules/highlights/components/HighlightMarker';
import type { Highlight } from './api/highlightsApiUtils';

type HighlightInfo = {
  id: number;
  startOffset: number;
  endOffset: number;
  color: string;
  createdAt: string;
};

type HighlightSection = {
  text: string;
  highlight?: HighlightInfo;
};

export const getHighlightSections = (
  text: string,
  highlights: Highlight[],
): Array<HighlightSection> => {
  const verseText = text;

  const highlightsCopy = [...highlights];

  highlightsCopy.sort((a, b) => a.startOffset - b.startOffset);
  let idx = 0;

  const highlightSections: HighlightSection[] = [];

  highlightsCopy.forEach((highlight) => {
    const { id, startOffset, endOffset, createdAt, color } = highlight;

    if (idx > startOffset) {
      const prevSubText = highlightSections.pop();
      const prevHighlight = prevSubText?.highlight as HighlightInfo;

      highlightSections.push({
        text: verseText.slice(prevHighlight.startOffset, startOffset),
        highlight: { ...prevHighlight, endOffset: startOffset },
      });

      const overlapHighlight = new Date(prevHighlight.createdAt).getTime()
        > new Date(createdAt).getTime()
        ? { ...prevHighlight, startOffset }
        : {
          id,
          startOffset,
          endOffset,
          createdAt,
          color: color || highlightColors.yellow,
        };

      highlightSections.push({
        text: verseText.slice(startOffset, idx),
        highlight: overlapHighlight,
      });

      highlightSections.push({
        text: verseText.slice(idx, endOffset),
        highlight: {
          ...highlight,
          startOffset: idx,
          endOffset,
          color: color || highlightColors.yellow,
        },
      });
    } else {
      highlightSections.push({
        text: verseText.slice(idx, startOffset),
      });
      highlightSections.push({
        text: verseText.slice(startOffset, endOffset),
        highlight: { ...highlight, color: color || highlightColors.yellow },
      });
    }

    idx = endOffset;
  });

  if (idx < verseText.length) {
    highlightSections.push({
      text: verseText.slice(idx, verseText.length),
    });
  }

  return highlightSections;
};
