import React, { useEffect } from 'react';
import type { NextRouter } from 'next/router';
import { withRouter } from 'next/router';
import { useAppDispatch, useAppSelector } from 'common/hooks/state-hooks';
import { selectSearch } from 'modules/search';
import SearchResults from 'modules/search/components/SearchResults';
import {
  searchVerses,
  loadMoreVerses,
} from 'modules/search/state/search.actions';
import { actions as searchActions } from 'modules/search/state/search.slice'; 
import SecondaryHeader from 'common/page-layout/SecondaryHeader';
import { StyledNavigateBack } from 'modules/search/components/__styles__/SearchResults.styles';
import Layout from 'common/page-layout/Layout';

interface Props {
  router: NextRouter;
}

const Search = ({ router }: Props) => {
  const dispatch = useAppDispatch();
  const { verses, versesLoaded, pagination, loadingMore } = useAppSelector(selectSearch);
  const {
    query: { q: searchString },
  } = router;

  useEffect(() => {
    window.scrollTo(0, 0); // Todo: Find a better solution to this scroll problem
    if (searchString) {
      dispatch(searchActions.setSearchString(searchString as string));
      (async () => {
        if (!verses.length) {
          dispatch(searchVerses(searchString as string));
        }
      })();
    }
  }, [searchString]);

  const loadMoreSearchResults = async () => {
    dispatch(loadMoreVerses(
      searchString as string,
      (pagination?.currentPage as number) + 1,
    ));
  };

  return (
    <Layout>
      <SecondaryHeader>
        <StyledNavigateBack
          backTitle={`Search results for: "${searchString}"`}
        />
      </SecondaryHeader>
      <SearchResults
        loadMoreSearchResults={loadMoreSearchResults}
        verses={verses}
        versesLoaded={versesLoaded}
        pagination={pagination}
        loadingMore={loadingMore}
        searchString={searchString as string}
      />
    </Layout>
  );
};


export default withRouter(Search);
