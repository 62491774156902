import styled from 'styled-components';
import { AccordionItemButton } from 'react-accessible-accordion';

export default styled(AccordionItemButton)`
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: ${({ theme: { colors } }) => colors.colorInputBg};
    border-bottom: 1px solid ${({ theme: { colors } }) => colors.colorPrimary3};
    height: 5rem;
    padding: 2rem;
    border-radius: 0.5rem;
`;
