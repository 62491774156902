import React from 'react';
import Link from 'next/link';
import { routeTo } from 'common/utils/routes';
import { WriteLine } from 'static/assets';
import * as S from 'modules/profile/components/styledComponents';
import { css } from 'styled-components';

const writeIconCss = css`
  path {
    fill: ${({ theme: { colors } }) => colors.colorTextWhite};
  }
`;

export type Props = {
  className?: string;
  title?: string;
};

const WriteButton = ({ className, title = 'New Journal' }: Props) => (
  <Link href={routeTo.newJournal} passHref legacyBehavior>
    <S.WriteButton className={className} type="button">
      <WriteLine css={writeIconCss} />
      <S.Write>{title}</S.Write>
    </S.WriteButton>
  </Link>
);

export default WriteButton;
