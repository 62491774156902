import { css } from 'styled-components';
import { isTabletOrLarger } from 'app/globalStyles/screens';

export const modalContentStyles = css`
  height: auto;
  padding: 20px;
  background: ${({ theme: { colors } }) => colors.colorBackground};
  z-index: 50;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.6);
  font-size: 1.2rem;
  width: 300px;
  ${isTabletOrLarger`
      width: 400px;
    `};
  animation-name: modal;
  animation-duration: 0.1s;
  animation-timing-function: ease-in-out;

  @keyframes modal {
    0% {
      opacity: 0.9;
    }
    100% {
      opacity: 0.9;
    }
  }
  h4 {
    margin-bottom: 20px;
  }
  .close-btn {
    border: unset;
    background: ${({ theme: { colors } }) => colors.colorBackground};
    color: ${({ theme: { colors } }) => colors.colorPrimary2};
    font-size: 1.5rem;
    float: right;
    margin-top: -12px;
    margin-right: -7px;
  }
  .btns {
    display: block;
    width: 100%;
    margin-top: 20px;
    .cancel,
    .delete {
      padding: 7px;
      font-size: 1rem;
      margin: 0 5px;
      color: ${({ theme: { colors } }) => colors.colorText};
      float: right;
      border: 1px solid ${({ theme: { colors } }) => colors.mainRed};
      border-radius: 5px;
    }
    .cancel {
      background: ${({ theme: { colors } }) => colors.colorBackground};
      border: 1px solid ${({ theme: { colors } }) => colors.colorBorder};
    }
    .delete {
      color: ${({ theme: { colors } }) => colors.colorBackground};
      background: ${({ theme: { colors } }) => colors.mainRed};
    }
  }
`;

export default modalContentStyles;
