import type { FormEvent } from 'react';
import React from 'react';
import type { DefaultTheme } from 'styled-components';
import {
  CardError,
  Label,
  StyledCardElement,
  StyledForm,
  SubmitButton,
} from 'modules/membership/components/styled';
import Loader from 'common/components/Loader';
import type {
  StripeCardElementChangeEvent,
  StripeCardElement,
} from '@stripe/stripe-js';
import { CardElement } from '@stripe/react-stripe-js';
import * as config from 'common/utils/stripe-config';
import type Stripe from 'stripe';
import {
  getDisplayedPrice,
  getPlanName,
} from 'modules/membership/utils/membership';

interface Props {
  selectedPlan: Stripe.Price;
  handleSubmit: (event: FormEvent<HTMLFormElement>) => void;
  isStripeLoading: boolean;
  onCardElementFocus: () => void;
  subscribeErrMessage: string;
  theme: DefaultTheme;
  isSubscribing: boolean;
  onCardElementChange: (event: StripeCardElementChangeEvent) => void;
  onCardElementReady: (element: StripeCardElement) => void;
}

const SubscriptionForm = ({
  selectedPlan,
  handleSubmit,
  isStripeLoading,
  onCardElementFocus,
  subscribeErrMessage,
  theme,
  isSubscribing,
  onCardElementChange,
  onCardElementReady,
}: Props) => {
  const options = config.useOptions(theme);
  return (
    <StyledForm onSubmit={handleSubmit}>
      <h3 css="margin-bottom: 10px; line-height: 1.5; font-weight: 500">
        Enter your card details.
        <br />
        Your subscription will start now.
      </h3>
      <div css="font-size: 14px">
        → Total due is&nbsp;
        <strong css="font-weight: 500">
          {getDisplayedPrice(selectedPlan.unit_amount as number)}
        </strong>
      </div>
      <div css="margin-bottom: 10px; font-size: 14px">
        → Subscribing to&nbsp;
        <strong css="font-weight: 500">
          {getPlanName(selectedPlan.recurring?.interval)}
        </strong>
      </div>
      {isStripeLoading ? (
        <Loader
          size="2.5rem"
          style={{
            borderTop: `0.2rem solid ${({ theme: { colors } }) =>
              colors.colorBackground}`,
            textAlign: 'center',
          }}
        />
      ) : (
        <Label htmlFor="#cardElement">
          Card Details
          <StyledCardElement>
            <CardElement
              id="cardElement"
              options={options}
              onFocus={onCardElementFocus}
              onChange={onCardElementChange}
              onReady={onCardElementReady}
            />
          </StyledCardElement>
        </Label>
      )}

      <SubmitButton type="submit" disabled={isStripeLoading}>
        <span>Start my membership</span>
        {isSubscribing && (
          <Loader
            size="2.5rem"
            css={`
              border-top: 0.2rem solid
                ${({ theme: { colors } }) => colors.colorTextWhite};
              position: absolute;
              right: 1rem;
            `}
          />
        )}
      </SubmitButton>
      <CardError>{subscribeErrMessage}</CardError>
    </StyledForm>
  );
};

export default SubscriptionForm;
