import React, { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { useAppDispatch, useAppSelector } from 'common/hooks/state-hooks';
import cookie from 'js-cookie';
import { routeTo } from 'common/utils/routes';
import Layout from 'common/page-layout/Layout';
import Header from 'common/page-layout/Header/Header';
import Editor from 'modules/thoughts/editor/components/Editor';
import EditorHeader from 'modules/thoughts/editor/components/EditorHeader';
import EditorFooter from 'modules/thoughts/editor/components/EditorFooter';
import { saveJournal, updateJournal } from 'modules/thoughts/journals/state/journals.actions';
import { fetchEditorThought } from 'modules/thoughts/editor/state/editor.actions';
import { actions as editorActions } from 'modules/thoughts/editor/state/editor.slice';
import { selectEditor } from 'modules/thoughts/editor';
import Toast from 'common/components/Toast';
import TrialExpiredPageCTA from 'modules/membership/components/TrialExpiredPageCTA';
import { HighlightedVerseText } from 'modules/thoughts/editor/components/Editor.styles';
import {
  trackShowThoughtEditor,
  trackAddThought,
  trackUpdateThought,
} from 'modules/thoughts/analytics';
import { selectJournals } from 'modules/thoughts/journals';
import type { Journal, Note, ThoughtAccessType } from 'modules/thoughts/journals/utils/journalsApiUtils';
import { selectUser } from 'modules/auth';
import { selectMembership } from 'modules/membership';

const countWords = (text) => text.match(/\S+/g)?.length || 0;

// This can create and update journals,
// but can only update notes

const EditorPage = () => {
  const { isSavingThought, isUpdatingThought, editorThought } = useAppSelector(selectEditor);
  const { myJournals } = useAppSelector(selectJournals);
  const user = useAppSelector(selectUser);
  const { isMemberOrFreeTrialUser } = useAppSelector(selectMembership);
  const dispatch = useAppDispatch();


  const router = useRouter();
  const {
    query: { slug },
  } = router;

  if (!user) {
    cookie.remove('token');
    router.push(routeTo.login);
    // Have to do this, otherwise the layout
    // will be rendered before the redirect
    return null;
  }

  // if (editorThought?.author.id !== currentUser.id) {
  // return <div>You don't have permission to edit this</div>; // TODO: Return appropriate feedback
  // }

  const [title, setTitle] = useState('');
  const [text, setText] = useState('');
  const [accessType, setAccessType] = useState<ThoughtAccessType>(
    editorThought?.accessType || 'private',
  );
  const [titleError, setTitleError] = useState('');
  const [textError, setTextError] = useState('');
  const [wordsCount, setWordsCount] = useState(0);
  const [lastText, setLastText] = useState('');

  const resetJournalForm = () => {
    setTitle('');
    setText('');
    setAccessType('private');
    setTitleError('');
    setTextError('');
    setLastText('');
  };

  const updateThought = (thought: Journal | Note) => dispatch(updateJournal({
    id: thought.id,
    title,
    content: text,
    accessType,
  }));

  const saveThought = () =>
    dispatch(saveJournal({
      title,
      accessType,
      content: text,
      kind: 'journal',
    }));

  useEffect(() => {
    if (slug) {
      const setOrFetchThought = async () => {
        const newEditorJournal = myJournals.find(
          (journal) => journal.slug === slug,
        );
        if (newEditorJournal) {
          dispatch(editorActions.setEditorThought(newEditorJournal));
        } else {
          dispatch(fetchEditorThought(slug as string));
        }
      };
      setOrFetchThought();
    }
    trackShowThoughtEditor();
    return () => {
      resetJournalForm();
      dispatch(editorActions.setEditorThought(null));
      dispatch(editorActions.clearNewEditorVerse());
    };
  }, []);

  useEffect(() => {
    if (editorThought) {
      if (editorThought.kind === 'journal') {
        setTitle(editorThought.title as string);
      }
      setAccessType(editorThought.accessType);
      setText(editorThought.content);
      setWordsCount(countWords(editorThought.content));
    }
  }, [editorThought]);

  useEffect(() => {
    const timer = setTimeout(async () => {
      if (lastText !== text) {
        if (editorThought) {
          await updateThought(editorThought);
        } else {
          await saveThought();
        }
        setLastText(text);
      }
    }, 3000);
    return () => clearTimeout(timer);
  }, [text, title, editorThought]);

  const onTitleChange = (event: React.ChangeEvent<HTMLInputElement>) =>
    setTitle(event.target.value);
  const onTextChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setWordsCount(countWords(event.target.value));
    setText(event.target.value);
  };
  const onAccessTypeChange = (e) =>
    setAccessType(e.target.value as ThoughtAccessType);
  const onEditorFocus = () => {
    setTitleError('');
    setTextError('');
  };

  const onEmailVerificationRequired = () =>
    Toast({
      type: 'warning',
      message:
        'Email not verified. Please check your inbox and confirm your email.',
    });

  const onJournalSaved = () => {
    Toast({
      type: 'success',
      message: 'Journal Saved.',
    });
    router.back();
  };

  const handleSave = async (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    if (!text) {
      setTextError('Enter some content');
    } else if (user.emailVerified === false) {
      onEmailVerificationRequired();
    } else {
      let response: any;
      if (editorThought) {
        response = await updateThought(editorThought);
        trackAddThought({ privacy: response.accessType, kind: response.kind });
      } else {
        response = await saveThought();
        trackUpdateThought({
          privacy: response.accessType,
          kind: response.kind,
        });
      }
      if (response) {
        onJournalSaved();
      }
    }
  };

  return (
    <Layout
      header={<Header />}
      allowFooter={false}
    >
      {isMemberOrFreeTrialUser ? (
        <>
          <EditorHeader
            accessType={accessType}
            isSavingThought={isSavingThought}
            isUpdatingThought={isUpdatingThought}
            onAccessTypeChange={onAccessTypeChange}
            handleSave={handleSave}
            isEditing={!!editorThought}
          />
          {editorThought?.quote && (
            <HighlightedVerseText>{editorThought.quote}</HighlightedVerseText>
          )}
          <Editor
            title={title}
            text={text}
            placeholder="Your thoughts..."
            onTitleChange={onTitleChange}
            onTextChange={onTextChange}
            onEditorFocus={onEditorFocus}
            titleError={titleError}
            textError={textError}
          />
          <EditorFooter
            wordsCount={wordsCount}
            isSavingThought={isSavingThought}
            isUpdatingThought={isUpdatingThought}
          />
        </>
      ) : (
        <TrialExpiredPageCTA />
      )}
    </Layout>
  );
};


export default EditorPage;
