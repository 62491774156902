import { apiCall } from 'common/utils/api-helpers';
import type { Chapter } from './chapterApiUtils';

export interface Bookmark {
  id: number;
  label?: string;
  chapterId: number;
  chapter?: Chapter; // TODO: We should send the chapter with lowercase from the BE.
  createdAt?: string;
  updatedAt?: string;
  verseNumber: number;
}

export const saveBookmark = async (
  chapterId: number,
  verseNumber: number,
): Promise<Bookmark> => {
  const { data } = await apiCall('bookmarks', 'post', {
    chapterId,
    verseNumber,
  });

  return data;
};

export const fetchChapterBookmark = async (
  chapterId: number,
): Promise<Bookmark> => {
  const { data } = await apiCall(`bookmarks/chapter/${chapterId}`, 'get');
  return data;
};
