import type { AllEffect, ForkEffect, SelectEffect } from 'redux-saga/effects';
import { all } from 'redux-saga/effects';
import { reloadCurrentChapterSaga } from 'modules/reading';
import { fetchSubscriptionSaga } from 'modules/membership';

function* rootSaga(): Generator<
  AllEffect<Generator<ForkEffect<never> | SelectEffect, void, string>>,
  void,
  unknown
  > {
  yield all([
    reloadCurrentChapterSaga(),
    fetchSubscriptionSaga(),
  ]);
}

export { rootSaga };
