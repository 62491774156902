import { apiCall } from 'common/utils/api-helpers';
import type { Chapter } from 'modules/reading/utils/api/chapterApiUtils';

export type Book = {
  id: number;
  slug: string;
  title: string;
  displayTitle: string;
  author: string;
  chapters?: Chapter[];
  accessType: string;
  price: number;
  isPublished: boolean;
  translator: string;
  source: string;
  imageUrl: string;
  description?: string;
  number: number;
  tag: number;
};

export const fetchBooks = async (): Promise<Book[]> => {
  const { data } = await apiCall('books', 'get');
  return data;
};

export const fetchBookBySlug = async (slug: string): Promise<Book> => {
  const { data } = await apiCall(`books/${slug}`, 'get');
  return data;
};

export const saveUserBook = async (bookId: number): Promise<Book[]> => {
  const { data } = await apiCall(`books/complete-purchase/${bookId}`, 'post');
  return data;
};
