import React, { useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import * as S from './__styles__/ProfileTabs.styles';
import MobileTabBar from './MobileTabBar';
import Tabs from './Tabs';
import type { User } from '../utils/profileApiUtils';

interface Props {
  pageName: 'journals' | 'highlights' | 'bookmarks' | 'settings';
  user: User;
}

const ProfileTabBar = (props: Props):JSX.Element => {
  const { pageName, user } = props;

  const [isModalOpen, toggleOpenTabsModal] = useState(false);

  const isMobile = useMediaQuery({ maxWidth: 767 });
  const openModal = () => toggleOpenTabsModal(true);
  const closeModal = () => toggleOpenTabsModal(false);

  return (
    <S.TabBar>
      {isMobile ? (
        <MobileTabBar
          isModalOpen={isModalOpen}
          openModal={openModal}
          closeModal={closeModal}
          activePageName={pageName}
          username={user.username}
        />
      ) : (
        <Tabs pageName={pageName} username={user.username} />
      )}
    </S.TabBar>
  );
};

export default ProfileTabBar;
