import React, { useState } from 'react';
import { useRouter } from 'next/router';
import Toast from 'common/components/Toast';
import { routeTo, routeAs } from 'common/utils/routes';
import ConfirmDeleteModal from 'common/components/ConfirmDeleteModal';
import JournalCard from 'modules/thoughts/journals/components/JournalCard';
import type { User } from 'modules/profile/utils/profileApiUtils';
import type { Journal } from 'modules/thoughts/journals/utils/journalsApiUtils';
import { useAppDispatch } from 'common/hooks/state-hooks';

export interface Props {
  myJournals: Journal[];
  user: User | null;
  deleteJournal: (id: number) => any;
  isRecentJournals?: boolean;
}

const Journals = ({
  myJournals,
  user,
  deleteJournal,
  isRecentJournals = false,
}: Props) => {
  const router = useRouter();
  const [selectedJournal, setSelectedJournal] = useState<Journal | null>(null);
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const dispatch = useAppDispatch();

  const closeDeleteModal = () => {
    setDeleteModalVisible(false);
  };

  const goToJournal = (journal: Journal) => {
    if (user) {
      router.push(
        routeTo.journal,
        routeAs.journal(user.username, journal.slug as string)
      );
    }
  };

  const handleFirstClickOnCard = (journal: Journal, charLength: number) => {
    if (charLength <= 280) {
      goToJournal(journal);
    } else {
      setSelectedJournal(journal);
    }
  };

  const handleSecondClickOnExpandedCard = (journal: Journal) => {
    if (journal.id === selectedJournal?.id) {
      goToJournal(journal);
    }
  };

  const handleDelete = async () => {
    const res = await dispatch(deleteJournal(selectedJournal?.id as number));
    if (res) {
      Toast({
        type: 'success',
        message: 'Journal deleted successfully!',
      });
    } else {
      Toast({
        type: 'error',
        message: 'Delete Error!',
      });
    }

    closeDeleteModal();
  };

  const openDeleteModal = (e, journal: Journal) => {
    e.stopPropagation();
    setSelectedJournal(journal);
    setDeleteModalVisible(true);
  };

  const takeToEditor = (e, journal: Journal) => {
    e.stopPropagation();
    router.push(routeTo.editJournal, routeAs.editJournal(journal.slug as string));
  };

  return (
    <>
      {deleteModalVisible && (
        <ConfirmDeleteModal
          onConfirm={handleDelete}
          closeModal={closeDeleteModal}
        />
      )}
      {myJournals.map((journal: Journal) => (
        <JournalCard
          key={journal.slug}
          isRecentJournal={isRecentJournals}
          journal={journal}
          isSelectedJournal={selectedJournal?.id === journal.id}
          goToJournal={goToJournal}
          takeToEditor={takeToEditor}
          openDeleteModal={openDeleteModal}
          handleFirstClickOnCard={handleFirstClickOnCard}
          handleSecondClickOnExpandedCard={handleSecondClickOnExpandedCard}
        />
      ))}
    </>
  );
};

export default Journals;
