import type { AxiosPromise, Method } from 'axios';
import axios from 'axios';
import cookie from 'js-cookie';
import type { Store } from '@reduxjs/toolkit';

const constructToken = (token: string) =>
  (token ? { Authorization: `Bearer ${token}` } : {});

const composeData = (method: Method, body: unknown) =>
  (method === 'post' || method === 'put' ? { data: body } : {});

// TODO: Use this
export const interceptor = (store: Store) => {
  axios.interceptors.request.use(
    (req) => {
      const token = store.getState().auth.userToken;
      req.headers.authorization = token ? `Bearer ${token}` : undefined;
      return req;
    },
    (error) => Promise.reject(error),
  );

  axios.interceptors.response.use(
    (next) => Promise.resolve(next),
    (error) => Promise.reject(error),
  );
};

export async function fetchGetJSON(url: string): Promise<any> {
  try {
    const response = await fetch(url, {
      headers: {
        'Content-type': 'application/json',
        Authorization: `Bearer ${cookie.get('token')}`,
      },
    });
    return response.json();
  } catch (err) {
    throw new Error(err);
  }
}

export async function fetchPostJSON(
  url: string,
  data?: unknown,
): Promise<any> {
  try {
    // Default options are marked with *
    const response = await fetch(url, {
      method: 'POST', // *GET, POST, PUT, DELETE, etc.
      mode: 'cors', // no-cors, *cors, same-origin
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      credentials: 'same-origin', // include, *same-origin, omit
      redirect: 'follow', // manual, *follow, error
      referrerPolicy: 'no-referrer', // no-referrer, *client
      headers: {
        'Content-type': 'application/json',
        Authorization: `Bearer ${cookie.get('token')}`,
      },
      body: JSON.stringify(data || {}), // body data type must match "Content-Type" header
    });
    return response.json(); // parses JSON response into native JavaScript objects
  } catch (err) {
    throw new Error(err.message);
  }
}

export const apiCall = (
  url: string,
  method: Method,
  body?: unknown,
  token?: string,
): AxiosPromise => {
  // We should not add baseUrl for calls to nextjs api routes
  const requestUrl = url.startsWith('/api')
    ? url
    : `${process.env.NEXT_PUBLIC_BASEURL}/${url}`;
  return axios({
    method,
    url: requestUrl,
    ...composeData(method, body),
    headers: {
      ...constructToken(token || cookie.get('token')),
    },
  });
};
