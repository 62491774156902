import styled, { css } from 'styled-components';
import { ArrowRight } from 'styled-icons/heroicons-outline';
import { Share } from 'styled-icons/remix-fill';
import {
  setFlex,
  setFont,
} from 'app/globalStyles/variables';
import {
  isTabletOrLarger,
} from 'app/globalStyles/screens';
import Link from 'next/link';

export const shareIconStyles = css`
  width: 16px;
  padding: 0;
  margin-right: 1rem;
`;

export const ShareIcon = styled(Share)`
  width: 16px;
  padding: 0;
  margin-right: 1rem;
`;

export const Heading = styled.h1`
  color: ${({ theme: { colors } }) => colors.colorText};
  font-family: ${setFont.main};
  position: relative;
  display: flex;
  flex-wrap: wrap;
  margin: 0 auto;
  text-align: center; font-size: 1.8rem; line-height: 1em;
  ${isTabletOrLarger` text-align: inherit; font-size: 2.2rem; line-height: inherit`};
`;

export const StyledVerseContainer = styled.div`
  position: relative;
  width: 85%;
  margin: 0 auto;
  ${isTabletOrLarger`
    width: 550px;
  `}
`;

export const StyledVerse = styled.div`
  padding-top: 30px;
  width: 85%;
  margin: 0 auto;
  padding-bottom: 9rem;
  color: ${({ theme: { colors } }) => colors.colorText};
  line-height: 23px;
  border-radius: 5px;
  position: relative;
  ${isTabletOrLarger`
    width: 550px;
    padding-top: 5rem;
  `}
`;

export const Text = styled.p<{
  fontFamily: string;
  fontSize: number;
}>`
  font-family: ${({ fontFamily }) => fontFamily};
  letter-spacing: ${({ fontFamily }) => (fontFamily === 'Inter' ? '-0.3px' : 0)}; // todo
  white-space: pre-wrap;
  color: ${({ theme: { colors } }) => colors.colorText};
  margin-bottom: 70px;
  text-align: left;
  font-size: 2rem;
  line-height: 4rem;
 ${({ fontSize }) =>
    isTabletOrLarger(css`
      text-align: initial;
      font-size: ${fontSize};
      line-height: 1.4;
    `)}
`;

export const Actions = styled.div`
  width: 100%;
  ${setFlex({ x: 'space-between' })};
  border-bottom: 1px solid ${({ theme: { colors } }) => colors.colorBorder};
  margin-bottom: 20px;
`;

export const HR = styled.hr`
  border: 1px solid ${({ theme: { colors } }) => colors.colorPrimary4};
  width: 80%;
  margin: 0 auto;
`;

export const ActionOptions = styled.ul`
  list-style: none;
  display: block;
  z-index: 50;
  font-size: 1.4rem;
  width: 13rem;
  background: ${({ theme: { colors } }) => colors.colorBackground};
  left: auto;
  right: 4%;
  top: 70%;
  ${isTabletOrLarger`
    left: initial;
    right: initial;
    top: initial;
  `}

  animation: grow-in 0.1s linear;

  @keyframes grow-in {
    0% {
      height: 70px;
      width: 90px;
      font-size: 1.2rem;
      opacity: 0.8;
    }
    25% {
      height: 75px;
      width: 92px;
      font-size: 1.2rem;
      opacity: 0.85;
    }
    50% {
      height: 80px;
      width: 94px;
      font-size: 1.3rem;
      opacity: 0.9;
    }
    75% {
      height: 85px;
      width: 96px;
      font-size: 1.4rem;
      opacity: 0.95;
    }
    100% {
      height: 90px;
      width: 98px;
      font-size: 1.4rem;
      opacity: 1;
    }
  }
`;

export const optionStyles = css`
  padding: 1rem;
  padding-left: 2.8rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  position: relative;
  color: ${({ theme: { colors } }) => colors.colorPrimary2};
  width: 100%;
  height: 60px;
  font-size: 1.4rem;
  text-decoration: none;

  &:hover {
    background: ${({ theme: { colors } }) => colors.colorPrimary4};
  }
`;

export const Arrow = styled(ArrowRight)`
  width: 16px;
  color: ${({ theme: { colors } }) => colors.colorText};
`;

export const actionStyles = css`
  border: unset;
  background: ${({ theme: { colors } }) => colors.colorPrimary4};
  border-radius: 3px;
  font-size: 1.6rem;
  font-family: ${setFont.main};
  line-height: 1.9rem;
  text-align: center;
  padding: 1.2rem 2rem;
  margin-right: 2rem;
  outline: unset;
  ${setFlex({ x: 'unset', y: 'center' })}
`;

export const Action = styled(Link)`
  ${optionStyles};
 `;
