import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { DraftHighlight } from 'modules/highlights/state/highlights.actions';
import { actions as authActions } from 'modules/auth/state/auth.slice';
import { actions as highlightsActions } from 'modules/reading/state/slices/highlights.slice';
import { HIGHLIGHTS } from 'modules/highlights/constants';
import type { Highlight } from 'modules/reading/utils/api/highlightsApiUtils';
import type { GenericPagination } from 'common/types';
import type { RootState } from 'app/state/store';

type HighlightPagination = { highlights: Highlight[]; pagination: GenericPagination | null };

export interface State {
  highlights: Array<Highlight | DraftHighlight>;
  pagination: GenericPagination | null;
  highlightsLoaded: boolean;
  loadingMore: boolean;
  unfetchedHighlightIds: Array<number>;
  fetchingHighlightsErrMsg: string;
  loadMoreHighlightsErrMsg: string;
}

export const initialState: State = {
  highlights: [],
  pagination: null,
  highlightsLoaded: false,
  loadingMore: false,
  unfetchedHighlightIds: [],
  fetchingHighlightsErrMsg: '',
  loadMoreHighlightsErrMsg: '',
};

const highlightsSlice = createSlice({
  name: HIGHLIGHTS,
  initialState,
  reducers: {
    addUnfetchedHighlight: (state, action: PayloadAction<number>) => ({
      ...state,
      unfetchedHighlightIds: [...state.unfetchedHighlightIds, action.payload],
    }),

    fetchHighlightsStarted: (state) => state,

    fetchHighlightsSucceeded: (state, action: PayloadAction<HighlightPagination>) => {
      const { highlights, pagination } = action.payload;
      return {
        ...state,
        highlights,
        pagination,
        unfetchedHighlightIds: [],
        highlightsLoaded: true,
      };
    },

    fetchHighlightsFailed: (state, action: PayloadAction<{ error: string }>) => {
      const { error } = action.payload;
      return {
        ...state,
        fetchingHighlightsErrMsg: error,
      };
    },

    loadMoreHighlightsStarted: (state) => { state.loadingMore = true; },

    loadMoreHighlightsSucceeded: (state, action: PayloadAction<HighlightPagination>) => {
      const { highlights, pagination } = action.payload;
      return {
        ...state,
        highlights: [...state.highlights, ...highlights],
        pagination,
        loadingMore: false,
      };
    },

    loadMoreHighlightsFailed: (state, action: PayloadAction<{ error: string }>) => {
      const { error } = action.payload;
      return {
        ...state,
        loadingMore: false,
        loadMoreHighlightsErrMsg: error,
      };
    },

    fetchUnfetchedHighlightsStarted: (state) => state,

    fetchUnfetchedHighlightsSucceeded: (state, action: PayloadAction<Highlight[]>) => ({
      ...state,
      highlights: [...action.payload, ...state.highlights],
      unfetchedHighlightIds: [],
    }),

    fetchUnfetchedHighlightsFailed: (state, action: PayloadAction<{ error: string }>) => {
      const { error } = action.payload;
      return {
        ...state,
        unfetchedHighlightIds: [],
        fetchingHighlightsErrMsg: error,
      };
    },

  },
  
  extraReducers: (builder) =>
    builder
      .addCase(highlightsActions.removeHighlightStarted, (state) => state)
      .addCase(highlightsActions.removeHighlightSucceeded,
        (state, action: PayloadAction<Highlight>) => {
          const highlights = state.highlights.filter(
            (highlight) => action.payload.id !== highlight.id,
          );
          return { ...state, highlights };
        })
      .addCase(highlightsActions.removeHighlightFailed, (state) => state)
      .addCase(authActions.logoutUser, () => initialState)
});

export const { actions } = highlightsSlice;

export const selectHighlights = (state: RootState): State => state[HIGHLIGHTS];

export const highlightsReducer = highlightsSlice.reducer;
