import React from 'react';
import {
  BooksContainer,
  Header,
  BookList,
} from 'modules/books/components/__styles__/Books.styles';
import { useAppSelector } from 'common/hooks/state-hooks';
import { selectBooks } from 'modules/books';
import { BookCard } from 'modules/books/components/BookCard';
import Layout from 'common/page-layout/Layout';
import type { Book } from '../utils/booksApiUtils';

const Books = () => {
  const { books } = useAppSelector(selectBooks);

  return (
    <Layout>
      <BooksContainer>
        <Header>All Books</Header>
        <BookList>
          {books.map((book: Book) => <BookCard book={book} key={book.id} />)}
        </BookList>
      </BooksContainer>
    </Layout>
  );
};

export default Books;
