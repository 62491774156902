import React from 'react';
import { useMediaQuery } from 'react-responsive';
import { routeTo } from 'common/utils/routes';
import { StyledHeaderLeft, Logo } from './__styles__/HeaderLeft.styles';

export const HeaderLeft = () => {
  const isMobile = useMediaQuery({ maxWidth: 767 });
  return (
    <StyledHeaderLeft href={routeTo.landing} passHref className="logo">
      <Logo src="/Stoic Bible Logo Icon.svg" alt="Stoic Bible Logo" />
      {!isMobile && <span>Stoic Bible</span>}
    </StyledHeaderLeft>
  );
};

export default HeaderLeft;
