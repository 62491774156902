import styled, { css } from 'styled-components';
import { Delete } from 'styled-icons/material';
import { ArrowRight } from 'styled-icons/heroicons-outline';
import { ShareLine, WriteLine } from 'static/assets';
import { setFlex, setFont } from 'app/globalStyles/variables';
import {
  isTabletOrLarger,
  isTabletOrSmaller,
  isDesktopOrLarger,
  isTablet,
} from 'app/globalStyles/screens';
import { PrimaryButton, SecondaryButton } from 'common/components/styled-components';

// //////// PAGE ////////////
export const PageContainer = styled.div`
  width: 90%;
  margin: 3rem auto;
  ${isTablet`
     width: 86%;
   `};
  ${isDesktopOrLarger`
     width: 55%;
   `};
`;

export const PageHeader = styled.div`
  position: relative;
  ${setFlex({ x: 'space-between', y: 'start' })};
  margin-bottom: 1rem;
  ${isTabletOrLarger`
    margin-bottom: 2rem;
  `}
`;

export const PageHeading = styled.h1`
  font-size: 2.6rem;
  font-weight: 500;
  font-family: ${setFont.headings};
`;

export const NoActivityPage = styled<any>(PageContainer)`
  ${setFlex()};
  flex-direction: column;
`;
export const NoActivityText = styled.div`
  text-align: center;
  max-width: 300px;
  margin: 4rem auto;
  font-size: 1.4rem;
  ${isTabletOrLarger`
    font-size: 1.6rem;
  `}
`;
export const StartActivityButton = styled<any>(SecondaryButton)`
  width: 80%;
  height: 55px;
  ${isTabletOrLarger`
    width: 30rem;
  `}
`;

// //////// END PAGE ////////////

// //////// CARD ///////////
export const Card = styled.div`
  border-bottom: 1px solid ${({ theme: { colors } }) => colors.colorBorder};
  line-height: 3.2rem;
  margin-bottom: 40px;
  outline: unset;
`;

export const CardHeader = styled.div`
  margin-bottom: 1rem;
  ${setFlex({ x: 'space-between', y: 'start' })}
`;

export const CardHeading = styled.h2`
  color: ${({ theme: { colors } }) => colors.colorText};
  font-weight: 500;

  line-height: 2.2rem;
  &:hover {
    cursor: pointer;
  }
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-right: 1rem;
  font-size: 1.6rem;
  max-width: 75%;
  ${isTabletOrLarger`
    font-size: 1.8rem;
    max-width: auto;
  `}
`;

export const CardTimeStamp = styled.p`
  opacity: 0.6;
  font-size: 1.2rem;
  ${isTabletOrLarger`
    font-size: 1.4rem;
  `}
`;

export const CardQuote = styled.div`
  width: 100%;
  margin-top: 10px;
  font-size: 16px;
  line-height: 1.5;
  border-radius: 3px;
  background: ${({ theme: { colors } }) => colors.colorBackgroundDark};
  border: 1px solid ${({ theme: { colors } }) => colors.colorBorder};
  padding: 10px;
`;

export const Quote = styled.p`
  display: inline;
  //background: yellow;
`;

export const CardBody = styled.div`
  font-size: 1.6rem;
  line-height: 1.6;
  margin-bottom: 3.2rem;
  white-space: pre-wrap;
`;

export const CardFooter = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
`;

export const FooterLeft = styled.button`
  position: relative;
  padding: 10px 20px;
  background: ${({ theme: { colors } }) => colors.colorPrimary4};
  color: ${({ theme: { colors } }) => colors.colorText};
  border-radius: 3px;
  border: unset;
  outline: unset;
  min-width: 177px;
  text-align: center;
  ${setFlex()};
  width: 120px;
  height: 40px;
  font-size: 1.1rem;
  ${isTabletOrSmaller`width: 170px;`}
  ${isTabletOrLarger`width: initial; height: 35px;
  font-size: 1.4rem;`}
`;

export const FooterLeftArrow = styled(ArrowRight)`
  width: 16px;
  margin-left: 5px;
`;

export const ActionOptions = styled.ul`
  list-style: none;
  display: block;
  z-index: 50;
  font-size: 1.4rem;
  width: 13rem;
  background: ${({ theme: { colors } }) => colors.colorBackground};
  left: auto;
  right: 4%;
  top: 70%;
  ${isTabletOrLarger`
    left: initial;
    right: initial;
    top: initial;
  `}

  animation: grow-in 0.1s linear;

  @keyframes grow-in {
    0% {
      height: 70px;
      width: 90px;
      font-size: 1.2rem;
      opacity: 0.8;
    }
    25% {
      height: 75px;
      width: 92px;
      font-size: 1.2rem;
      opacity: 0.85;
    }
    50% {
      height: 80px;
      width: 94px;
      font-size: 1.3rem;
      opacity: 0.9;
    }
    75% {
      height: 85px;
      width: 96px;
      font-size: 1.4rem;
      opacity: 0.95;
    }
    100% {
      height: 90px;
      width: 98px;
      font-size: 1.4rem;
      opacity: 1;
    }
  }
`;

export const optionStyles = css`
  padding: 1rem;
  padding-left: 2.8rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  position: relative;
  color: ${({ theme: { colors } }) => colors.colorPrimary2};
  width: 100%;
  height: 60px;
  font-size: 1.4rem;
  border: 0;
  outline: 0;

  &:hover {
    background: ${({ theme: { colors } }) => colors.colorPrimary4};
  }
`;

export const Option = styled.li`
  ${optionStyles}
`;

export const OptionText = styled.span``;

// @ts-ignore
export const ShareIcon = styled<any>(ShareLine)`
  width: 16px;
  margin-right: 1.069rem;
`;

export const DeleteIcon = styled(Delete)`
  width: 16px;
  margin-right: 1rem;
`;

export const EditIcon = styled<any>(WriteLine)`
  color: ${({ theme: { colors } }) => colors.mainRed};
  margin-right: 1rem;
`;

export const HR = styled.hr`
  border: 1px solid ${({ theme: { colors } }) => colors.colorPrimary4};
  width: 80%;
  margin: 0 auto;
`;

export const DeleteOption = styled<any>(Option)`
  color: ${({ theme: { colors } }) => colors.mainRed};
`;
// //////// END CARD ///////////

// /////// JOURNALS ONLY ////////////////
export const WriteButton = styled<any>(PrimaryButton)`
  font-size: 1.6rem;
  height: 40px;
  padding: 0 20px;
  font-weight: 400;
  letter-spacing: -0.3px;
  color: #fff;
  border: 1px solid ${({ theme: { colors } }) => colors.colorBorder};
  background: ${({ theme: { colors } }) => colors.colorPrimary};
  ${setFlex()}
  &:hover {
    cursor: pointer;
  }
`;

export const JournalCardBody = styled<any>(CardBody)`
  cursor: pointer;
  outline: unset;
  white-space: pre-wrap;
  .click-to-expand {
    display: inline;
    font-weight: 500;
  }
`;

export const Write = styled.span`
  margin-left: 10px;
`;

// /////// BOOKMARKS ONLY ////////////////
export const AtVerse = styled.span`
  opacity: 0.6;
  font-weight: 300;
  font-size: 1.4rem;
  line-height: 17px;
`;

// ///////// HIGHLIGHTS ONLY /////////////
export const HighlightCardBody = styled<any>(CardBody)`
  a {
    // For the ShowLess and ShowMore links
    color: ${({ theme: { colors } }) => colors.colorText};
    font-weight: 500;
  }
`;
