import { Mixpanel, eventNames } from 'common/utils/mixpanel';
import fbPixel from 'common/utils/facebook-pixel';
import type { AuthServiceResponse } from './authApiUtils';

export const trackShowAuthModal = (modalName: 'signup' | 'signin') => {
  if (modalName === 'signup') {
    Mixpanel.trackEvent(eventNames.view_sign_up_modal);
  } else {
    Mixpanel.trackEvent(eventNames.view_sign_in_modal);
  }
};

export const trackLogout = () => Mixpanel.trackEvent(eventNames.log_out);

export const trackAuth = (payload: AuthServiceResponse) => {
  const { user, isNewUser } = payload;
  const { id, name, email, username, createdAt, provider } = user;
  Mixpanel.identifyUser(`${id}`);
  // we set all 4 at both login and sign up
  Mixpanel.people.set({
    $name: name,
    $email: email,
    $created: createdAt,
    username,
  });
  if (isNewUser) {
    Mixpanel.trackEvent(eventNames.sign_up, { provider });
    fbPixel.logLead();
  } else {
    Mixpanel.trackEvent(eventNames.log_in, { provider });
  }
};
