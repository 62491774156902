import { Mixpanel, eventNames } from 'common/utils/mixpanel';

type PurchaseSubscriptionEventPropTypes = {
  plan: string;
  currency: string;
  price: number;
};

export const trackViewMembershipPage = (): void =>
  Mixpanel.trackEvent(eventNames.view_membership_page);

export const trackEnterCardDetails = (): void =>
  Mixpanel.trackEvent(eventNames.enter_card_details);

export const trackOpenSubscriptionForm = ({
  plan,
  currency,
  price,
}: PurchaseSubscriptionEventPropTypes): void => {
  Mixpanel.trackEvent(eventNames.open_subscription_form, { plan, currency, price });
};

export const trackPurchaseSubscription = ({
  plan,
  currency,
  price,
}: PurchaseSubscriptionEventPropTypes): void => {
  Mixpanel.trackEvent(eventNames.purchase_subscription, { plan, currency, price });
};
