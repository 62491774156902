import React from 'react';
import AuthComponentContainer from 'modules/auth/components/AuthComponentContainer';
import * as S from 'modules/auth/components/__styles__/AuthComponent.styles';

interface Props {
  goToSignUp: () => void;
  goToSignIn: () => void;
  visibleAuthModal?: 'signin' | 'signup' | undefined;
}

const AuthPage = (props: Props): JSX.Element => {
  const { visibleAuthModal, goToSignIn, goToSignUp } = props;

  return (
    <S.AuthComponent>
      <AuthComponentContainer
        visibleAuthModal={visibleAuthModal}
        goToSignIn={goToSignIn}
        goToSignUp={goToSignUp}
      />
    </S.AuthComponent>
  );
};

export default AuthPage;
