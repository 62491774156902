import React from 'react';
import * as S from 'modules/thoughts/editor/components/Editor.styles';
import AccessTypeSelector from 'common/components/AccessTypeSelector';

export interface EditorHeaderProps {
  isSavingThought?: boolean;
  isUpdatingThought?: boolean;
  handleSave?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  onAccessTypeChange: (event: React.ChangeEvent<HTMLSelectElement>) => void;
  accessType: string;
  isEditing: boolean;
}

const EditorHeader = ({
  isSavingThought,
  isUpdatingThought,
  handleSave,
  onAccessTypeChange,
  accessType,
  isEditing,
}: EditorHeaderProps) => {
  const submitButtonValue = isEditing ? 'Update' : 'Save';
  return (
    <S.StyledEditorHeader>
      <AccessTypeSelector
        activityType="Journal"
        accessType={accessType}
        setAccessType={onAccessTypeChange}
        css="font-size: 18px"
      />
      <S.SubmitButton
        disabled={isSavingThought || isUpdatingThought}
        onClick={handleSave}
        type="button"
      >
        {isSavingThought || isUpdatingThought ? 'saving...' : submitButtonValue}
      </S.SubmitButton>
    </S.StyledEditorHeader>
  );
};

export default EditorHeader;
