import { useAppDispatch, useAppSelector } from 'common/hooks/state-hooks';
import type { ChangeEvent } from 'react';
import React, { useEffect, useState } from 'react';
import Modal from 'common/components/Modal';
import Toast from 'common/components/Toast';
import TextareaAutosize from 'react-textarea-autosize';
import {
  noteEditorModalStyles,
  Footer,
  Quote,
  Form,
  textAreaStyles,
  Header,
  CloseModal,
  QuoteCard,
} from 'modules/thoughts/editor/components/NewThoughtEditorModal.styles';
import { SubmitButton } from 'common/components/styled-components';
import { saveJournal } from 'modules/thoughts/journals/state/journals.actions';
import { actions as editorActions } from 'modules/thoughts/editor/state/editor.slice';
import { selectEditor } from 'modules/thoughts/editor';
import AccessTypeSelector from 'common/components/AccessTypeSelector';
import {
  trackAddAnnotation,
  trackAddThought,
  trackShowThoughtEditor,
} from 'modules/thoughts/analytics';
import type { Book } from 'modules/books/utils/booksApiUtils';
import type { Chapter, Verse } from 'modules/reading/utils/api/chapterApiUtils';
import type { Highlight } from 'modules/reading/utils/api/highlightsApiUtils';
import type { ThoughtAccessType, VerseOffsets } from 'modules/thoughts/journals/utils/journalsApiUtils';

type LinkedHighlightPropTypes = {
  currentBook: Book;
  currentChapter: Chapter;
  verse: Verse;
  selectedText: string;
  selectedTextOffsets: VerseOffsets;
  selectedHighlight: Highlight | null;
};

type LinkedChapterPropsTypes = {
  currentBook: Book;
  currentChapter: Chapter;
};


interface OwnProps {
  linkedHighlightProps?: LinkedHighlightPropTypes;
  linkedChapterProps?: LinkedChapterPropsTypes;
  closeModal: () => void;
}

type Props = OwnProps;

const NewThoughtEditorModal = (props: Props) => {
  const {
    closeModal,
    linkedHighlightProps,
  } = props;

  const dispatch = useAppDispatch();
  const { isSavingThought } = useAppSelector(selectEditor);
  const [accessType, setAccessType] = useState<ThoughtAccessType>('private');
  const [content, setContent] = useState('');

  useEffect(() => {
    trackShowThoughtEditor();
    return () => {
      dispatch(editorActions.setEditorThought(null));
    };
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();

    const baseAttrs = {
      content,
      accessType,
    };

    let thoughtAttrs;
    if (linkedHighlightProps) {
      const {
        verse,
        selectedHighlight,
        selectedTextOffsets,
        selectedText,
      } = linkedHighlightProps;

      if (selectedHighlight) {
        thoughtAttrs = {
          ...baseAttrs,
          highlightId: selectedHighlight.id,
          quote: selectedText,
          thinkableType: 'highlight',
        };
      } else {
        thoughtAttrs = {
          ...baseAttrs,
          verseId: verse.id,
          verseOffsets: selectedTextOffsets,
          quote: selectedText,
          thinkableType: 'highlight',
        };
      }
    } else {
      thoughtAttrs = baseAttrs;
    }

    // TODO: Replace 'any'
    const response: any = await dispatch(saveJournal(thoughtAttrs));

    if (response) {
      Toast({
        type: 'success',
        message: 'Journal saved successfully!',
      });
      props.closeModal();
      if (linkedHighlightProps) {
        const { currentBook, currentChapter, verse } = linkedHighlightProps;
        trackAddAnnotation({
          kind: response.kind,
          privacy: response.accessType,
          book_title: currentBook.title,
          book_author: currentBook.author,
          chapter_number: currentChapter.number,
          verse_number: verse.number,
        });
      } else {
        trackAddThought({ kind: response.kind, privacy: response.accessType });
      }
    } else {
      Toast({
        type: 'error',
        message: 'Error occurred while saving journal. Try again',
      });
    }
  };

  const onAccessTypeChange = (event: ChangeEvent<HTMLSelectElement>) =>
    setAccessType(event.target.value as ThoughtAccessType);
  const onTextChange = (event: ChangeEvent<HTMLTextAreaElement>) =>
    setContent(event.target.value);

  return (
    <Modal
      style={{
        content: noteEditorModalStyles,
      }}
      closeModal={closeModal}
      allowClose={false}
      shouldCloseOnOverlayClick={false}
    >
      <Header>
        <AccessTypeSelector
          activityType="Journal"
          accessType={accessType}
          setAccessType={onAccessTypeChange}
          css="font-size: 16px"
        />
        <CloseModal onClick={closeModal}>&times;</CloseModal>
      </Header>

      <Form onSubmit={handleSubmit}>
        {linkedHighlightProps && (
          <QuoteCard>
            <Quote>{linkedHighlightProps.selectedText}</Quote>
          </QuoteCard>
        )}

        <TextareaAutosize
          css={textAreaStyles}
          minRows={5}
          autoFocus
          placeholder="Your thoughts on this.."
          onChange={onTextChange}
        />
      </Form>
      <Footer>
        <div />
        <SubmitButton
          type="button"
          style={{ padding: '8px 24px' }}
          onClick={handleSubmit}
        >
          <span>{isSavingThought ? 'Saving...' : 'Save'}</span>
        </SubmitButton>
      </Footer>
    </Modal>
  );
};


export default NewThoughtEditorModal;
