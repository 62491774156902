import React, { useState } from 'react';
import VerseActionsTooltip from 'modules/reading/components/VerseActionsTooltip';
import AuthPrompt from 'modules/reading/components/AuthPrompt';
import type { Highlight } from 'modules/reading/utils/api/highlightsApiUtils';

export interface Props {
  sharableQuote: string;
  sharableUrl: string;
  selectedHighlight: Highlight | null;
  isFullVerseSelected: boolean;
  closeModal: () => void;
  highlightVerse: (color: string) => void;
  onUpdateHighlight: (color: string) => void;
  handleShare: (networkName: string) => void;
  removeHighlight: () => void;
  handleOpenBookmarkModal: () => void;
  openNoteModal: () => void;
  goToVerse: () => void;
  isAuthenticated: boolean;
  isActionRestricted: boolean;
  popOverPosition: { x?: number; y?: number };
}

interface AuthPromptType {
  isAuthPromptOpen: boolean;
  authPromptType?:
    | 'verse'
    | 'highlight'
    | 'bookmark'
    | 'journal'
    | 'note'
    | 'read';
}

const VerseActions = ({
  sharableUrl,
  sharableQuote,
  selectedHighlight,
  isFullVerseSelected,
  isActionRestricted,
  goToVerse,
  highlightVerse,
  onUpdateHighlight,
  removeHighlight,
  handleOpenBookmarkModal,
  isAuthenticated,
  popOverPosition,
  closeModal,
  openNoteModal,
  handleShare,
}: Props): JSX.Element => {
  const [{ isAuthPromptOpen, authPromptType }, setAuthPrompt] = useState<
    AuthPromptType
  >({
    isAuthPromptOpen: false,
    authPromptType: undefined,
  });

  const clostAuthPrompt = () => {
    setAuthPrompt({
      isAuthPromptOpen: false,
      authPromptType: undefined,
    });
    closeModal();
  };

  const handleHighlight = (color: string) => {
    if (!isAuthenticated) {
      setAuthPrompt({ isAuthPromptOpen: true, authPromptType: 'highlight' });
    } else {
      highlightVerse(color);
    }
  };

  const handleBookmark = () => {
    if (!isAuthenticated) {
      setAuthPrompt({ isAuthPromptOpen: true, authPromptType: 'bookmark' });
    } else {
      handleOpenBookmarkModal();
    }
  };

  const handleVerseRead = () => {
    if (!isAuthenticated) {
      setAuthPrompt({ isAuthPromptOpen: true, authPromptType: 'verse' });
    } else {
      goToVerse();
    }
  };

  const handleNote = () => {
    if (!isAuthenticated) {
      setAuthPrompt({ isAuthPromptOpen: true, authPromptType: 'note' });
    } else {
      openNoteModal();
    }
  };

  return (
    <>
      {isAuthPromptOpen && authPromptType && (
        <AuthPrompt type={authPromptType} closeModal={clostAuthPrompt} />
      )}
      <VerseActionsTooltip
        popOverPosition={popOverPosition}
        isHighlightSelected={!!selectedHighlight}
        selectedHighlightColor={selectedHighlight?.color}
        isFullVerseSelected={isFullVerseSelected}
        isActionRestricted={isActionRestricted}
        openNoteModal={handleNote}
        openBookmarkModal={handleBookmark}
        highlightVerse={handleHighlight}
        onUpdateHighlight={onUpdateHighlight}
        removeHighlight={removeHighlight}
        goToVerse={handleVerseRead}
        sharableQuote={sharableQuote}
        sharableUrl={sharableUrl}
        handleShare={handleShare}
      />
    </>
  );
};

export default VerseActions;
