/* eslint-disable no-underscore-dangle */
import storage from 'redux-persist/lib/storage';
import type { InputStoreState } from 'app/state/rootReducer';
import rootReducer, { initialStoreState } from 'app/state/rootReducer';
import type { Action } from '@reduxjs/toolkit';
import { configureStore } from '@reduxjs/toolkit';
import type { Persistor } from 'redux-persist/es/types';
import { FLUSH, PAUSE, PERSIST, persistReducer, persistStore, PURGE, REGISTER, REHYDRATE } from 'redux-persist';
import createSagaMiddleware from 'redux-saga';
import { rootSaga } from 'app/state/rootSaga';
import type { ThunkAction } from 'redux-thunk';

const persistConfig = {
  key: 'root',
  storage,
  // All reducers are blacklisted by default.
  // To persist any reducer, configure it in the reducer file.
  blacklist: Object.keys(initialStoreState),
};

const isClient = typeof window !== 'undefined';

const persistedReducer = persistReducer(persistConfig, rootReducer);
const sagaMiddleware = createSagaMiddleware();

// type PersistedStore = {
//   store: Store<InputStoreState>;
//   persistor: Persistor | null;
// };

const createStore = (initialState: InputStoreState = initialStoreState) => {
  const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({
      serializableCheck: {
        /* ignore persistance actions */
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat(sagaMiddleware),
    preloadedState: initialState,
    devTools: process.env.NODE_ENV !== 'production',
  });

  let persistor: Persistor | null = null;
  if (isClient) {
    persistor = persistStore(store);
  }

  sagaMiddleware.run(rootSaga);

  return { store, persistor };
};

const __NEXT_REDUX_STORE__ = '__NEXT_REDUX_STORE__';

export const getStore = (initialState?: InputStoreState) => {
  // Always make a new store if server, otherwise state is shared between requests
  if (!isClient) {
    return createStore(initialState);
  }

  // Create store if unavailable on the client and set it on the window object
  if (!window[__NEXT_REDUX_STORE__]) {
    window[__NEXT_REDUX_STORE__] = createStore(initialState);
  }
  return window[__NEXT_REDUX_STORE__];
};

const { store } = getStore();

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
  >;
