import styled from 'styled-components';
import { ArrowRight } from 'styled-icons/heroicons-outline';
import { setFlex } from 'app/globalStyles/variables';

export const CustomerDetails = styled.div`
  align-self: start;
  margin: 15px 0 40px 0;
  width: 100%;
  font-size: 1.6rem; 
`;
export const Detail = styled.div`
  ${setFlex({ x: 'space-between' })};
  margin: 10px 0; 
  color: ${({ theme: { colors } }) => colors.colorPrimary3};
`;

export const DetailAction = styled.button`
  font: inherit;
  color: #D94D3A;
  font-weight: 500;
  opacity: .9;
  border: 0;
  outline: 0;
  background: transparent;
  ${setFlex()};
`;
export const ActionArrow = styled(ArrowRight)`
  width: 15px;
  height: 15px;
  margin-left: 5px; 
`;
export const SubscribedPlatformDetails = styled.p`
  width: 100%;  
  font-size: 1.2rem;
  font-weight: 400;
  margin: 0;
  margin-top: 10px; 
  outline: none;
  text-align: center;
  word-wrap: wrap;
  
`;
