import styled from 'styled-components';
import { isTabletOrLarger } from 'app/globalStyles/screens';
import NavigateBack from 'common/components/NavigateBack';

export const StyledNavigateBack = styled<any>(NavigateBack)`
  margin-left: unset;
  position: absolute;
  top: 0;
  left: 6%;
  ${isTabletOrLarger`
     left: 12%;
  `}
`;

export const SearchWrapper = styled.div`
  margin: 40px auto;
  padding-top: 5rem;
  display: flex;
  flex-flow: column nowrap;
  // border: 1px solid ${({ theme: { colors } }) => colors.colorBorder};
  // border-left: 3px solid ${({ theme: { colors } }) => colors.colorBorder};
  box-sizing: border-box;
  border-radius: 5px;
  background: ${({ theme: { colors } }) => colors.colorBackground};
  width: 100%;
  height: 100%;

  ${isTabletOrLarger`
    width: 88%;
    height: initial;
  `}

  .verse {
    color: ${({ theme: { colors } }) => colors.colorText};
    padding: 2.3rem 3rem 2.5rem 2.4rem;
    // border: 1px solid ${({ theme: { colors } }) => colors.colorBorder};
    border-bottom: 1px solid ${({ theme: { colors } }) => colors.colorBorder};
    border-radius: 3px;
    font-size: 1.2rem;
    text-align: left;
    outline: unset;

    h4 {
      margin-bottom: 1.8rem;
      color: ${({ theme: { colors } }) => colors.colorPrimary2};
    }
    .text {
      font-size: 1.6rem;
      line-height: 2.4rem;
      letter-spacing: -0.25px;
    }
    margin: 10px 5% 10px 5%;
    ${isTabletOrLarger`margin: 10px 15% 10px 15%;`}
    &:hover {
      cursor: pointer;
    }
  }
`;
