import ChapterVerse from 'modules/reading/components/ChapterVerse';
import type { NextRouter } from 'next/router';
import { withRouter } from 'next/router';
import React, { useEffect } from 'react';
import type {
  removeHighlight,
  addHighlight,
  updateHighlight
} from 'modules/reading/state/actions/highlights.actions';
import ChapterAuthLock from 'modules/reading/components/ChapterAuthLock';
import ChapterMembershipLock from 'modules/reading/components/ChapterMembershipLock';

import {
  StyledChapterContents,
  StyledLockedChapterContent,
  ChapterTitle,
  TitleUnderline,
  StyledVerses,
  StyledVerse,
} from 'modules/reading/components/__styles__/ChapterContents.styles';
import type { ChapterHighlights } from 'modules/reading/utils/readingUtils';
import { ChapterLockReasons } from 'modules/reading/utils/readingUtils';
import type { Book } from 'modules/books/utils/booksApiUtils';
import type { Chapter, Verse } from 'modules/reading/utils/api/chapterApiUtils';

export interface Props {
  currentChapterVerses: Verse[];
  currentChapter: Chapter;
  currentBook: Book;
  activeVerse: Verse | null;
  setActiveVerse: (verse: Verse | null) => void;
  verseNumber?: number;
  lockReason?: ChapterLockReasons;
  fontFamily: string;
  fontSize: number;
  shouldShowVerseNumbers: boolean;
  router: NextRouter;
  isAuthenticated: boolean;
  isMemberOrFreeTrialUser: boolean;
  isEmailNotVerified: boolean;
  chapterHighlights: ChapterHighlights;
  addHighlight: typeof addHighlight;
  updateHighlight: typeof updateHighlight;
  removeHighlight: typeof removeHighlight;
  openBookmarkModal: () => void;
}

export const ChapterContents = (props: Props): JSX.Element => {
  const {
    currentBook,
    currentChapter,
    currentChapterVerses,
    activeVerse,
    verseNumber,
    fontFamily,
    fontSize,
    isAuthenticated,
    isEmailNotVerified,
    isMemberOrFreeTrialUser,
    setActiveVerse,
    chapterHighlights,
    addHighlight,
    updateHighlight,
    removeHighlight,
    openBookmarkModal,
    lockReason,
    shouldShowVerseNumbers,
  } = props;
  
  const refs = currentChapterVerses.reduce((acc, value) => {
    acc[value.number] = React.createRef();
    return acc;
  }, {});

  useEffect(() => {
    if (verseNumber && refs[verseNumber].current) {
      refs[verseNumber].current.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
  }, []);

  const StyledContainer = lockReason ? StyledLockedChapterContent : StyledChapterContents;

  return (
    <StyledContainer>
      <ChapterTitle key={currentChapter.id}>
        {currentChapter.title}
      </ChapterTitle>
      <TitleUnderline />
      <StyledVerses>
        {currentChapterVerses.map((verse) => (
          <StyledVerse
            fontFamily={fontFamily}
            fontSize={fontSize}
            ref={refs[verse.number]}
            key={`${currentChapter.slug}-${verse.number}`}
          >
            <ChapterVerse
              verse={verse}
              highlights={chapterHighlights[verse.id]}
              currentBook={currentBook}
              currentChapter={currentChapter}
              activeVerse={activeVerse}
              setActiveVerse={setActiveVerse}
              isEmailNotVerified={isEmailNotVerified}
              isMemberOrFreeTrialUser={isMemberOrFreeTrialUser}
              isAuthenticated={isAuthenticated}
              shouldShowVerseNumbers={shouldShowVerseNumbers}
              addHighlight={addHighlight}
              updateHighlight={updateHighlight}
              removeHighlight={removeHighlight}
              openBookmarkModal={openBookmarkModal}
            />
          </StyledVerse>
        ))}
        {lockReason === ChapterLockReasons.AUTH && <ChapterAuthLock />}
        {lockReason === ChapterLockReasons.MEMBERSHIP && (
        <ChapterMembershipLock currentBook={currentBook} />
        )}
      </StyledVerses>
    </StyledContainer>
  );
};

export default withRouter(ChapterContents);
