import styled from 'styled-components';
import {
  setFont,
} from 'app/globalStyles/variables';

export const Email = styled.div`
   font-size: 1.6rem;
`;

export const Name = styled.div`
  font-family: ${setFont.headings};
  font-size: 3rem;
  line-height: 41px;
  text-align: center;
`;
