import React from 'react';
import { useRouter } from 'next/router';
import { DangerZone } from 'static/assets';
import { routeTo } from 'common/utils/routes';
import type { handleLogoutAction } from 'modules/auth/state/auth.actions';
import type { deleteAccount } from 'modules/profile/state/profile.actions';
import { useAppDispatch } from 'common/hooks/state-hooks';
import {
  SettingsCard,
  Body,
  Heading,
  DeleteButton,
  Instruction,
  ProfileBody,
} from './__styles__/SettingsV2.styles';
import ConfirmDeleteModal from './ConfirmDelete';
import type { User } from '../utils/profileApiUtils';

const warningInstruction = `If you delete your account, you will permanently eliminate all of your
          data, such as journals, bookmarks, highlights, etc.) within Stoic Bible.
          This action cannot be reversed by you or by us. If you're certain you'd like
          to move forward, proceed below.`;

export interface Props {
  isDeletingAccount: boolean;
  deleteAccount: typeof deleteAccount;
  user: User;
  handleLogout: typeof handleLogoutAction;
}

export const DeleteAccount = (props: Props): JSX.Element => {
  const {
    user, isDeletingAccount, deleteAccount, handleLogout,
  } = props;
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const router = useRouter();
  const openModal = () => setIsOpen(true);
  const closeModal = () => setIsOpen(false);
  const dispatch = useAppDispatch();

  const handleAccountDelete = async (event) => {
    event.preventDefault();
    if (user.id) {
      await dispatch(deleteAccount(user.id));
      dispatch(handleLogout());
      router.push(routeTo.read);
    }
  };

  return (
    <SettingsCard>
      <Heading>Danger Zone</Heading>
      <Body>
        <ProfileBody>
          <DangerZone />
          <Instruction>Delete your account and data.</Instruction>
        </ProfileBody>
        <DeleteButton onClick={openModal}>
          <span>Delete Account</span>
        </DeleteButton>
      </Body>
      {modalIsOpen && (
        <ConfirmDeleteModal
          user={user}
          closeModal={closeModal}
          handleSubmit={handleAccountDelete}
          isDeletingAccount={isDeletingAccount}
          warningInstruction={warningInstruction}
          title="Delete Account"
          submitButtonText="Delete My Account"
        />
      )}
    </SettingsCard>
  );
};

export default DeleteAccount;
