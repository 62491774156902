import type { CSSObject, CSSProp } from 'styled-components';
import styled, { keyframes } from 'styled-components';

const spinLoader = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

interface LoaderProps {
  size: string;
  style?: CSSObject | CSSProp;
}

export const Loader = styled.div<LoaderProps>`
  border: 0.5rem solid rgba(0, 0, 0, 0.1);
  border-top: 0.3rem solid ${({ theme: { colors } }) => colors.colorLoader};
  border-radius: 50%;
  width: ${(props) => props.size};
  height: ${(props) => props.size};
  animation: ${spinLoader} 0.6s linear infinite;
  margin-left: auto;
  margin-right: auto;
  ${(props: LoaderProps) => props.style}
`;

export const PageLoader = styled<any>(Loader)`
  margin-top: 40px;
  height: 3rem;
  width: 3rem;
`;

export default Loader;
