import styled, { css } from 'styled-components';
import { submitButtonStyles } from 'modules/auth/components/__styles__/AuthComponent.styles';

const commonStyles = css`
  margin-top: 0;
  margin-bottom: 10px;
  border-radius: 50px;
  &:focus {
    outline: 0;
  };
`;

export const LoginButton = styled.button<any>`
  ${submitButtonStyles};
  ${commonStyles};
`;

export const SignUpButton = styled.button<any>`
  ${submitButtonStyles};
  ${commonStyles};
  background: ${({ theme: { colors } }) => colors.colorPrimary4};
  border: 1px solid ${({ theme: { colors } }) => colors.colorPrimary1};
  color: ${({ theme: { colors } }) => colors.colorText};
`;
