import React from 'react';
import * as S from 'modules/books/components/__styles__/BookDetails';
import { selectBooks } from 'modules/books';
import { useAppSelector } from 'common/hooks/state-hooks';
import { BookDetails } from 'modules/books/components/BookDetails';
import NavigateBack from 'common/components/NavigateBack';
import { routeTo } from 'common/utils/routes';
import { useRouter } from 'next/router';
import styled from 'styled-components';
import { isTabletOrLarger } from 'app/globalStyles/screens';
import Layout from 'common/page-layout/Layout';

const StyledNavigateBack = styled<any>(NavigateBack)`
  margin-left: unset;
  position: absolute;
  top: 0;
  left: 6%;
  ${isTabletOrLarger`
     left: 12%;
  `}
`;

const Book = () => {
  const { currentBook } = useAppSelector(selectBooks);
  const router = useRouter();
  const navigateBack = () => router.push(routeTo.books);
  return (
    <Layout>
      <div style={{ paddingTop: '5.1rem', marginTop: '10px' }}>
        <StyledNavigateBack
          backTitle="Back to all books"
          goBack={navigateBack}
        />

        <S.StyledBookPage>
          {currentBook && <BookDetails book={currentBook} />}
        </S.StyledBookPage>
      </div>
    </Layout>
  );
};

export default Book;
