/* eslint-disable jsx-a11y/no-autofocus */
import React, { useState, useEffect } from 'react';
import { useRouter } from 'next/router';
import { useAppDispatch } from 'common/hooks/state-hooks';
import { routeAs, routeTo } from 'common/utils/routes';
import { searchVerses } from 'modules/search/state/search.actions';
import {
  trackOpenSearchBox,
  trackPerformSearch,
} from 'modules/search/utils/analytics';
import { StyledSearchBarModal } from './__styles__/SearchModal.styles';

export interface Props {
  hideSearchModal: () => void;
}

const SearchModal = ({ hideSearchModal }: Props) => {
  let modalRef: HTMLInputElement | null;
  const router = useRouter();
  const dispatch = useAppDispatch();
  const [searchString, setSearchString] = useState('');

  const handleChange = (e) => {
    e.preventDefault();
    setSearchString(e.target.value);
  };

  const handleSearch = async (e) => {
    e.preventDefault();
    if (searchString) {
      await dispatch(searchVerses(searchString));
      router.push(
        routeTo.searchResult(searchString),
        routeAs.searchResult(searchString),
      );
      trackPerformSearch(searchString);
      hideSearchModal();
    }
  };

  const handleHideSearchModal = (e) => {
    if (modalRef && !modalRef.contains(e.target)) {
      hideSearchModal();
    }
  };

  useEffect(() => {
    trackOpenSearchBox();
  }, []);

  useEffect(() => {
    document.addEventListener('mousedown', handleHideSearchModal);
    return () => {
      document.removeEventListener('mousedown', handleHideSearchModal);
    };
  }, [handleHideSearchModal]);

  const setRef = (node) => {
    modalRef = node;
  };

  return (
    <StyledSearchBarModal onSubmit={handleSearch}>
      <input
        type="text"
        name="search"
        onChange={handleChange}
        placeholder="Search Stoic Bible"
        value={searchString}
        autoFocus
        ref={setRef}
        required
      />
    </StyledSearchBarModal>
  );
};

export default SearchModal;
