import { useRouter } from 'next/router';
import React from 'react';
import Moment from 'react-moment';
import { routeTo, routeAs } from 'common/utils/routes';
import Share from 'common/components/Share';
import * as S from 'modules/profile/components/styledComponents';
import DropDownMenu from 'common/components/DropDownMenu';
import { useMediaQuery } from 'react-responsive';
import type { deleteBookmarkAction } from 'modules/bookmarks/state/bookmarks.actions';
import type { Bookmark } from 'modules/reading/utils/api/bookmarksApiUtils';

export interface Props {
  bookmarks: Bookmark[];
  deleteBookmark: typeof deleteBookmarkAction;
}

const BookMarks = ({ bookmarks, deleteBookmark: removeBookmark }: Props): JSX.Element => {
  const router = useRouter();

  const goToChapter = (bookmark: Bookmark): void => {
    router.push(
      routeTo.chapter,
      routeAs.chapter(
        `${bookmark.chapter?.slug}?verseNumber=${bookmark.verseNumber}`,
      ),
    );
  };

  const handleDelete = (bookmark: Bookmark) =>
    removeBookmark(bookmark.id as number);
  const isMobile = useMediaQuery({ maxWidth: 767 });

  return (
    <S.PageContainer>
      {!isMobile && (
        <S.PageHeader>
          <S.PageHeading>Your Bookmarks</S.PageHeading>
        </S.PageHeader>
      )}

      {bookmarks.map(
        (bookmark: Bookmark): JSX.Element => {
          const shareUrl = `${window.location.origin}${routeAs.chapter(
            `${bookmark.chapter?.slug}?verseNumber=${bookmark.verseNumber}`,
          )}`;
          const shareTitle = `${bookmark.chapter?.title} at Verse ${bookmark.verseNumber}`;

          return (
            <S.Card key={bookmark.createdAt}>
              <S.CardHeader>
                <S.CardHeading>
                  {bookmark.chapter?.title}
                  <S.AtVerse>
                    &nbsp; at Verse&nbsp;
                    {bookmark.verseNumber}
                  </S.AtVerse>
                </S.CardHeading>

                <S.CardTimeStamp>
                  <Moment fromNow date={bookmark.updatedAt} />
                </S.CardTimeStamp>
              </S.CardHeader>

              <S.CardBody>
                <p>{`${bookmark.chapter?.book?.title} by ${bookmark.chapter?.book?.author}`}</p>
                {bookmark.label && (
                  <p>
                    <span>Bookmark label: </span>
                    {bookmark.label}
                  </p>
                )}
              </S.CardBody>
              <S.CardFooter>
                <S.FooterLeft
                  type="button"
                  onClick={() => goToChapter(bookmark)}
                >
                  Chapter
                  <S.FooterLeftArrow />
                </S.FooterLeft>
                <DropDownMenu>
                  <S.ActionOptions>
                    <Share
                      title={shareTitle}
                      shareUrl={shareUrl}
                      buttonStyle={S.optionStyles}
                    >
                      <S.ShareIcon />
                      <S.OptionText>Share</S.OptionText>
                    </Share>
                    <S.HR />
                    <S.DeleteOption onClick={() => handleDelete(bookmark)}>
                      <S.DeleteIcon />
                      <S.OptionText>Delete</S.OptionText>
                    </S.DeleteOption>
                  </S.ActionOptions>
                </DropDownMenu>
              </S.CardFooter>
            </S.Card>
          );
        },
      )}
    </S.PageContainer>
  );
};

export default BookMarks;
