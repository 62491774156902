import type { DefaultTheme } from 'styled-components';
import styled, { css } from 'styled-components';
import { setFlex, setFont } from 'app/globalStyles/variables';
import { isTabletOrLarger } from 'app/globalStyles/screens';
import { CaretDown, CaretRight, Check } from 'styled-icons/boxicons-regular';
import { Close, CheckCircle } from 'styled-icons/material-rounded';

const Button = styled.button`
  font-family: ${setFont.main};
  font-size: 1.6rem;
  background: unset;
  outline: unset;
  border: unset;
  cursor: pointer;
  border-radius: 5px;
`;

const Select = styled.select`
  width: 100%;
  height: 4.5rem;
  border-radius: 4px;
  line-height: 1.6em;
  padding: 0.6em 1em 0.5em 1em;
  outline: 0;
  margin: 0;
  font-size: 1.6rem;
  border: unset;
  background: ${({ theme: { colors } }) => colors.colorPrimary4} !important;
  color: ${({ theme: { colors } }) => colors.colorPrimary1} !important;
  font-family: ${setFont.main};
  letter-spacing: -0.3px;
`;

const SelectOption = styled.option`
  border: unset;
  outline: 0 !important;
  text-align: center;
  background: ${({ theme: { colors } }) => colors.colorPrimary4} !important;
`;

const PrimaryButton = styled<any>(Button)`
  background: ${({ theme: { colors } }) => colors.colorPrimary1};
  color: ${({ theme: { colors } }) => colors.colorTextWhite};
  font-weight: 500;
`;

const SecondaryButton = styled<any>(Button)`
  color: ${({ theme: { colors } }) => colors.colorText};
  background: ${({ theme: { colors } }) => colors.colorPrimary4};
`;

const SubmitButton = styled<any>(PrimaryButton)`
  font-size: 1.6rem;
  border-radius: 5px;
  padding: 12px 32px;
`;

const Form = styled.form`
  margin: 2rem auto;
  width: 90%;
  ${isTabletOrLarger`
    width: 50%;
    `};
`;

const FormGroup = styled.div`
  margin-bottom: 5px;
`;

interface Props {
  showBorder?: boolean;
}

const InputField = styled.input<Props>`
  width: 100%;
  display: block;
  height: 4.5rem;
  border-radius: 4px;
  color: ${({ theme: { colors } }) => colors.colorText};
  border: 1px solid ${({ theme: { colors } }) => colors.colorBorder};
  outline: unset;
  font-size: 1.6rem;
  font-weight: 500;
  padding-left: 10px;
  background: inherit;
  //font-family: 'Lora', serif;
  &::placeholder {
    color: ${({ theme: { colors } }) => colors.inputPlaceHolderColor};
  }
`;

const Div = styled.div``;

const InputLabel = styled.p`
  color: ${({ theme: { colors } }) => colors.colorText};
  margin: 0rem;
  font-family: 'Lora', serif;
  font-size: 1.6rem;
  padding-left: 5px;
`;

const CheckBoxWrapper = styled.div`
  position: relative;
`;

const CheckBoxLabel = styled.label`
  position: absolute;
  top: 0;
  left: 0;
  width: 42px;
  height: 26px;
  border-radius: 15px;
  background: #bebebe;
  cursor: pointer;
  &::after {
    content: '';
    display: block;
    border-radius: 50%;
    width: 18px;
    height: 18px;
    margin: 3px;
    background: #ffffff;
    box-shadow: 1px 3px 3px 1px rgba(0, 0, 0, 0.2);
    transition: 0.2s;
  }
`;

const CheckBox = styled.input`
  opacity: 0;
  z-index: 1;
  border-radius: 15px;
  width: 42px;
  height: 26px;
  &:checked + ${CheckBoxLabel} {
    background: #4fbe79;
    &::after {
      content: '';
      display: block;
      border-radius: 50%;
      width: 18px;
      height: 18px;
      margin-left: 21px;
      transition: 0.2s;
    }
  }
`;

const ShowAndEditField = styled.input<Props>`
  width: 100%;
  display: block;
  height: 4.5rem;
  border-radius: 4px;
  color: ${({ theme: { colors } }) => colors.colorText};
  border: ${(props) =>
    (props.showBorder
      ? `20px solid ${({ theme: { colors } }) => colors.colorBorder}`
      : 'unset')};
  outline: unset;
  font-size: 2.1rem;
  font-weight: 500;
  padding: 0 5px 0 5px;
  background: ${({ theme: { colors } }) => colors.colorInputBg};
  font-family: 'Lora', serif;
  &::placeholder {
    color: ${({ theme: { colors } }) => colors.inputPlaceHolderColor};
  }

  :hover {
    background: ${({ theme: { colors } }) => colors.colorPrimary4};
    color: ${({ theme: { colors } }) => colors.colorText};
  }

  :focus {
    background: ${({ theme: { colors } }) => colors.colorBackground};
    border: 2px solid ${({ theme: { colors } }) => colors.colorPrimary3};
  }
`;

const StyledCloseIcon = styled(Close)`
  color: ${({ theme: { colors } }) => colors.colorText};
  right: 10px;
  width: 18px;
  height: 18px;
  ${isTabletOrLarger(css`
    right: 1.0416666667vw;
    width: 1.5625vw;
    height: 1.5625vw;
  `)};
`;

const StyledCheckIcon = styled(Check)`
  color: ${({ theme: { colors } }) => colors.colorText};
  right: 10px;
  width: 18px;
  height: 18px;
  ${isTabletOrLarger(css`
    right: 1.0416666667vw;
    width: 1.5625vw;
    height: 1.5625vw;
  `)};
`;

const StyledCaretDown = styled(CaretDown)`
  color: ${({ theme: { colors } }) => colors.colorText};
  right: 10px;
  width: 18px;
  height: 18px;
  ${isTabletOrLarger(css`
    right: 1.0416666667vw;
    width: 1.5625vw;
    height: 1.5625vw;
  `)};
`;

const ErrorMsg = styled.p`
  color: ${({ theme: { colors } }) => colors.mainRed};
`;

const StyledCaretUp = styled(CaretRight)`
  color: ${({ theme: { colors } }) => colors.colorText};
  right: 10px;
  width: 18px;
  height: 18px;
  ${isTabletOrLarger(css`
    right: 1.0416666667vw;
    width: 1.5625vw;
    height: 1.5625vw;
  `)};
`;

const CheckedIcon = styled(CheckCircle)`
  width: 14px;
  height: 14px;
  margin: 0 0.4rem;
  color: ${(p) => p.theme.colors.colorBackground};
`;

// ////////// ATOMIC COMPONENTS ///////////
interface FlexProps {
  justifyContent?:
    | 'flex-start'
    | 'flex-end'
    | 'center'
    | 'space-around'
    | 'space-evenly'
    | 'space-between';
  alignItems?: 'stretch' | 'flex-start' | 'flex-end' | 'center';
  width?: string;
  height?: string;
  margin?: string;
  padding?: string;
  flexDirection?: 'column' | 'row' | 'column-reverse' | 'row-reverse';
}

const Flex = styled.div<FlexProps>`
  ${(p) =>
    setFlex({
      x: p.justifyContent,
      y: p.alignItems,
    })}
  flex-direction: ${(p) => p.flexDirection};
  width: ${(p) => p.width};
  height: ${(p) => p.height};
  margin: ${(p) => p.margin};
  padding: ${(p) => p.padding};
`;

// TEXT COMPONENT
interface TextProps {
  as?:
    | 'span'
    | 'h1'
    | 'h2'
    | 'h3'
    | 'h4'
    | 'h5'
    | 'h6'
    | 'p'
    | 'strong'
    | 'small'
    | 'em';
  fontSize?: string;
  margin?: string;
  fontFamily?:
    | "'Inter', sans-serif"
    | "'Playfair Display', serif"
    | "'Lora', serif"
    | "'Avenir', sans-serif";
  color?: string;
  lineHeight?: string;
  fontWeight?: number | string;
  letterSpacing?: number | string;
  textAlign?: string;
}

const Text = styled.span<TextProps>`
  font-size: ${(p) => p.fontSize};
  font-family: ${(p) => p.fontFamily};
  font-weight: ${(p) => p.fontWeight};
  line-height: ${(p) => p.lineHeight};
  text-align: ${(p) => p.textAlign};
  letter-spacing: ${(p) => p.letterSpacing};
  margin: ${(p) => p.margin};
  color: ${(p) => p.theme.colors[p.color || 'colorText']};
`;

// ////////////// BUTTON COMPONENT /////////////////
interface ButtonProps {
  color?: string;
  backgroundColor?: string;
  borderColor?: string;
  padding?: string;
  variant?: 'contained' | 'outline';
  theme?: DefaultTheme;
  fontFamily?:
    | "'Inter', sans-serif"
    | "'Playfair Display', serif"
    | "'Lora', serif"
    | "'Avenir', sans-serif";
  fontSize?: string;
  fontWeight?: string | number;
  height?: string;
  width?: string;
  margin?: string;
  shape?: 'rounded' | 'square' | 'pill';
}

const buttonVariants = {
  outline: css`
    border-color: ${(p: ButtonProps) =>
    p.theme?.colors[p.borderColor || p.backgroundColor || 'transparent']};
    border-width: 1px;
    border-style: solid;
    background: transparent;
  `,
  contained: css`
    border-color: ${(p: ButtonProps) =>
    p.theme?.colors[p.borderColor || p.backgroundColor || 'transparent']};
    border-width: 1px;
    border-style: solid;
  `,
};

const buttonShapes = {
  rounded: css`
    border-radius: 4px;
  `,
  pill: css`
    border-radius: 50px;
  `,
  square: css`
    border-radius: 0;
  `,
};

const CustomButton = styled.button<ButtonProps>`
  color: ${(p) => p.theme?.colors[p.color || 'colorText']};
  font-family: ${(p) => p.fontFamily};
  font-weight: ${(p) => p.fontWeight};
  font-size: ${(p) => p.fontSize};
  background: ${(p) => p.theme?.colors[p.backgroundColor || 'transparent']};;
  padding: ${(p) => p.padding};
  height: ${(p) => p.height};
  width: ${(p) => p.width};
  margin: ${(p) => p.margin};
  outline: 0;
  &:hover {
    opacity: 0.92;
    transition: opacity 0.3s linear;
  }

  /* Apply Variant */
  ${(p) => buttonVariants[p.variant || 'contained']}
  /* Appy Shape */
  ${(p) => buttonShapes[p.shape || 'rounded']}
`;

export {
  Button,
  Select,
  CustomButton,
  SelectOption,
  SubmitButton,
  Form,
  ErrorMsg,
  FormGroup,
  InputField,
  PrimaryButton,
  SecondaryButton,
  Div,
  Flex,
  Text,
  CheckedIcon,
  InputLabel,
  CheckBoxWrapper,
  CheckBoxLabel,
  CheckBox,
  ShowAndEditField,
  StyledCheckIcon,
  StyledCloseIcon,
  StyledCaretDown,
  StyledCaretUp,
};
