import { apiCall } from 'common/utils/api-helpers';
import type { HighlightCreationParams } from 'modules/highlights/state/highlights.actions';
import type { HighlightProps } from 'modules/reading/state/actions/highlights.actions';
import type { Verse } from './chapterApiUtils';

export interface Highlight {
  id: number;
  userId: number;
  color?: string;
  verseId: number;
  startOffset: number;
  endOffset: number;
  verse?: Verse;
  createdAt: string;
}


export const addHighlight = async (
  highlight: HighlightCreationParams,
): Promise<Highlight> => {
  const { data } = await apiCall('highlights', 'post', highlight);
  return data;
};

export const fetchChapterHighlights = async (
  chapterId: number,
): Promise<Highlight[]> => {
  const { data } = await apiCall(`highlights/chapter/${chapterId}`, 'get');
  return data;
};

export const updateHighlight = async ({
  highlightId,
  color,
}: HighlightProps): Promise<Highlight> => {
  const { data } = await apiCall(`highlights/${highlightId}`, 'put', { color });
  return data;
};

export const removeHighlight = async (
  highlightId: number,
): Promise<Highlight> => {
  const { data } = await apiCall(`highlights/${highlightId}`, 'delete');
  return data;
};
