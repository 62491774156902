import React, { useState, useEffect } from 'react';
import { useRouter } from 'next/router';
import type { fetchBooks } from 'modules/books';
import { routeTo, routeAs } from 'common/utils/routes';
import SelectChapter from 'modules/reading/components/SelectChapter';
import { SelectChapterModalWrapper } from 'modules/reading/components/__styles__/SelectChapterModal.styles';
import type { Book } from 'modules/books/utils/booksApiUtils';
import { useAppDispatch } from 'common/hooks/state-hooks';
import type { Chapter } from 'modules/reading/utils/api/chapterApiUtils';

export interface Props {
  closeChapterPicker: () => void;
  currentBook: Book | null;
  currentChapter: Chapter | null;
  books: Book[];
  isBooksLoading: boolean;
  fetchBooks: typeof fetchBooks;
}

const shouldNumberChapter = (bookSlug: string): boolean =>
  bookSlug?.startsWith('discourses')
  || bookSlug?.startsWith('letters-from-a-stoic')
  || bookSlug?.startsWith('as-a-man-thinketh');

export const SelectChapterModal = (props: Props) => {
  const [selectedBook, setSelectedBook] = useState(props.currentBook);
  const [activeTab, setActiveTab] = useState({
    Books: true,
    Chapters: false,
    Universal: false,
  });
  
  const dispatch = useAppDispatch();
  const router = useRouter();

  useEffect(() => {
    const { books } = props;
    if (!books.length) {
      dispatch(props.fetchBooks());
    }
  }, []);

  const selectBook = (book: Book): void => {
    setSelectedBook(book);
    setActiveTab({
      Universal: activeTab.Universal,
      Books: false,
      Chapters: true,
    });
  };

  const selectChapter = (chapter: Chapter): void => {
    router.push(routeTo.chapter, routeAs.chapter(chapter.slug));
    props.closeChapterPicker();
  };

  const { isBooksLoading, books, currentChapter, currentBook } = props;

  const book = books.find((book) => book.id === selectedBook?.id);
  const { chapters: currentBookChapters } = book || {};

  return (
    <SelectChapterModalWrapper>
      <SelectChapter
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        closeChapterPicker={props.closeChapterPicker}
        isBooksLoading={isBooksLoading}
        books={books}
        selectedBook={selectedBook}
        shouldNumberChapter={shouldNumberChapter}
        selectBook={selectBook}
        selectChapter={selectChapter}
        currentBookChapters={currentBookChapters}
        currentChapter={currentChapter}
        currentBook={currentBook}
      />
    </SelectChapterModalWrapper>
  );
};

export default SelectChapterModal;
