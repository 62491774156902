import React from 'react';
import VerseComponent, {
  VerseHeader,
} from 'modules/reading/components/VerseWithJournals';
import { useAppSelector } from 'common/hooks/state-hooks';
import Highlighter from 'react-highlight-words';
import { fetchPublicJournalsForVerse } from 'modules/reading/state/actions/journals.actions';
import SecondaryHeader from 'common/page-layout/SecondaryHeader';
import { StyledNavigateBack } from 'modules/search/components/__styles__/SearchResults.styles';
import Layout from 'common/page-layout/Layout';
import type { Journal } from 'modules/thoughts/journals/utils/journalsApiUtils';
import { selectSearch } from 'modules/search';
import { selectCurrentChapter, selectReaderSettings } from 'modules/reading';

interface Props {
  isLoadingVerseJournals: boolean;
  verseJournals: Journal[];
}

const Index = (props: Props) => {
  const {
    isLoadingVerseJournals,
    verseJournals,
  } = props;
 
  const { verse, searchString } = useAppSelector(selectSearch);
  const { currentChapter } = useAppSelector(selectCurrentChapter);
  const { fontFamily, fontSize } = useAppSelector(selectReaderSettings);

  const verseTitle = `${verse?.chapter?.book?.title} ${verse?.chapter?.number} : ${verse?.number}`;

  const renderVerse = () => (
    <Highlighter
      searchWords={searchString?.split(' ')}
      autoEscape
      caseSensitive={false}
      textToHighlight={verse?.text}
      highlightStyle={{
        backgroundColor: 'yellow',
        borderRadius: '5px',
        color: ({ theme: { colors } }) => colors.colorHighlightedText,
      }}
    />
  );

  return (
    <Layout>
      <SecondaryHeader>
        <StyledNavigateBack backTitle="Back to search results" />
        <VerseHeader title={verseTitle} />
      </SecondaryHeader>
      {verse && (
        <VerseComponent
          renderVerse={renderVerse}
          verseTitle={verseTitle}
          verseText={verse?.text as string}
          chapterSlug={currentChapter?.slug as string}
          fontFamily={fontFamily}
          fontSize={fontSize}
          verse={verse}
          fetchPublicJournalsForVerse={fetchPublicJournalsForVerse}
          journals={verseJournals}
          isLoadingVerseJournals={isLoadingVerseJournals}
        />
      )}
    </Layout>
  );
};


export default Index;
