import type { AppThunk } from 'app/state/store';
import { actions } from 'modules/search/state/search.slice';
import * as api from '../utils/searchApiUtils';


export const searchVerses = (searchString: string, pageNumber = 1, limit = 100): AppThunk =>
  async (dispatch) => {
    dispatch(actions.searchForVersesStarted());
    try {
      const data = await api.searchVerses(searchString, pageNumber, limit);
      dispatch(actions.searchForVersesSucceeded(data));
    } catch (err) {
      if (err && err.response.data) {
        dispatch(actions.searchForVersesFailed(err.response.data));
      }
      throw err;
    }
  };

export const loadMoreVerses = (searchString: string, pageNumber = 1, limit = 10): AppThunk =>
  async (dispatch) => {
    dispatch(actions.loadMoreVersesStarted());
    try {
      const data = await api.loadMoreVerses(searchString, pageNumber, limit);
      dispatch(actions.loadMoreVersesSucceeded(data));
    } catch (err) {
      if (err && err.response.data) {
        dispatch(actions.loadMoreVersesFailed());
      }
      throw err;
    }
  };

export const fetchOneVerse = (chapterSlug: string, verseNumber: number): AppThunk =>
  async (dispatch) => {
    dispatch(actions.fetchOneVerseStarted());
    try {
      const data = await api.fetchOneVerse(chapterSlug, verseNumber);
      dispatch(actions.fetchOneVerseSucceeded(data));
    } catch (err) {
      if (err && err.response.data) {
        dispatch(actions.fetchOneVerseFailed(err.response.data));
      }
      throw err;
    }
  };
