import styled from 'styled-components';
import { isDesktopOrLarger } from 'app/globalStyles/screens';

export const VerseContainer = styled.div`
`;

export const VerseNumber = styled.small`
  font-weight: 500;
  font-size: 10px;
  cursor: pointer;
  color: ${({ theme: { colors } }) => colors.colorText};
  opacity: 0.8;
  position: absolute;
  top: 50%;
  left: -20px;
  ${isDesktopOrLarger`
    left: -60px;
  `}
`;

export const VerseText = styled.p<{
  isUnderlined: boolean;
  isVerseTargeted: boolean;
}>`
  position: ${({ isVerseTargeted }) => isVerseTargeted && 'relative'};
  text-decoration: currentColor solid
    ${({ isUnderlined }) => isUnderlined && 'underline'};
`;

export const Popover = styled.div<any>`
  padding: 6px 10px;
  background: ${({ theme: { colors } }) => colors.colorBackground};
  border-radius: 4px;
  position: absolute;
  left: ${({ positionX, isFullVerseSelected }) =>
    `${isFullVerseSelected ? 0 : positionX}px`};
  top: ${({ positionY }) => `${positionY - 8}px`};
  z-index: 50;
  transform: ${({ isFullVerseSelected }) =>
    (isFullVerseSelected ? 'translateY(-100%)' : 'translate(-50%, -100%)')};
  display: flex;
  align-items: center;
  border: 1px solid ${({ theme: { colors } }) => colors.colorBorder};
  transition: visibility 0.5s, color 0.5s, background-color 0.5s, width 0.5s,
    ease-in;
  &:after {
    content: '';
    position: absolute;
    left: 50%;
    bottom: -8px;
    transform: translateX(-50%);
    width: 0;
    height: 0;
    background: transparent;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-top: 8px solid ${({ theme: { colors } }) => colors.colorBorder};
  }
`;
