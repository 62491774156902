import styled, { css } from 'styled-components';
import { Search } from 'styled-icons/boxicons-regular';
import {
  isTabletOrSmaller,
  isTabletOrLarger,
} from 'app/globalStyles/screens';

export const SearchIcon = styled(Search)`
  color: ${({ theme: { colors } }) => colors.colorPrimary3};
  cursor: pointer;
  margin-top: 1px;
  margin-right: 0;
  width: 20px;
  &:hover {
    opacity: .7;
  };
  ${isTabletOrLarger`
    margin-right: 12px;
    width: 14px;
  `}
`;

export const StyledSearchBar = styled.div`
  width: 38%;
  align-items: center;
  justify-content: center;
  border: 1px solid ${({ theme: { colors } }) => colors.colorBorder};
  height: 30px;
  border-radius: 3px;
  position: absolute;
  left: 31%;
  right: 31%;
  display: none;
  ${isTabletOrSmaller`
    width: 30%;
  `}
  ${isTabletOrLarger(css`
    display: flex;
  `)}
  span {
    font-size: 1.4rem;
    color: ${({ theme: { colors } }) => colors.inputPlaceHolderColor};
  }
`;
