import React from 'react';
import styled from 'styled-components';
import { Check } from 'styled-icons/entypo';
import { StyledEditorFooter } from 'modules/thoughts/editor/components/Editor.styles';

const DoneIcon = styled(Check)`
  width: 18px;
  margin-left: 5px;
  margin-bottom: 5px;
  color: ${({ theme: { colors } }) => colors.colorSuccess};
`;

export interface Props {
  wordsCount: number;
  isUpdatingThought?: boolean;
  isSavingThought?: boolean;
}

const EditorFooter = ({
  wordsCount,
  isSavingThought,
  isUpdatingThought,
}: Props) => {
  const isSaving = isSavingThought || isUpdatingThought;
  return (
    <StyledEditorFooter>
      <div>| Your thoughts to your self!</div>
      <div>
        <DoneIcon />
        <span>
          {isSaving
            ? 'Saving...'
            : `AutoSaved ${wordsCount} ${wordsCount === 1 ? 'word' : 'words'}`}
        </span>
      </div>
    </StyledEditorFooter>
  );
};

export default EditorFooter;
