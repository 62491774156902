import type { AppThunk } from 'app/state/store';
import { apiCall } from 'common/utils/api-helpers';

import type { AxiosResponse } from 'axios';
import { actions } from 'modules/thoughts/editor/state/editor.slice';

export const fetchEditorThought = (slug: string): AppThunk =>
  async (dispatch): Promise<void | AxiosResponse<any>> => {
    try {
      dispatch(actions.fetchEditorThoughtStarted());
      const { data } = await apiCall(`thoughts/${slug}/edit`, 'get', null);
      dispatch(actions.fetchEditorThoughtSucceeded(data));
      return data;
    } catch (err) {
      if (err && err.response.data) {
        dispatch(actions.fetchEditorThoughtFailed(err.response.data));
      }
      throw err;
    }
  };
