import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { actions as authActions } from 'modules/auth/state/auth.slice';
import { BOOKMARKS, READING } from 'modules/reading/constants';
// eslint-disable-next-line import/no-cycle
import { actions as bookmarkActions } from 'modules/bookmarks/state/bookmarks.slice';
import type { Bookmark } from 'modules/reading/utils/api/bookmarksApiUtils';
import type { RootState } from 'app/state/store';

export type State = {
  isBookmarking: boolean;
  currentChapterBookmark: Bookmark | null;
};

export const initialBookmarksState: State = {
  isBookmarking: false,
  currentChapterBookmark: null,
};

const bookmarksSlice = createSlice({
  name: BOOKMARKS,
  initialState: initialBookmarksState,
  reducers: {
    saveBookmarkStarted: (state) => {
      state.isBookmarking = true;
    },

    saveBookmarkSucceeded: (state, action: PayloadAction<Bookmark>) => {
      state.isBookmarking = false;
      state.currentChapterBookmark = action.payload;
    },

    saveBookmarkFailed: (state) => {
      state.isBookmarking = false;
    },

    fetchChapterBookmarkStarted: (state) => ({
      ...state,
      currentChapterBookmark: null,
    }),

    fetchChapterBookmarkSucceeded: (state, action: PayloadAction<Bookmark>) => ({
      ...state,
      currentChapterBookmark: action.payload,
    }),

    fetchChapterBookmarkFailed: (state) => ({
      ...state,
      currentChapterBookmark: null,
    }),
  },

  extraReducers: (builder) =>
    builder
      .addCase(bookmarkActions.deleteBookmarkStarted, (state) => state)
      .addCase(bookmarkActions.deleteBookmarkSucceeded, (state) => ({
        ...state,
        currentChapterBookmark: null
      }))
      .addCase(bookmarkActions.deleteBookmarkFailed, (state) => state)
      .addCase(authActions.logoutUser, () => initialBookmarksState),
});

export const {
  actions
} = bookmarksSlice;

export const bookmarksReducer = bookmarksSlice.reducer;
export const selectCurrentChapterBookmark = (
  state: RootState,
): State => state[READING][BOOKMARKS];
