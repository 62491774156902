import React, { useState } from 'react';
import styled from 'styled-components';
import { AuthModal } from 'modules/auth';
import { LoginButton } from 'modules/auth/components/AuthButtons';
import {
  AlreadyHave,
  AlreadyHaveLink,
  AlreadyHaveText,
} from 'modules/auth/components/__styles__/AuthComponent.styles';
import { Content } from 'modules/reading/components/__styles__/ChapterLock.styles';
import { SubTitle, Title } from './__styles__/AuthPrompt.styles';

const Login = styled<any>(LoginButton)`
  width: unset;
  padding: 15px 100px;
`;

export interface Props {
  title?: string;
  subTitle?: string;
}

const defaultTitle = 'Log in to read the rest of this chapter';

const ChapterAuthLock = ({
  title = defaultTitle,
  subTitle = '',
}: Props) => {
  const [visibleAuthModal, setVisibleAuthModal] = useState<
    'signup' | 'signin' | undefined
  >(undefined);
  const showSignIn = () => setVisibleAuthModal('signin');
  const showSignUp = () => setVisibleAuthModal('signup');
  return (
    <Content>
      <Title>{title}</Title>
      {subTitle && <SubTitle>{subTitle}</SubTitle>}
      <Login onClick={showSignIn}>Log In</Login>
      <AlreadyHave css="margin-top: 10px">
        <AlreadyHaveText>Don't have an account?</AlreadyHaveText>
        <AlreadyHaveLink type="button" onClick={showSignUp}>
          Sign Up
        </AlreadyHaveLink>
      </AlreadyHave>
      {!!visibleAuthModal && (
        <AuthModal
          closeModal={() => setVisibleAuthModal(undefined)}
          visibleAuthModal={visibleAuthModal}
        />
      )}
    </Content>
  );
};

export default ChapterAuthLock;
