/* eslint-disable react/no-array-index-key */
import React, { useState, useEffect } from 'react';
import { routeAs, routeTo } from 'common/utils/routes';
import cookie from 'js-cookie';
import type { NextRouter } from 'next/router';
import { useAppDispatch } from 'common/hooks/state-hooks';
import { withRouter } from 'next/router';
import {
  getHighlightCoordinates,
  getSelectionCoordinates,
} from 'modules/reading/utils/selectionUtils';
import VerseActions from 'modules/reading/components/VerseActions';
import {
  VerseContainer,
  VerseNumber,
  VerseText,
} from 'modules/reading/components/__styles__/ChapterVerse.styles';
import { getHighlightSections } from 'modules/reading/utils/highlightUtils';
import type { removeHighlight, addHighlight, updateHighlight } from 'modules/reading/state/actions/highlights.actions';

import NewThoughtEditorModal from 'modules/thoughts/editor/components/NewThoughtEditorModal';
import {
  trackAddHighlight,
  trackChangeHighlightColor,
  trackShareTextOnSocial,
} from 'modules/reading/utils/analytics';
import { Marker } from 'modules/highlights/components/HighlightMarker';
import type { Book } from 'modules/books/utils/booksApiUtils';
import type { Chapter, Verse } from 'modules/reading/utils/api/chapterApiUtils';
import type { Highlight } from 'modules/reading/utils/api/highlightsApiUtils';
import { notifyEmailVerify, notifyUserMembershipOnHighlights } from './utils';

export interface Props {
  currentBook: Book;
  currentChapter: Chapter;
  verse: Verse;
  activeVerse: Verse | null;
  setActiveVerse: (verse: Verse | null) => void;
  isMemberOrFreeTrialUser: boolean;
  isEmailNotVerified: boolean;
  isAuthenticated: boolean;
  highlights: Highlight[];
  addHighlight: typeof addHighlight;
  updateHighlight: typeof updateHighlight;
  removeHighlight: typeof removeHighlight;
  openBookmarkModal: () => void;
  router: NextRouter;
  shouldShowVerseNumbers: boolean;
}

interface State {
  selectedHighlight: Highlight | null;
  startOffset?: number;
  endOffset?: number;
  selectedText: string;
  isFullVerseSelected: boolean;
  positionX?: number;
  positionY?: number;
  isPopoverOpen: boolean;
  hasSelection: boolean;
}

const initialState = {
  selectedHighlight: null,
  startOffset: undefined,
  endOffset: undefined,
  selectedText: '',
  isFullVerseSelected: false,
  positionX: undefined,
  positionY: undefined,
  isPopoverOpen: false,
  hasSelection: false,
};

const ChapterVerse = (props: Props) => {
  const {
    verse,
    activeVerse,
    currentBook,
    currentChapter,
    isEmailNotVerified,
    openBookmarkModal,
    setActiveVerse,
    isAuthenticated,
    isMemberOrFreeTrialUser,
    router,
    highlights,
    addHighlight,
    updateHighlight,
    removeHighlight,
    shouldShowVerseNumbers,
  } = props;
  const dispatch = useAppDispatch();
  // TODO: Use useReducer to handle this state
  const [
    {
      selectedHighlight,
      startOffset,
      endOffset,
      selectedText,
      isFullVerseSelected,
      positionX,
      positionY,
      isPopoverOpen,
      hasSelection,
    },
    setState,
  ] = useState<State>(initialState);
  const [isNoteModalOpen, setNoteModalOpen] = useState(false);

  const isVerseTargeted = activeVerse?.id === verse.id;
  // TODO: This should be taken up/out of here.
  const isActionRestricted = (() => {
    if (!cookie.get('token')) {
      return false;
    }
    return !isMemberOrFreeTrialUser;
  })();

  const verseTextRef = React.useRef<any>(null);
  const containerRef = React.useRef<any>(null);

  const sharableQuote = `"${selectedText}" \n– ${
    currentChapter.author || currentBook?.author
  } \n`;

  const sharableUrl = `${window.location.origin}/read/${currentChapter.slug}/verses/${activeVerse?.number}`;

  const resetState = () => {
    setState(() => ({ ...initialState }));
    setActiveVerse(null);
  };

  const processSelection = (): void => {
    const selection = window.getSelection();

    if (hasSelection) {
      resetState();
    }

    if (!selection || !selection.rangeCount || !selection.toString()) {
      resetState();
      return;
    }

    const {
      startOffset,
      endOffset,
      positionX,
      positionY,
    } = getSelectionCoordinates(selection, verseTextRef.current);

    setActiveVerse(verse);
    setState((prevState) => ({
      ...prevState,
      hasSelection: true,
      startOffset,
      endOffset,
      selectedText: selection.toString(),
      positionX,
      positionY,
      isPopoverOpen: true,
      isFullVerseSelected: false,
      selectedHighlight: null,
    }));
  };

  const handlePointerUp = (event: {
    target: {
      removeEventListener: (arg0: string, arg1: (event) => void) => void;
    };
  }) => {
    event.target.removeEventListener('pointerup', handlePointerUp);
    processSelection();
  };

  const handlePointerDown = (event: {
    target: {
      addEventListener: (arg0: string, arg1: (event) => void) => void;
    };
  }) => {
    resetState();
    event.target.addEventListener('pointerup', handlePointerUp);
  };

  const handleDocumentClick = (event) => {
    if (
      containerRef
      && containerRef.current
      && !containerRef.current.contains(event.target)
    ) {
      resetState();
    }
  };

  useEffect(() => {
    if (verseTextRef && verseTextRef.current) {
      /* we use pointerup instead of mouse down so it works on mobile */
      verseTextRef.current.addEventListener('pointerdown', handlePointerDown);
    }
    return () => {
      if (verseTextRef && verseTextRef.current) {
        verseTextRef.current.removeEventListener(
          'pointerdown',
          handlePointerDown,
        );
      }
    };
  });

  useEffect(() => {
    if (isVerseTargeted) {
      document.addEventListener('click', handleDocumentClick);
    }

    return () => {
      if (isVerseTargeted) {
        document.removeEventListener('click', handleDocumentClick);
      }
    };
  }, [isVerseTargeted]);

  const toggleSelectHighlight = (event, highlight: Highlight) => {
    event.preventDefault();
    const isSelectedHighlight = selectedHighlight?.id === highlight.id;

    if (isSelectedHighlight) {
      resetState();
    } else {
      const { positionX, positionY } = getHighlightCoordinates(
        verseTextRef.current,
        event,
        highlight,
      );
      setActiveVerse(verse);
      setState((prevState) => ({
        ...prevState,
        selectedHighlight: highlight,
        startOffset: highlight.startOffset,
        endOffset: highlight.endOffset,
        selectedText: verse.text.slice(
          highlight.startOffset,
          highlight.endOffset,
        ),
        positionX,
        positionY,
        isPopoverOpen: true,
        hasSelection: true,
      }));
    }
  };

  const toggleSelectFullVerse = (event) => {
    event.preventDefault();
    // If there is already a selection, we want to reset and close the modal.
    if (isVerseTargeted) {
      resetState();
    } else {
      setActiveVerse(verse);

      // if there's a highlight start to end of the verse,
      // and the verse is currently not targeted,
      // then we're selecting the highlight, and should be able to remove it.
      const fullVerseHighlight = highlights?.find(
        (h) => h.startOffset === 0 && h.endOffset === verse.text.length - 1,
      ) || null;

      setState(
        (prevState): State => ({
          ...prevState,
          isPopoverOpen: true,
          startOffset: 0,
          endOffset: verse.text.length - 1,
          selectedText: verse.text,
          selectedHighlight: fullVerseHighlight,
          isFullVerseSelected: true,
        }),
      );
    }
  };

  const handleHighlightVerse = async (color: string) => {
    if (isEmailNotVerified) {
      notifyEmailVerify();
    } else if (!isMemberOrFreeTrialUser) {
      notifyUserMembershipOnHighlights();
    } else if (startOffset !== undefined && endOffset) {
      dispatch(addHighlight({
        color,
        startOffset,
        endOffset,
        verseId: (activeVerse as Verse).id,
      }));
      if (currentBook && currentChapter && activeVerse) {
        trackAddHighlight({
          book_title: currentBook.title,
          book_author: currentBook.author,
          chapter_number: currentChapter.number,
          verse_number: activeVerse.number,
          color,
        });
      }
    }
    resetState();
  };

  const onUpdateHighlight = async (color: string) => {
    if (currentBook && currentChapter && activeVerse) {
      if (selectedHighlight) {
        dispatch(updateHighlight({
          highlightId: selectedHighlight.id as number,
          color,
          verseId: activeVerse.id,
        }));
        trackChangeHighlightColor({
          book_title: currentBook.title,
          book_author: currentBook.author,
          chapter_number: currentChapter.number,
          verse_number: activeVerse.number,
          color,
        });
      }
    }
    resetState();
  };

  const handleRemoveHighlight = async () => {
    if (selectedHighlight) {
      dispatch(removeHighlight(selectedHighlight.id as number));
    }
    resetState();
  };

  const handleShare = (networkName: string) => {
    if (currentBook && currentChapter && activeVerse) {
      trackShareTextOnSocial({
        book_title: currentBook.title,
        book_author: currentBook.author,
        chapter_number: currentChapter.number,
        verse_number: activeVerse.number,
        network_name: networkName,
      });
    }
  };

  const goToVerse = () => {
    router.push(
      routeTo.verse,
      routeAs.verse(currentChapter?.slug as string, activeVerse?.number as number),
      { shallow: true },
    );
  };

  const handleOpenBookmarkModal = () => {
    if (isEmailNotVerified) {
      notifyEmailVerify();
    } else {
      openBookmarkModal();
    }
  };

  const closeNoteModal = () => {
    setNoteModalOpen(false);
    resetState();
  };

  const openNoteModal = () => {
    setNoteModalOpen(true);
  };

  return (
    <VerseContainer ref={containerRef as any}>
      {isPopoverOpen && isVerseTargeted && (
        <VerseActions
          popOverPosition={{ x: positionX, y: positionY }}
          sharableUrl={sharableUrl}
          sharableQuote={sharableQuote}
          isActionRestricted={isActionRestricted}
          isAuthenticated={isAuthenticated}
          selectedHighlight={selectedHighlight}
          isFullVerseSelected={isFullVerseSelected}
          openNoteModal={openNoteModal}
          closeModal={resetState}
          highlightVerse={handleHighlightVerse}
          onUpdateHighlight={onUpdateHighlight}
          removeHighlight={handleRemoveHighlight}
          goToVerse={goToVerse}
          handleOpenBookmarkModal={handleOpenBookmarkModal}
          handleShare={handleShare}
        />
      )}
      {isNoteModalOpen && startOffset !== undefined && endOffset !== undefined && (
        <NewThoughtEditorModal
          linkedHighlightProps={{
            currentBook,
            currentChapter,
            verse,
            selectedText,
            selectedTextOffsets: { startOffset, endOffset },
            selectedHighlight,
          }}
          closeModal={closeNoteModal}
        />
      )}
      {shouldShowVerseNumbers && (
        <VerseNumber onClick={toggleSelectFullVerse}>
          {`v${verse.number}`}
        </VerseNumber>
      )}
      <VerseText
        ref={verseTextRef as any}
        isUnderlined={isVerseTargeted && isFullVerseSelected}
        isVerseTargeted={isVerseTargeted}
      >
        {highlights && highlights.length > 0
          ? getHighlightSections(verse.text, highlights).map(
            (range, index) => {
              if (range.highlight) {
                const { highlight } = range;
                const handleSelect = (event) => {
                  // todo: this casting is not correct.
                  toggleSelectHighlight(event, highlight as Highlight);
                };
                return (
                  <Marker
                    key={`${highlight.id}_${index}`}
                    color={highlight.color}
                    isSelected={selectedHighlight?.id === highlight.id}
                    selectHighlight={handleSelect}
                  >
                    {range.text}
                  </Marker>
                );
              }
              return range.text;
            },
          )
          : verse.text}
      </VerseText>
    </VerseContainer>
  );
};

export default withRouter(ChapterVerse);
