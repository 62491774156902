import { PageLoader } from 'common/components/Loader';
import DropDownMenu from 'common/components/DropDownMenu';
import { deleteJournal } from 'modules/thoughts/journals/state/journals.actions';
import React, { useState } from 'react';
import type { NextRouter } from 'next/router';
import { withRouter } from 'next/router';
import { ShareLine } from 'static/assets';
import { ModeComment } from 'styled-icons/material-outlined';
import { routeAs } from 'common/utils/routes';
import Share from 'common/components/Share';
import { useAppDispatch } from 'common/hooks/state-hooks';
import Journals from 'modules/thoughts/journals/components/Journals';
import type { Journal } from 'modules/thoughts/journals/utils/journalsApiUtils';
import {
  StyledVerse,
  Heading,
  HR,
  Text,
  ActionOptions,
  Actions,
  shareIconStyles,
  Arrow,
  Action,
  optionStyles,
} from './__styles__/Verse.styles';
import type { Verse } from '../utils/api/chapterApiUtils';

export interface Props {
  verseTitle: string;
  chapterSlug: string;
  verseText: string;
  router: NextRouter;
  fontSize: number;
  fontFamily: string;
  verse: Verse;
  fetchPublicJournalsForVerse: any;
  isLoadingVerseJournals: boolean;
  journals: Journal[];
  renderVerse: () => JSX.Element | null;
}

export const VerseHeader = ({ title }: { title: string }): JSX.Element => (
  <Heading>{title}</Heading>
);

const VerseComponent = (props: Props) => {
  const {
    verseTitle,
    verseText,
    chapterSlug,
    fontFamily,
    fontSize,
    verse,
    fetchPublicJournalsForVerse,
    isLoadingVerseJournals,
    journals = [],
    router,
    renderVerse,
  } = props;
  
  const dispatch = useAppDispatch();
  const shareUrl = `${window.location.origin}${router.asPath}`;
  const [isSharedThoughtsVisible, toggleSharedThoughts] = useState(false);

  const fetchRelatedPublicVerses = async () => {
    if (!isSharedThoughtsVisible) {
      toggleSharedThoughts(true);
      if (verse) {
        await dispatch(fetchPublicJournalsForVerse(verse.id));
      }
    } else {
      toggleSharedThoughts(false);
    }
  };

  const renderJournals = () => {
    if (isLoadingVerseJournals) {
      return <PageLoader />;
    }

    if (!journals.length) {
      return (
        <div css="width: 100%; padding: 10px; text-align: center;font-size: 1.5rem;">
          No shared thoughts
        </div>
      );
    }

    return (
      <Journals
        myJournals={journals}
        user={{} as any}
        deleteJournal={deleteJournal}
        isRecentJournals
      />
    );
  };

  return (
    <StyledVerse>
      <Text fontFamily={fontFamily} fontSize={fontSize}>
        {renderVerse()}
      </Text>

      <Actions css="padding-bottom: 10px">
        <div
          role="button"
          tabIndex={0}
          css="font-size: 14px; cursor: pointer; display: inline; outline: none"
          onClick={fetchRelatedPublicVerses}
        >
          <ModeComment css={shareIconStyles} />
          <span>Shared thoughts</span>
        </div>
        <DropDownMenu>
          <ActionOptions>
            <Share
              title={verseTitle}
              quote={verseText}
              shareUrl={shareUrl}
              buttonStyle={optionStyles}
            >
              <ShareLine css={shareIconStyles} />
              <span>Share</span>
            </Share>
            <HR />
            <Action href={routeAs.chapter(chapterSlug)}>
              <>
                <Arrow css={shareIconStyles} />
                <span>Chapter</span>
              </>
            </Action>
          </ActionOptions>
        </DropDownMenu>
      </Actions>

      {isSharedThoughtsVisible && renderJournals()}
    </StyledVerse>
  );
};

export default withRouter(VerseComponent);
