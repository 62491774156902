import React, { useState } from 'react';
import Modal from 'common/components/Modal';
import * as S from 'modules/auth/components/__styles__/AuthComponent.styles';
import AuthComponentContainer from 'modules/auth/components/AuthComponentContainer';

type Props = {
  closeModal: () => void;
  visibleAuthModal: 'signin' | 'signup' | undefined;
};

const AuthModal = (props: Props): JSX.Element => {
  const { closeModal, visibleAuthModal } = props;
  const [authMode, setAuthMode] = useState<'signin' | 'signup' | undefined>(
    visibleAuthModal,
  );

  const goToSignIn = () => setAuthMode('signin');

  const goToSignUp = () => setAuthMode('signup');

  return (
    <Modal closeModal={closeModal} allowClose style={S.customModalStyles}>
      <AuthComponentContainer
        closeModal={closeModal}
        visibleAuthModal={authMode}
        goToSignIn={goToSignIn}
        goToSignUp={goToSignUp}
      />
    </Modal>
  );
};

export default AuthModal;
