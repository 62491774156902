import { persistReducer } from 'redux-persist';
import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage';
import { AUTH } from 'modules/auth/constants';
import type { AuthServiceResponse } from 'modules/auth/utils/authApiUtils';
import type { AuthResponse } from 'modules/auth/state/auth.actions';
import type { User } from 'modules/profile/utils/profileApiUtils';
import type { RootState } from 'app/state/store';

export interface State {
  user: User | null; // use sparingly. Defer to using user details from profile module.
  token: string;
  authError: string;
  isAuthenticating: boolean;
  isGoogleAuthenticating: boolean;
  isFacebookAuthenticating: boolean;
  sendingResetLink: boolean;
  resettingPassword: boolean;
  isConfirmingEmail: boolean;
}

export const initialState: State = {
  user: null,
  token: '',
  authError: '',
  isAuthenticating: false,
  isGoogleAuthenticating: false,
  isFacebookAuthenticating: false,
  sendingResetLink: false,
  resettingPassword: false,
  isConfirmingEmail: false,
};

const authSlice = createSlice({
  name: AUTH,
  initialState,
  reducers: {
    refreshAuthSucceeded: (state, action: PayloadAction<AuthServiceResponse>) => {
      const { user, token } = action.payload;
      return {
        ...state,
        token,
        user,
        isAuthenticating: false,
      };
    },
    signUpUserStarted: (state) => {
      state.isAuthenticating = true;
    },

    signUpUserSucceeded: (state, action: PayloadAction<AuthServiceResponse>) => {
      state.isAuthenticating = false;
      state.token = action.payload.token;
    },
    signUpUserFailed: (state, action: PayloadAction<{ error: string }>) => {
      state.isAuthenticating = false;
      state.authError = action.payload.error;
    },
    signInUserStarted: (state) => { state.isAuthenticating = true; },
    signInUserSucceeded: (state, action: PayloadAction<AuthServiceResponse>) => {
      const { user, token } = action.payload;
      return {
        ...state,
        token,
        user,
        isAuthenticating: false,
      };
    },
    signInUserFailed: (state, action: PayloadAction<{ error: string }>) => {
      const { error } = action.payload;
      return { ...state, authError: error, isAuthenticating: false };
    },
    googleAuthStarted: (state) => {
      state.isGoogleAuthenticating = true;
    },
    googleAuthSucceeded: (state, action: PayloadAction<AuthServiceResponse>) => {
      const { user, token } = action.payload;
      return {
        ...state,
        token,
        user,
        isGoogleAuthenticating: false,
      };
    },
    googleAuthFailed: (state, action: PayloadAction<{ error: string }>) => {
      state.isGoogleAuthenticating = false;
      state.authError = action.payload.error || 'Error Logging In';
    },
    facebookAuthStarted: (state) => {
      state.isFacebookAuthenticating = true;
    },
    facebookAuthSucceeded: (state, action: PayloadAction<AuthServiceResponse>) => {
      const { user, token } = action.payload;
      return {
        ...state,
        token,
        user,
        isFacebookAuthenticating: false,
      };
    },
    facebookAuthFailed: (state, action: PayloadAction<{ error: string }>) => {
      const { error } = action.payload;
      return {
        ...state,
        authError: error || 'Error Logging In',
        isFacebookAuthenticating: false,
      };
    },
    confirmEmailStarted: (state) => {
      state.isConfirmingEmail = true;
    },
    confirmEmailSucceeded: (state, action: PayloadAction<AuthResponse>) => {
      const { token } = action.payload;
      return {
        ...state,
        token,
        isConfirmingEmail: false
      };
    },
    confirmEmailFailed: (state, action: PayloadAction<{ error: string }>) => {
      state.isConfirmingEmail = false;
      state.authError = action.payload.error;
    },
    sendPasswordResetLinkStarted: (state) => {
      state.sendingResetLink = true;
    },
    sendPasswordResetLinkSucceeded: (state) => {
      state.sendingResetLink = false;
    },
    sendPasswordResetLinkFailed: (state, action: PayloadAction<{ error: string }>) => {
      state.sendingResetLink = false;
      state.authError = action.payload.error;
    },
    resetPasswordStarted: (state) => {
      state.resettingPassword = true;
    },
    resetPasswordSucceeded: (state) => {
      state.resettingPassword = false;
    },
    resetPasswordFailed: (state) => {
      state.resettingPassword = false;
    },
    resetAuthError: (state) => {
      state.authError = '';
      state.isAuthenticating = false;
    },
    setLoginUser: (state, action: PayloadAction<AuthResponse>) => {
      const { user, token } = action.payload;
      return {
        ...state,
        user,
        token,
      };
    },
    resendEmailVerificationStarted: () => initialState,
    resendEmailVerificationSucceeded: (state, action: PayloadAction<AuthServiceResponse>) => {
      state.isConfirmingEmail = false;
      state.user = action.payload.user;
    },
    resendEmailVerificationFailed: (state, action: PayloadAction<{ error: string }>) => {
      state.isAuthenticating = false;
      state.authError = action.payload.error;
    },
    logoutUser: () => initialState,
  },
});

export const { actions } = authSlice;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const { user, token, ...rest } = initialState;

const persistConfig = {
  key: AUTH,
  storage,
  blacklist: Object.keys(rest),
  whitelist: ['user', 'token'], // We're not blacklisting the user because we need it.
};

export const selectUser = (state: RootState): User | null =>
  state[AUTH].user;

export const selectIsEmailVerified = (state: RootState): boolean =>
  !!selectUser(state)?.emailVerified;

export const selectAuth = (state: RootState): State => state[AUTH];

export const authReducer = persistReducer(persistConfig, authSlice.reducer);
