import React from 'react';
import TextareaAutosize from 'react-textarea-autosize';
import { setFont } from 'app/globalStyles/variables';
import styled, { css } from 'styled-components';
import * as S from './Editor.styles';

const textAreaStyles = css`
  width: 100%;
  background: ${({ theme: { colors } }) => colors.colorBackground};
  color: ${({ theme: { colors } }) => colors.colorText};
  font: 400 1.8rem ${setFont.main};
  line-height: 32px;
  border: unset;
  outline: unset;
  resize: none;
`;

const Error = styled.p`
  color: ${({ theme: { colors } }) => colors.mainRed};
`;

export interface Props {
  title: string;
  text: string;
  placeholder?: string;
  titleError: string;
  textError: string;
  onTitleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onTextChange: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
  onEditorFocus: () => void;
}

export const Editor = ({
  title,
  text,
  titleError,
  textError,
  onTitleChange,
  onTextChange,
  onEditorFocus,
  placeholder,
}: Props) => (
  <S.Form onKeyUp={(e) => e.keyCode === 13 && null}>
    <S.FormGroup>
      <S.TitleEditor
        autoComplete="off"
        onChange={onTitleChange}
        type="text"
        name="title"
        placeholder="Title"
        defaultValue={title}
      />
      <p>{titleError}</p>
    </S.FormGroup>
    <S.RichEditorFormGroup>
      <TextareaAutosize
        css={textAreaStyles}
        placeholder={placeholder}
        minRows={5}
        value={text}
        onChange={onTextChange}
        autoFocus
        onFocus={onEditorFocus}
      />
      <Error>{textError}</Error>
    </S.RichEditorFormGroup>
  </S.Form>
);

export default Editor;
