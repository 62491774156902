import { AccordionItemState } from 'react-accessible-accordion';
import React from 'react';
import { Div, StyledCaretDown, StyledCaretUp } from 'common/components/styled-components';
import AccordionItemButton from 'modules/admin/components/AccordionItemButton/__styles__/AccordionItemButton.styles';

export interface Props {
    text: string;
}

export default ({ text }: Props) => (
  <AccordionItemButton>
    <Div>
      <h3>{text}</h3>
    </Div>
    <Div>
      <AccordionItemState>
        {({ expanded }) => (expanded ? <StyledCaretDown /> : <StyledCaretUp />)}
      </AccordionItemState>
    </Div>
  </AccordionItemButton>
);
