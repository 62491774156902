import type Stripe from 'stripe';
import Toast from 'common/components/Toast';

export const getPlanName = (planInterval?: Stripe.Price.Recurring.Interval) => {
  switch (planInterval) {
    case 'month':
      return 'Monthly';
    case 'year':
      return 'Yearly';
    default:
      return undefined;
  }
};

export const getDisplayedPrice = (unitAmount: number): string =>
  `$${unitAmount / 100}`;

export const notifyEmailVerify = () =>
  Toast({
    type: 'warning',
    message:
      'Email not verified. Please check your inbox and confirm your email.',
  });

export const notifySuccess = () =>
  Toast({
    type: 'success',
    message:
      'Thank you for Subscribing. You now have full access to Stoic Bible. Enjoy Reading.',
  });

export const notifyFreeTrialExpired = () =>
  Toast({
    type: 'warning',
    message:
      'Free trial expired. Please subscribe to a plan to continue.',
  });
