import {
  isDesktopOrLarger,
  isTabletOrLarger,
} from 'app/globalStyles/screens';
import { setFlex } from 'app/globalStyles/variables';
import { useRouter } from 'next/router';
import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import Toast from 'common/components/Toast';
import { routeTo, routeAs } from 'common/utils/routes';
import ConfirmDeleteModal from 'common/components/ConfirmDeleteModal';
import type { deleteJournal } from 'modules/thoughts/journals/state/journals.actions';
import { useAppDispatch } from 'common/hooks/state-hooks';
import type { User } from 'modules/profile/utils/profileApiUtils';
import JournalCardV2 from './JournalCardV2';
import type { Journal } from '../utils/journalsApiUtils';

const StyledJournals = styled.div`
  ${setFlex({ x: 'flex-start', y: 'stretch' })}
  flex-wrap: wrap;
  ${isTabletOrLarger(css`
    flex-direction: row;
  `)}
  ${isDesktopOrLarger(css`
    flex-wrap: wrap;
  `)}
`;

export interface Props {
  myJournals: Journal[];
  user: User | null;
  deleteJournal: typeof deleteJournal;
  isRecentJournals?: boolean;
}

const JournalsV2 = ({
  myJournals,
  user,
  deleteJournal,
  isRecentJournals = false,
}: Props): JSX.Element => {
  const router = useRouter();
  const [selectedJournal, setSelectedJournal] = useState<Journal | null>(null);
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const dispatch = useAppDispatch();

  const closeDeleteModal = () => {
    setDeleteModalVisible(false);
  };

  const goToJournal = (journal: Journal) => {
    if (user) {
      router.push(
        routeTo.journal,
        routeAs.journal(user.username, journal.slug as string),
        {
          shallow: true,
        },
      );
    }
  };

  const handleDelete = async () => {
    const res: any = await dispatch(deleteJournal(selectedJournal?.id as number));
    if (res) {
      Toast({
        type: 'success',
        message: 'Journal deleted successfully!',
      });
    } else {
      Toast({
        type: 'error',
        message: 'Delete Error!',
      });
    }

    closeDeleteModal();
  };

  const openDeleteModal = (
    e: { stopPropagation: () => void },
    journal: Journal,
  ) => {
    e.stopPropagation();
    setSelectedJournal(journal);
    setDeleteModalVisible(true);
  };

  const takeToEditor = (
    e: { stopPropagation: () => void },
    journal: Journal,
  ) => {
    e.stopPropagation();
    router.push(routeTo.editJournal, routeAs.editJournal(journal.slug as string));
  };

  return (
    <>
      {deleteModalVisible && (
        <ConfirmDeleteModal
          onConfirm={handleDelete}
          closeModal={closeDeleteModal}
        />
      )}
      <StyledJournals>
        {myJournals.map((journal: Journal) => (
          <JournalCardV2
            key={journal.slug}
            isRecentJournal={isRecentJournals}
            journal={journal}
            goToJournal={goToJournal}
            takeToEditor={takeToEditor}
            openDeleteModal={openDeleteModal}
          />
        ))}
      </StyledJournals>
    </>
  );
};

export default JournalsV2;
