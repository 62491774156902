import React, { useState } from 'react';
import { css } from 'styled-components';
import { resetPasswordAction } from 'modules/auth/state/auth.actions';
import Loader from 'common/components/Loader';
import { useAppDispatch, useAppSelector } from 'common/hooks/state-hooks';
import { selectAuth } from 'modules/auth/state/auth.slice';
import * as S from '../components/__styles__/AuthComponent.styles';
import { validatePass } from '../utils/validateAuth';

interface Props {
  passwordRestToken: string;
}

const ResetPasswordPage = (props: Props) => {
  const [password, setPassword] = useState('');
  const [confPassword, setConfPassword] = useState('');
  const [errors, setError] = useState('');

  const dispatch = useAppDispatch();
  const { resettingPassword } = useAppSelector(selectAuth);

  const handleChange = (e, name: string): void => {
    if (name === 'password') {
      setPassword(e.target.value);
    } else {
      setConfPassword(e.target.value);
    }
  };

  const handleSubmit = (e): void => {
    e.preventDefault();
    const { errors } = validatePass(password, confPassword);
    if (errors.password.length > 0) {
      setError(errors.password);
    }
    if (password && password === confPassword) {
      dispatch(resetPasswordAction(props.passwordRestToken, password));
    }
  };

  return (
    <S.AuthComponent>
      <S.Heading
        css={css`
          margin-bottom: 50px;
        `}
      >
        Reset your password
      </S.Heading>
      <S.Form>
        {errors && <S.ErrorMsg>{errors}</S.ErrorMsg>}
        <S.FormGroup className="form-group">
          <S.InputLabel htmlFor="password">
            Enter your new password
          </S.InputLabel>
          <S.InputField
            type="password"
            onChange={(e) => handleChange(e, 'password')}
            name="password"
            placeholder="Enter new Password"
          />
        </S.FormGroup>
        <S.FormGroup className="form-group">
          <S.InputField
            type="password"
            onChange={(e) => handleChange(e, 'confPassword')}
            name="confPassword"
            placeholder="Confirm Password"
          />
        </S.FormGroup>
        <S.SubmitButton
          type="submit"
          id="submit"
          onClick={handleSubmit}
          isAuthenticating={resettingPassword}
          disabled={resettingPassword}
        >
          {resettingPassword ? (
            'Changing...'
          ) : (
            <S.SubmitButtonText>Change Password</S.SubmitButtonText>
          )}
          {resettingPassword && (
            <Loader
              size="2.5rem"
              style={{
                borderTop: '0.2rem solid #fff',
                position: 'absolute',
                right: '1rem',
              }}
            />
          )}
        </S.SubmitButton>
      </S.Form>
    </S.AuthComponent>
  );
};

export default ResetPasswordPage;
