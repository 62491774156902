import Share from 'common/components/Share';
import {
  Action,
  actionButtonStyles,
} from 'modules/reading/components/__styles__/VerseActions.styles';
import React from 'react';
import { BookmarksFill, WriteFill, ShareFill } from 'static/assets';
import styled, { css } from 'styled-components';
import { Popover } from 'modules/reading/components/__styles__/ChapterVerse.styles';
import { HighlighterV2 } from 'modules/reading/components/Highlighter';

const styles = {
  iconStyles: css<{ disabled?: boolean }>`
    width: 20px;
    height: 20px;
    path {
      fill: ${({ theme: { colors } }) => colors.colorText};
      stroke: ${({ theme: { colors } }) => colors.colorText};
    }
  `,
};

const BookmarkIcon = styled<any>(BookmarksFill)`
  ${styles.iconStyles};
`;

const NoteIcon = styled<any>(WriteFill)`
  ${styles.iconStyles};
`;

const ShareIcon = styled<any>(ShareFill)`
  ${styles.iconStyles};
`;

interface Props {
  sharableQuote: string;
  sharableUrl: string;
  handleShare: (networkName: string) => void;
  isHighlightSelected: boolean;
  selectedHighlightColor?: string;
  isFullVerseSelected: boolean;
  openNoteModal: () => void;
  openBookmarkModal: () => void;
  highlightVerse: (color: string) => void;
  onUpdateHighlight: (color: string) => void;
  removeHighlight: () => void;
  goToVerse: () => void;
  isActionRestricted: boolean;
  popOverPosition: { x?: number; y?: number };
  selectionCordX?: number;
  selectionCordY?: number;
}

const VerseActionsTooltip = (props: Props): JSX.Element => {
  const {
    sharableQuote,
    sharableUrl,
    handleShare,
    isHighlightSelected,
    selectedHighlightColor,
    isFullVerseSelected,
    openNoteModal,
    openBookmarkModal,
    highlightVerse,
    onUpdateHighlight,
    removeHighlight,
    isActionRestricted,
    popOverPosition,
  } = props;

  return (
    <Popover
      positionX={popOverPosition.x}
      positionY={popOverPosition.y}
      isFullVerseSelected={isFullVerseSelected}
      role="presentation"
    >
      <HighlighterV2
        isHighlightSelected={isHighlightSelected}
        selectedHighlightColor={selectedHighlightColor}
        highlightVerse={highlightVerse}
        onUpdateHighlight={onUpdateHighlight}
        removeHighlight={removeHighlight}
      />
      <Action
        disabled={isActionRestricted}
        type="button"
        onClick={openNoteModal}
      >
        <NoteIcon disabled={isActionRestricted} />
      </Action>
      {isFullVerseSelected && (
        <Action
          disabled={isActionRestricted}
          type="button"
          onClick={openBookmarkModal}
        >
          <BookmarkIcon disabled={isActionRestricted} />
        </Action>
      )}
      <Share
        title={sharableQuote}
        quote={sharableQuote}
        shareUrl={sharableUrl}
        buttonStyle={actionButtonStyles}
        handleShare={handleShare}
      >
        <ShareIcon />
      </Share>
    </Popover>
  );
};

export default VerseActionsTooltip;
