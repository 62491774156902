import Link from 'next/link';
import React from 'react';
import { Settings, Bookmarks, Feedback } from 'styled-icons/material';
import { Admin } from 'styled-icons/remix-fill';
import { LogOut } from 'styled-icons/entypo';
import { css } from 'styled-components';
import { HighlightFill, AllBooksFill } from 'static/assets';

import { routeTo, routeAs } from 'common/utils/routes';
import {
  StyledDropdown,
  DropDownItem,
} from './__styles__/ProfileDropdown.styles';

const styles = {
  iconStyles: css`
    width: 18px;
    color: ${({ theme: { colors } }) => colors.colorText};
    margin-right: 10px;
  `,
  svgIconStyle: css`
    width: 18px;
    height: 18px;
    color: ${({ theme: { colors } }) => colors.colorText};
    margin-right: 10px;
    path {
      fill: ${({ theme: { colors } }) => colors.colorText};
    }
  `,
};

export type Props = {
  handleLogout: () => void;
  closeProfileDropdown: () => void;
  showProfileDropdown: () => void;
  isActiveMember: boolean;
  username: string;
  isAdminUser: boolean;
};

const ProfileDropDown = ({
  isAdminUser,
  username,
  isActiveMember,
  handleLogout,
  closeProfileDropdown,
  showProfileDropdown,
}: Props) => (
  <StyledDropdown
    onMouseEnter={showProfileDropdown}
    onMouseLeave={closeProfileDropdown}
  >
    <DropDownItem>
      {isActiveMember ? (
        "You're a member"
      ) : (
        <Link href={routeTo.membership}>Become a member</Link>
      )}
    </DropDownItem>
    <DropDownItem>
      <Link href={routeTo.books} as={routeAs.books}>
        <AllBooksFill css={styles.svgIconStyle} />
        All Books
      </Link>
    </DropDownItem>
    <DropDownItem>
      <Link href={routeTo.highlights} as={routeAs.highlights(username)}>
        <HighlightFill css={styles.svgIconStyle} />
        Highlights
      </Link>
    </DropDownItem>
    <DropDownItem>
      <Link href={routeTo.bookmarks} as={routeAs.bookmarks(username)}>
        <Bookmarks css={styles.iconStyles} />
        Bookmarks
      </Link>
    </DropDownItem>
    <DropDownItem>
      <Link href={routeTo.feedback}>
        <Feedback css={styles.iconStyles} />
        Feedback
      </Link>
    </DropDownItem>
    <DropDownItem>
      <Link href={routeTo.settings} as={routeAs.settings(username)}>
        <Settings css={styles.iconStyles} />
        Settings
      </Link>
    </DropDownItem>
    {isAdminUser && (
      <DropDownItem>
        <Link href={routeTo.admin}>
          <Admin css={styles.iconStyles} />
          Admin
        </Link>
      </DropDownItem>
    )}
    <DropDownItem onClick={handleLogout} id="logout">
      <div>
        <LogOut css={styles.iconStyles} />
        Logout
      </div>
    </DropDownItem>
  </StyledDropdown>
);

export default ProfileDropDown;
