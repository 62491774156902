import React, { Component } from 'react';
import { connect } from 'react-redux';
import type { RootState } from 'app/state/store';
import InfiniteScrollList from 'common/components/InfiniteScrollList';
import { PageLoader } from 'common/components/Loader';
import Layout from 'common/page-layout/Layout';
import ProfileTabBar from 'modules/profile/components/ProfileTabBar';
import { BOOKMARKS } from 'modules/bookmarks/constants';
import { PROFILE } from 'modules/profile';
import {
  fetchBookmarksAction,
  loadMoreBookmarks,
  deleteBookmarkAction,
} from 'modules/bookmarks/state/bookmarks.actions';
import type { User } from 'modules/profile/utils/profileApiUtils';
import type { Bookmark } from 'modules/reading/utils/api/bookmarksApiUtils';
import type { GenericPagination } from 'common/types';
import BookmarksEmptyMsg from '../components/BookmarksEmptyMsg';
import BookMarks from '../components/BookMarks';

interface StateProps {
  bookmarks: Bookmark[];
  bookmarksLoaded: boolean;
  pagination: GenericPagination | null;
  loadingMore: boolean;
  user: User | null;
}

interface DispatchProps {
  fetchBookmarks: typeof fetchBookmarksAction;
  loadMoreBookmarks: typeof loadMoreBookmarks;
  deleteBookmark: typeof deleteBookmarkAction;
}

export type Props = StateProps & DispatchProps & { user: User | null };

export class BookmarksPage extends Component<Props> {
  async componentDidMount(): Promise<void> {
    const { bookmarksLoaded } = this.props;
    if (!bookmarksLoaded) {
      await this.props.fetchBookmarks(1);
    }
  }

  loadMore = async (): Promise<void> => {
    await this.props.loadMoreBookmarks(
      (this.props.pagination?.currentPage as number) + 1,
    );
  };

  renderBookmarks(): JSX.Element {
    const { bookmarks, bookmarksLoaded, pagination, deleteBookmark } = this.props;

    if (!bookmarksLoaded) {
      return <PageLoader />;
    }

    return (
      <>
        {!bookmarks.length ? (
          <BookmarksEmptyMsg />
        ) : (
          <InfiniteScrollList
            dataLength={bookmarks.length}
            loadMore={this.loadMore}
            pagination={pagination}
          >
            <BookMarks bookmarks={bookmarks} deleteBookmark={deleteBookmark} />
          </InfiniteScrollList>
        )}
      </>
    );
  }

  render(): JSX.Element {
    const { user } = this.props;
    return (
      <Layout allowFooter={false}>
        {user && <ProfileTabBar pageName="bookmarks" user={user} />}

        {this.renderBookmarks()}
      </Layout>
    );
  }
}

const mapStateToProps = ({
  [BOOKMARKS]: { bookmarks, bookmarksLoaded, pagination, loadingMore },
  [PROFILE]: { user },
}: RootState): StateProps => ({
  bookmarks,
  bookmarksLoaded,
  pagination,
  user,
  loadingMore,
});

export default connect<StateProps, DispatchProps>(mapStateToProps, {
  fetchBookmarks: fetchBookmarksAction,
  loadMoreBookmarks,
  deleteBookmark: deleteBookmarkAction,
})(BookmarksPage);
