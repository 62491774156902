import { logInUser } from 'common/utils/onesignal';
import React, { useEffect, useState } from 'react';
import moment from 'moment-timezone';
import type { GoogleLoginResponse } from 'react-google-login';
import ReactGA from 'react-ga';
import {
  signUpUserAction,
  signInUserAction,
  signInWithGoogleAction,
  signInWithFacebookAction,
  resetAuthErrorAction,
} from 'modules/auth/state/auth.actions';
import { useAppDispatch, useAppSelector } from 'common/hooks/state-hooks';
import { selectAuth } from 'modules/auth/state/auth.slice';
import AuthComponent from 'modules/auth/components/AuthComponent';
import {
  validateSignIn,
  validateSignUp,
} from 'modules/auth/utils/validateAuth';
import { trackAuth, trackShowAuthModal } from 'modules/auth/utils/analytics';
import { routeAs } from 'common/utils/routes';
import { useRouter } from 'next/router';
import type { AuthServiceResponse } from '../utils/authApiUtils';

interface OwnProps {
  closeModal?: () => void;
  goToSignUp: () => void;
  goToSignIn: () => void;
  visibleAuthModal: 'signin' | 'signup' | undefined;
}

interface State {
  name: string;
  email: string;
  password: string;
  errors: {
    name: string;
    email: string;
    password: string;
  };
}

const AuthPage = (props: OwnProps) => {
  const { visibleAuthModal } = props;

  const dispatch = useAppDispatch();
  const {
    authError,
    isAuthenticating,
    isGoogleAuthenticating,
    isFacebookAuthenticating
  } = useAppSelector(selectAuth);

  const [state, setState] = useState<State>({
    name: '',
    email: '',
    password: '',
    errors: {
      name: '',
      email: '',
      password: '',
    },
  });

  const router = useRouter();
  const { asPath } = router;
  const isSignUpView = visibleAuthModal === 'signup' || asPath === routeAs.signup;

  useEffect(() => {
    const isProd = window.location.hostname.search('stoicbible.com') !== -1;
    if (props.visibleAuthModal && isProd) {
      trackShowAuthModal(props.visibleAuthModal);
      ReactGA.modalview(`/${props.visibleAuthModal}`);
    }
  }, [props.visibleAuthModal]);

  const closeModal = () => {
    if (props.closeModal) {
      props.closeModal();
    }
  };

  const authSuccessCallback = (payload: AuthServiceResponse) => {
    logInUser(payload.user, payload.isNewUser);
    closeModal();
    trackAuth(payload);
  };

  const handleNameChange = (event) => {
    event.preventDefault();
    const name = event.target.value;
    setState((prevState) => ({ ...prevState, name }));
  };

  const handleEmailChange = (event) => {
    event.preventDefault();
    const email = event.target.value;
    setState((prevState) => ({ ...prevState, email }));
  };

  const handlePasswordChange = (event) => {
    event.preventDefault();
    const password = event.target.value;
    setState((prevState) => ({ ...prevState, password }));
  };

  const resetInputErrors = () => {
    setState((prevState) => ({
      ...prevState,
      errors: {
        name: '',
        email: '',
        password: '',
      },
    }));
    if (authError) {
      dispatch(resetAuthErrorAction());
    }
  };

  const goToSignIn = () => {
    props.goToSignIn();
    resetInputErrors();
  };

  const goToSignUp = () => {
    props.goToSignUp();
    resetInputErrors();
  };

  const handleSignUp = async (event) => {
    event.preventDefault();
    const { name, email, password } = state;

    const { errors, isValid } = validateSignUp({ name, email, password });
    if (!isValid) {
      setState((prevState) => ({ ...prevState, errors }));
    } else {
      const timezone = moment.tz.guess();
      dispatch(signUpUserAction(
        {
          name,
          email,
          password,
          timezone,
        },
        authSuccessCallback,
      ));
    }
  };

  const handleSignIn = async (event) => {
    event.preventDefault();
    const { email, password } = state;
    const { errors, isValid } = validateSignIn({ email, password });
    if (!isValid) {
      setState((prevState) => ({ ...prevState, errors }));
    } else {
      dispatch(signInUserAction(
        {
          email,
          password,
        },
        authSuccessCallback,
      ));
    }
  };

  const handleGoogleAuth = async (response: GoogleLoginResponse) => {
    const timezone = moment.tz.guess();
    dispatch(signInWithGoogleAction(
      {
        access_token: response.accessToken,
        timezone,
      },
      authSuccessCallback,
    ));
  };

  const handleFacebookAuth = async (response) => {
    const timezone = moment.tz.guess();
    dispatch(signInWithFacebookAction(
      {
        access_token: response.accessToken,
        timezone,
      },
      authSuccessCallback,
    ));
  };

  const onGoogleFailure = () => {};

  return (
    <AuthComponent
      name={state.name}
      email={state.email}
      password={state.password}
      isSignUpView={isSignUpView}
      errors={state.errors}
      isAuthenticating={isAuthenticating}
      isGoogleAuthenticating={isGoogleAuthenticating}
      isFacebookAuthenticating={isFacebookAuthenticating}
      goToSignIn={goToSignIn}
      goToSignUp={goToSignUp}
      handleFocus={resetInputErrors}
      handleSignUp={handleSignUp}
      handleSignIn={handleSignIn}
      onGoogleSuccess={handleGoogleAuth}
      onFaceBookSuccess={handleFacebookAuth}
      onGoogleFailure={onGoogleFailure}
      authError={authError}
      handleNameChange={handleNameChange}
      handleEmailChange={handleEmailChange}
      handlePasswordChange={handlePasswordChange}
    />
  );
};

export default AuthPage;
