import * as React from 'react';
import { useRouter } from 'next/router';
import { NoNotes } from 'static/assets';
import { routeTo } from 'common/utils/routes';
import * as S from 'modules/profile/components/styledComponents';

export const JournalsEmptyMessage = () => {
  const router = useRouter();
  return (
    <S.NoActivityPage>
      <NoNotes />
      <S.NoActivityText>
        You have no entry in your journal yet. Click the button below to start
        writing.
      </S.NoActivityText>
      <S.StartActivityButton
        type="button"
        onClick={() => router.push(routeTo.newJournal)}
      >
        Start Writing
      </S.StartActivityButton>
    </S.NoActivityPage>
  );
};

export default JournalsEmptyMessage;
