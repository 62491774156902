import React, { useState } from 'react';
import type { CSSProp } from 'styled-components';
import styled from 'styled-components';
import { setFlex, setZindex } from 'app/globalStyles/variables';
import { highlightColors } from 'modules/highlights/components/HighlightMarker';
import { CloseIcon } from 'static/assets';

const StyledHighlighter = styled.div`
  position: relative;
  button {
    outline: 0;
    border: unset;
  }
`;

const Popover = styled.div`
  position: absolute;
  bottom: 100%;
  right: auto;
  left: -15px;
  width: 180px;
  height: 60px;
  ${setFlex({ x: 'space-around', y: 'center' })};
  padding: 0 5px;
  border: 1px solid ${({ theme: { colors } }) => colors.colorBorder};
  border-bottom: unset;
  border-radius: 5px;
  background: ${({ theme: { colors } }) => colors.colorBackground};
  z-index: ${setZindex.high};
`;

const HighlightColorButton = styled.div<{ color: string }>`
  width: 28px;
  height: 28px;
  margin: 0 3px;
  background: ${({ color }) => color};
  cursor: pointer;
  ${setFlex()};
  border-radius: 50%;
  border: 1px solid ${({ theme: { colors } }) => colors.colorBorder};
`;

const DeleteHighlightIcon = styled<any>(CloseIcon)`
  width: 18px;
  height: 18px;
`;

export interface Props {
  children: JSX.Element | Array<JSX.Element>;
  buttonStyle?: CSSProp;
  isHighlightSelected: boolean;
  selectedHighlightColor?: string;
  highlightVerse: (color: string) => void;
  onUpdateHighlight: (color: string) => void;
  removeHighlight: () => void;
}

const Highlighter = ({
  children,
  buttonStyle,
  isHighlightSelected,
  selectedHighlightColor,
  highlightVerse,
  onUpdateHighlight,
  removeHighlight,
}: Props): JSX.Element => {
  const [popoverVisible, setPopoverVisible] = useState(false);

  const closePopover = () => {
    setPopoverVisible(false);
  };

  const openPopover = (e) => {
    e.stopPropagation();
    setPopoverVisible(!popoverVisible);
  };

  const handleHighlight = (color = 'yellow') => {
    if (!isHighlightSelected) {
      highlightVerse(color);
    } else if (selectedHighlightColor !== color) {
      onUpdateHighlight(color);
    } else {
      removeHighlight();
    }
  };

  return (
    <StyledHighlighter onMouseEnter={openPopover} onMouseLeave={closePopover}>
      {popoverVisible && (
        <Popover>
          {Object.entries(highlightColors).map(([color, value]) => (
            <HighlightColorButton
              key={color}
              color={value}
              role="button"
              onClick={() => handleHighlight(color)}
            >
              {selectedHighlightColor === color && <DeleteHighlightIcon />}
            </HighlightColorButton>
          ))}
        </Popover>
      )}
      <button
        css={buttonStyle}
        type="button"
        onClick={
          isHighlightSelected
            ? removeHighlight
            : () => handleHighlight('yellow')
        }
      >
        {children}
      </button>
    </StyledHighlighter>
  );
};

interface HighlighterV2Props {
  isHighlightSelected: boolean;
  selectedHighlightColor?: string;
  highlightVerse: (color: string) => void;
  onUpdateHighlight: (color: string) => void;
  removeHighlight: () => void;
}

const ColorButtons = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1,
  ${setFlex({ x: 'space-around', y: 'center' })};
  padding-right: 10px;
`;

export const HighlighterV2 = ({
  isHighlightSelected,
  selectedHighlightColor,
  highlightVerse,
  onUpdateHighlight,
  removeHighlight,
}: HighlighterV2Props): JSX.Element => {
  const handleHighlight = (color = 'yellow') => {
    if (!isHighlightSelected) {
      highlightVerse(color);
    } else if (selectedHighlightColor !== color) {
      onUpdateHighlight(color);
    } else {
      removeHighlight();
    }
  };

  return (
    <ColorButtons>
      {Object.entries(highlightColors).map(([color, value]) => (
        <HighlightColorButton
          key={color}
          color={value}
          role="button"
          onClick={() => handleHighlight(color)}
        >
          {selectedHighlightColor === color && <DeleteHighlightIcon />}
        </HighlightColorButton>
      ))}
    </ColorButtons>
  );
};

export default Highlighter;
