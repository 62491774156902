import React, { useState } from 'react';
import Modal from 'common/components/Modal';
import {
  bookmarkModalStyles,
  SelectVerseNumber,
  Select,
  SelectOption,
} from 'modules/reading/components/__styles__/BookMarkModal.styles';
import type { saveBookmark } from 'modules/reading/state/actions/bookmarks.actions';
import { trackSaveBookmark } from 'modules/reading/utils/analytics';
import type { Book } from 'modules/books/utils/booksApiUtils';
import type { Chapter, Verse } from 'modules/reading/utils/api/chapterApiUtils';
import { useAppDispatch } from 'common/hooks/state-hooks';

export type Props = {
  currentBook: Book;
  currentChapter: Chapter;
  activeVerse: Verse | null;
  closeModal: () => void;
  saveBookmark: typeof saveBookmark;
  isBookmarking: boolean;
  currentChapterVerses: Array<Verse>;
};

export const SaveBookmarkModal = (props: Props): JSX.Element => {
  const {
    currentBook,
    currentChapter,
    activeVerse,
    isBookmarking,
    currentChapterVerses,
    closeModal,
  } = props;
  
  const dispatch = useAppDispatch();
  const [selectedVerseNumber, setSelectedVerseNumber] = useState(
    activeVerse?.number || 1,
  );

  const handleSubmit = async (event) => {
    event.preventDefault();
    await dispatch(props.saveBookmark(currentChapter.id, selectedVerseNumber));
    props.closeModal();
    trackSaveBookmark({
      book_title: currentBook.title,
      book_author: currentBook.author,
      chapter_number: currentChapter.number,
      verse_number: selectedVerseNumber,
    });
  };

  const onActiveVerseChange = (event) => {
    setSelectedVerseNumber(event.target.value);
  };

  const options = currentChapterVerses.map((verse) => ({
    value: verse.number,
    label: `Verse ${verse.number}`,
  }));

  return (
    <Modal
      style={{
        content: bookmarkModalStyles,
      }}
      closeModal={closeModal}
    >
      <h2 className="header">Bookmark</h2>
      <div className="content">{`${currentBook.title} ${currentChapter.number} ${selectedVerseNumber}`}</div>
      <form onSubmit={handleSubmit}>
        <SelectVerseNumber>
          <Select
            onChange={onActiveVerseChange}
            value={options[selectedVerseNumber - 1].value}
          >
            {options.map((option) => (
              <SelectOption key={option.label} value={option.value}>
                {option.label}
              </SelectOption>
            ))}
          </Select>
        </SelectVerseNumber>
        <input type="submit" value={isBookmarking ? 'Saving...' : 'Save'} />
      </form>
    </Modal>
  );
};

export default SaveBookmarkModal;
