import type { ChangeEvent, MouseEvent } from 'react';
import React from 'react';
import type { GoogleLoginResponse } from 'react-google-login';
import { GoogleLogin } from 'react-google-login';
import type { ReactFacebookLoginInfo } from 'react-facebook-login';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import * as S from 'modules/auth/components/__styles__/AuthComponent.styles';

export interface Props {
  name: string;
  email: string;
  password: string;
  errors: {
    name: string;
    email: string;
    password: string;
  };
  isAuthenticating: boolean;
  isGoogleAuthenticating: boolean;
  isFacebookAuthenticating: boolean;
  isSignUpView?: boolean;
  goToSignIn: () => void;
  goToSignUp: () => void;
  handleNameChange: (event: ChangeEvent<HTMLInputElement>) => void;
  handleEmailChange: (event: ChangeEvent<HTMLInputElement>) => void;
  handlePasswordChange: (event: ChangeEvent<HTMLInputElement>) => void;
  handleFocus: () => void;
  handleSignUp: (event: MouseEvent) => void;
  handleSignIn: (event: MouseEvent) => void;
  onGoogleSuccess: (response: GoogleLoginResponse) => void;
  onFaceBookSuccess: (userInfo: ReactFacebookLoginInfo) => void;
  onGoogleFailure: () => void;
  authError: string | undefined;
}

export const AuthComponent = ({
  name,
  email,
  password,
  isAuthenticating,
  isFacebookAuthenticating,
  isGoogleAuthenticating,
  isSignUpView,
  goToSignIn,
  goToSignUp,
  handleFocus,
  handleSignUp,
  handleSignIn,
  handleNameChange,
  handleEmailChange,
  handlePasswordChange,
  onGoogleSuccess,
  onGoogleFailure,
  authError,
  errors,
  onFaceBookSuccess,
}: Props): JSX.Element => (
  <>
    <S.Heading>
      {isSignUpView ? 'Get Started for Free' : 'Welcome Back!'}
    </S.Heading>
    {authError && <S.AuthError>{authError}</S.AuthError>}
    <S.Form>
      {isSignUpView && (
        <S.FormGroup>
          <S.InputLabel htmlFor="name">Name</S.InputLabel>
          <S.InputField
            type="text"
            name="name"
            onChange={handleNameChange}
            onFocus={handleFocus}
            placeholder="Enter your full name"
            value={name}
          />
          <S.FieldError>{errors.name}</S.FieldError>
        </S.FormGroup>
      )}
      <S.FormGroup>
        <S.InputLabel htmlFor="email">Email</S.InputLabel>
        <S.InputField
          type="email"
          name="email"
          onChange={handleEmailChange}
          onFocus={handleFocus}
          placeholder="name@email.com"
          value={email}
        />
        <S.FieldError>{errors.email}</S.FieldError>
      </S.FormGroup>
      <S.FormGroup>
        <S.InputLabel htmlFor="password">Password</S.InputLabel>
        <S.InputField
          type="password"
          name="password"
          onChange={handlePasswordChange}
          onFocus={handleFocus}
          placeholder="create password"
          value={password}
        />
        <S.FieldError>{errors.password}</S.FieldError>
      </S.FormGroup>
      {!isSignUpView && (
        <S.ForgotPasswordLink href="/forgot_password">Forgot password?</S.ForgotPasswordLink>
      )}
      <S.SubmitButton
        type="submit"
        onClick={isSignUpView ? handleSignUp : handleSignIn}
        isAuthenticating={isAuthenticating}
        disabled={isAuthenticating}
      >
        <S.SubmitButtonText>
          {isSignUpView ? 'Sign up' : 'Login'}
        </S.SubmitButtonText>
        {isAuthenticating && (
          <S.AuthLoader
            size="2.5rem"
            isWhite
          />
        )}
      </S.SubmitButton>
    </S.Form>
    <S.OR>
      <div>
        <hr />
        OR
        <hr />
      </div>
    </S.OR>
    <GoogleLogin
      clientId={process.env.NEXT_PUBLIC_GOOGLE_CLIENT_ID as string}
      onSuccess={onGoogleSuccess}
      onFailure={onGoogleFailure}
      render={(renderProps) => (
        <button
          className="google-login-button"
          type="button"
          onClick={renderProps.onClick}
        >
          <S.GoogleLogoIcon />
          <S.SubmitButtonText>Continue with Google</S.SubmitButtonText>
          {isGoogleAuthenticating && (
            <S.AuthLoader
              size="2.5rem"
            />
          )}
        </button>
      )}
    />
    <FacebookLogin
      appId={process.env.NEXT_PUBLIC_FACEBOOK_APP_ID as string}
      fields="name,email,picture"
      cssClass="facebook-login-button"
      autoLoad={false}
      render={(renderProps) => (
        <button
          className="facebook-login-button"
          type="button"
          onClick={renderProps.onClick}
        >
          <S.FacebookLogoIcon />
          <S.SubmitButtonText>Continue with Facebook</S.SubmitButtonText>
          {isFacebookAuthenticating && (
            <S.AuthLoader
              size="2.5rem"
            />
          )}
        </button>
      )}
      callback={onFaceBookSuccess}
    />
    <S.AlreadyHave onClick={isSignUpView ? goToSignIn : goToSignUp}>
      <S.AlreadyHaveText>
        {isSignUpView ? 'Already have an account?' : "Don't have an account?"}
      </S.AlreadyHaveText>
      <S.AlreadyHaveLink type="button" onClick={goToSignIn}>
        {isSignUpView ? 'Login' : 'Sign up'}
      </S.AlreadyHaveLink>
    </S.AlreadyHave>
  </>
);

export default AuthComponent;
