import * as api from 'modules/reading/utils/api/journalsApiUtils';

import type { AppThunk } from 'app/state/store';
import type { AxiosResponse } from 'axios';
import { actions } from 'modules/reading/state/slices/journals.slice';

export const fetchPublicJournalsForVerse = (verseId: number): AppThunk =>
  async (dispatch): Promise<void | AxiosResponse<any>> => {
    try {
      dispatch(actions.fetchJournalsForVerseStarted());
      const data = await api.fetchPublicJournalsForVerse(verseId);
      dispatch(actions.fetchJournalsForVerseSucceeded(
        {
          verseId,
          journals: data,
        }
      ));
    } catch (err) {
      if (err && err.response) {
        dispatch(actions.fetchJournalsForVerseFailed(err.response.data));
      }
      throw err;
    }
  };
