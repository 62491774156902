import React, { Fragment } from 'react';
import styled from 'styled-components';
import { setFlex, setFont, setPosition } from 'app/globalStyles/variables';
import { isTabletOrLarger } from 'app/globalStyles/screens';
import Tabs from './Tabs';
import WriteButton from '../../thoughts/common/WriteButton';

const ActiveTab = styled.h1`
  font-weight: 500;
  font-size: 1.6rem;
  color: ${({ theme: { colors } }) => colors.colorText};
  line-height: 19px;
  letter-spacing: -0.25px;
`;

export const MenuButton = styled.button`
  width: 3rem;
  height: 3rem;
  background: transparent;
  border: unset;
  cursor: pointer;
  padding: 5px 4px;
  margin-right: 1.4rem;
  margin-left: 2.8rem;
  border: 2px solid ${({ theme: { colors } }) => colors.colorPrimary1};
  border-radius: 50%;

  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  ${isTabletOrLarger`
      display: initial;
      flex-direction: initial;
      justify-content: initial;
      align-items: initial;
  `}

  &:focus {
    outline: unset;
  }

  div {
    width: 1.4rem;
    height: 2px;
    border-radius: 10px;
    transition: all 0.3s linear;
    position: relative;
    transform-origin: 1px;
    background: ${({ theme: { colors } }) => colors.colorPrimary1};
  }
`;

const ModalOverlay = styled.div`
  position: ${setPosition.fixed};
  top: 0;
  left: 0;
  bottom: 0;
  height: 100%;
  right: 0;
  background: ${({ theme: { colors } }) => colors.colorOverlay};
  opacity: 0.999;
  background-size: cover;
  z-index: 1200;
  overflow-y: scroll;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Modal = styled.div`
  width: 90%;
  position: relative;
  background: ${({ theme: { colors } }) => colors.colorBackground};
  ${setFlex({ x: 'start', y: 'center' })};
  flex-direction: column;
  border: 1px solid ${({ theme: { colors } }) => colors.colorBorder};
  z-index: 1200;
  ${isTabletOrLarger`
     width: 80%;
  `}
`;

const CloseModal = styled.button`
  position: ${setPosition.absolute};
  top: 20px;
  right: 30px;
  font-size: 2rem;
  font-weight: 400;
  border: unset;
  color: ${({ theme: { colors } }) => colors.colorText};
  background: ${({ theme: { colors } }) => colors.colorBackground};
  cursor: pointer;
`;

const ModalHeading = styled.h1`
  font-size: 2rem;
  line-height: 2.8rem;
  letter-spacing: -0.444444px;
  font-family: ${setFont.headings};
  margin: 5.1rem auto 2.8rem 4.1rem;
`;

interface Props {
  activePageName: string;
  isModalOpen: boolean;
  openModal: () => void;
  closeModal: () => void;
  username: string;
}

const MobileTabBar = ({
  activePageName,
  isModalOpen,
  openModal,
  closeModal,
  username,
}: Props) => {
  function capitalizeFirstLetterOfPageName(pageName) {
    return pageName.charAt(0).toUpperCase() + pageName.slice(1);
  }

  return (
    <Fragment>
      <MenuButton type="button" onClick={openModal}>
        <div />
        <div />
        <div />
      </MenuButton>
      <ActiveTab>{capitalizeFirstLetterOfPageName(activePageName)}</ActiveTab>
      {activePageName === 'journals' && <WriteButton />}
      {isModalOpen && (
        <ModalOverlay>
          <Modal>
            <CloseModal type="button" onClick={closeModal}>
              &times;
            </CloseModal>
            <ModalHeading>Menu</ModalHeading>
            <Tabs
              pageName={activePageName}
              username={username}
              closeModal={closeModal}
            />
          </Modal>
        </ModalOverlay>
      )}
    </Fragment>
  );
};

export default MobileTabBar;
