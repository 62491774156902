import type { Journal } from 'modules/thoughts/journals/utils/journalsApiUtils';
import type { Highlight } from 'modules/reading/utils/api/highlightsApiUtils';

export type ChapterHighlights = { [verseId: number]: Array<Highlight> };
export type VerseJournals = { [verseId: number]: Array<Journal> };

export enum ChapterLockReasons {
  AUTH = 'REQUIRES_LOGIN',
  MEMBERSHIP = 'REQUIRES_MEMBERSHIP_OR_PURCHASE',
}
