import { apiCall } from 'common/utils/api-helpers';
import type { Book } from 'modules/books/utils/booksApiUtils';
import type { Chapter } from 'modules/reading/utils/api/chapterApiUtils';


export type ChapterPayload = {
  chapter: Chapter;
  nextChapter: Chapter | null;
  prevChapter: Chapter | null;
};

export const fetchBooks = async (): Promise<Book[]> => {
  const { data } = await apiCall('books', 'get');
  return data;
};

export const fetchBook = async (bookId: number): Promise<Book> => {
  const { data } = await apiCall(`books/getById/${bookId}`, 'get');
  return data;
};

export const updateBook = async (book: Partial<Book>): Promise<Book> => {
  const { data } = await apiCall(`books/${book.id}`, 'put', book);
  return data;
};

export const updateChapter = async (
  chapter: Partial<Chapter>,
): Promise<ChapterPayload> => {
  const { data } = await apiCall(`chapters/${chapter.id}`, 'put', chapter);
  return data;
};

export const fetchBookChapter = async (
  url: string,
  token?: string,
): Promise<ChapterPayload> => {
  const { data } = await apiCall(url, 'get', null, token);
  return data;
};
