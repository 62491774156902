import type { NextPage } from 'next';
import React from 'react';
import { Provider } from 'react-redux';
import App from 'next/app';
import { PersistGate } from 'redux-persist/integration/react';

import type { Persistor } from 'redux-persist/es/types';
import { getStore } from 'app/state/store';

// Journal: For now, all pages will need to wrapped with this hoc since all pages use the header
// that is connected to redux to use the signOut thunk action.
export const withRedux = (
  PageComponent: NextPage,
  { ssr }: { ssr: boolean } = { ssr: true },
) => {
  const WithRedux = ({ initialReduxState, ...rest }: any) => {
    const { persistor, store } = getStore(initialReduxState);
    return (
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor as Persistor}>
          <PageComponent {...rest} />
        </PersistGate>
      </Provider>
    );
  };

  // Make sure people don't use this HOC on _app.js level
  if (process.env.NODE_ENV !== 'production') {
    // @ts-ignore
    const isAppHoc = PageComponent === App || PageComponent.prototype instanceof App;
    if (isAppHoc) {
      throw new Error('The withRedux HOC only works with PageComponents');
    }
  }

  // Set the correct displayName in development
  if (process.env.NODE_ENV !== 'production') {
    const displayName = PageComponent.displayName || PageComponent.name || 'Component';

    WithRedux.displayName = `withRedux(${displayName})`;
  }

  if (ssr || PageComponent.getInitialProps) {
    WithRedux.getInitialProps = async (context) => {
      // Get or Create the store with `undefined` as initialState
      // This allows you to set a custom default initialState
      const { store: reduxStore } = getStore();

      // Provide the store to getInitialProps of pages
      context.reduxStore = reduxStore;

      // Run getInitialProps from HOCed PageComponent
      const pageProps = typeof PageComponent.getInitialProps === 'function'
        ? await PageComponent.getInitialProps(context)
        : {};

      // Pass props to PageComponent
      return {
        ...pageProps,
        initialReduxState: reduxStore.getState(),
      };
    };
  }

  return WithRedux;
};

export default withRedux;
