import React, { useState, useEffect } from 'react';
import { StyledSearchBar, SearchIcon } from './__styles__/SearchBar.styles';
import SearchModal from './SearchModal';

export interface Props {
  placeholder: string;
}

const SearchBar = ({ placeholder }: Props) => {
  const [searchModalVisible, setSearchModalVisible] = useState(false);
  const showSearchModal = () => setSearchModalVisible(true);

  const hideSearchModal = () => setSearchModalVisible(false);
  useEffect(() => {
    if (searchModalVisible) {
      document.body.classList.add('Modal-Open');
    } else {
      document.body.classList.remove('Modal-Open');
    }
  }, [searchModalVisible]);

  return (
    <>
      {!searchModalVisible && (
        <StyledSearchBar
          role="button"
          className="search-bar"
          onClick={showSearchModal}
        >
          <SearchIcon />
          <span>{placeholder}</span>
        </StyledSearchBar>
      )}
      {searchModalVisible && <SearchModal hideSearchModal={hideSearchModal} />}
    </>
  );
};

export default SearchBar;
