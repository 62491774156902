import React, { useState } from 'react';
import { css } from 'styled-components';
import { Navigation } from 'styled-icons/feather';
import Modal from 'common/components/Modal';
import { isTabletOrLarger } from 'app/globalStyles/screens';
import {
  BookmarksFill,
  HighlightFill,
  WriteFill,
  ShareFill,
} from 'static/assets';
import { SignUpButton, LoginButton } from 'modules/auth/components/AuthButtons';
import { AuthModal } from 'modules/auth';
import {
  Content,
  Title,
  SubTitle,
} from 'modules/reading/components/__styles__/AuthPrompt.styles';

const cardIconStyle = css`
  height: 40px;
  width: 40px;
  margin-bottom: 30px;
  ${isTabletOrLarger(css`
    height: 50px;
    width: 50px;
    margin-bottom: 50px;
  `)};
  // ensures dynamically changing svg color in dark mode.
  path {
    fill: ${({ theme: { colors } }) => colors.colorText};
  }
`;

const ICON_MAP = {
  verse: () => <Navigation css={cardIconStyle} />,
  highlight: () => <HighlightFill css={cardIconStyle} />,
  bookmark: () => <BookmarksFill css={cardIconStyle} />,
  journal: () => <WriteFill css={cardIconStyle} />,
  note: () => <WriteFill css={cardIconStyle} />,
  read: () => <ShareFill css={cardIconStyle} />,
};

const featureData = {
  journal: {
    title: 'Write your thoughts in your journal',
    subTitle:
      'When you join Stoic Bible, you can write your thoughts and summarize what you read.',
  },
  note: {
    title: 'Write your thoughts on selected text.',
    subTitle:
      'When you join Stoic Bible, you can write your thoughts and summarize what you read.',
  },
  verse: {
    title: 'Swipe through verses',
    subTitle:
      'When you join Stoic Bible, you can read chapters by swiping through the verses.',
  },
  highlight: {
    title: 'Highlight favorite verses',
    subTitle:
      'When you join Stoic Bible, you can highlight verses so you can revisit them later on.',
  },
  bookmark: {
    title: 'Bookmark chapters as you read',
    subTitle:
      'When you join Stoic Bible, you can bookmark chapters as you read so that you can revisit them',
  },
  read: {
    title: "Mark chapters you've read and re-read",
    subTitle:
      "Join Stoic Bible so you can mark chapters you've read and see how many times you've read them",
  },
};

const style = {
  content: css`
    width: 95%;
    border-radius: 10px;
    ${isTabletOrLarger(css`
      width: initial;
    `)};
  `,
};

export interface Props {
  type: 'verse' | 'highlight' | 'bookmark' | 'journal' | 'read' | 'note';
  closeModal: () => void;
}

const AuthPrompt = ({ type, closeModal }: Props) => {
  const [visibleAuthModal, setVisibleAuthModal] = useState<
    'signup' | 'signin' | undefined
  >(undefined);

  const showSignIn = () => {
    setVisibleAuthModal('signin');
  };
  const showSignUp = () => {
    setVisibleAuthModal('signup');
  };

  const closeAuthModal = () => setVisibleAuthModal(undefined);

  const Icon = ICON_MAP[type];
  const { title, subTitle } = featureData[type];
  return (
    <>
      <Modal
        shouldCloseOnOverlayClick={false}
        style={style}
        closeModal={closeModal}
      >
        <Content>
          <Icon />
          <Title>{title}</Title>
          <SubTitle>{subTitle}</SubTitle>
          <LoginButton onClick={showSignIn}>Log In</LoginButton>
          <SignUpButton onClick={showSignUp}>Sign Up</SignUpButton>
        </Content>
      </Modal>
      {!!visibleAuthModal && (
        <AuthModal
          visibleAuthModal={visibleAuthModal}
          closeModal={closeAuthModal}
        />
      )}
    </>
  );
};

export default AuthPrompt;
