import styled, { css } from 'styled-components';
import { isTabletOrLarger } from 'app/globalStyles/screens';

export const Content = styled.div`
  padding-top: 20px;
  padding-bottom: 40px;
  width: 100%;
  position: absolute;
  top: 100px;
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  text-align: center;
  background: ${({ theme: { colors } }) => colors.colorBackground};
  ${isTabletOrLarger(css`
      padding: 40px 0;
  `)}
`;
