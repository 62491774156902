import type { FormEvent } from 'react';
import React, { useState, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'common/hooks/state-hooks';
import { actions as membershipActions } from 'modules/membership/state/membership.slice';
import { selectMembership } from 'modules/membership';
import { selectUser, AuthModal } from 'modules/auth';
import type { DefaultTheme } from 'styled-components';
import { css, withTheme } from 'styled-components';
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import fbPixel from 'common/utils/facebook-pixel';
import {
  StyledPage,
  paymentModalStyles,
  MembershipPlansSection,
  MembershipPlans,
  MembershipPlansHero,
} from 'modules/membership/components/styled';

import Modal from 'common/components/Modal';
import { CustomButton, Text } from 'common/components/styled-components';
import { isTabletOrLarger } from 'app/globalStyles/screens';
import SubscriptionForm from 'modules/membership/components/SubscriptionForm';
import BenefitsCard from 'modules/membership/components/BenefitsCard';
import PlanCard from 'modules/membership/components/PlanCard';
import { createSubscription } from 'modules/membership/state/membership.actions';

import type {
  StripeCardElement,
  StripeCardElementChangeEvent,
} from '@stripe/stripe-js';
import {
  trackEnterCardDetails,
  trackViewMembershipPage,
  trackOpenSubscriptionForm,
  trackPurchaseSubscription,
} from 'modules/membership/utils/analytics';
import { useRouter } from 'next/router';
import { routeTo } from 'common/utils/routes';
import {
  getPlanName,
  notifySuccess,
  notifyEmailVerify,
} from 'modules/membership/utils/membership';
import type Stripe from 'stripe';

const pageStyles = css`
  align-items: center;
  padding-top: 4rem;
  ${isTabletOrLarger(css`
    padding-top: 8rem;
  `)};
`;

export type Props = {
    theme: DefaultTheme;
    plans: Array<Stripe.Price>;
  };

const MembershipPage = ({ theme, plans }: Props) => {
  const { isSubscribing, subscription, subscribeErrMessage } = useAppSelector(selectMembership);
  const user = useAppSelector(selectUser);
  const dispatch = useAppDispatch();
  const [selectedPlan, setSelectedPlan] = useState<Stripe.Price>(
    plans.find((plan) => plan.recurring?.interval === 'year') as Stripe.Price,
  );
  const { email } = user || {};
  const [isFormModalOpen, setFormModalOpen] = useState(false);
  const [isCardElementValid, setIsCardElementValid] = useState(false);
  const [cardElementError, setIsCardElementError] = useState<string>();
  const [errorMessage, setErrorMessage] = useState<string>(subscribeErrMessage);
  const stripe = useStripe();
  const elements = useElements();

  const router = useRouter();

  const togglePlan = (event, plan: Stripe.Price) => {
    event.stopPropagation();
    if (plan.id !== selectedPlan.id) {
      setSelectedPlan(plan);
    }
  };

  // Auth modal.
  const [visibleAuthModal, setVisibleAuthModal] = useState<
    'signup' | 'signin' | undefined
  >(undefined);
  const showSignIn = () => setVisibleAuthModal('signin');

  useEffect(() => {
    fbPixel.logEvent('View Content', {
      content_name: 'Stoic Bible Membership',
      content_type: 'product',
      content_ids: plans.map((plan) => plan.id),
    });
    trackViewMembershipPage();
  }, []);

  const onSubscriptionSuccess = () => {
    notifySuccess();
    router.push(routeTo.home);
    fbPixel.logEvent('Subscribe', {
      currency: 'USD',
      value: selectedPlan.unit_amount as number,
    });
    trackPurchaseSubscription({
      plan: (selectedPlan.product as Stripe.Product).name,
      currency: 'USD',
      price: selectedPlan.unit_amount as number,
    });
  };

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (stripe && elements && isCardElementValid) {
      const cardElement = elements.getElement(CardElement);

      dispatch(createSubscription(
        {
          customerEmail: email,
          priceId: selectedPlan.id,
          cardElement,
          stripe,
        },
        onSubscriptionSuccess,
      ));
    } else {
      setErrorMessage(cardElementError || 'Your card number is invalid');
    }
  };

  const toggleMonthlyForm = () => {
    if (!user) {
      showSignIn();
    } else if (user && !user.emailVerified) {
      notifyEmailVerify();
    } else {
      setFormModalOpen((isFormModalOpen) => !isFormModalOpen);
      trackOpenSubscriptionForm({
        plan: (selectedPlan.product as Stripe.Product).name,
        currency: 'USD',
        price: selectedPlan.unit_amount as number,
      });
    }
  };

  const onCardElementChange = (event: StripeCardElementChangeEvent) => {
    if (event.complete && !event.error) {
      setIsCardElementValid(true);
      trackEnterCardDetails();
    } else if (!event.complete && event.error) {
      setIsCardElementError(event.error.message);
    }
  };

  const onCardElementFocus = () => {
    dispatch(membershipActions.clearSubscriptionError());
    if (errorMessage) {
      setErrorMessage('');
    }
  };

  const onCardElementReady = (element: StripeCardElement) => {
    element.focus();
  };

  if (subscription && subscription.status === 'active') {
    return (
      <StyledPage css="justify-content: center">
        <h2 css="font-weight: 500; max-width: 55%; text-align: center; margin: 30vh auto;">
          Thank you for Subscribing. You are now a member of the Stoic Bible
          community.
        </h2>
      </StyledPage>
    );
  }

  return (
    <StyledPage css={pageStyles}>
      <BenefitsCard />

      <MembershipPlansSection>
        <MembershipPlansHero>
          <Text as="h1" margin="0 0 14px 0" color="colorTitle">
            Become a member
          </Text>
          <Text
            textAlign="center"
            as="small"
            color="colorPrimary3"
            fontSize="1.6rem"
          >
            Support Stoic Bible and take your Stoicism to the next level with
            awesome features for just $6/month or $50/year. Cancel anytime.
          </Text>
        </MembershipPlansHero>

        <MembershipPlans>
          {plans.map((plan) => (
            <PlanCard
              key={plan.id}
              plan={plan}
              heading={
                plan.recurring?.interval === 'year' ? 'Best Value' : 'Regular'
              }
              togglePlan={togglePlan}
              isPlanSelected={selectedPlan.id === plan.id}
            />
          ))}
        </MembershipPlans>

        <CustomButton
          height="50px"
          width="300px"
          padding="0 4rem"
          margin="0 auto"
          variant="contained"
          color="colorBackground"
          backgroundColor="colorPrimary1"
          fontSize="16px"
          onClick={toggleMonthlyForm}
        >
          {`Get ${getPlanName(selectedPlan.recurring?.interval)}`}
        </CustomButton>
      </MembershipPlansSection>

      {!!visibleAuthModal && (
        <AuthModal
          closeModal={() => setVisibleAuthModal(undefined)}
          visibleAuthModal={visibleAuthModal}
        />
      )}
      {isFormModalOpen && (
        <Modal
          closeModal={toggleMonthlyForm}
          style={{ content: paymentModalStyles }}
          allowClose={false}
        >
          <SubscriptionForm
            selectedPlan={selectedPlan}
            handleSubmit={handleSubmit}
            isStripeLoading={!stripe || !elements}
            onCardElementFocus={onCardElementFocus}
            subscribeErrMessage={errorMessage}
            theme={theme}
            isSubscribing={isSubscribing}
            onCardElementChange={onCardElementChange}
            onCardElementReady={onCardElementReady}
          />
        </Modal>
      )}
    </StyledPage>
  );
};


const ThemedPage: any = withTheme(MembershipPage);

export default ThemedPage;
