import styled, { css } from 'styled-components';
import {
  isDesktopOrLarger,
  isTabletOrLarger,
} from 'app/globalStyles/screens';
import { setFont, setPosition } from 'app/globalStyles/variables';

export const noteEditorModalStyles = css`
  width: 100%;
  padding: 10px;
  height: 70vh;
  background: ${({ theme: { colors } }) => colors.colorBackground};
  z-index: 10;
  position: relative;
  ${isDesktopOrLarger`
    width: 680px;
    padding: 20px;
    height: 40vh;
  `}
`;

export const Header = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 50px;
  right: 0;
  overflow: hidden;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
`;

export const Footer = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  padding: 10px;
  height: 50px;
  width: 100%;
  overflow: hidden;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Form = styled.form`
  position: absolute;
  top: 40px;
  bottom: 50px;
  right: 0;
  left: 0;
  overflow: auto;
  padding: 10px;
`;

export const textAreaStyles = css`
  width: 100%;
  background: ${({ theme: { colors } }) => colors.colorBackground};
  color: ${({ theme: { colors } }) => colors.colorText};
  font: 400 1.6rem ${setFont.main};
  line-height: 1.5;
  border: unset;
  outline: unset;
  resize: none;
`;

export const QuoteCard = styled.div`
  width: 100%;
  margin-top: 10px;
  font-size: 16px;
  line-height: 1.5;
  border-radius: 3px;
  border: 1px solid ${({ theme: { colors } }) => colors.colorBorder};
  padding: 10px;
`;

export const Quote = styled.p`
  display: inline;
  //background: yellow;
`;

export const CloseModal = styled.button`
  position: ${setPosition.absolute};
  top: 10px;
  right: 10px;
  font-size: 3rem;
  font-weight: 400;
  width: 30px;
  height: 40px;
  border: unset;
  outline: unset;
  cursor: pointer;
  background: transparent;
  color: ${({ theme: { colors } }) => colors.colorText};
  ${isTabletOrLarger(css`
    width: 40px;
  `)};
  &:hover {
    background: ${({ theme: { colors } }) => colors.colorPrimary4};
    border-radius: 50px;
  }
`;
