import React, { useState } from 'react';
import { Settings } from 'styled-icons/feather';
import ReaderSettings from 'modules/reading/components/ReaderSettings';
import type { actions } from 'modules/reading/state/slices/settings.slice';
import { useAppDispatch } from 'common/hooks/state-hooks';

import {
  Container,
  NowReading,
  StyledChapterPickerButton,
  StyledArrowDropDown,
  ToolButton,
  BookmarkFillIcon,
  BookmarkLineIcon,
} from 'modules/reading/components/__styles__/ChapterToolbar.styles';
import type { ThemeType } from 'modules/settings/utils/settingsUtils';
import {
  trackChangeReaderFontFamily,
  trackChangeReaderFontSize,
  trackChangeReaderVerseNumbers,
} from 'modules/reading/utils/analytics';
import { trackChangeTheme } from 'modules/settings/state/settings.actions';

export type Props = {
  bookTitle: string;
  chapterNumber: number;
  theme: ThemeType;
  openChapterPicker: () => void;
  toggleDarkMode: (theme: ThemeType) => void;
  fontFamily: string;
  fontSize: number;
  shouldShowVerseNumbers: boolean;
  isMemberOrFreeTrialUser: boolean;
  isAuthenticated: boolean;
  setFontFamily: typeof actions.setFontFamily;
  setFontSize: typeof actions.setFontSize;
  showVerseNumbers: typeof actions.showVerseNumbers;
  openBookmarkModal: () => void;
  isChapterBookmarked: boolean;
  deleteBookmark: () => void;
};

const ChapterToolbar = ({
  bookTitle,
  chapterNumber,
  openChapterPicker,
  theme,
  toggleDarkMode,
  fontFamily,
  fontSize,
  shouldShowVerseNumbers,
  setFontFamily,
  setFontSize,
  showVerseNumbers,
  isMemberOrFreeTrialUser,
  isAuthenticated,
  isChapterBookmarked,
  openBookmarkModal,
  deleteBookmark,
}: Props): JSX.Element => {
  const dispatch = useAppDispatch();
  const [isSettingsOpen, setIsSettingsOpen] = useState(false);

  const handleToggleSettings = () => {
    setIsSettingsOpen((isOpen) => !isOpen);
  };

  const selectFont = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    family: string,
  ) => {
    dispatch(setFontFamily(family));
    trackChangeReaderFontFamily(family);
  };

  const selectFontSize = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    size: number,
  ) => {
    dispatch(setFontSize(size));
    trackChangeReaderFontSize(size);
  };

  const toggleNightMode = (event: { target: { value: string } }) => {
    if (event.target.value !== 'light') {
      dispatch(toggleDarkMode('light'));
      trackChangeTheme('light');
    } else {
      dispatch(toggleDarkMode('dark'));
      trackChangeTheme('dark');
    }
  };

  const onShowVerseNumbers = (event) => {
    dispatch(showVerseNumbers(event.target.checked));
    trackChangeReaderVerseNumbers(event.target.checked);
  };

  const handleBookmark = (event) => {
    event.preventDefault();
    if (isChapterBookmarked) {
      dispatch(deleteBookmark());
    } else {
      openBookmarkModal();
    }
  };

  return (
    <Container>
      <NowReading>Now Reading</NowReading>
      <StyledChapterPickerButton type="button" onClick={openChapterPicker}>
        <h1>{`${bookTitle} : ${chapterNumber}`}</h1>
        <StyledArrowDropDown size={26} />
      </StyledChapterPickerButton>
      <ToolButton role="button" tabIndex={0} onClick={handleToggleSettings}>
        <Settings size={25} />
      </ToolButton>
      <ToolButton onClick={handleBookmark}>
        {isChapterBookmarked ? <BookmarkFillIcon /> : <BookmarkLineIcon />}
      </ToolButton>
      {isSettingsOpen && (
        <ReaderSettings
          toggleModal={handleToggleSettings}
          toggleNightMode={toggleNightMode}
          fontFamily={fontFamily}
          fontSize={fontSize}
          shouldShowVerseNumbers={shouldShowVerseNumbers}
          isAuthenticated={isAuthenticated}
          isMemberOrFreeTrialUser={isMemberOrFreeTrialUser}
          theme={theme}
          selectFontSize={selectFontSize}
          selectFont={selectFont}
          onShowVerseNumbers={onShowVerseNumbers}
        />
      )}
    </Container>
  );
};

export default ChapterToolbar;
