import styled, { css } from 'styled-components';
import { setFont } from 'app/globalStyles/variables';
import { isTabletOrLarger } from 'app/globalStyles/screens';

export const StyledSearchBarModal = styled.form`
  position: fixed;
  left: 0;
  top: 0;
  height: 100vh;
  right: 0;
  background: ${({ theme: { colors } }) => colors.colorOverlay};
  z-index: 30;
  display: flex;
  justify-content: center;
  align-items: flex-start;

  animation-name: fadein;
  animation-duration: 0.1s;
  animation-timing-function: ease-in-out;

  @keyframes fadein {
    0% {
      bottom: 50%;
      height: 0;
    }
    100% {
      top: 0;
      bottom: 0;
    }
  }

  input {
    border: 2px solid ${({ theme: { colors } }) => colors.colorBorder};
    border-radius: 6px;
    background: ${({ theme: { colors } }) => colors.colorBackgroundDark};
    color: ${({ theme: { colors } }) => colors.colorText};
    outline: unset;
    overflow-x: hidden;
    box-shadow: 0 14px 14px rgba(30, 44, 70, 0.1);
    &::-webkit-input-placeholder {
      font-family: ${setFont.main};
    }
    margin-top: 25%;
    height: 40px;
    font-size: 1rem;
    padding: 8px;
    width: 80%;
    button {
      margin-left: 10px;
    }
    ${isTabletOrLarger(css`
      margin-top: 10%;
      height: 60px;
      font-size: 2rem;
      padding: 20px;
      width: 50%;
      button {
          margin-left: initial;
        }
    `)}

    animation-name: expand;
    animation-duration: 0.3s;
    animation-timing-function: ease-in-out;

    @keyframes expand {
      0% {
        width: 10%;
        height: 34px;
        margin-top: 2%;
      }
      25% {
        width: 20%;
        height: 38px;
        margin-top: 4%;
      }
      50% {
        width: 30%;
        height: 42px;
        margin-top: 6%;
      }
      75% {
        width: 40%;
        height: 46px;
        margin-top: 8%;
      }
      100% {
        width: 50%;
        height: 50px;
        margin-top: 10%;
      }
    }

    input {
      text-align: left;
      width: 95%;
      font-size: 1.6rem;
      padding: 10px;
    }
    button {
      display: none;
      outline: unset;
    }
  }
`;
