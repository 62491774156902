import React from 'react';
import type { TypeOptions } from 'react-toastify';
import { toast } from 'react-toastify';
// TODO: Remove this package and change the icons. Stick to styled-icons
import {
  FaInfo,
  FaCheck,
  FaExclamationTriangle,
  FaBug,
  FaExclamationCircle,
} from 'react-icons/fa';

const style = {
  marginTop: 9,
};


export const displayIcon = (type) => {
  switch (type) {
    case 'success':
      return <FaCheck style={style} />;
    case 'info':
      return <FaInfo />;
    case 'error':
      return <FaExclamationCircle />;
    case 'warning':
      return <FaExclamationTriangle style={style} />;
    case 'default':
      return <FaBug style={style} />;
    default:
      return <FaBug style={style} />;
  }
};

export interface Props {
  message: string;
  type: TypeOptions;
}

const ToastMessage = ({ type, message }: Props) =>
  toast[type](
    <div data-testid="modal-alert" style={{ display: 'flex', color: 'white' }}>
      <div
        data-testid="modal-alert-type"
        style={{
          fontSize: 15,
          paddingTop: 8,
          flexShrink: 0,
          textAlign: 'center',
          width: '30px',
        }}
      >
        {displayIcon(type)}
      </div>
      <div
        data-testid="modal-message"
        style={{ flexGrow: 1, fontSize: 15, padding: '8px 12px' }}
      >
        {message}
      </div>
    </div>,
  );

export default ToastMessage;
