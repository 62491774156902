import styled, { css } from 'styled-components';
import {
  setPosition,
  setFont,
  setFlex,
} from 'app/globalStyles/variables';
import {
  isSmallPhone,
  isTabletOrSmaller,
  isTabletOrLarger,
} from 'app/globalStyles/screens';
import { FacebookLogo, GoogleLogo } from 'static/assets';
import Loader from 'common/components/Loader';
import Link from 'next/link';

type Props = {
  isAuthenticating?: boolean;
  isModal?: boolean;
};

export const FacebookLogoIcon = styled<any>(FacebookLogo)`
  width: 20px;
  height: 20px;
  position: absolute;
  left: 2rem;
`;

export const GoogleLogoIcon = styled<any>(GoogleLogo)`
  width: 20px;
  height: 20px;
  position: absolute;
  left: 2rem;
`;

export const authComponentStyles = css`
  border: 1px solid ${({ theme: { colors } }) => colors.colorBorder};
  border-radius: 6px;
  padding: 50px 0;
  flex-flow: column nowrap;
  color: inherit;
  width: 60%;
  ${isTabletOrLarger`
    width: 600px;
  `}
  ${isSmallPhone`
    width: 90%;
  `}

  .google-login-button, .facebook-login-button {
    height: 50px;
    display: flex !important;
    justify-content: center;
    align-items: center;
    outline: unset;
    border-radius: 4px;
    font-family: ${setFont.main} !important;
    box-shadow: unset !important;
    border: 1px solid ${({ theme: { colors } }) => colors.colorBorder}!important;
    background: ${(p) => p.theme.colors.colorBackground};
    font-size: 1.4rem !important;
    font-weight: 500 !important;
    color: ${({ theme: { colors } }) => colors.colorTextPrimary} !important;
    position: relative;
    margin: 20px auto 5px auto;
    width: 80%;
    ${isTabletOrLarger`
        margin: 15px auto 0 auto;
        width: 66%;
    `}

    &:hover {
      opacity: 0.9 !important;
      transition: all 0.3s linear !important;
    }
  }
`;

export const customModalStyles = {
  content: authComponentStyles,
};

export const AuthComponent = styled.div`
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  ${authComponentStyles};
  width: 600px;
  margin: 50px auto;
`;

export const FormInfo = styled.div`
  text-align: center;
  font-size: 1.4rem;
  width: 66%;
  padding: 0;
  margin: auto;
  margin-bottom: 50px;
`;
export const CloseModal = styled.button`
  position: ${setPosition.absolute};
  top: 20px;
  right: 30px;
  font-size: 1.5rem;
  font-weight: 400;
  border: unset;
  background: #fffdfd;
  cursor: pointer;
  ${isTabletOrLarger`font-size: 2rem;`}
`;

export const AuthError = styled.div`
  color: red;
  text-align: center;
  padding: 10px;
  background: #fdf5f2;
  border-radius: 2px;
  border: 1px solid #d04138;
  font-size: 1.4rem;
  width: 66%;
  margin: -1rem auto 1rem auto;
`;

export const SuccessMsg = styled<any>(AuthError)`
  color: green;
  background: lightgreen;
  border: unset;
  margin: 15px auto;
  width: 80%;
`;

export const ErrorMsg = styled<any>(SuccessMsg)`
  color: red;
  background: #fdf5f2;
`;

export const Heading = styled.h1`
  font-family: ${setFont.headings};
  margin-bottom: 30px;
  text-align: center;
  color: ${({ theme: { colors } }) => colors.colorText};
  font-weight: lighter;
`;

export const Form = styled.form`
  width: 80%;
  margin: 0 auto;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  color: ${({ theme: { colors } }) => colors.colorText};
  ${isTabletOrLarger`
      width: 66%;
   `}
  ${isTabletOrSmaller`margin-top: 8px;`}
`;

export const FormGroup = styled.div`
  margin-bottom: 10px;
  font-size: 1.4rem;
  text-align: left;
`;

export const InputLabel = styled.label`
  font-size: 14px;
  font-weight: 500;
`;
export const InputField = styled.input`
  width: 100%;
  background: ${({ theme: { colors } }) => colors.colorInputBg} !important;
  border: 1px solid ${({ theme: { colors } }) => colors.colorBorder};
  border-radius: 4px;
  height: 50px;
  font-size: 1.6rem;
  padding-left: 10px;
  margin-top: 1rem;
  font-family: ${setFont.main};
  font-weight: 400;
  outline: unset;
  &::-webkit-input-placeholder {
    font-family: ${setFont.main};
    color: ${({ theme: { colors } }) => colors.inputPlaceHolderColor};
  }
  &:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 30px
      ${({ theme: { colors } }) => colors.colorInputBg} inset !important;
  }
`;

export const FieldError = styled.small`
  color: red;
`;

export const ForgotPasswordLink = styled(Link)`
  font-size: 1.4rem;
  font-weight: 400;
  text-decoration: none;
  color: ${({ theme: { colors } }) => colors.colorText};
  text-align: right;
  &:hover {
    color: #0366d6;
    cursor: pointer;
  }
`;

export const submitButtonStyles = css`
  width: 100%;
  background: ${({ theme: { colors } }) => colors.colorPrimary1};
  border: 1px solid ${({ theme: { colors } }) => colors.colorPrimary1};
  border-radius: 4px;
  height: 50px;
  margin-top: 2rem;
  font-size: 1.4rem;
  outline: 0;
  font-weight: 500;
  color: ${({ theme: { colors } }) => colors.colorTextWhite};
  font-family: ${setFont.main};
  position: relative;
  ${setFlex()};

  &:hover {
    opacity: 0.95;
    transition: all 0.3s ease-in-out;
    ${(props: Props) =>
    (props.isAuthenticating
      ? `
          cursor: no-drop;
          opacity: .5;
        `
      : 'cursor: pointer;')}
  }
`;

export const SubmitButton = styled.button`
  ${submitButtonStyles};
`;

export const SubmitButtonText = styled.span`
  //text-transform: uppercase;
`;

export const OR = styled.div`
  width: 100%;
  padding: 10px 0;

  div {
    display: flex;
    justify-content: center;
    flex-flow: row nowrap;
    align-items: center;
    margin: 10px 0 0 0;
    hr {
      width: 35px;
      margin: 0 5px;
      border: 0.5px solid ${({ theme: { colors } }) => colors.colorBorder};
    }
  }
`;

export const AlreadyHave = styled.div`
  font-size: 1.4rem;
  font-weight: 400;
  margin-top: 3rem;
  text-align: center;
`;

export const AlreadyHaveText = styled.span`
  margin-right: 0.8rem;
`;

export const AlreadyHaveLink = styled.button`
  border: unset;
  outline: unset;
  font-size: 1.4rem;
  cursor: pointer;
  color: inherit;
  border-bottom: 1px solid ${({ theme: { colors } }) => colors.colorPrimary1};
  padding-bottom: 3px;
  background: transparent;
  &:hover {
    color: ${({ theme: { colors } }) => colors.colorOverlay};
  }
  &:active {
    outline: 0;
  }
`;

export const AuthLoader = styled<any>(Loader) <{ isWhite: boolean }>`
  border-top: 0.2rem solid
    ${({ isWhite, theme: { colors } }) =>
    (isWhite ? colors.colorTextWhite : colors.colorText)};
  position: absolute;
  right: 1rem;
`;
