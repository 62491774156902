/* eslint-disable no-nested-ternary */
import React from 'react';
import { Div, Form } from 'common/components/styled-components';
import { ChaptersButton } from 'modules/admin/components/__styles__/DashboardContent.styles';
import SubmitInput from 'modules/admin/components/ui/SubmitInput';
import { Accordion, AccordionItem, AccordionItemHeading, AccordionItemPanel } from 'react-accessible-accordion';
import AccordionItemButton from 'modules/admin/components/AccordionItemButton/AccordionItemButton';
import { PageLoader } from 'common/components/Loader';
import type { Book } from 'modules/books/utils/booksApiUtils';
import type { Chapter, Verse } from 'modules/reading/utils/api/chapterApiUtils';

interface Props {
  book: Book | null;
  index: number;
  chapter: Chapter | null;
  chapterVerses: Verse[];
  chapterFieldSaveHandler: any;
  chapterFieldChangeHandler: any;
  setDisplayVerse: any;
  displayVerse: boolean;
}

const Verses = (props: Props) => {
  const {
    book,
    index,
    chapter,
    chapterVerses,
    chapterFieldSaveHandler,
    chapterFieldChangeHandler,
    setDisplayVerse,
    displayVerse,
  } = props;

  return (

    <Form>
      { (chapter === null) ? <PageLoader /> : (!displayVerse && book !== null ? (
        <Div key={chapter.id}>
          <SubmitInput
            name="title"
            value={chapter.title}
            label="Title"
            onChange={(e) => chapterFieldChangeHandler(e, index)}
            onSave={({ name, value }) =>
              chapterFieldSaveHandler({
                id: chapter.id,
                bookId: book.id,
                name,
                value,
              })}
          />
          <SubmitInput
            name="accessType"
            value={chapter.slug}
            label="Slug"
            onChange={(e) => chapterFieldChangeHandler(e, index)}
            onSave={({ name, value }) =>
              chapterFieldSaveHandler({
                id: chapter.id,
                bookId: book.id,
                name,
                value,
              })}
          />
          <SubmitInput
            name="number"
            value={chapter.number}
            type="number"
            label="Number"
            onChange={(e) => chapterFieldChangeHandler(e, index)}
            onSave={({ name, value }) =>
              chapterFieldSaveHandler({
                id: chapter.id,
                bookId: book.id,
                name,
                value,
              })}
          />
          <SubmitInput
            name="accessType"
            value={chapter.accessType}
            label="Access Type"
            onChange={(e) => chapterFieldChangeHandler(e, index)}
            onSave={({ name, value }) =>
              chapterFieldSaveHandler({
                id: chapter.id,
                bookId: book.id,
                name,
                value,
              })}
          />
          <ChaptersButton
            type="button"
            onClick={() => setDisplayVerse(true)}
          >
            Verses
          </ChaptersButton>
        </Div>
      ) : (
        <Accordion allowZeroExpanded style={{ margin: '10px auto' }}>
          { book !== null && chapterVerses.map(({ id, text, number }, index) => (
            <AccordionItem key={id}>
              <AccordionItemHeading>
                <AccordionItemButton text={`${text.split('').slice(0, 50).join('')}...`} />
              </AccordionItemHeading>
              <AccordionItemPanel>
                <Div key={id}>
                  <SubmitInput
                    name="id"
                    value={id}
                    label="Verse Id"
                    onChange={(e) => chapterFieldChangeHandler(e, index)}
                    onSave={({ name, value }) =>
                      chapterFieldSaveHandler({
                        id,
                        bookId: book.id,
                        name,
                        value,
                      })}
                  />

                  <SubmitInput
                    name="number"
                    value={number}
                    type="number"
                    label="Number"
                    onChange={(e) => chapterFieldChangeHandler(e, index)}
                    onSave={({ name, value }) =>
                      chapterFieldSaveHandler({
                        id,
                        bookId: book.id,
                        name,
                        value,
                      })}
                  />
                  <SubmitInput
                    name="text"
                    value={text}
                    label="Text"
                    onChange={(e) => chapterFieldChangeHandler(e, index)}
                    onSave={({ name, value }) =>
                      chapterFieldSaveHandler({
                        id,
                        bookId: book.id,
                        name,
                        value,
                      })}
                  />
                </Div>
              </AccordionItemPanel>
            </AccordionItem>
          ))}
        </Accordion>
      )) }
    </Form>
  );
};

export default Verses;
