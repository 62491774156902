import styled, { css } from 'styled-components';
import { setFlex } from 'app/globalStyles/variables';
import { isDesktopOrLarger, isTabletOrLarger } from 'app/globalStyles/screens';
import { PageHeading as PHeading } from 'modules/profile/components/styledComponents';
import {
  SecondaryButton,
  SubmitButton as StyledSubmitBtn,
} from 'common/components/styled-components';

// settings page.
export const PageContents = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  ${isTabletOrLarger(css`
    justify-content: space-between;
    flex-direction: row;
  `)};
  ${isDesktopOrLarger(css`
    padding-top: 20px;
    width: 85%;
  `)};
`;

export const PageHeading = styled<any>(PHeading)``;

// Settings Side Bar
export const SideBar = styled.ol<{ color: string }>`
  // consider nav instead of ol
  padding-left: 0;
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
  color: ${({ color, theme: { colors } }) =>
    (color !== 'light' ? colors.colorText : colors.colorPrimary3)};
  ${isTabletOrLarger(css`
    margin-bottom: initial;
  `)}
`;

export const Tab = styled.li<{ active: boolean }>`
  display: inline-block;
  list-style: none;
  padding: 0.5rem 0;
  margin-bottom: 0.5rem;
  cursor: pointer;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: -0.025px;
  color: ${({ active, theme: { colors } }) => active && colors.colorSuccess};
  font-weight: ${({ active }) => active && 500};
`;

export const ActiveTab = styled<any>(Tab)``;
// End Settings Side Bar

// page cards
export const SettingsCard = styled.div`
  ${setFlex({ x: 'start', y: 'center' })};
  flex-direction: column;
  width: 100%;
  border: 1px solid ${({ theme: { colors } }) => colors.colorBorder};
  border-radius: 5px;
  padding: 48px 20px;
  ${isTabletOrLarger`
    width: 500px;
    margin-bottom: initial;
  `}
`;

export const Heading = styled.h3`
  font-weight: 500;
  font-size: 1.8rem;
  margin: 0 auto;
`;

export const Body = styled.div`
  width: 100%;
  margin: 1.8rem auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const ProfileBody = styled<any>(Body)`
  margin: 2.8rem auto 5.8rem auto;
`;

export const Instruction = styled.div`
  margin-top: 2.6rem;
  font-size: 1.6rem;
`;

export const Button = styled<any>(SecondaryButton)`
  font-size: 16px;
  width: 80%;
  height: 5rem;
  border-radius: 0.5rem;
`;

export const SubmitButton = styled<any>(StyledSubmitBtn)`
  height: 5rem;
  width: 75%;
  position: relative;
  ${setFlex()};
  ${isTabletOrLarger`
    width: 100%;
  `};
`;

export const DeleteButton = styled<any>(SubmitButton)`
  color: ${({ theme: { colors } }) => colors.mainRed};
  background: ${({ theme: { colors } }) => colors.lightRed};
  ${isTabletOrLarger`
    width: 80%;
  `};
`;

export const Form = styled.form`
  margin-top: 4rem;
  width: 80%;
  ${setFlex({ x: 'center', y: 'center' })};
  flex-direction: column;
`;

export const InputWithButton = styled.div`
  ${setFlex({ x: 'start', y: 'center' })};
  width: 100%;
`;

export const InputSubmitButton = styled<any>(SecondaryButton)`
  padding: 16px;
  position: relative;
  border: unset;
  border-radius: unset;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  ${setFlex()};
`;

export const FormGroup = styled.div`
  width: 100%;
  ${setFlex({ x: 'start', y: 'center' })};
  flex-direction: column;
  margin-bottom: 4rem;
`;

export const EditProfileFormGroup = styled<any>(FormGroup)`
  margin-bottom: 0px;
  width: 100%;
`;

export const ValidationFailMsg = styled.span`
  color: ${({ theme: { colors } }) => colors.mainRed};
`;

export const ValidationSuccessMsg = styled.span`
  color: green;
`;

export const Label = styled.label`
  font-size: 1.4rem;
  align-self: start;
  margin-bottom: 1.6rem;
`;

export const InputField = styled.input`
  width: 100%;
  height: 5rem;
  background: unset;
  border: 1px solid ${({ theme: { colors } }) => colors.colorBorder};
  color: inherit;
  border-radius: 5px;
  font-size: 1.6rem;
  padding: 10px;
  outline: unset;
`;

export const ProfileInputField = styled<any>(InputField)`
  border-top-right-radius: unset;
  border-bottom-right-radius: unset;
`;

export const ThemeSelect = styled.div`
  font-size: 1.4rem;
  margin-top: 24px;
  ${setFlex({ x: 'space-between', y: 'center' })};
`;

export const LabelName = styled.span``;

export const SelectorWrapper = styled.div`
  position: relative;
  box-sizing: border-box;
  display: flex;
`;
export const SelectorIconStyles = css`
  position: absolute;
  z-index: 30;
  top: 0;
  right: 18px;
  height: 50px;
  display: flex;
  align-items: center;
  pointer-events: none;
  border: none;
  border-radius: 0 8px 8px 0;
`;

export const SelectBtn = styled.select`
  width: 180px;
  height: 50px;
  padding: 0 50px;
  font-size: 100%;
  color: ${({ theme: { colors } }) => colors.colorText};
  background: ${({ theme: { colors } }) => colors.colorBackground};
  font-weight: bold;
  border: 1px solid ${({ theme: { colors } }) => colors.colorBorder};
  border-radius: 8px;
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  appearance: none;
  &::-ms-expand {
    display: none;
  }
  outline: none;

  ${isTabletOrLarger(css`
    width: 200px;
  `)};
`;

export const SelectOption = styled.option`
  padding: 10px;
`;
