import styled, { css } from 'styled-components';
import {
  setFont,
  setPosition,
  setZindex,
} from 'app/globalStyles/variables';
import { isTabletOrLarger } from 'app/globalStyles/screens';
import { CloseIcon } from 'static/assets';

export const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  height: 100%;
  right: 0;
  z-index: ${setZindex.high};
  background: ${({ theme: { colors } }) => colors.colorOverlay};
  overflow-y: hidden;
`;

export const Modal = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 95vw;
  border: 1px solid ${({ theme: { colors } }) => colors.colorBorder};
  background: ${({ theme: { colors } }) => colors.colorBackground};
  font-family: ${setFont.main};
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  border-radius: 4px;
  outline: unset;
  padding: 20px;
  width: 90vw;
  max-height: 95vh;
  z-index: ${setZindex.critical};
  ${isTabletOrLarger(css`
      width: 600px;
      max-height: 98vh;
  `)};
`;

export const CloseModal = styled.button`
  position: ${setPosition.absolute};
  top: 10px;
  right: 10px;
  font-size: 3rem;
  font-weight: 400;
  width: 30px;
  height: 40px;
  border: unset;
  outline: unset;
  cursor: pointer;
  background: transparent;
  ${isTabletOrLarger(css`
    width: 40px;
  `)};
  &:hover {
    background: ${({ theme: { colors } }) => colors.colorPrimary4};
    border-radius: 50px;
  }
`;

export const Close = styled<any>(CloseIcon)`
  width: 20px;
  height: 20px;
  path {
    fill: ${({ theme: { colors } }) => colors.colorPrimary3};
    stroke: ${({ theme: { colors } }) => colors.colorPrimary3};
  }
`;

export const ModalHeading = styled.h4`
  font-size: 3.2rem;
  line-height: 4.3rem;
  text-align: center;
  letter-spacing: -0.444444px;
  font-family: ${setFont.headings};
  margin: 5rem auto 3rem auto;
`;
