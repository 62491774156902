import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { actions as journalActions } from 'modules/thoughts/journals/state/journals.slice';
import { EDITOR } from 'modules/thoughts/editor/constants';
import type { Journal, JournalVerse, Note } from 'modules/thoughts/journals/utils/journalsApiUtils';
import type { RootState } from 'app/state/store';

export interface State {
  editorThought: Journal | Note | null;
  isSavingThought: boolean;
  isUpdatingThought: boolean;
  saveThoughtErrorMessage: string;
  journalVerse: JournalVerse | null;
  createJournalErrorMsg: string;
  fetchingEditorThoughtError: string;
}

export const initialState: State = {
  editorThought: null,
  isSavingThought: false,
  isUpdatingThought: false,
  saveThoughtErrorMessage: '',
  journalVerse: null,
  createJournalErrorMsg: '',
  fetchingEditorThoughtError: '',
};

export const editorSlice = createSlice({
  name: EDITOR,
  initialState,
  reducers: {
    fetchEditorThoughtStarted: (state) => state,

    fetchEditorThoughtSucceeded: (state, action: PayloadAction<Journal>) => ({
      ...state, editorThought: action.payload
    }),

    fetchEditorThoughtFailed: (state, action: PayloadAction<{ error: string }>) => {
      const { error } = action.payload;
      return {
        ...state,
        fetchingEditorThoughtError: error,
      };
    },

    setEditorThought: (state, action: PayloadAction<Journal | Note | null>) => ({
      ...state, editorThought: action.payload,
    }),

    clearNewEditorVerse: (state) => ({
      ...state,
      journalVerse: null,
    })

  },
  extraReducers: (builder) =>
    builder
      .addCase(journalActions.saveJournalSucceeded, (state, action: PayloadAction<Journal>) => ({
        ...state,
        editorThought: action.payload,
        isSavingThought: false,
      }))
      .addCase(journalActions.saveJournalFailed, (state, action:
        PayloadAction<{ error: string }>) => {
        const { error } = action.payload;
        return {
          ...state,
          isSavingThought: false,
          createJournalErrorMsg: error,
        };
      })
      .addCase(journalActions.updateJournalStarted, (state) => { state.isUpdatingThought = true; })
      .addCase(journalActions.updateJournalSucceeded, (state, action: PayloadAction<Journal>) => ({
        ...state,
        editorThought: action.payload,
        isUpdatingThought: false,
      }))
      .addCase(journalActions.updateJournalFailed, (state) => { state.isUpdatingThought = false; })
});

export const { actions } = editorSlice;

export const selectEditor = (state:RootState): State => state[EDITOR];

export const editorReducer = editorSlice.reducer;
