import React from 'react';
import styled, { css } from 'styled-components';
import { ChevronLeft, ChevronRight } from 'styled-icons/boxicons-regular';
import { setPosition } from 'app/globalStyles/variables';
import {
  isTabletOrSmaller,
  isDesktopOrLarger,
  isLargeTablet,
} from 'app/globalStyles/screens';
import type { Chapter, Verse } from 'modules/reading/utils/api/chapterApiUtils';
import Link from 'next/link';

const StyledButton = styled(Link)`
  position: ${setPosition.fixed};
  bottom: 50vh;
  cursor: pointer;
  border: 1px solid ${({ theme: { colors } }) => colors.colorBorder};
  border-radius: 50%;
  outline: unset;
  background: unset;
  ${isTabletOrSmaller(css`
    background: ${({ theme: { colors } }) => colors.colorBackground};
    z-index: 3;
    bottom: 20px;
  `)}

  &:hover {
    transform: translateY(-2%);
    transition: all 0.3s ease-in-out;
  }
`;

const ButtonNext = styled<any>(StyledButton)<{
  nextprevVisible?: boolean;
  isVersePage?: boolean;
}>`
  right: 2.5%;
  transition: visibility 0.5s ease-in-out;
  ${isLargeTablet`right: 6%`};
  ${isDesktopOrLarger(css`
    right: 15%; // 6.30208vw;
  `)};
`;

const ButtonPrev = styled<any>(StyledButton)<{
  nextprevVisible?: boolean;
  isVersePage?: boolean;
}>`
  left: 2.5%;
  transition: visibility 0.5s ease-in-out;
  ${isLargeTablet`left: 6%`};
  ${isDesktopOrLarger(css`
    left: 15%; // 6.30208vw;
  `)}
`;

const IconNext = styled(ChevronRight)`
  width: 40px;
  color: ${({ theme: { colors } }) => colors.colorText};
  ${isDesktopOrLarger(css`
    width: 50px;
  `)}
`;
const IconPrev = styled(ChevronLeft)`
  width: 40px;
  color: ${({ theme: { colors } }) => colors.colorText};
  ${isDesktopOrLarger(css`
    width: 50px;
  `)}
`;

export interface Props {
  prevItem: Chapter | Verse | null;
  nextItem: Chapter | Verse | null;
  handlePrev: (event) => void;
  handleNext: (event) => void;
  nextPrevVisible?: boolean;
}

export const NextPrevIcons = ({
  prevItem,
  nextItem,
  handlePrev,
  handleNext,
  nextPrevVisible,
}: Props) => (
  <React.Fragment>
    {prevItem && (
      <ButtonPrev href="#" onClick={handlePrev} nextprevVisible={nextPrevVisible}>
        <IconPrev />
      </ButtonPrev>
    )}
    {nextItem && (
      <ButtonNext href="#" onClick={handleNext} nextprevVisible={nextPrevVisible}>
        <IconNext />
      </ButtonNext>
    )}
  </React.Fragment>
);

export default NextPrevIcons;
