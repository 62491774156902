import React from 'react';
import { useAppDispatch, useAppSelector } from 'common/hooks/state-hooks';
import { selectMembership } from 'modules/membership';
import { selectUser } from 'modules/auth';
import { cancelSubscription } from 'modules/membership/state/membership.actions';
import GetMembership from 'modules/profile/components/GetSettingsMembershipCTA';
import Toast from 'common/components/Toast';
import ConfirmDeleteModal from './ConfirmDelete';
import {
  ActionArrow,
  CustomerDetails,
  Detail,
  DetailAction,
} from './__styles__/Membership.styles';
import { Heading, SettingsCard } from './__styles__/SettingsV2.styles';

const warningInstruction = `This will cancel your subscription but you will still have membership access till the end of your current billing cycle.
Are you sure you want to do this?`;
const getMembershipInstruction = "You're not a Member. Please become a member and enjoy exclusive benefits while supporting the Stoic Bible project.";

const Membership = () => {
  const { subscription, isCancelingSubscription } = useAppSelector(selectMembership);
  const user = useAppSelector(selectUser);
  const dispatch = useAppDispatch();
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const isSubscriptionActive = subscription && subscription.status === 'active';
  const openModal = () => setIsOpen(true);
  const closeModal = () => setIsOpen(false);

  const onCancelSuccess = () => {
    Toast({
      type: 'info',
      message:
        'Your subscription has been canceled. Your membership access will end at end of the current billing cycle.',
    });
  };

  const handleCancelSubscription = async (event) => {
    event.preventDefault();
    if (subscription) {
      dispatch(cancelSubscription(subscription.stripeId, onCancelSuccess));
    }
  };

  const { platform } = subscription || {};
  const isAndroidSubscription = platform === 'android';
  const isIosSubscription = platform === 'ios';
  const isWebSubscription = platform !== 'ios' && platform !== 'android';

  const getSubscriptionPlan = () => {
    if (isSubscriptionActive) {
      const isMobileSubscription = isAndroidSubscription || isIosSubscription;
      if (isWebSubscription) {
        return subscription?.priceId
          === process.env.NEXT_PUBLIC_STRIPE_MONTHLY_PRICE_ID
          ? 'Monthly'
          : 'Yearly';
      }
      if (isMobileSubscription) {
        return subscription?.iapSku === 'stoicbible_monthly'
          ? 'Monthly'
          : 'Yearly';
      }
    }
    return null;
  };

  const getSubscriptionPlatform = () => {
    if (isIosSubscription) {
      return 'IOS';
    }
    if (isAndroidSubscription) {
      return 'Android';
    }
    return 'Web';
  };

  const renderExpirationStatus = () => {
    if (!subscription) {
      return null;
    }
    const { currentPeriodEnd } = subscription;
    return new Date(currentPeriodEnd).toDateString();
  };

  return (
    <SettingsCard>
      <Heading>Your Stoic Bible Membership</Heading>
      {isSubscriptionActive ? (
        <CustomerDetails>
          <Detail>
            <div>Current Plan</div>
            <div>{getSubscriptionPlan()}</div>
          </Detail>
          <Detail>
            <div>Plan Expiry</div>
            <div>{renderExpirationStatus()}</div>
          </Detail>
          {isWebSubscription && (
            <Detail>
              <div>Credit Card</div>
              <div>
                {subscription?.paymentMethodBrand?.toUpperCase()}
                &bull;&bull;&bull;
                {subscription?.paymentMethodLast4}
              </div>
            </Detail>
          )}
          <Detail>
            <div>Subscription Platform</div>
            <div>{getSubscriptionPlatform()}</div>
          </Detail>
          {isWebSubscription && (
            <Detail>
              <DetailAction type="button" onClick={openModal}>
                <span>Cancel Subscription</span>
                <ActionArrow />
              </DetailAction>
            </Detail>
          )}
        </CustomerDetails>
      ) : (
        <GetMembership instruction={getMembershipInstruction} />
      )}
      {modalIsOpen && (
        <ConfirmDeleteModal
          user={user}
          closeModal={closeModal}
          handleSubmit={handleCancelSubscription}
          isDeletingAccount={isCancelingSubscription}
          warningInstruction={warningInstruction}
          title="Cancel Subscription"
          submitButtonText="Cancel My Subscription"
        />
      )}
    </SettingsCard>
  );
};

export default Membership;
