import React from 'react';
import type {
  CSSProp,
  DefaultTheme } from 'styled-components';
import styled, {
  ThemeProvider
} from 'styled-components';
import { useAppSelector } from 'common/hooks/state-hooks';
import {
  colors,
  dark2Theme,
  darkTheme,
  dimTheme,
} from 'app/globalStyles/variables';
import GlobalStyles from 'app/globalStyles';
import CustomNProgress from 'common/page-layout/CustomNProgress';
import VerifyEmailNotice from 'modules/auth/components/VerifyEmailNotice';
import { selectProfile } from 'modules/profile';
import { selectSettings } from 'modules/settings';
import Footer from './Footer';
import Header from './Header/Header';


type Props = {
  children?: any;
  header?: JSX.Element;
  layoutStyles?: CSSProp;
  allowFooter?: boolean;
};

const StyledLayout = styled.div``;

const getThemeProps = (theme: string): DefaultTheme => {
  if (theme === 'dark') {
    return {
      isDarkTheme: true,
      colors: darkTheme.colors,
    };
  }
  if (theme === 'dark2') {
    return { colors: dark2Theme.colors, isDarkTheme: true };
  }
  if (theme === 'dim') {
    return { colors: dimTheme.colors, isDarkTheme: true };
  }
  return { colors };
};

export const Layout = ({
  children,
  header,
  layoutStyles,
  allowFooter = true,
}: Props): JSX.Element => {
  const { user } = useAppSelector(selectProfile);
  const { theme } = useAppSelector(selectSettings);
  const showVerifyEmailNotice = !!user && !user.emailVerified;
  return (
    <ThemeProvider theme={getThemeProps(theme)}>
      <CustomNProgress options={{ easing: 'ease', speed: 200 }} />
      <GlobalStyles />
      <StyledLayout css={layoutStyles} id="rendered-page">
        {header || <Header />}
        {showVerifyEmailNotice && <VerifyEmailNotice />}
        <main style={{ minHeight: '90vh', position: 'relative' }}>
          {children}
        </main>
        {allowFooter && <Footer />}
      </StyledLayout>
    </ThemeProvider>
  ); 
};


export default Layout;
