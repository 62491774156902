import { HighlightFill, NoteFill, OpenQuote } from 'static/assets';
import { BookmarksIcon } from 'common/page-layout/Header/__styles__/ProfileDropdown.styles';
import {
  isTabletOrLarger,
  isTablet,
} from 'app/globalStyles/screens';
import { setFlex } from 'app/globalStyles/variables';
import { Text, Flex } from 'common/components/styled-components';
import React from 'react';
import styled, { css } from 'styled-components';
import { memberShipBenefitIconstyles } from 'modules/membership/components/styled';

const StyledBenefits = styled.div`
  padding: 1rem;
  ${setFlex({ x: 'flex-start', y: 'stretch' })};
  flex-direction: column;
  background: ${(p) => p.theme.colors.colorPrimary4};
  border: 1px solid ${(p) => p.theme.colors.colorBorder};
  border-radius: 16px;
  max-width: 502px;

  margin-bottom: 4rem;
  width: 90%;
  ${isTablet(css`
    margin-top: 60px;
  `)};
  ${isTabletOrLarger(css`
    margin-bottom: initial;
    width: initial;
  `)}
`;

const BenefitContent = styled.div`
  background: ${(p) => p.theme.colors.colorBackground};
  border-top: 2px solid ${(p) => p.theme.colors.colorBorder};
  width: 100%;
  min-height: 300px;
  border-radius: 0 0 16px 16px;
  color: ${(p) => p.theme.colors.colorText};
  font-size: 17.96px;
  line-height: 30px;
  padding: 2rem;
  ul {
    margin-left: 2rem;
  }
  ${isTabletOrLarger(css`
    font-size: 17.96px;
    line-height: 34px;
    padding: 2.5rem;
     ul {
      margin-left: 3rem;
    }
  `)}
`;
// @ts-ignore
const MembershipCardHeading = styled(Flex)`
    height: 70px;
    h1 {
      font-size: 2rem;
    }
  
`;

const BenefitsCard = (): JSX.Element => (
  <StyledBenefits>
    <MembershipCardHeading height="100px">
      <Text
        as="h1"
        letterSpacing="-0.459204px"
        color="colorTitle"
        fontSize="29.3891px"
        fontWeight="400"
        line-height="36px"
      >
        Membership Benefits
      </Text>
    </MembershipCardHeading>

    <BenefitContent>
      <ul>
        <li> Enjoy an ad-free experience</li>
        <li> Unlock access to all books</li>
        <li> Write unlimited private and public journals</li>
        <li>
          Make it your own, by:
          <ul css="list-style: none;">
            <li>
              <BookmarksIcon css={memberShipBenefitIconstyles.iconStyles} />
              Highlighting
            </li>
            <li>
              <HighlightFill css={memberShipBenefitIconstyles.svgIconStyle} />
              Bookmarking
            </li>
            <li>
              <NoteFill css={memberShipBenefitIconstyles.svgIconStyle} />
              Taking notes
            </li>
            <li>
              <OpenQuote css={memberShipBenefitIconstyles.svgIconStyle} />
              Making quotes(coming soon)
            </li>
          </ul>
        </li>
        <li> Use app in Night Mode</li>
        <li>
          Support the development of a great product for practising Stoicism.
        </li>
      </ul>
    </BenefitContent>
  </StyledBenefits>
);

export default BenefitsCard;
