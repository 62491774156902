import { persistReducer } from 'redux-persist';
import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage';
import { PROFILE } from 'modules/profile/constants';
import { actions as authActions } from 'modules/auth';
import type { AuthResponse } from 'modules/auth/state/auth.actions';
import type { User } from 'modules/profile/utils/profileApiUtils';
import type { RootState } from 'app/state/store';

export interface State {
  user: User | null;
  isUsernameAvailable: boolean | null;
  isUpdatingUsername: boolean;
  updateUsernameErrMessage?: string;
  isUpdatingName: boolean;
  updateNameErrMessage?: string;
  isUpdatingEmail: boolean;
  updateEmailErrMessage: string | null;
  updateEmailSuccessMessage: string | null;
  isUpdatingPassword: boolean;
  updatePasswordSuccessMessage: string | null;
  updatePasswordErrMessage: string | null;
  isDeletingAccount: boolean;
}

export const initialState: State = {
  user: null,
  isUsernameAvailable: null,
  isUpdatingUsername: false,
  updateUsernameErrMessage: '',
  isUpdatingName: false,
  updateNameErrMessage: '',
  isUpdatingEmail: false,
  updateEmailErrMessage: null,
  updateEmailSuccessMessage: null,
  isUpdatingPassword: false,
  updatePasswordSuccessMessage: null,
  updatePasswordErrMessage: null,
  isDeletingAccount: false,
};

const setUser = (state, action: PayloadAction<AuthResponse>) => {
  state.user = action.payload.user;
};

const profileSlice = createSlice({
  name: PROFILE,
  initialState,
  reducers: {
    checkUsernameAvailabilityStarted: (state) => state,
    checkUsernameAvailabilitySucceeded: (
      state,
      action: PayloadAction<{ isUsernameAvailable: boolean }>
    ) => {
      const { isUsernameAvailable } = action.payload;
      return {
        ...state,
        isUsernameAvailable,
      };
    },
    
    checkUsernameAvailabilityFailed: (state) => state,

    updateUsernameStarted: (state) => {
      state.isUpdatingUsername = true;
    },

    updateUsernameSucceeded: (state, action: PayloadAction<User>) => {
      const user = action.payload;
      
      return {
        ...state,
        user,
        isUpdatingUsername: false,
      };
    },

    updateUsernameFailed: (state, action: PayloadAction<{ error: string}>) => {
      state.isUpdatingUsername = false;
      state.updateUsernameErrMessage = action.payload.error;
    },

    resetUpdateUsernameNotification: (state) => ({
      ...state,
      updateUsernameErrMessage: undefined,
    }),

    // update full name
    updateNameStarted: (state) => {
      state.isUpdatingName = true;
    },

    updateNameSucceeded: (state, action: PayloadAction<User>) => {
      const user = action.payload;
      return {
        ...state,
        user,
        isUpdatingName: false,

      };
    },

    updateNameFailed: (state, action: PayloadAction<{ error: string}>) => {
      state.isUpdatingName = false;
      state.updateNameErrMessage = action.payload.error;
    },

    resetUpdateNameNotification: (state) => ({
      ...state,
      updateNameErrMessage: undefined,
    }),

    // update email
 
    updateEmailStarted: (state) => {
      state.isUpdatingEmail = true;
    },

    updateEmailSucceeded: (state, action : PayloadAction<{ user: User, message: string}>) => {
      const { message } = action.payload;

      return {
        ...state,
        updateEmailSuccessMessage: message,
        isUpdatingEmail: false,

      };
    },

    updateEmailFailed: (state, action: PayloadAction<{ error: string}>) => {
      state.isUpdatingEmail = false;
      state.updateEmailErrMessage = action.payload.error;
    },

    resetUpdateEmailNotification: (state) => ({
      ...state,
      updateEmailErrMessage: null,
      updateEmailSuccessMessage: null,
    }),

    updatePasswordStarted: (state) => {
      state.isUpdatingPassword = true;
    },
    updatePasswordSucceeded: (state, action: PayloadAction<{ message: string }>) => {
      const { message } = action.payload;
      return {
        ...state,
        updatePasswordSuccessMessage: message,
        isUpdatingPassword: false,
      };
    },
    updatePasswordFailed: (state, action: PayloadAction<{ error: string }>) => {
      const { error } = action.payload;
      return {
        ...state,
        updatePasswordErrMessage: error,
        isUpdatingPassword: false,
      };
    },
    resetUpdatePasswordError: (state) => {
      state.updatePasswordErrMessage = '';
    },
    deleteAccountStarted: (state) => {
      state.isDeletingAccount = true;
    },
    deleteAccountSucceeded: (state) => {
      state.isDeletingAccount = false;
    },
    deleteAccountError: (state) => {
      state.isDeletingAccount = false;
    },
  },

  extraReducers: (builder) =>
    builder
      .addCase(authActions.signUpUserSucceeded, setUser)
      .addCase(authActions.signInUserSucceeded, setUser)
      .addCase(authActions.googleAuthSucceeded, setUser)
      .addCase(authActions.facebookAuthSucceeded, setUser)
      .addCase(authActions.confirmEmailSucceeded, setUser)
      .addCase(authActions.resendEmailVerificationSucceeded, setUser)
      .addCase(authActions.logoutUser, () => initialState),
});

export const {
  actions
} = profileSlice;

const persistConfig = {
  key: PROFILE,
  storage,
  blacklist: [
    'authError',
    'signInError',
    'authenticating',
    'updatePasswordErr',
  ], // We're not blacklisting the user because we need it.
};

export const profileReducer = persistReducer(
  persistConfig,
  profileSlice.reducer
);

export const selectProfile = (state: RootState): State => state[PROFILE];
