/* eslint-disable global-require,import/no-absolute-path,
import/no-unresolved,@typescript-eslint/no-var-requires */
import React, { useEffect } from 'react';
import Image from 'next/image';
import styled, { css } from 'styled-components';
import { Loader } from 'common/components/Loader';
import { CloseIcon } from 'static/assets';
import {
  Tab,
  CloseBtn,
  SectionMainHeader,
  SectionFirstHeader,
  MobileSectionHeader,
  BooksSection,
  Section,
  BookItem,
  BookItemDetail,
  ChapterItem,
  ChaptersContainer,
} from 'modules/reading/components/__styles__/SelectChapterModal.styles';
import type { Book } from 'modules/books/utils/booksApiUtils';
import type { Chapter } from 'modules/reading/utils/api/chapterApiUtils';

const StyledImage = styled(Image)`
  max-width: 80px;
  width: 80px;
  height: 60px;
  border-radius: 5px;
`;

// TODO.
const BookThumbnail = ({ book }: { book: Book }) => {
  let bookImg = '/defaultBookImg.jpg';
  const bookAuthor = book.author.toLowerCase();
  if (bookAuthor.includes('seneca')) {
    bookImg = '/books/senecaa.jpg';
  }
  if (bookAuthor.includes('aurelius')) {
    bookImg = '/books/aurelius.jpg';
  }
  if (bookAuthor.includes('epictetus')) {
    bookImg = '/books/epictetus.jpg';
  }

  return <StyledImage src={bookImg} alt={book.author} width={80} height={60} />;
};

const closeIconStyle = css`
  height: 20px;
  width: 20px;
  // ensures dynamically changing svg color in dark mode.
  path {
    stroke: ${({ theme: { colors } }) => colors.colorText};
  }
`;

export interface Props {
  closeChapterPicker: (e: any) => void;
  shouldNumberChapter: (bookSlug: string) => boolean;
  selectedBook: Book | null;
  selectBook: (e: any) => void;
  isBooksLoading: boolean;
  books: Book[];
  currentBook: Book | null;
  selectChapter: any;
  currentBookChapters?: Chapter[];
  currentChapter: Chapter | null;
  activeTab: any;
  setActiveTab: any;
}

export const SelectChapter = ({
  activeTab,
  setActiveTab,
  closeChapterPicker,
  isBooksLoading,
  books,
  selectedBook,
  selectBook,
  selectChapter,
  currentBookChapters,
  currentChapter,
  shouldNumberChapter,
  currentBook,
}: Props): JSX.Element => {
  let pickerModalRef: HTMLDivElement | null;
  const onChapterSelect = (chapter) => {
    selectChapter(chapter);
    setActiveTab({
      Universal: false,
      Books: false,
      Chapters: true,
    });
  };
  const tabBooks = () =>
    setActiveTab({ ...activeTab, Books: true, Chapters: false });

  const tabChapters = () =>
    setActiveTab({ ...activeTab, Books: false, Chapters: true });

  const closePicker = (e) => {
    if (pickerModalRef && !pickerModalRef.contains(e.target)) {
      closeChapterPicker(e);
    }
  };
  useEffect(() => {
    document.addEventListener('mousedown', closePicker);
    return () => {
      document.removeEventListener('mousedown', closePicker);
    };
  });

  return (
    <Section
      ref={(ref) => {
        pickerModalRef = ref;
      }}
    >
      <SectionMainHeader>
        <SectionFirstHeader>
          {selectedBook && selectedBook.displayTitle
            ? selectedBook.displayTitle
            : currentBook?.displayTitle || 'Select a book'}
          <CloseBtn
            type="button"
            className="closeBtn"
            onClick={(e) => closeChapterPicker(e)}
          >
            <CloseIcon css={closeIconStyle} />
          </CloseBtn>
        </SectionFirstHeader>
        <MobileSectionHeader>
          <Tab onClick={tabBooks} activeTab={activeTab}>
            Books
          </Tab>
          <Tab onClick={tabChapters} activeTab={activeTab}>
            Chapters
          </Tab>
        </MobileSectionHeader>
      </SectionMainHeader>
      {activeTab.Books && !activeTab.Universal && (
        <BooksSection>
          {isBooksLoading ? (
            <Loader
              size="3rem"
              style={{
                marginTop: '50px',
              }}
            />
          ) : (
            books.map((book) => (
              <BookItem
                selected={!!selectedBook && selectedBook.id === book.id}
                key={book.id}
                onClick={() => selectBook(book)}
              >
                <BookThumbnail book={book} />

                <BookItemDetail>
                  <span>{book.displayTitle || book.title}</span>

                  <span>
                    <i>by</i>
                    {book.author}
                  </span>
                </BookItemDetail>
              </BookItem>
            ))
          )}
        </BooksSection>
      )}
      {activeTab.Chapters && (
        <>
          {selectedBook && (
            <ChaptersContainer>
              {selectedBook
                && currentBookChapters
                && currentBookChapters.map((chapter) => (
                  <ChapterItem
                    key={chapter.id}
                    onClick={() => onChapterSelect(chapter)}
                    selected={
                      !!currentChapter && currentChapter.id === chapter.id
                    }
                    isCentralized={
                      !shouldNumberChapter(
                        selectedBook.slug || (currentBook?.slug as string),
                      )
                    }
                  >
                    {shouldNumberChapter(
                      selectedBook.slug || (currentBook?.slug as string),
                    ) ? (
                      <span>
                        <span style={{ fontSize: '12px', opacity: 0.7 }}>
                          {`${chapter.number}. `}
                        </span>
                        {chapter.title}
                      </span>
                      ) : (
                        <span>{chapter.title}</span>
                      )}
                  </ChapterItem>
                ))}
            </ChaptersContainer>
          )}
        </>
      )}
    </Section>
  );
};

export default SelectChapter;
