import { StyledVerseContainer } from 'modules/reading/components/__styles__/Verse.styles';
import React, { useEffect, useState } from 'react';
import VerseWithJournals, {
  VerseHeader,
} from 'modules/reading/components/VerseWithJournals';
import { useAppDispatch, useAppSelector } from 'common/hooks/state-hooks';
import { useRouter } from 'next/router';
import ChapterVerse from 'modules/reading/components/ChapterVerse';
import NextPrevIcons from 'modules/reading/components/NextPrevIcons';
import NavigateBack from 'common/components/NavigateBack';
import SecondaryHeader from 'common/page-layout/SecondaryHeader';
import { fetchPublicJournalsForVerse } from 'modules/reading/state/actions/journals.actions';
import {
  fetchChapterHighlights,
  addHighlight,
  removeHighlight,
  updateHighlight,
} from 'modules/reading/state/actions/highlights.actions';
import styled from 'styled-components';
import { routeTo, routeAs } from 'common/utils/routes';
import { ChapterLockReasons } from 'modules/reading/utils/readingUtils';
import { saveBookmark } from 'modules/reading/state/actions/bookmarks.actions';
import SaveBookmarkModal from 'modules/reading/components/SaveBookmarkModal';
import ChapterAuthLock from 'modules/reading/components/ChapterAuthLock';
import ChapterMembershipLock from 'modules/reading/components/ChapterMembershipLock';
import Layout from 'common/page-layout/Layout';
import type { Verse } from 'modules/reading/utils/api/chapterApiUtils';
import { selectProfile } from 'modules/profile';
import { selectMembership } from 'modules/membership';
import { 
  selectCurrentChapter, 
  selectReaderSettings,
  selectCurrentChapterBookmark,
  selectCurrentChapterHighlights,
  selectReadingJournals
} from 'modules/reading';

const StyledNavigateBack = styled<any>(NavigateBack)`
  margin-left: unset;
  position: absolute;
  left: 12%;
`;

// export type Props = StateProps & DispatchProps;

// Todo: Handle scenario where a user passes in
//  verseNumber from the route for which no verse exists.

const StyledVerse = styled.div<{
  fontFamily: string;
  fontSize: number;
}>`
  position: relative;
  font-family: ${({ fontFamily }) => fontFamily};
  font-size: ${({ fontSize }) => `${fontSize}rem`};
  line-height: 1.6;
  letter-spacing: ${({ fontFamily }) =>
    (fontFamily === 'Inter' ? '-0.3px' : 0)}; // todo
  color: ${({ theme: { colors } }) => colors.colorText};
  background: ${({ theme: { colors } }) => colors.colorBackground};
  text-align: left;
  white-space: pre-wrap;
  margin-bottom: 2rem;
`;

const VersePage = () => {
  const dispatch = useAppDispatch();
  const {
    currentBook,
    currentChapter,
    currentChapterVerses,
    chapterLock,
  } = useAppSelector(selectCurrentChapter);
  const {
    verseJournals, 
    isLoadingVerseJournals
  } = useAppSelector(selectReadingJournals);
  const { chapterHighlights } = useAppSelector(selectCurrentChapterHighlights);
  const { isBookmarking } = useAppSelector(selectCurrentChapterBookmark);
  const { fontFamily, fontSize, shouldShowVerseNumbers } = useAppSelector(selectReaderSettings);
  const { user } = useAppSelector(selectProfile);
  const { isMemberOrFreeTrialUser } = useAppSelector(selectMembership);
  const isEmailNotVerified = user?.emailVerified === false;
  const isAuthenticated = !!user;
  const lockReason = chapterLock?.reason;

  const router = useRouter();
  const { verseNumber } = router.query;

  const currentVerseNumber = parseInt(verseNumber as string, 10);
  const getVerseByNumber = (number: number) =>
    currentChapterVerses.find((verse: Verse) => verse.number === number)
    || null;

  const currentVerse = getVerseByNumber(currentVerseNumber);
  const prevVerse = getVerseByNumber(currentVerseNumber - 1);
  const nextVerse = getVerseByNumber(currentVerseNumber + 1);
  const verseTitle = `${currentBook?.title} ${currentChapter?.number} : ${currentVerse?.number}`;

  const handlePrev = () =>
    router.push(
      routeTo.verse,
      routeAs.verse(currentChapter?.slug as string, currentVerseNumber - 1),
    );
  const handleNext = () =>
    router.push(
      routeTo.verse,
      routeAs.verse(currentChapter?.slug as string, currentVerseNumber + 1),
    );

  const handleKeyDown = (event) => {
    if (event.key === 'ArrowRight' && nextVerse) {
      handleNext();
    } else if (event.key === 'ArrowLeft' && prevVerse) {
      handlePrev();
    }
  };

  const backToChapter = () =>
    router.push(
      routeTo.chapter,
      routeAs.chapter(currentChapter?.slug as string),
    );

  useEffect(() => {
    if (currentChapter && isAuthenticated) {
      dispatch(fetchChapterHighlights(currentChapter.id));
    }
    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [verseNumber]);

  const [targetedVerse, setTargetVerse] = useState<Verse | null>(null);
  const [isBookmarkModalOpen, setIsBookmarkModalOpen] = useState(false);

  const setTargetedVerse = (verse: Verse) => {
    setTargetVerse(verse);
  };

  const openBookmarkModal = () => setIsBookmarkModalOpen(true);
  const closeBookmarkModal = () => setIsBookmarkModalOpen(false);

  const refs = currentChapterVerses.reduce((acc, value) => {
    acc[value.number] = React.createRef();
    return acc;
  }, {});

  const renderVerse = () => {
    if (currentBook && currentChapter && currentVerse) {
      return (
        <StyledVerse
          fontFamily={fontFamily}
          fontSize={fontSize}
          ref={refs[currentVerse.number]}
        >
          <ChapterVerse
            key={currentVerse.number}
            verse={currentVerse}
            highlights={chapterHighlights[currentVerse.id]}
            currentBook={currentBook}
            currentChapter={currentChapter}
            activeVerse={targetedVerse}
            setActiveVerse={setTargetedVerse}
            isEmailNotVerified={isEmailNotVerified}
            isMemberOrFreeTrialUser={isMemberOrFreeTrialUser}
            isAuthenticated={isAuthenticated}
            addHighlight={addHighlight}
            updateHighlight={updateHighlight}
            removeHighlight={removeHighlight}
            shouldShowVerseNumbers={shouldShowVerseNumbers}
            openBookmarkModal={openBookmarkModal}
          />
        </StyledVerse>
      );
    }
    return null;
  };

  return (
    <Layout allowFooter={false}>
      <SecondaryHeader>
        <StyledNavigateBack goBack={backToChapter} backTitle="Back" />
        <VerseHeader title={verseTitle} />
      </SecondaryHeader>

      <StyledVerseContainer>
        {currentVerse && (
          <VerseWithJournals
            verseTitle={verseTitle}
            verseText={currentVerse?.text as string}
            chapterSlug={currentChapter?.slug as string}
            fontFamily={fontFamily}
            fontSize={fontSize}
            verse={currentVerse} // todo
            fetchPublicJournalsForVerse={fetchPublicJournalsForVerse}
            renderVerse={renderVerse}
            journals={verseJournals[currentVerse.id]}
            isLoadingVerseJournals={isLoadingVerseJournals}
          />
        )}
        {lockReason === ChapterLockReasons.AUTH && <ChapterAuthLock />}
        {currentBook && lockReason === ChapterLockReasons.MEMBERSHIP && (
          <ChapterMembershipLock currentBook={currentBook} />
        )}
      </StyledVerseContainer>

      {isBookmarkModalOpen && currentBook && currentChapter && (
        <SaveBookmarkModal
          isBookmarking={isBookmarking}
          currentBook={currentBook}
          currentChapter={currentChapter}
          currentChapterVerses={currentChapterVerses}
          saveBookmark={saveBookmark}
          closeModal={closeBookmarkModal}
          activeVerse={currentVerse}
        />
      )}

      <NextPrevIcons
        prevItem={prevVerse}
        nextItem={nextVerse}
        handlePrev={handlePrev}
        handleNext={handleNext}
        nextPrevVisible
      />
    </Layout>
  );
};

export default VersePage;
