/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
// Todo: Add routes for remaining pages
// Some of the `as` paths are unused but I'll still leave them here for the sake of convention

const routes = {
  routeTo: {
    landing: '/',
    admin: '/admin',
    adminBooks: '/admin/books',
    home: '/home',
    login: '/login',
    signup: '/signup',
    books: '/books',
    book: '/books/[slug]',
    read: '/read',
    chapter: '/read/[slug]',
    verse: '/read/[slug]/verses/[verseNumber]',
    notes: '/[username]/notes',
    note: '/[username]/notes/[slug]',
    journals: '/[username]/journals',
    journal: '/[username]/journals/[slug]',
    highlights: '/[username]/highlights',
    bookmarks: '/[username]/bookmarks',
    settings: '/[username]/settings',
    searchResult: (searchString: string) => `/search?q=${searchString}`,
    searchedVerse: '/search/verses/[verseSlug]',
    newJournal: '/write/new-journal',
    editJournal: '/write/[slug]',
    membership: '/membership',
    donate: '/donate',
    about: '/about',
    terms: '/terms',
    privacy: '/privacy',
    features: '/features',
    feedback: '/feedback',
    faqs: '/faqs',
    editBook: '/admin/books/[bookId]/edit',
    app: '/app',
  },
  routeAs: {
    landing: '/',
    login: '/login',
    signup: '/signup',
    books: '/books',
    book: (slug: string) => `/books/${slug}`,
    read: '/read',
    chapter: (slug: string) => `/read/${slug}`,
    verse: (chapterSlug: string, verseNumber: number) =>
      `/read/${chapterSlug}/verses/${verseNumber}`,
    notes: (username: string) => `/@${username}/notes`,
    note: (username: string, slug: string) => `/@${username}/notes/${slug}`,
    journals: (username: string) => `/@${username}/journals`,
    journal: (username: string, slug: string) =>
      `/@${username}/journals/${slug}`,
    highlights: (username: string) => `/@${username}/highlights`,
    bookmarks: (username: string) => `/@${username}/bookmarks`,
    settings: (username: string) => `/@${username}/settings`,
    searchResult: (searchString: string) => `/search?q=${searchString}`,
    searchedVerse: (chapterSlug: string, verseNumber: number) =>
      `/search/verses/${chapterSlug}.${verseNumber}`,
    newJournal: '/write/new-journal',
    editJournal: (slug: string) => `/write/${slug}`,
    membership: '/membership',
    donate: '/donate',
    editBook: (bookId: number) => `/admin/books/${bookId}/edit`,
  },
};

export const { routeTo, routeAs } = routes;

export default routes;
