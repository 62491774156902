import styled, { css } from 'styled-components';
import { setFlex } from 'app/globalStyles/variables';
import {
  isTabletOrLarger,
  isDesktopOrLarger,
  isTablet,
} from 'app/globalStyles/screens';
import Link from 'next/link';

export const TabBar = styled.div`
  border-bottom: 1px solid ${({ theme: { colors } }) => colors.colorBorder};
  height: 60px;
  width: 100%;
  position: -webkit-sticky; /* Safari */
  position: sticky;
  top: 0;
  background: ${({ theme: { colors } }) => colors.colorBackground};
  z-index: 1;
  padding: 0;
  max-height: 70px;
  ${setFlex({ x: 'start', y: 'center' })};
  ${isTabletOrLarger`
    padding: initial;
    max-height: initial;
    ${setFlex({ x: 'initial', y: 'initial' })};
    display: initial;
  `};
`;

export const Tabs = styled.div`
  width: 90%;
  height: 100%;
  ${setFlex({ y: 'center' })};
  margin: 0 auto;
  ${isTablet(
    css`
      width: 86%;
    `,
  )};
  ${isDesktopOrLarger(css`
    width: 55%;
  `)};
`;

export const TabItem = styled(Link)<{ activeTab: boolean }>`
  border: 0;
  outline: unset;
  text-decoration: none;
  width: 100%;
  height: 25%;
  padding-right: 5px;
  padding-left: 35px;
  font-size: 1.4rem;
  ${setFlex({ x: 'start' })};
  border-bottom: none;
  font-weight: 500;
  &:last-of-type {
    margin-left: auto;
  }
  span {
    font-size: 1.4rem;
    color: ${({ theme: { colors } }) => colors.colorText};
    opacity: 0.8;
    line-height: 1.7rem;
    letter-spacing: -0.3px;
  }

  img {
    width: 1rem;
    margin-right: 5px;
  }

  ${({ activeTab, theme: { colors } }) =>
    isTabletOrLarger(css`
      width: 15%;
      height: 100%;
      padding-right: initial;
      padding-left: initial;
      ${activeTab
    ? `border-bottom: 3px solid ${colors.colorPrimary3}`
    : 'border-bottom: initial'};
      font-size: initial;
      ${setFlex()};
      img {
        width: initial;
        margin-right: initial;
      }
    `)}

  &:hover {
    cursor: pointer;
    background: ${({ theme: { colors } }) => colors.colorPrimary4};
    transition: all 0.3s ease-in-out;
  }
`;
