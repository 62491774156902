import { actions as authActions } from 'modules/auth/state/auth.slice';
import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { MEMBERSHIP } from 'modules/membership/constants';
import storage from 'redux-persist/lib/storage';
import { persistReducer } from 'redux-persist';
import type { RootState } from 'app/state/store';
import type { Subscription } from '../utils/membershipApiUtils';

export interface State {
  subscription: Subscription | null;
  isSubscribing: boolean;
  isSubscriptionActive: boolean;
  isFreeTrialUser: boolean;
  isMemberOrFreeTrialUser: boolean;
  isCancelingSubscription: boolean;
  subscribeErrMessage: string;
  trialDaysLeft: any;
}

export const initialState: State = {
  subscription: null,
  isSubscribing: false,
  isSubscriptionActive: false,
  isFreeTrialUser: false,
  isMemberOrFreeTrialUser: false,
  isCancelingSubscription: false,
  subscribeErrMessage: '',
  trialDaysLeft: null,
};

const authSuccessReducer = (state, action) => {
  state.isMemberOrFreeTrialUser = action.payload.isMemberOrFreeTrialUser;
};

const membershipSlice = createSlice({
  name: MEMBERSHIP,
  initialState,
  reducers: {
    purchaseSubscriptionStarted: (state) => {
      state.isSubscribing = true;
    },
    purchaseSubscriptionSucceeded: (state, action: PayloadAction<Subscription>) => {
      state.isSubscribing = false;
      const subscription = action.payload;
      state.subscription = subscription;
      state.isMemberOrFreeTrialUser = state.isMemberOrFreeTrialUser || subscription.status === 'active';
    },
    purchaseSubscriptionFailed: (state, action: PayloadAction<string>) => {
      state.isSubscribing = false;
      state.subscribeErrMessage = action.payload;
    },
    fetchSubscriptionStarted: (state) => state,
    fetchSubscriptionSucceeded: (state, action: PayloadAction<Subscription>) => {
      const subscription = action.payload;
      state.subscription = subscription;
      state.isMemberOrFreeTrialUser = state.isMemberOrFreeTrialUser || subscription.status === 'active';
    },
    fetchSubscriptionFailed: (state) => state,

    cancelSubscriptionStarted: (state) => {
      state.isCancelingSubscription = true;
    },
    cancelSubscriptionSucceeded: (state) => {
      state.isCancelingSubscription = false;
    },
    cancelSubscriptionFailed: (state) => {
      state.isCancelingSubscription = false;
    },
    clearSubscriptionError: (state) => {
      state.subscribeErrMessage = '';
    },
  },
  extraReducers: (builder) =>
    builder
      .addCase(authActions.signUpUserSucceeded, authSuccessReducer)
      .addCase(authActions.signInUserSucceeded, authSuccessReducer)
      .addCase(authActions.googleAuthSucceeded, authSuccessReducer)
      .addCase(authActions.facebookAuthSucceeded, authSuccessReducer)
      .addCase(authActions.refreshAuthSucceeded, authSuccessReducer)
      .addCase(authActions.resendEmailVerificationSucceeded, authSuccessReducer)
      .addCase(authActions.logoutUser, () => initialState),
});

export const { actions } = membershipSlice;

const persistConfig = {
  key: MEMBERSHIP,
  storage,
  blacklist: [
    'isSubscribing',
    'subscribeErrMessage',
    'isCancelingSubscription',
  ],
};

export const membershipReducer = persistReducer(
  persistConfig,
  membershipSlice.reducer,
);

export const selectMembership = (state: RootState): State => state[MEMBERSHIP];
