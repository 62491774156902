import { useMemo } from 'react';
import type { DefaultTheme } from 'styled-components';

import { useResponsiveFontSize } from 'common/hooks/useResponsiveFontSize';

export const CURRENCY = 'usd';
// Set your amount limits: Use float for decimal currencies and
// Integer for zero-decimal currencies: https://stripe.com/docs/currencies#zero-decimal.
export const MIN_AMOUNT = 5.0;
export const MAX_AMOUNT = 5000.0;
export const AMOUNT_STEP = 5.0;

export const useOptions = ({ colors }: DefaultTheme) => {
  const fontSize = useResponsiveFontSize();
  const options = useMemo(
    () => ({
      style: {
        base: {
          fontSize,
          color: colors.colorText,
          letterSpacing: '0.025em',
          fontFamily: 'Inter, sans-serif',
          '::placeholder': {
            color: '#aab7c4',
          },
        },
        invalid: {
          color: '#9e2146',
        },
      },
    }),
    [fontSize, colors.colorText],
  );

  return options;
};
