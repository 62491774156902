import React, { useState } from 'react';
import styled from 'styled-components';
import {
  Button,
  Div,
  InputLabel,
  ShowAndEditField,
  StyledCheckIcon,
  StyledCloseIcon,
} from 'common/components/styled-components';

const ButtonContainer = styled<any>(Div)`
  display: flex;
  justify-content: flex-end;
  right: 0;
  position: absolute;
  z-index: 1000;
`;

const InputContainer = styled<any>(Div)`
  position: relative;
  margin: 2rem;
`;

const FieldButton = styled<any>(Button)`
  background: ${({ theme: { colors } }) => colors.colorInputBg};
  margin-top: 5px;
  margin-left: 10px;
  padding: 10px;
  :hover {
    box-shadow: 2px 2px 5px ${({ theme: { colors } }) => colors.colorPrimary3};
  }
  box-shadow: 5px 5px 7px ${({ theme: { colors } }) => colors.colorPrimary3};
`;

interface Props {
  required?: boolean;
  name: string;
  value: string | number;
  label?: string;
  placeholder?: string;
  onSave: any;
  onCancel?: any;
  onChange: any;
  type?: string;
}

// TODO: There is a bug in handling focus state for this component
//  when a user selects on and then selects another without clicking away.
//  The walk around is closing or confirming or clicking away from
//  each field before selecting another
const SubmitInput = ({
  name,
  type = 'input',
  label,
  value,
  placeholder,
  onSave,
  onChange,
  ...rest
}: Props) => {
  const [isActive, setIsActive] = useState(false);

  const handleSave = (e) => {
    e.preventDefault();
    onSave({ name, value });
    setIsActive(!isActive);
  };

  const handleCancel = (e) => {
    e.preventDefault();
    if (!e.relatedTarget) {
      setIsActive(!isActive);
    }
  };

  const handleFocus = (e) => {
    e.preventDefault();
    setIsActive(!isActive);
  };

  return (
    <InputContainer>
      <InputLabel>{label}</InputLabel>
      <ShowAndEditField
        onBlur={(e) => handleCancel(e)}
        onFocus={(e) => handleFocus(e)}
        onChange={(e) => onChange(e)}
        name={name}
        value={value}
        placeholder={placeholder}
        type={type}
        autoComplete="off"
        {...rest}
      />

      {isActive && (
        <ButtonContainer>
          {/* TODO: Make use of "X" and "√" icons here. App currently crashes when SVGs are used */}
          <FieldButton onClick={(e) => handleSave(e)}>
            <StyledCheckIcon />
          </FieldButton>
          <FieldButton onClick={() => setIsActive(!isActive)}>
            <StyledCloseIcon />
          </FieldButton>
        </ButtonContainer>
      )}
    </InputContainer>
  );
};

export default SubmitInput;
