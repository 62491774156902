import DashboardPage from './pages/DashboardPage';
import EditBookPage from './pages/EditBookPage';

export { ADMIN } from './constants';
export {
  fetchBook,
  fetchBookChapterAction,
  fetchBooks,
} from './state/admin.actions';
export { adminReducer, initialState } from 'modules/admin/state/admin.slice';
export type { State } from 'modules/admin/state/admin.slice';

export { DashboardPage, EditBookPage };
export { selectAdmin } from 'modules/admin/state/admin.slice';
