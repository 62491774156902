import styled from 'styled-components';
import React from 'react';
import { colors } from 'app/globalStyles/variables';

interface MakerProps {
  color?: string;
  isSelected?: boolean;
  selectHighlight?: (event: any) => void;
  children: string;
}
export const Colors = [
  'yellow',
  'green',
  // 'blue',
  // 'orange',
  'purple',
  'transparent',
] as const;

export type HighlightColor = typeof Colors[number];

export const highlightColors: { [key in HighlightColor]: string } = {
  yellow: '#FFFE00',
  green: '#A2FFA7',
  // blue: '#80C3F8',
  purple: '#FF94EE',
  // orange: '#F8B871',
  transparent: colors.colorPrimary4,
};


const StyledMarker = styled.mark<{ isSelected?: boolean; color?: string }>`
  background: ${({ color }) =>
    (color ? highlightColors[`${color}`] : 'transparent')};
  cursor: pointer;
  font-size: inherit;
  line-height: inherit;
  color: #1e2c46;
`;

export const Marker = ({
  color,
  selectHighlight,
  children,
  isSelected,
}: MakerProps) => (
  <StyledMarker onClick={selectHighlight} isSelected={isSelected} color={color}>
    {children}
  </StyledMarker>
);
