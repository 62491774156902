import styled from 'styled-components';
import { setFont } from 'app/globalStyles/variables';
import {
  isTabletOrLarger,
  isTabletOrSmaller,
  isTablet,
  isLargeTablet,
  isDesktopOrLarger,
} from 'app/globalStyles/screens';
import { SecondaryButton } from 'common/components/styled-components';
import { Check } from 'styled-icons/entypo';
import { Loader } from 'common/components/Loader';

export const StyledChapterContents = styled.div`
  background: ${({ theme: { colors } }) => colors.colorBackground};
  padding-top: 2.6rem;
  padding-bottom: 15rem;
  width: 85%;
  margin: 0 auto;
  min-height: 100vh;
  position: relative;
  ${isTablet`width: 86%`};
  ${isLargeTablet`width: 650px`};
  ${isDesktopOrLarger`
    width: 550px;
  `}
`;

export const StyledLockedChapterContent = styled<any>(StyledChapterContents)`
  min-height: unset;
  height: 60vh;
  overflow-y: hidden;
`;

export const ChapterTitle = styled.h2`
  font-family: ${setFont.main};
  color: ${({ theme: { colors } }) => colors.colorTitle};
  font-weight: 400;
  font-size: 2.2rem;
  margin: 0 auto 0.5rem auto;
  text-align: center;
  ${isTabletOrSmaller`
    width: 75%;
  `};
  ${isTabletOrLarger`
    font-size: 2.8rem;
  `}
`;

export const TitleUnderline = styled.div`
  width: 15%;
  text-align: left;
  border-bottom: 2px solid ${({ theme: { colors } }) => colors.colorText};
  margin: 5px auto 3rem auto;
`;

const lineHeight = (fontSize: number) => 1.4 * fontSize;

export const StyledVerses = styled.div`
  position: relative;
`;

export const StyledVerse = styled.div<{
  fontFamily: string;
  fontSize: number;
}>`
  position: relative;
  font-family: ${({ fontFamily }) => fontFamily};
  font-size: ${({ fontSize }) => `${fontSize}rem`};
  line-height: ${({ fontSize }) => lineHeight(fontSize)};
  letter-spacing: ${({ fontFamily }) => (fontFamily === 'Inter' ? '-0.3px' : 0)}; // todo
  color: ${({ theme: { colors } }) => colors.colorText};
  background: ${({ theme: { colors } }) => colors.colorBackground};
  text-align: left;
  white-space: pre-wrap;
  margin-bottom: ${({ fontSize }) => `${lineHeight(fontSize)}rem`};
`;

export const DoneIcon = styled(Check)`
  width: 18px;
  margin-right: 5px;
  margin-bottom: 5px;
  color: ${({ theme: { colors } }) => colors.colorSuccess};
`;

export const StyledMarkRead = styled<any>(SecondaryButton)`
  padding: 10px 20px;
`;

export const ReadCount = styled.span`
  padding: 5px 8px;
  margin-left: 12px;
  border-radius: 50%;
  font-size: 12px;
  background: ${({ theme: { colors } }) => colors.colorSuccess};
  opacity: .8;
  color: ${({ theme: { colors } }) => colors.colorTextWhite};
`;

export const ChapterLoader = styled<any>(Loader)`
  margin-top: 40px;
`;
