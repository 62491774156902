import React from 'react';
import { useMediaQuery } from 'react-responsive';
import {
  StyledBookDetails,
  styles,
  ImageSection,
  Header,
  SubHeader,
  BuyButton,
  Content,
  TableOfContent,
  TableOfContentItem,
} from 'modules/books/components/__styles__/BookDetails';
import Share from 'common/components/Share';
import { routeAs } from 'common/utils/routes';
import type { Chapter } from 'modules/reading/utils/api/chapterApiUtils';
import type { Book } from '../utils/booksApiUtils';

export type Props = {
  book: Book;
};

// eslint-disable-next-line no-return-assign
const addDefaultSrc = (e): string =>
  ((e.target as HTMLInputElement).src = '/defaultBookImg.jpg');

export const BookDetails = ({ book }: Props) => {
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const {
    imageUrl,
    displayTitle,
    translator,
    source,
    author,
    chapters,
    description,
  } = book as Book;

  const firstChapter = (book.chapters as Chapter[])[0];
  const bookTitle = (title: string, chapter: Chapter) => {
    switch (true) {
      case title.includes('Letters from a Stoic'): {
        return `Letter ${chapter.number}: ${chapter.title}`;
      }
      case title.includes('Meditations') || title.includes('Poems'): {
        return chapter.title;
      }
      case title.includes('Discourses'): {
        return `${chapter.number}: ${chapter.title}`;
      }
      case title.includes('As a Man Thinketh'): {
        return `${chapter.number}: ${chapter.title}`;
      }
      default:
        return `Chapter ${chapter.number}`;
    }
  };

  return (
    <StyledBookDetails>
      <ImageSection href="#">
        <img
          src={`/books/${imageUrl}`}
          onError={addDefaultSrc}
          alt="Default img"
        />

        {isMobile && (
          <>
            <div className="headings">
              <Header>{displayTitle}</Header>
              <SubHeader>{`by ${author}`}</SubHeader>
            </div>
            {description && <Content>{description}</Content>}
          </>
        )}

        <div className="CTA">
          <BuyButton
            href={routeAs.chapter(firstChapter.slug)}
          >
            Start Reading
          </BuyButton>

          <div className="shareBtn">
            <Share
              title={displayTitle}
              quote={translator}
              shareUrl={source}
              buttonStyle={styles}
            >
              <span>Share</span>
            </Share>
          </div>
        </div>

        {description && isMobile && (
          <hr
            style={{
              margin: '25px 0px',
              width: '100%',
            }}
          />
        )}
      </ImageSection>

      <TableOfContent>
        {!isMobile && (
          <>
            <div className="headings">
              <Header>{displayTitle}</Header>
              <SubHeader>{`by ${author}`}</SubHeader>
            </div>
            {description && (
              <Content>
                {description}
                <hr
                  style={{
                    margin: '20px 0px',
                  }}
                />
              </Content>
            )}
          </>
        )}
        <div style={{ width: '100%', marginBottom: '100px' }}>
          <h2>Table Of Content</h2>
          {(chapters as Chapter[]).map((chapter: Chapter) => (
            <TableOfContentItem href={routeAs.chapter(chapter.slug)} key={chapter.id}>
              {bookTitle(displayTitle, chapter)}
            </TableOfContentItem>
          ))}

          <h6 style={{ fontSize: '18px', marginTop: '20px' }}>Source: </h6>
          <TableOfContentItem href={book.source}>
            {book?.source}
          </TableOfContentItem>
        </div>
      </TableOfContent>
    </StyledBookDetails>
  );
};
