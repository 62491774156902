import * as api from 'modules/reading/utils/api/highlightsApiUtils';
import type { AppThunk } from 'app/state/store';
import type { AxiosResponse } from 'axios';
import { getHighlightId } from 'modules/highlights/utils';
import { actions } from 'modules/reading/state/slices/highlights.slice';
import type { HighlightCreationParams } from 'modules/highlights/state/highlights.actions';
import { actions as highlightsActions } from 'modules/highlights/state/highlights.slice';

export type HighlightProps = { highlightId: number; color: string; verseId: number };

export const addHighlight = (
  highlight: HighlightCreationParams,
): AppThunk => async (dispatch): Promise<void | AxiosResponse<any>> => {
  try {
    dispatch(actions.addHighlightStarted({
      ...highlight,
      // TODO: Why is id set to number and value is string here?
      id: getHighlightId(highlight.startOffset, highlight.endOffset),
    }));
    const data = await api.addHighlight(highlight);
    dispatch(actions.addHighlightSucceeded(data));
    dispatch(highlightsActions.addUnfetchedHighlight(data.id));
  } catch (err) {
    if (err && err.response) {
      dispatch(actions.addHighlightFailed(err.response.data));
    }
    throw err;
  }
};

export const fetchChapterHighlights = (chapterId: number): AppThunk =>
  async (dispatch): Promise<void | AxiosResponse<any>> => {
    try {
      dispatch(actions.fetchChapterHighlightsStarted());
      const data = await api.fetchChapterHighlights(chapterId);
      dispatch(actions.fetchChapterHighlightsSucceeded(data));
    } catch (err) {
      if (err && err.response) {
        dispatch(actions.fetchChapterHighlightsFailed());
      }
      throw err;
    }
  };

export const updateHighlight = (
  { highlightId, color, verseId }: HighlightProps
): AppThunk =>
  async (dispatch): Promise<void | AxiosResponse<any>> => {
    try {
      dispatch(actions.updateHighlightStarted({ highlightId, color, verseId }));
      const data = await api.updateHighlight({ highlightId, color, verseId });
      dispatch(actions.updateHighlightSucceeded(data));
    } catch (err) {
      if (err && err.response) {
        dispatch(actions.updateHighlightFailed(err.response.data));
      }
      throw err;
    }
  };

export const removeHighlight = (highlightId: number): AppThunk<any> | any =>
  async (dispatch): Promise<void> => {
    dispatch(actions.removeHighlightStarted());
    try {
      const data = await api.removeHighlight(highlightId);
      dispatch(actions.removeHighlightSucceeded(data));
    } catch (err) {
      if (err && err.response) {
        dispatch(actions.removeHighlightFailed());
      }
      throw err;
    }
  };
