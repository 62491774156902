import * as React from 'react';
import type { NextRouter } from 'next/router';
import { withRouter } from 'next/router';
import { NoBookmarks } from 'static/assets';
import { routeTo } from 'common/utils/routes';
import * as S from 'modules/profile/components/styledComponents';

export interface Props {
  router: NextRouter;
}

export const BookmarksEmptyMsg = ({ router }: Props) => (
  <S.NoActivityPage>
    <NoBookmarks />
    <S.NoActivityText>
      You dont have any bookmark yet. When reading, you can click on the verse
      number to bookmark the verse.
    </S.NoActivityText>
    <S.StartActivityButton
      type="button"
      onClick={() => router.push(routeTo.read)}
    >
      Start Reading
    </S.StartActivityButton>
  </S.NoActivityPage>
);

export default withRouter(BookmarksEmptyMsg);
