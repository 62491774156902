// TODO: Consider moving this into the svgs directory
import BookmarksFillSvg from './svgs/Bookmarks-Fill-Dark.svg';
import BookmarksLineSvg from './svgs/Bookmarks-Line.svg';
import BookmarkFillSvg from './svgs/Bookmark_fill.svg';
import BookmarkLineSvg from './svgs/Bookmark_border.svg';
import NoBookmarksSvg from './svgs/NoBookmarks.svg';
import HighlightFillSvg from './svgs/Highlight-Fill-Dark.svg';
import HighlightLineSvg from './svgs/Highlight-Line.svg';
import NoHighlightsSvg from './svgs/NoHighlights.svg';
import NoteFillSvg from './svgs/Note-Fill.svg';
import NoteLineSvg from './svgs/Note-Line.svg';
import NoNotesSvg from './svgs/NoNotes.svg';
import CloseIconSvg from './svgs/Close.svg';
import WriteFillSvg from './svgs/Write-Fill-Dark.svg';
import WriteLineSvg from './svgs/Write-Line-Dark.svg';
import ShareFillSvg from './svgs/Share-Fill-Dark.svg';
import ShareLineSvg from './svgs/Share-Line.svg';
import FacebookLogoSvg from './svgs/Facebook-logo.svg';
import GoogleLogoSvg from './svgs/Google-logo.svg';
import UserAvatarSvg from './svgs/UserAvatar.svg';
import SettingsFillSvg from './svgs/Settings-Fill.svg';
import SettingsLineSvg from './svgs/Settings-Line.svg';
import RadioButtonUncheckedSvg from './svgs/Radio_button_unchecked.svg';
import CheckCircleLineSvg from './svgs/Check_circle_outline.svg';
import DangerZoneSvg from './svgs/DangerZone.svg';
import SecuritySettingsSvg from './svgs/SecuritySettings.svg';
import SecurityLine from './svgs/SecurityLine.svg';
import DimSvg from './svgs/Dim.svg';
import DarkMode2Svg from './svgs/DarkMode2.svg';
import HomeLineSvg from './svgs/Home Line.svg';
import HomeFillSvg from './svgs/Home Filled.svg';
import JournalLineSvg from './svgs/Journal-Line.svg';
import JournalFillSvg from './svgs/Journal-Fill.svg';
import OpenQuoteSvg from './svgs/quotationIcon.svg';
import AllBooksFillSvg from './svgs/All-Books.svg';
import BookFillSvg from './svgs/Book-Fill.svg';
import DeleteLineSvg from './svgs/Delete-Line.svg';
import DonateBannerSvg from './svgs/Donate Banner.svg';
import DonateBannerDimSvg from './svgs/Donate Banner Dim.svg';

export const BookmarksFill = BookmarksFillSvg;
export const BookmarkFill = BookmarkFillSvg;
export const BookmarkLine = BookmarkLineSvg;
export const BookmarksLine = BookmarksLineSvg;
export const NoBookmarks = NoBookmarksSvg;
export const HighlightFill = HighlightFillSvg;
export const HighlightLine = HighlightLineSvg;
export const NoHighlights = NoHighlightsSvg;
export const NoteFill = NoteFillSvg;
export const NoteLine = NoteLineSvg;
export const CloseIcon = CloseIconSvg;
export const WriteFill = WriteFillSvg;
export const WriteLine = WriteLineSvg;
export const ShareFill = ShareFillSvg;
export const ShareLine = ShareLineSvg;
export const FacebookLogo = FacebookLogoSvg;
export const GoogleLogo = GoogleLogoSvg;
export const UserAvatar = UserAvatarSvg;
export const SettingsFill = SettingsFillSvg;
export const SettingsLine = SettingsLineSvg;
export const RadioButtonUnchecked = RadioButtonUncheckedSvg;
export const CheckCircleLine = CheckCircleLineSvg;
export const DangerZone = DangerZoneSvg;
export const NoNotes = NoNotesSvg;
export const SecuritySettings = SecuritySettingsSvg;
export const Security = SecurityLine;
export const HalfLightDown = DimSvg;
export const MoonStar = DarkMode2Svg;
export const HomeFill = HomeFillSvg;
export const HomeLine = HomeLineSvg;
export const JournalLine = JournalLineSvg;
export const JournalFill = JournalFillSvg;
export const OpenQuote = OpenQuoteSvg;
export const AllBooksFill = AllBooksFillSvg;
export const BookFill = BookFillSvg;
export const DeleteLine = DeleteLineSvg;
export const DonateBanner = DonateBannerSvg;
export const DonateBannerDim = DonateBannerDimSvg;
