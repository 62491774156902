import Router from 'next/router';
import { clearToken } from 'modules/auth/utils/authApiUtils';
import { routeTo } from 'common/utils/routes';

export const completeAuth = (): void => {
  switch (Router.pathname) {
    case routeTo.login:
    case routeTo.signup:
    case routeTo.landing:
      Router.push(routeTo.home);
      break;
    case routeTo.chapter:
      Router.push(routeTo.read);
      break;
    case routeTo.read:
      break;
    default:
    // Router.push(routeTo.home);
  }
};

export const logout = async (signOut: () => void): Promise<void> => {
  clearToken();
  signOut(); // Signs out and removes user details in redux
  // to support logging out from all windows
  window.localStorage.setItem('logout', `${Date.now()}`);
  switch (Router.pathname) {
    case routeTo.home:
    case routeTo.journal:
    case routeTo.chapter:
      Router.push(routeTo.read);
      break;
    default:
      Router.push(Router.asPath);
  }
};
