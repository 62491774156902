import React from 'react';
import { Marker } from 'modules/highlights/components/HighlightMarker';

export const renderSingleHighlight = (
  color: string | undefined,
  startOffset: number,
  endOffset: number,
  verseText: string,
  extraLength = 100,
): Array<string | JSX.Element> => {
  const newText: Array<string | JSX.Element> = [];

  // todo: can be simplified.
  if (startOffset > extraLength) {
    newText.push(
      `...${verseText.slice(startOffset - extraLength, startOffset)}`,
    );
  } else {
    newText.push(verseText.slice(0, startOffset));
  }

  const quote = verseText.slice(startOffset, endOffset);

  newText.push(
    <Marker color={color} key={quote}>
      {quote}
    </Marker>,
  );

  // todo: can be simplified.
  if (verseText.length - endOffset > extraLength) {
    newText.push(`${verseText.slice(endOffset, endOffset + extraLength)}...`);
  } else {
    newText.push(verseText.slice(endOffset, verseText.length));
  }

  return newText;
};

export const getHighlightId = (startOffset: number, endOffset: number) =>
  `${startOffset}~${endOffset}`;
