import { createSlice } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';
import type { PayloadAction } from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage';
import { READING, SETTINGS } from 'modules/reading/constants';
import type { RootState } from 'app/state/store';

export interface State {
  fontSize: number;
  fontFamily: string;
  shouldShowVerseNumbers: boolean;
}

export const initialState: State = {
  fontSize: 1.8, // in rem
  fontFamily: 'Avenir',
  shouldShowVerseNumbers: false
};

const settingsSlice = createSlice({
  name: SETTINGS,
  initialState,
  reducers: {
    setFontFamily: (state, action: PayloadAction<string>) => ({
      ...state,
      fontFamily: action.payload,
    }),

    setFontSize: (state, action: PayloadAction<number>) => ({
      ...state,
      fontSize: action.payload,
    }),

    showVerseNumbers: (state, action: PayloadAction<boolean>) => ({
      ...state,
      shouldShowVerseNumbers: action.payload,
    }),
  }
});

export const { actions } = settingsSlice;

const readerPersistConfig = {
  key: SETTINGS,
  storage,
};

export const settingsReducer = persistReducer(
  readerPersistConfig,
  settingsSlice.reducer
);

export const selectReaderSettings = (state: RootState): State =>
  state[READING][SETTINGS];
