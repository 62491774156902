import cookie from 'js-cookie';
import type {
  AuthResponse,
  FacebookAuthParams,
  GoogleAuthParams,
  UserParams,
} from 'modules/auth/state/auth.actions';
import { apiCall } from 'common/utils/api-helpers';
import jwtDecode from 'jwt-decode';

interface DecodedJwtType {
  userId: number;
  email: string;
  isMemberOrFreeTrialUser: boolean;
}

export type AuthServiceResponse = AuthResponse & {
  isMemberOrFreeTrialUser: boolean;
};

export const setToken = (token: string): void => cookie.set('token', token);
export const clearToken = (): void => cookie.remove('token');

const decodeJWT = (token: string): DecodedJwtType => {
  const { userId, email, isMemberOrFreeTrialUser } = jwtDecode(token);
  return { userId, email, isMemberOrFreeTrialUser };
};

const getAuthResponse = (data: AuthResponse): AuthServiceResponse => {
  const { isMemberOrFreeTrialUser } = decodeJWT(data.token);
  return { ...data, isMemberOrFreeTrialUser };
};

export const signUpUser = async (
  userDetails: UserParams,
): Promise<AuthServiceResponse> => {
  const { data } = await apiCall('auth/sign_up', 'post', userDetails);
  setToken(data.token);
  return getAuthResponse(data);
};

export const signInUser = async (
  userDetails: UserParams,
): Promise<AuthServiceResponse> => {
  const { data } = await apiCall('auth/sign_in', 'post', userDetails);
  setToken(data.token);
  return getAuthResponse(data);
};

export const signInWithGoogle = async (
  authDetails: GoogleAuthParams,
): Promise<AuthServiceResponse> => {
  const { data } = await apiCall('auth/google', 'post', authDetails);
  setToken(data.token);
  return getAuthResponse(data);
};

export const signInWithFacebook = async (
  authDetails: FacebookAuthParams,
): Promise<AuthServiceResponse> => {
  const { data } = await apiCall('auth/facebook/token', 'post', authDetails);
  setToken(data.token);
  return getAuthResponse(data);
};

export const refreshAuth = async (
  token?: string,
): Promise<AuthServiceResponse> => {
  const { data } = await apiCall('auth/refresh_auth_token', 'get', null, token);
  setToken(data.token);
  return getAuthResponse(data);
};

export const sendPasswordReset = async (
  email: string,
): Promise<{ message: string }> => {
  const { data } = await apiCall('auth/forgot_password', 'post', { email });
  return data;
};

export const resetPassword = async (
  token: string,
  password: string,
): Promise<void> => {
  await apiCall(`auth/reset_password/${token}`, 'put', {
    password,
  });
};

export const confirmEmailAddress = async (
  token: string,
): Promise<AuthResponse> => {
  const { data } = await apiCall(`auth/confirm_email/${token}`, 'post');
  return data;
};

export const resendEmailVerification = async (
  email: string,
): Promise<any> => {
  const { data } = await apiCall('auth/resend_email_verification', 'post', {
    email,
  });
  return data;
};
