import { PageLoader } from 'common/components/Loader';
import React from 'react';
import { useRouter } from 'next/router';
import { routeTo } from 'common/utils/routes';
import type { Book } from 'modules/books/utils/booksApiUtils';
import type { Chapter } from 'modules/reading/utils/api/chapterApiUtils';
import { BookTitle, Main, Header } from './__styles__/DashboardContent.styles';


interface Props {
    book: Book | null;
    handleChapterClick: (chapter: Chapter, index: number) => void;
    selectedChapter: Chapter | null;
  }

function Sidebar({
  book, handleChapterClick, selectedChapter }: Props) {
  const router = useRouter();

  const handleBackClick = () => {
    router.push(routeTo.adminBooks);
  };

  return (
    <Main>
      <Header>
        <div>Chapters</div>
        <button type="button" onClick={() => handleBackClick()}>⬅︎ Back </button>
      </Header>
      {
      !book || !book.chapters?.length ? <PageLoader />
        : book.chapters?.map((chapter, index) => (
          <BookTitle
            key={chapter.id}
            activeTab={selectedChapter && selectedChapter.id === chapter.id}
            onClick={() => handleChapterClick(chapter as Chapter, index)}
          >
            { chapter.title }
          </BookTitle>
        ))
        }
    </Main>
  );
}

export default Sidebar;
