import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { getHighlightId } from 'modules/highlights/utils';
import { actions as authActions } from 'modules/auth/state/auth.slice';
import type { ChapterHighlights } from 'modules/reading/utils/readingUtils';
import { HIGHLIGHTS, READING } from 'modules/reading/constants';
import type { HighlightProps } from 'modules/reading/state/actions/highlights.actions';
import type { Highlight } from 'modules/reading/utils/api/highlightsApiUtils';
import type { RootState } from 'app/state/store';

export type State = {
  chapterHighlights: ChapterHighlights;
  addingHighlightError: string,
  updatingHighlightError: string,
};

export const initialHighlightsState: State = {
  chapterHighlights: {},
  addingHighlightError: '',
  updatingHighlightError: '',
};

export type DraftHighlight = {
  id: string;
  color?: string;
  verseId: number;
  userId?: number;
  startOffset: number;
  endOffset: number;
}

const highlightsSlice = createSlice({
  name: HIGHLIGHTS,
  initialState: initialHighlightsState,
  reducers: {
    fetchChapterHighlightsStarted: (state) => {
      state.chapterHighlights = {};
    },

    fetchChapterHighlightsSucceeded: (state, action: PayloadAction<Highlight[]>) => {
      const chapterHighlights = action.payload.reduce(
        (highlightMap, highlight) => {
          if (!(highlight.verseId in highlightMap)) {
            highlightMap[highlight.verseId] = [highlight];
          } else {
            highlightMap[highlight.verseId].push(highlight);
          }
          return highlightMap;
        },
        {},
      );
      return {
        ...state,
        chapterHighlights,
      };
    },
    fetchChapterHighlightsFailed: (state) => state,

    addHighlightStarted: (state, action: PayloadAction<DraftHighlight>) => {
      const highlight = action.payload;
      const verseHighlights = state.chapterHighlights[highlight.verseId];
      let chapterHighlights = {};
      if (!verseHighlights) {
        chapterHighlights = {
          ...state.chapterHighlights,
          [highlight.verseId]: [highlight],
        };
      } else {
        chapterHighlights = {
          ...state.chapterHighlights,
          [highlight.verseId]: [...verseHighlights, highlight],
        };
      }
      return {
        ...state,
        chapterHighlights,
      };
    },

    addHighlightSucceeded: (state, action: PayloadAction<Highlight>) => {
      const highlight = action.payload;
      const verseHighlights = state.chapterHighlights[highlight.verseId];
      let chapterHighlights = {};
      if (!verseHighlights) {
        chapterHighlights = {
          ...state.chapterHighlights,
          [highlight.verseId]: [highlight],
        };
      } else {
        const savedHighlights = verseHighlights.filter(
          (h) =>
            // @ts-ignore
            h.id !== getHighlightId(highlight.startOffset, highlight.endOffset),
        );
        chapterHighlights = {
          ...state.chapterHighlights,
          [highlight.verseId]: [...savedHighlights, highlight],
        };
      }
      return {
        ...state,
        chapterHighlights,
      };
    },

    addHighlightFailed: (state, action: PayloadAction<{ error: string }>) => {
      const { error } = action.payload;
      return {
        ...state,
        errorAddingHighlight: error,
      };
    },

    updateHighlightStarted: (state, action: PayloadAction<HighlightProps>) => {
      const updatedHighlight = action.payload;
      const verseHighlights = state.chapterHighlights[updatedHighlight.verseId];
      const highlights = verseHighlights.map((highlight) => {
        if (highlight.id === updatedHighlight.highlightId) {
          return {
            ...highlight,
            color: updatedHighlight.color,
          };
        }
        return highlight;
      });
      const chapterHighlights = {
        ...state.chapterHighlights,
        [updatedHighlight.verseId]: highlights,
      };
      return {
        ...state,
        chapterHighlights,
      };
    },

    updateHighlightSucceeded: (state, action: PayloadAction<Highlight>) => {
      const updatedHighlight = action.payload;
      const verseHighlights = state.chapterHighlights[updatedHighlight.verseId];
      const highlights = verseHighlights.map((highlight) => {
        if (highlight.id === updatedHighlight.id) {
          return updatedHighlight;
        }
        return highlight;
      });
      const chapterHighlights = {
        ...state.chapterHighlights,
        [updatedHighlight.verseId]: highlights,
      };

      return {
        ...state,
        chapterHighlights,
      };
    },

    updateHighlightFailed: (state, action: PayloadAction<{ error: string }>) => {
      const { error } = action.payload;
      return {
        ...state,
        updatingHighlightError: error,
      };
    },

    removeHighlightStarted: (state) => state,

    removeHighlightSucceeded: (state, action: PayloadAction<Highlight>) => {
      const highlight = action.payload;
      // highlight may not exist in current chapter
      const verseHighlights = state.chapterHighlights[highlight.verseId] || [];
      const highlights = {
        ...state.chapterHighlights,
        [highlight.verseId]: verseHighlights.filter(
          (h) => h.id !== highlight.id,
        ),
      };
      return {
        ...state,
        chapterHighlights: highlights,
      };
    },

    removeHighlightFailed: (state) => state,

  },
  extraReducers: (builder) =>
    builder
      .addCase(authActions.logoutUser, () => initialHighlightsState),
});

export const { actions } = highlightsSlice;

export const selectCurrentChapterHighlights = (state: RootState): State =>
  state[READING][HIGHLIGHTS];

export const highlightsReducer = highlightsSlice.reducer;
