import * as api from 'modules/reading/utils/api/bookmarksApiUtils';
import type { AppThunk } from 'app/state/store';
import type { AxiosResponse } from 'axios';
import { actions } from 'modules/reading/state/slices/bookmarks.slice';

export const saveBookmark = (
  chapterId: number,
  verseNumber: number,
): AppThunk => async (dispatch): Promise<void | AxiosResponse<any>> => {
  try {
    dispatch(actions.saveBookmarkStarted());
    const data = await api.saveBookmark(chapterId, verseNumber);
    dispatch(actions.saveBookmarkSucceeded(data));
  } catch (err) {
    if (err && err.response) {
      dispatch(actions.saveBookmarkFailed());
    }
    throw err;
  }
};

export const fetchChapterBookmark = (chapterId: number): AppThunk => async (
  dispatch,
): Promise<void | AxiosResponse<any>> => {
  try {
    dispatch(actions.fetchChapterBookmarkStarted());
    const data = await api.fetchChapterBookmark(chapterId);
    dispatch(actions.fetchChapterBookmarkSucceeded(data));
  } catch (err) {
    if (err && err.response.data) {
      dispatch(actions.fetchChapterBookmarkFailed());
    }
    throw err;
  }
};
