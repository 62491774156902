import DropDownMenu from 'common/components/DropDownMenu';
import {
  isTabletOrLarger,
  isDesktopOrLarger,
} from 'app/globalStyles/screens';
import ShowMoreText from 'react-show-more-text';
import { setFlex } from 'app/globalStyles/variables';
import {
  HR,
  Option,
  EditIcon,
  DeleteIcon,
  OptionText,
  CardHeader,
  DeleteOption,
  CardTimeStamp,
  CardHeading,
  ActionOptions,
} from 'modules/profile/components/styledComponents';
import React from 'react';
import Moment from 'react-moment';
import styled, { css } from 'styled-components';
import type { Journal } from '../utils/journalsApiUtils';

export const JournalCard = styled.div`
  width: 100%;
  min-height: 200px;
  font-size: 16px;
  line-height: 1.5;
  border-radius: 5px;
  border: 1px solid ${({ theme: { colors } }) => colors.colorBorder};
  padding: 20px;
  position: relative;
  ${setFlex({ x: 'flex-start', y: 'stretch' })}
  flex-direction: column;
  margin: 1rem 0;
  ${isTabletOrLarger(css`
    width: calc(100% / 2 - 2rem);
    margin: 1rem;
  `)}
  ${isDesktopOrLarger(css`
    width: calc(100% / 3 - 2rem);
    margin: 1rem;
  `)}
`;

const CardContent = styled.p`
  &:hover {
    cursor: pointer;
  }
`;

const CardFooter = styled.footer`
  margin-top: 0.8rem;
  ${setFlex({ x: 'space-between', y: 'start' })}
  justify-self: flex-end;
  font-size: 1.2rem;
  opacity: 1;
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  padding: 0 20px;
`;

export interface Props {
  journal: Journal;
  takeToEditor: (e: any, journal: Journal) => void;
  openDeleteModal: (e: any, journal: Journal) => void;
  goToJournal: (journal: Journal) => void;
  isRecentJournal: boolean;
}

const JournalCardV2 = ({
  journal,
  takeToEditor,
  goToJournal,
  openDeleteModal,
}: Props) => {
  const onEdit = (e: any) => {
    e.stopPropagation();
    takeToEditor(e, journal);
  };

  const onDelete = (e: any) => {
    e.stopPropagation();
    openDeleteModal(e, journal);
  };

  return (
    <JournalCard>
      <CardHeader onClick={() => goToJournal(journal)}>
        {journal.title && <CardHeading>{journal.title}</CardHeading>}
      </CardHeader>
      <CardContent onClick={() => goToJournal(journal)}>
        <ShowMoreText more="" less="" lines={3} expanded={false}>
          {journal.content}
        </ShowMoreText>
      </CardContent>
      <CardFooter>
        <CardTimeStamp>
          <Moment fromNow date={journal.updatedAt} />
        </CardTimeStamp>
        <DropDownMenu buttonStyles={{ height: 'unset' }}>
          <ActionOptions>
            <Option onClick={onEdit}>
              <EditIcon />
              <OptionText>Edit</OptionText>
            </Option>
            <HR />
            <DeleteOption onClick={onDelete}>
              <DeleteIcon />
              <OptionText>Delete</OptionText>
            </DeleteOption>
          </ActionOptions>
        </DropDownMenu>
      </CardFooter>
    </JournalCard>
  );
};

export default JournalCardV2;
