import { apiCall } from 'common/utils/api-helpers';
import { actions as AuthActions } from 'modules/auth/state/auth.slice';
import { actions as chapterActions } from 'modules/reading/state/slices/chapter.slice';
import type {
  ForkEffect,
} from 'redux-saga/effects';
import { call, put, takeEvery, select } from 'redux-saga/effects';
import type { RootState } from 'app/state/store';
import { CHAPTER, READING } from 'modules/reading/constants';

const {
  signInUserSucceeded,
  signUpUserSucceeded,
  googleAuthSucceeded,
  facebookAuthSucceeded,
  logoutUser,
} = AuthActions;

// Should be imported from a selector file.
const getCurrentChapterSlug = (state: RootState) =>
  state[READING][CHAPTER].currentChapterSlug;

function* reloadCurrentChapter() {
  try {
    yield put(chapterActions.reloadCurrentChapterStarted());
    const currentChapterSlug = yield select(getCurrentChapterSlug);
    const { data } = yield call(
      apiCall,
      `chapters/${currentChapterSlug}`,
      'get',
    );
    yield put(chapterActions.reloadCurrentChapterSucceeded(data));
  } catch (err) {
    if (err && err.response) {
      yield put(chapterActions.reloadCurrentChapterFailed());
    }
  }
}

export function* reloadCurrentChapterSaga(): Generator<
  ForkEffect<never>,
  void,
  string
  > {
  yield takeEvery(
    [
      signInUserSucceeded,
      signUpUserSucceeded,
      googleAuthSucceeded,
      facebookAuthSucceeded,
      logoutUser,
    ],
    reloadCurrentChapter,
  );
}
